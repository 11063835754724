import React, { useEffect } from 'react'
import { IngredientCategoryRecord } from '../../../types/records/ingredient-record'
import { RecipeIngredientInputParam } from '../../../types/records/recipe-record'
import { SizeRecord } from '../../../types/records/size-record'
import {
  SizeScalingTypeRecord,
  SizeScalingType,
} from '../../../types/records/utensil-record'
import { Grid, Typography, InputLabel, Checkbox, Button } from '@mui/material'
import { TextInput, NumberInput, required } from 'react-admin'
import {
  labelGrayOutSx,
  textInputSx,
  numberInputSx,
  selectInputSx,
} from '../../../assets/sx/form'
import { modalSubtitleSx } from '../../../assets/sx/modalSx'
import { ReactComponent as CrossIcon } from '../../../assets/images/cross.svg'
import CustomPlaceholderSelectInput from '../../customComponent/CustomPlaceholderSelectInput'

type Props = {
  recipeIngredientInputParam: RecipeIngredientInputParam
  ingredientCategory: IngredientCategoryRecord
  source: string
  label: string
  sizes: SizeRecord[]
  recipeIngredientCategoryType: string
  validate: boolean
  sizeScalingType: SizeScalingTypeRecord
  findTargetSizeScalingType: (
    ingredientID: number,
    recipeIngredientCategoryType: string,
  ) => void
  handleOnChangeSingleIngredientID: (
    event: React.ChangeEvent<HTMLInputElement>,
    recipeIngredientCategoryType: string,
  ) => void
  handleOnChangeSingleSizeScalingPresetID: (
    event: React.ChangeEvent<HTMLInputElement>,
    recipeIngredientCategoryType: string,
  ) => void
  isCheckedManualInput: boolean
  handleOnChangeSingleCheckedManualInput: (
    recipeIngredientCategoryType: string,
  ) => void
  handleOnChangeSingleNewSizeScalingPresetName: (
    event: React.ChangeEvent<HTMLInputElement>,
    recipeIngredientCategoryType: string,
  ) => void
  handleOnChangeSingleNewSizeScalingPresetCoefficient: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    recipeIngredientCategoryType: string,
  ) => void
  handleOnResetSingleRecipeIngredientInput: (
    recipeIngredientCategoryType: string,
  ) => void
}

const RecipeIngredientInput: React.FC<Props> = ({
  recipeIngredientInputParam,
  ingredientCategory,
  source,
  label,
  sizes,
  recipeIngredientCategoryType,
  validate,
  sizeScalingType,
  findTargetSizeScalingType,
  handleOnChangeSingleIngredientID,
  handleOnChangeSingleSizeScalingPresetID,
  isCheckedManualInput,
  handleOnChangeSingleCheckedManualInput,
  handleOnChangeSingleNewSizeScalingPresetName,
  handleOnChangeSingleNewSizeScalingPresetCoefficient,
  handleOnResetSingleRecipeIngredientInput,
}) => {
  useEffect(() => {
    if (recipeIngredientInputParam && recipeIngredientInputParam.ingredientID) {
      findTargetSizeScalingType(
        recipeIngredientInputParam.ingredientID,
        recipeIngredientCategoryType,
      )
    }
  }, [recipeIngredientInputParam])

  const pumpAndScoopList = [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' },
    { id: 8, name: '8' },
  ]

  const coefficientSelectInputTSX = (index: number) => {
    if (!recipeIngredientInputParam) {
      return <></>
    }
    const newSizeScalingPreset = recipeIngredientInputParam.newSizeScalingPreset

    return (
      <Grid container>
        <CustomPlaceholderSelectInput
          type="formInput"
          placeholder="選択"
          source={`${source}[newSizeScalingPreset[ingredientSizeCoefficients[${index}][coefficient]]]`}
          variant="outlined"
          label={false}
          sx={{ ...selectInputSx, minWidth: '90px' }}
          validate={required('エラー入力してください')}
          choices={pumpAndScoopList}
          defaultValue={
            newSizeScalingPreset?.ingredientSizeCoefficients[index].coefficient
              ? newSizeScalingPreset?.ingredientSizeCoefficients[index]
                  .coefficient
              : ''
          }
          onChange={(event) => {
            handleOnChangeSingleNewSizeScalingPresetCoefficient(
              event,
              index,
              recipeIngredientCategoryType,
            )
          }}
        />
        <Grid
          sx={{
            fontSize: '14px',
            color: '#757575',
            mt: '20px',
            ml: '10px',
            width: '60px',
          }}>
          <Grid>{sizeScalingType.name}</Grid>
        </Grid>
      </Grid>
    )
  }

  const coefficientNumberInputTSX = (index: number) => {
    if (!recipeIngredientInputParam) {
      return <></>
    }
    const newSizeScalingPreset = recipeIngredientInputParam.newSizeScalingPreset
    return (
      <Grid container>
        <NumberInput
          source={`${source}[newSizeScalingPreset[ingredientSizeCoefficients[${index}][coefficient]]]`}
          variant="outlined"
          label={false}
          sx={{ ...numberInputSx, width: '100px' }}
          validate={required('エラー入力してください')}
          placeholder="0"
          fullWidth
          min={1}
          defaultValue={
            newSizeScalingPreset?.ingredientSizeCoefficients[index].coefficient
              ? newSizeScalingPreset?.ingredientSizeCoefficients[index]
                  .coefficient
              : ''
          }
          onChange={(event) => {
            handleOnChangeSingleNewSizeScalingPresetCoefficient(
              event,
              index,
              recipeIngredientCategoryType,
            )
          }}
        />
        <Grid
          sx={{
            fontSize: '14px',
            color: '#757575',
            m: '8px',
          }}>
          {sizeScalingType.name}
        </Grid>
      </Grid>
    )
  }

  const whichNewSizeScalingPresetInputTSX = (
    index: number,
    sizeScalingTypeName: string,
  ) => {
    switch (sizeScalingTypeName) {
      case SizeScalingType.G:
        return coefficientNumberInputTSX(index)
      case SizeScalingType.ML:
        return coefficientNumberInputTSX(index)
      case SizeScalingType.Pump:
        return coefficientSelectInputTSX(index)
      case SizeScalingType.Scoop:
        return coefficientSelectInputTSX(index)
      default:
        return <></>
    }
  }

  const newSizeScalingPresetInputTSX = () => {
    return (
      <Grid>
        <Grid sx={{ mb: '8px', mt: '8px' }}>
          <InputLabel sx={labelGrayOutSx}>投入プリセット名</InputLabel>
          <TextInput
            source={`${source}[newSizeScalingPreset[name]]`}
            variant="outlined"
            label={false}
            sx={textInputSx}
            validate={required('エラー入力してください')}
            placeholder="入力してください"
            fullWidth
            onChange={(event) => {
              handleOnChangeSingleNewSizeScalingPresetName(
                event,
                recipeIngredientCategoryType,
              )
            }}
          />
        </Grid>
        <Grid wrap="wrap" container>
          {sizes.map((size, index) => (
            <Grid key={`size_{${size.name}}`} sx={{ width: '175px' }}>
              <InputLabel sx={labelGrayOutSx}>
                <Grid>{size.name}</Grid>
              </InputLabel>
              <>
                {whichNewSizeScalingPresetInputTSX(index, sizeScalingType.name)}
              </>
            </Grid>
          ))}
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid>
      <Typography sx={modalSubtitleSx}>{label}</Typography>
      <Grid>
        <Grid container justifyContent="space-between">
          <InputLabel sx={{ ...labelGrayOutSx, mt: 'auto', mb: 'auto' }}>
            材料を選択
          </InputLabel>
          <Button
            variant="text"
            onClick={() => {
              handleOnResetSingleRecipeIngredientInput(
                recipeIngredientCategoryType,
              )
            }}>
            <CrossIcon className="cross-icon" />
            <Grid sx={{ color: '#C80000' }}>削除</Grid>
          </Button>
        </Grid>
        <Grid>
          <CustomPlaceholderSelectInput
            type="formInput"
            placeholder="選択してください"
            source={`${source}[ingredientID]`}
            variant="outlined"
            label={false}
            sx={selectInputSx}
            choices={ingredientCategory ? ingredientCategory?.ingredients : []}
            fullWidth
            defaultValue={
              recipeIngredientInputParam?.ingredientID
                ? recipeIngredientInputParam?.ingredientID
                : ''
            }
            validate={validate ? required('エラー選択してください') : undefined}
            onChange={(e) => {
              handleOnChangeSingleIngredientID(e, recipeIngredientCategoryType)
              findTargetSizeScalingType(
                Number(e.target.value),
                recipeIngredientCategoryType,
              )
            }}
          />
        </Grid>
        {recipeIngredientInputParam && sizeScalingType && (
          <Grid>
            <Grid>
              <InputLabel sx={labelGrayOutSx}>投入量</InputLabel>
              <Grid display="flex">
                <Checkbox
                  onClick={() => {
                    handleOnChangeSingleCheckedManualInput(
                      recipeIngredientCategoryType,
                    )
                  }}
                  checked={isCheckedManualInput}
                />
                <Grid
                  sx={{
                    fontSize: '14px',
                    color: '#757575',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}>
                  手動で入力
                </Grid>
              </Grid>
              <Grid>
                {isCheckedManualInput ? (
                  newSizeScalingPresetInputTSX()
                ) : (
                  <CustomPlaceholderSelectInput
                    type="formInput"
                    placeholder="選択してください"
                    source={`${source}[sizeScalingPresetID]`}
                    variant="outlined"
                    label={false}
                    sx={selectInputSx}
                    choices={
                      sizeScalingType ? sizeScalingType.sizeScalingPresets : []
                    }
                    fullWidth
                    defaultValue={
                      recipeIngredientInputParam &&
                      recipeIngredientInputParam.sizeScalingPresetID
                        ? recipeIngredientInputParam.sizeScalingPresetID
                        : ''
                    }
                    validate={required('エラー選択してください')}
                    onChange={(e) => {
                      handleOnChangeSingleSizeScalingPresetID(
                        e,
                        recipeIngredientCategoryType,
                      )
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default RecipeIngredientInput
