import React from 'react'
import { Grid, InputLabel, Typography, Modal, Box } from '@mui/material'
import { IngredientRecord } from '../../../types/records/ingredient-record'
import { IngredientCategoryRecord } from '../../../types/records/ingredient-record'
import { UtensilRecord } from '../../../types/records/utensil-record'
import { TuningCustomizePresetRecord } from '../../../types/records/tuning-customize-preset-record'
import { SizeScalingPresetRecord } from '../../../types/records/recipe-record'
import { SizeRecord } from '../../../types/records/size-record'
import CancelButton from '../../button/CancelButton'
import { formatDate } from '../../../assets/utils/formData'
import {
  confirmGridContainerSx,
  confirmInputGridSx,
  modalWrapperSx,
  cancelButtonWrapperSx,
} from '../../../assets/sx/form'
import {
  modalTitleSx,
  modalLeftItemLabelSx,
  modalRightItemInputSx,
} from '../../../assets/sx/modalSx'
type Props = {
  ingredient: IngredientRecord
  ingredientCategories: IngredientCategoryRecord[]
  sizes: SizeRecord[]
  utensils: UtensilRecord[]
  sizeScalingPresets: SizeScalingPresetRecord[]
  tuningCustomizePresets: TuningCustomizePresetRecord[]
  open: boolean
  onClose: () => void
}

const ShowIngredientModal: React.FC<Props> = ({
  ingredient,
  sizeScalingPresets,
  tuningCustomizePresets,
  open,
  onClose,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalWrapperSx}>
        <Grid>
          <Typography sx={modalTitleSx}>材料を確認する</Typography>
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel sx={modalLeftItemLabelSx}>材料</InputLabel>
            <Grid sx={confirmInputGridSx}>{ingredient?.name}</Grid>
          </Grid>
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel sx={modalLeftItemLabelSx}>表示名</InputLabel>
            <Grid sx={modalRightItemInputSx}>{ingredient?.displayName}</Grid>
          </Grid>
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel sx={modalLeftItemLabelSx}>材料カテゴリ</InputLabel>
            <Grid sx={modalRightItemInputSx}>
              {ingredient?.ingredientCategory?.name}
            </Grid>
          </Grid>
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel sx={modalLeftItemLabelSx}>容器容量</InputLabel>
            <Grid sx={confirmInputGridSx}>
              {ingredient?.containerCapacityAmount + 'g'}
            </Grid>
          </Grid>
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel sx={modalLeftItemLabelSx}>画像</InputLabel>
            {ingredient?.imageURL != null ? (
              <img
                src={ingredient?.imageURL}
                alt="previewImage"
                width="64"
                height="64"
                style={{ borderRadius: '5px', border: '1px solid #ccc' }}
              />
            ) : (
              <Grid sx={confirmInputGridSx}> - </Grid>
            )}
          </Grid>
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel sx={modalLeftItemLabelSx}>投入器具</InputLabel>
            <Grid sx={confirmInputGridSx}>{ingredient?.utensil?.name}</Grid>
          </Grid>

          <Grid sx={confirmGridContainerSx} container>
            <InputLabel sx={modalLeftItemLabelSx}>提供開始日</InputLabel>
            <Grid sx={confirmGridContainerSx} container>
              <InputLabel sx={modalLeftItemLabelSx}>提供開始日</InputLabel>
              <Grid sx={modalRightItemInputSx}>
                {formatDate(ingredient?.offerStartAt)}
              </Grid>
            </Grid>
          </Grid>

          <Grid sx={confirmGridContainerSx} container>
            <InputLabel sx={modalLeftItemLabelSx}>提供終了日</InputLabel>
            <Grid sx={modalRightItemInputSx}>
              {formatDate(ingredient?.offerEndAt)}
            </Grid>
          </Grid>
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel sx={modalLeftItemLabelSx}>追加カスタマイズ</InputLabel>
            <Grid sx={confirmInputGridSx}>
              {ingredient && sizeScalingPresets
                ? sizeScalingPresets.find(
                    (preset) => preset.id === ingredient?.sizeScalingPresetID,
                  )?.name || '-'
                : null}
            </Grid>
          </Grid>
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel sx={modalLeftItemLabelSx}>
              量調整カスタマイズ
            </InputLabel>
            <Grid sx={confirmInputGridSx}>
              {ingredient && tuningCustomizePresets
                ? tuningCustomizePresets.find(
                    (preset) =>
                      preset.id === ingredient?.tuningCustomizePresetID,
                  )?.name || '-'
                : null}
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Box sx={cancelButtonWrapperSx}>
              <CancelButton onClick={onClose}>閉じる</CancelButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default ShowIngredientModal
