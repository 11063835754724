import React, { useState, useEffect } from 'react'
import {
  RecipeParams,
  InputTimingRecord,
  RecipeIngredientInputParam,
  SizeScalingPresetParam,
  RecipeSetting,
  RecipeIngredientCategoryType,
  RecipeRecord,
} from '../../../types/records/recipe-record'
import {
  SizeScalingTypeRecord,
  SizeScalingType,
} from '../../../types/records/utensil-record'
import { IngredientRecord } from '../../../types/records/ingredient-record'
import { TextInput, required } from 'react-admin'
import {
  Grid,
  Typography,
  Divider,
  InputLabel,
  Button,
  Select,
  MenuItem,
} from '@mui/material'
import RecipeIngredientInput from './RecipeIngredientInput'
import RecipeIngredientArrayInput from './RecipeIngredientArrayInput'
import {
  gridSx,
  labelGrayOutSx,
  selectInputSx,
  textInputSx,
  addButtonSx,
} from '../../../assets/sx/form'
import {
  modalTitleSx,
  modalSubtitleSx,
  modalDividerSx,
  modalLeftItemLabelSx,
} from '../../../assets/sx/modalSx'

import { ReactComponent as PlusIcon } from '../../../assets/images/plus_black.svg'
import { ReactComponent as CrossIcon } from '../../../assets/images/cross.svg'
import ValidateMessageInfo from '../../field/ValidateMessageInfo'
import CustomPlaceholderSelectInput from '../../customComponent/CustomPlaceholderSelectInput'

type Props = {
  action: 'create' | 'update'
  formData: RecipeParams
  setFormData: React.Dispatch<React.SetStateAction<RecipeParams>>
  inputTimingBeforeBlending: InputTimingRecord
  inputTimingBeforeSetPitcher: InputTimingRecord
  recipeSetting: RecipeSetting
  ingredients: IngredientRecord[]
  isCheckedDairyAndJuiceManualInput: boolean
  isCheckedRoastAndTeaManualInput: boolean
  isCheckedSyrupBaseManualInput: boolean
  handleOnChangeSingleCheckedManualInput: (
    recipeIngredientCategoryType: string,
  ) => void
  isCheckedSyrupManualInputs: boolean[]
  isCheckedPowderManualInputs: boolean[]
  isCheckedChipManualInputs: boolean[]
  handleOnChangeCheckedManualInputs: (
    recipeIngredientCategoryType: string,
    index: number,
  ) => void
  resetIsCheckedManualInput: (recipeIngredientCategoryType: string) => void
  addNewCheckedManualInputs: (recipeIngredientCategoryType: string) => void
  removeCheckedManualInputs: (
    index: number,
    recipeIngredientCategoryType: string,
  ) => void
  validateMassages: string[]
  recipes?: RecipeRecord[]
  handleOnChangeShortCutRecipe?: (recipeID: number) => void
}

const RecipeModalIngredientInput: React.FC<Props> = ({
  action,
  formData,
  setFormData,
  inputTimingBeforeBlending,
  inputTimingBeforeSetPitcher,
  recipeSetting,
  ingredients,
  isCheckedDairyAndJuiceManualInput,
  isCheckedRoastAndTeaManualInput,
  isCheckedSyrupBaseManualInput,
  handleOnChangeSingleCheckedManualInput,
  isCheckedSyrupManualInputs,
  isCheckedPowderManualInputs,
  isCheckedChipManualInputs,
  handleOnChangeCheckedManualInputs,
  addNewCheckedManualInputs,
  validateMassages,
  recipes,
  handleOnChangeShortCutRecipe,
}) => {
  const modalTitle: string =
    action === 'create'
      ? 'レシピマスターを追加する'
      : 'レシピマスターを更新する'
  const [dairyAndJuiceSizeScalingType, setDairyAndJuiceSizeScalingType] =
    useState<SizeScalingTypeRecord>()
  const [roastAndTeaSizeScalingType, setRoastAndTeaSizeScalingType] =
    useState<SizeScalingTypeRecord>()
  const [syrupBaseSizeScalingType, setSyrupBaseSizeScalingType] =
    useState<SizeScalingTypeRecord>()
  const [syrupSizeScalingType, setSyrupSizeScalingType] =
    useState<SizeScalingTypeRecord>()
  const [chipSizeScalingType, setChipSizeScalingType] =
    useState<SizeScalingTypeRecord>()
  const [powderSizeScalingType, setPowderSizeScalingType] =
    useState<SizeScalingTypeRecord>()

  const findTargetSizeScalingType = (
    ingredientID: number,
    recipeSizeScalingType: string,
    index?: number,
  ) => {
    const ingredient = ingredients.find(
      (ingredient) => ingredient.id === ingredientID,
    )
    if (!ingredient) return
    const sizeScalingType = recipeSetting.sizeScalingTypes.find(
      (sizeScalingType) =>
        sizeScalingType.id === ingredient.utensil.sizeScalingType.id,
    )
    if (!sizeScalingType) return
    if (index === undefined) {
      switch (recipeSizeScalingType) {
        case RecipeIngredientCategoryType.DairyAndJuice:
          setDairyAndJuiceSizeScalingType(sizeScalingType)
          break
        case RecipeIngredientCategoryType.RoastAndTea:
          setRoastAndTeaSizeScalingType(sizeScalingType)
          break
        case RecipeIngredientCategoryType.SyrupBase:
          setSyrupBaseSizeScalingType(sizeScalingType)
          break
        case RecipeIngredientCategoryType.Syrup:
          setSyrupSizeScalingType(sizeScalingType)
          break
        case RecipeIngredientCategoryType.Chip:
          setChipSizeScalingType(sizeScalingType)
          break
        case RecipeIngredientCategoryType.Powder:
          setPowderSizeScalingType(sizeScalingType)
          break
      }
    }
  }

  useEffect(() => {
    if (
      formData.dairyAndJuiceRecipeIngredientInput?.newSizeScalingPreset &&
      dairyAndJuiceSizeScalingType
    ) {
      formData.dairyAndJuiceRecipeIngredientInput.newSizeScalingPreset.sizeScalingTypeID =
        dairyAndJuiceSizeScalingType.id
      setFormData({
        ...formData,
      })
    }
  }, [dairyAndJuiceSizeScalingType, isCheckedDairyAndJuiceManualInput])

  useEffect(() => {
    if (
      formData.roastAndTeaRecipeIngredientInput?.newSizeScalingPreset &&
      roastAndTeaSizeScalingType
    ) {
      formData.roastAndTeaRecipeIngredientInput.newSizeScalingPreset.sizeScalingTypeID =
        roastAndTeaSizeScalingType.id
      setFormData({
        ...formData,
      })
    }
  }, [roastAndTeaSizeScalingType, isCheckedRoastAndTeaManualInput])

  useEffect(() => {
    if (
      formData.syrupBaseRecipeIngredientInput?.newSizeScalingPreset &&
      syrupBaseSizeScalingType
    ) {
      formData.syrupBaseRecipeIngredientInput.newSizeScalingPreset.sizeScalingTypeID =
        syrupBaseSizeScalingType.id
      setFormData({
        ...formData,
      })
    }
  }, [syrupBaseSizeScalingType, isCheckedSyrupBaseManualInput])

  useEffect(() => {
    if (
      formData.syrupRecipeIngredientInputs &&
      formData.syrupRecipeIngredientInputs.length > 0 &&
      syrupSizeScalingType
    ) {
      formData.syrupRecipeIngredientInputs.forEach(
        (syrupRecipeIngredientInput) => {
          if (
            syrupRecipeIngredientInput.newSizeScalingPreset &&
            syrupSizeScalingType
          ) {
            syrupRecipeIngredientInput.newSizeScalingPreset.sizeScalingTypeID =
              syrupSizeScalingType.id as number
          }
        },
      )
      setFormData({
        ...formData,
      })
    }
  }, [syrupSizeScalingType, isCheckedSyrupManualInputs])

  useEffect(() => {
    if (
      formData.chipRecipeIngredientInputs &&
      formData.chipRecipeIngredientInputs.length > 0 &&
      chipSizeScalingType
    ) {
      formData.chipRecipeIngredientInputs.forEach(
        (chipRecipeIngredientInput) => {
          if (
            chipRecipeIngredientInput.newSizeScalingPreset &&
            chipSizeScalingType
          ) {
            chipRecipeIngredientInput.newSizeScalingPreset.sizeScalingTypeID =
              chipSizeScalingType.id as number
          }
        },
      )
      setFormData({
        ...formData,
      })
    }
  }, [chipSizeScalingType, isCheckedChipManualInputs])

  useEffect(() => {
    if (
      formData.powderRecipeIngredientInputs &&
      formData.powderRecipeIngredientInputs.length > 0 &&
      powderSizeScalingType
    ) {
      formData.powderRecipeIngredientInputs.forEach(
        (powderRecipeIngredientInput) => {
          if (
            powderRecipeIngredientInput.newSizeScalingPreset &&
            powderSizeScalingType
          ) {
            powderRecipeIngredientInput.newSizeScalingPreset.sizeScalingTypeID =
              powderSizeScalingType.id as number
          }
        },
      )
      setFormData({
        ...formData,
      })
    }
  }, [powderSizeScalingType, isCheckedPowderManualInputs])

  const handleOnChangeSingleIngredientID = (
    e: React.ChangeEvent<HTMLInputElement>,
    recipeIngredientCategoryType: string,
  ) => {
    const postFormData = formData
    switch (recipeIngredientCategoryType) {
      case RecipeIngredientCategoryType.SyrupBase:
        if (!postFormData.syrupBaseRecipeIngredientInput) {
          postFormData.syrupBaseRecipeIngredientInput = {
            ingredientID: Number(e.target.value),
            inputTimingID: inputTimingBeforeBlending?.id,
          }
        } else {
          postFormData.syrupBaseRecipeIngredientInput.ingredientID = Number(
            e.target.value,
          )
        }
        break
      case RecipeIngredientCategoryType.DairyAndJuice:
        if (!postFormData.dairyAndJuiceRecipeIngredientInput) {
          postFormData.dairyAndJuiceRecipeIngredientInput = {
            ingredientID: Number(e.target.value),
            inputTimingID: inputTimingBeforeBlending?.id,
          }
        } else {
          postFormData.dairyAndJuiceRecipeIngredientInput.ingredientID = Number(
            e.target.value,
          )
        }
        break
      case RecipeIngredientCategoryType.RoastAndTea:
        if (!postFormData.roastAndTeaRecipeIngredientInput) {
          postFormData.roastAndTeaRecipeIngredientInput = {
            ingredientID: Number(e.target.value),
            inputTimingID: inputTimingBeforeBlending?.id,
          }
        } else {
          postFormData.roastAndTeaRecipeIngredientInput.ingredientID = Number(
            e.target.value,
          )
        }
    }
    setFormData({
      ...postFormData,
    })
  }

  const handleOnChangeArrayIngredientID = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    recipeIngredientCategoryType: string,
  ) => {
    const postFormData = formData
    switch (recipeIngredientCategoryType) {
      case RecipeIngredientCategoryType.Syrup:
        {
          const syrupRecipeIngredientInputs =
            postFormData.syrupRecipeIngredientInputs as RecipeIngredientInputParam[]
          syrupRecipeIngredientInputs[index].ingredientID = Number(
            e.target.value,
          )
          postFormData.syrupRecipeIngredientInputs = syrupRecipeIngredientInputs
        }
        break
      case RecipeIngredientCategoryType.Chip:
        {
          const chipRecipeIngredientInputs =
            postFormData.chipRecipeIngredientInputs as RecipeIngredientInputParam[]
          chipRecipeIngredientInputs[index].ingredientID = Number(
            e.target.value,
          )
          postFormData.chipRecipeIngredientInputs = chipRecipeIngredientInputs
        }
        break
      case RecipeIngredientCategoryType.Powder:
        {
          const powderRecipeIngredientInputs =
            postFormData.powderRecipeIngredientInputs as RecipeIngredientInputParam[]
          powderRecipeIngredientInputs[index].ingredientID = Number(
            e.target.value,
          )
          postFormData.powderRecipeIngredientInputs =
            powderRecipeIngredientInputs
        }
        break
      case RecipeIngredientCategoryType.ManualInput:
        {
          const manualInputRecipeIngredientInputs =
            postFormData.manualRecipeIngredientInputs as RecipeIngredientInputParam[]
          manualInputRecipeIngredientInputs[index].ingredientID = Number(
            e.target.value,
          )
          postFormData.manualRecipeIngredientInputs =
            manualInputRecipeIngredientInputs
        }
        break
    }
    setFormData({
      ...postFormData,
    })
  }

  const handleOnChangeSingleSizeScalingPresetID = (
    e: React.ChangeEvent<HTMLInputElement>,
    recipeIngredientCategoryType: string,
  ) => {
    const postFormData = formData
    switch (recipeIngredientCategoryType) {
      case RecipeIngredientCategoryType.SyrupBase:
        if (!postFormData.syrupBaseRecipeIngredientInput) {
          postFormData.syrupBaseRecipeIngredientInput = {
            sizeScalingPresetID: Number(e.target.value),
            inputTimingID: inputTimingBeforeBlending?.id,
          }
        } else {
          postFormData.syrupBaseRecipeIngredientInput.sizeScalingPresetID =
            Number(e.target.value)
        }
        break
      case RecipeIngredientCategoryType.DairyAndJuice:
        if (!postFormData.dairyAndJuiceRecipeIngredientInput) {
          postFormData.dairyAndJuiceRecipeIngredientInput = {
            sizeScalingPresetID: Number(e.target.value),
            inputTimingID: inputTimingBeforeBlending?.id,
          }
        } else {
          postFormData.dairyAndJuiceRecipeIngredientInput.sizeScalingPresetID =
            Number(e.target.value)
        }
        break
      case RecipeIngredientCategoryType.RoastAndTea:
        if (!postFormData.roastAndTeaRecipeIngredientInput) {
          postFormData.roastAndTeaRecipeIngredientInput = {
            sizeScalingPresetID: Number(e.target.value),
            inputTimingID: inputTimingBeforeBlending?.id,
          }
        } else {
          postFormData.roastAndTeaRecipeIngredientInput.sizeScalingPresetID =
            Number(e.target.value)
        }
    }
    setFormData({
      ...postFormData,
    })
  }

  const handleOnChangeArraySizeScalingPresetID = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    recipeIngredientCategoryType: string,
  ) => {
    const postFormData = formData
    switch (recipeIngredientCategoryType) {
      case RecipeIngredientCategoryType.Syrup:
        {
          const syrupRecipeIngredientInputs =
            postFormData.syrupRecipeIngredientInputs as RecipeIngredientInputParam[]
          syrupRecipeIngredientInputs[index].sizeScalingPresetID = Number(
            e.target.value,
          )
          postFormData.syrupRecipeIngredientInputs = syrupRecipeIngredientInputs
        }
        break
      case RecipeIngredientCategoryType.Chip:
        {
          const chipRecipeIngredientInputs =
            postFormData.chipRecipeIngredientInputs as RecipeIngredientInputParam[]
          chipRecipeIngredientInputs[index].sizeScalingPresetID = Number(
            e.target.value,
          )
          postFormData.chipRecipeIngredientInputs = chipRecipeIngredientInputs
        }
        break
      case RecipeIngredientCategoryType.Powder:
        {
          const powderRecipeIngredientInputs =
            postFormData.powderRecipeIngredientInputs as RecipeIngredientInputParam[]
          powderRecipeIngredientInputs[index].sizeScalingPresetID = Number(
            e.target.value,
          )
          postFormData.powderRecipeIngredientInputs =
            powderRecipeIngredientInputs
        }
        break
    }
    setFormData({
      ...postFormData,
    })
  }

  const handleOnChangeSingleNewSizeScalingPresetName = (
    e: React.ChangeEvent<HTMLInputElement>,
    recipeIngredientCategoryType: string,
  ) => {
    const postFormData = formData
    switch (recipeIngredientCategoryType) {
      case RecipeIngredientCategoryType.SyrupBase:
        if (
          postFormData.syrupBaseRecipeIngredientInput &&
          postFormData.syrupBaseRecipeIngredientInput.newSizeScalingPreset
        ) {
          postFormData.syrupBaseRecipeIngredientInput.newSizeScalingPreset.name =
            e.target.value
        }
        break
      case RecipeIngredientCategoryType.DairyAndJuice:
        if (
          postFormData.dairyAndJuiceRecipeIngredientInput &&
          postFormData.dairyAndJuiceRecipeIngredientInput.newSizeScalingPreset
        ) {
          postFormData.dairyAndJuiceRecipeIngredientInput.newSizeScalingPreset.name =
            e.target.value
        }
        break
      case RecipeIngredientCategoryType.RoastAndTea:
        if (
          postFormData.roastAndTeaRecipeIngredientInput &&
          postFormData.roastAndTeaRecipeIngredientInput.newSizeScalingPreset
        ) {
          postFormData.roastAndTeaRecipeIngredientInput.newSizeScalingPreset.name =
            e.target.value
        }
    }
    setFormData({
      ...postFormData,
    })
  }

  const handleOnChangeArrayNewSizeScalingPresetName = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    recipeIngredientCategoryType: string,
  ) => {
    const postFormData = formData
    switch (recipeIngredientCategoryType) {
      case RecipeIngredientCategoryType.Syrup:
        {
          const syrupRecipeIngredientInputs =
            postFormData.syrupRecipeIngredientInputs as RecipeIngredientInputParam[]
          const newSizeScalingPreset = syrupRecipeIngredientInputs[index]
            .newSizeScalingPreset as SizeScalingPresetParam
          newSizeScalingPreset.name = e.target.value
          syrupRecipeIngredientInputs[index].newSizeScalingPreset =
            newSizeScalingPreset
          postFormData.syrupRecipeIngredientInputs = syrupRecipeIngredientInputs
        }
        break
      case RecipeIngredientCategoryType.Chip:
        {
          const chipRecipeIngredientInputs =
            postFormData.chipRecipeIngredientInputs as RecipeIngredientInputParam[]
          const newSizeScalingPreset = chipRecipeIngredientInputs[index]
            .newSizeScalingPreset as SizeScalingPresetParam
          newSizeScalingPreset.name = e.target.value
          chipRecipeIngredientInputs[index].newSizeScalingPreset =
            newSizeScalingPreset
          postFormData.chipRecipeIngredientInputs = chipRecipeIngredientInputs
        }
        break
      case RecipeIngredientCategoryType.Powder: {
        const powderRecipeIngredientInputs =
          postFormData.powderRecipeIngredientInputs as RecipeIngredientInputParam[]
        const newSizeScalingPreset = powderRecipeIngredientInputs[index]
          .newSizeScalingPreset as SizeScalingPresetParam
        newSizeScalingPreset.name = e.target.value
        powderRecipeIngredientInputs[index].newSizeScalingPreset =
          newSizeScalingPreset
        postFormData.powderRecipeIngredientInputs = powderRecipeIngredientInputs
      }
    }
    setFormData({
      ...postFormData,
    })
  }

  const handleOnChangeSingleNewSizeScalingPresetCoefficient = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    recipeIngredientCategoryType: string,
  ) => {
    const postFormData = formData
    switch (recipeIngredientCategoryType) {
      case RecipeIngredientCategoryType.SyrupBase:
        if (
          postFormData.syrupBaseRecipeIngredientInput &&
          postFormData.syrupBaseRecipeIngredientInput.newSizeScalingPreset
        ) {
          postFormData.syrupBaseRecipeIngredientInput.newSizeScalingPreset.ingredientSizeCoefficients[
            index
          ].coefficient = Number(e.target.value)
        }
        break
      case RecipeIngredientCategoryType.DairyAndJuice:
        if (
          postFormData.dairyAndJuiceRecipeIngredientInput &&
          postFormData.dairyAndJuiceRecipeIngredientInput.newSizeScalingPreset
        ) {
          postFormData.dairyAndJuiceRecipeIngredientInput.newSizeScalingPreset.ingredientSizeCoefficients[
            index
          ].coefficient = Number(e.target.value)
        }
        break
      case RecipeIngredientCategoryType.RoastAndTea:
        if (
          postFormData.roastAndTeaRecipeIngredientInput &&
          postFormData.roastAndTeaRecipeIngredientInput.newSizeScalingPreset
        ) {
          postFormData.roastAndTeaRecipeIngredientInput.newSizeScalingPreset.ingredientSizeCoefficients[
            index
          ].coefficient = Number(e.target.value)
        }
    }
    setFormData({
      ...postFormData,
    })
  }

  const handleOnChangeArrayNewSizeScalingPresetCoefficient = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    sizeIndex: number,
    recipeIngredientCategoryType: string,
  ) => {
    const postFormData = formData
    switch (recipeIngredientCategoryType) {
      case RecipeIngredientCategoryType.Syrup:
        {
          const syrupRecipeIngredientInputs =
            postFormData.syrupRecipeIngredientInputs as RecipeIngredientInputParam[]
          const newSizeScalingPreset = syrupRecipeIngredientInputs[index]
            .newSizeScalingPreset as SizeScalingPresetParam
          newSizeScalingPreset.ingredientSizeCoefficients[
            sizeIndex
          ].coefficient = Number(e.target.value)
          syrupRecipeIngredientInputs[index].newSizeScalingPreset =
            newSizeScalingPreset
          postFormData.syrupRecipeIngredientInputs = syrupRecipeIngredientInputs
        }
        break
      case RecipeIngredientCategoryType.Chip:
        {
          const chipRecipeIngredientInputs =
            postFormData.chipRecipeIngredientInputs as RecipeIngredientInputParam[]
          const newSizeScalingPreset = chipRecipeIngredientInputs[index]
            .newSizeScalingPreset as SizeScalingPresetParam
          newSizeScalingPreset.ingredientSizeCoefficients[
            sizeIndex
          ].coefficient = Number(e.target.value)
          chipRecipeIngredientInputs[index].newSizeScalingPreset =
            newSizeScalingPreset
          postFormData.chipRecipeIngredientInputs = chipRecipeIngredientInputs
        }
        break
      case RecipeIngredientCategoryType.Powder: {
        const powderRecipeIngredientInputs =
          postFormData.powderRecipeIngredientInputs as RecipeIngredientInputParam[]
        const newSizeScalingPreset = powderRecipeIngredientInputs[index]
          .newSizeScalingPreset as SizeScalingPresetParam
        newSizeScalingPreset.ingredientSizeCoefficients[sizeIndex].coefficient =
          Number(e.target.value)
        powderRecipeIngredientInputs[index].newSizeScalingPreset =
          newSizeScalingPreset
        postFormData.powderRecipeIngredientInputs = powderRecipeIngredientInputs
      }
    }
    setFormData({
      ...postFormData,
    })
  }

  const handleOnAddNewRecipeIngredientInput = (
    recipeIngredientCategoryType: string,
  ) => {
    const postFormData = formData
    switch (recipeIngredientCategoryType) {
      case RecipeIngredientCategoryType.Syrup:
        if (postFormData.syrupRecipeIngredientInputs) {
          postFormData.syrupRecipeIngredientInputs.push({
            inputTimingID: inputTimingBeforeBlending?.id,
          })
          addNewCheckedManualInputs(RecipeIngredientCategoryType.Syrup)
        }
        break
      case RecipeIngredientCategoryType.Powder:
        if (postFormData.powderRecipeIngredientInputs) {
          postFormData.powderRecipeIngredientInputs.push({
            inputTimingID: inputTimingBeforeBlending?.id,
          })
          addNewCheckedManualInputs(RecipeIngredientCategoryType.Powder)
        }
        break
      case RecipeIngredientCategoryType.Chip:
        if (postFormData.chipRecipeIngredientInputs) {
          postFormData.chipRecipeIngredientInputs.push({
            inputTimingID: inputTimingBeforeBlending?.id,
          })
          addNewCheckedManualInputs(RecipeIngredientCategoryType.Chip)
        }
        break
      case RecipeIngredientCategoryType.ManualInput:
        if (postFormData.manualRecipeIngredientInputs) {
          const manualInputSizeScalingType =
            recipeSetting.sizeScalingTypes.find(
              (target) => target.name === SizeScalingType.ManualInput,
            )
          postFormData.manualRecipeIngredientInputs.push({
            inputTimingID: inputTimingBeforeSetPitcher?.id,
            sizeScalingPresetID:
              manualInputSizeScalingType?.sizeScalingPresets[0].id,
          })
        }
        break
    }
    setFormData({
      ...postFormData,
    })
  }

  const handleOnResetSingleRecipeIngredientInput = (
    recipeIngredientCategoryType: string,
  ) => {
    const postFormData = formData
    switch (recipeIngredientCategoryType) {
      case RecipeIngredientCategoryType.DairyAndJuice:
        postFormData.dairyAndJuiceRecipeIngredientInput = undefined

        break
      case RecipeIngredientCategoryType.RoastAndTea:
        postFormData.roastAndTeaRecipeIngredientInput = undefined
        break
      case RecipeIngredientCategoryType.SyrupBase:
        postFormData.syrupBaseRecipeIngredientInput = undefined
        break
    }
    setFormData({
      ...postFormData,
    })
  }

  const handleOnRemoveRecipeIngredientInputs = (
    index: number,
    recipeIngredientCategoryType: string,
  ) => {
    const postFormData = formData
    switch (recipeIngredientCategoryType) {
      case RecipeIngredientCategoryType.Syrup:
        if (postFormData.syrupRecipeIngredientInputs) {
          postFormData.syrupRecipeIngredientInputs.splice(index, 1)
        }
        break
      case RecipeIngredientCategoryType.Powder:
        if (postFormData.powderRecipeIngredientInputs) {
          postFormData.powderRecipeIngredientInputs.splice(index, 1)
        }
        break
      case RecipeIngredientCategoryType.Chip:
        if (postFormData.chipRecipeIngredientInputs) {
          postFormData.chipRecipeIngredientInputs.splice(index, 1)
        }
        break
      case RecipeIngredientCategoryType.ManualInput:
        if (postFormData.manualRecipeIngredientInputs) {
          postFormData.manualRecipeIngredientInputs.splice(index, 1)
        }
        break
    }
    setFormData({
      ...postFormData,
    })
  }

  const handleOnAddNewTopping = () => {
    const postFormData = formData
    if (postFormData.toppings) {
      postFormData.toppings.push({
        description: '',
      })
    }
    setFormData({
      ...postFormData,
    })
  }

  const handleOnRemoveTopping = (index: number) => {
    const postFormData = formData
    if (postFormData.toppings) {
      postFormData.toppings.splice(index, 1)
    }
    setFormData({
      ...postFormData,
    })
  }

  const handleOnChangeToppingDescription = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const postFormData = formData
    if (postFormData.toppings) {
      postFormData.toppings[index].description = e.target.value
    }
    setFormData({
      ...postFormData,
    })
  }
  return (
    <Grid>
      <Typography sx={modalTitleSx}>{modalTitle} 2/2</Typography>
      <ValidateMessageInfo
        validateMassages={validateMassages}
        sx={{ mb: '20px' }}
      />
      {recipes && handleOnChangeShortCutRecipe && (
        <Grid sx={gridSx}>
          <Typography sx={modalSubtitleSx}>入力ショートカット</Typography>
          <Grid>
            <InputLabel sx={modalLeftItemLabelSx}>
              過去のレシピマスターを選択
            </InputLabel>
            <Select
              displayEmpty
              defaultValue=""
              onChange={(e) => {
                const recipeID = Number(e.target.value)
                handleOnChangeShortCutRecipe(recipeID)
              }}
              renderValue={(selected) => {
                if (selected === '') {
                  return (
                    <span style={{ color: '#9f9f9f', fontStyle: 'normal' }}>
                      選択してください
                    </span>
                  )
                }
                return recipes.find((recipe) => recipe.id === Number(selected))
                  ?.name
              }}
              sx={{ ...selectInputSx, height: '40px' }}
              fullWidth>
              {recipes.map((recipe, index) => (
                <MenuItem value={recipe.id} key={index}>
                  {recipe.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Divider sx={modalDividerSx} />
        </Grid>
      )}
      <RecipeIngredientInput
        recipeIngredientInputParam={
          formData.syrupBaseRecipeIngredientInput as RecipeIngredientInputParam
        }
        sizeScalingType={syrupBaseSizeScalingType as SizeScalingTypeRecord}
        findTargetSizeScalingType={findTargetSizeScalingType}
        ingredientCategory={recipeSetting.ingredientCategoryType.syrupBase}
        source="syrupBaseRecipeIngredientInput"
        label={recipeSetting.ingredientCategoryType.syrupBase.name}
        sizes={recipeSetting.sizes}
        recipeIngredientCategoryType={RecipeIngredientCategoryType.SyrupBase}
        validate={false}
        handleOnChangeSingleIngredientID={handleOnChangeSingleIngredientID}
        handleOnChangeSingleSizeScalingPresetID={
          handleOnChangeSingleSizeScalingPresetID
        }
        isCheckedManualInput={isCheckedSyrupBaseManualInput}
        handleOnChangeSingleCheckedManualInput={
          handleOnChangeSingleCheckedManualInput
        }
        handleOnChangeSingleNewSizeScalingPresetName={
          handleOnChangeSingleNewSizeScalingPresetName
        }
        handleOnChangeSingleNewSizeScalingPresetCoefficient={
          handleOnChangeSingleNewSizeScalingPresetCoefficient
        }
        handleOnResetSingleRecipeIngredientInput={
          handleOnResetSingleRecipeIngredientInput
        }
      />
      <Divider sx={modalDividerSx} />
      <RecipeIngredientInput
        recipeIngredientInputParam={
          formData.roastAndTeaRecipeIngredientInput as RecipeIngredientInputParam
        }
        sizeScalingType={roastAndTeaSizeScalingType as SizeScalingTypeRecord}
        findTargetSizeScalingType={findTargetSizeScalingType}
        ingredientCategory={recipeSetting.ingredientCategoryType.roastAndTea}
        source="roastAndTeaRecipeIngredientInput"
        label={recipeSetting.ingredientCategoryType.roastAndTea.name}
        sizes={recipeSetting.sizes}
        recipeIngredientCategoryType={RecipeIngredientCategoryType.RoastAndTea}
        validate={false}
        handleOnChangeSingleIngredientID={handleOnChangeSingleIngredientID}
        handleOnChangeSingleSizeScalingPresetID={
          handleOnChangeSingleSizeScalingPresetID
        }
        isCheckedManualInput={isCheckedRoastAndTeaManualInput}
        handleOnChangeSingleCheckedManualInput={
          handleOnChangeSingleCheckedManualInput
        }
        handleOnChangeSingleNewSizeScalingPresetName={
          handleOnChangeSingleNewSizeScalingPresetName
        }
        handleOnChangeSingleNewSizeScalingPresetCoefficient={
          handleOnChangeSingleNewSizeScalingPresetCoefficient
        }
        handleOnResetSingleRecipeIngredientInput={
          handleOnResetSingleRecipeIngredientInput
        }
      />
      <Divider sx={modalDividerSx} />
      <RecipeIngredientArrayInput
        recipeIngredientInputParams={
          formData.syrupRecipeIngredientInputs as RecipeIngredientInputParam[]
        }
        ingredientCategory={recipeSetting.ingredientCategoryType.syrup}
        source="syrupRecipeIngredientInputs"
        label={recipeSetting.ingredientCategoryType.syrup.name}
        buttonLabel="シロップを追加"
        sizes={recipeSetting.sizes}
        recipeIngredientCategoryType={RecipeIngredientCategoryType.Syrup}
        findTargetSizeScalingType={findTargetSizeScalingType}
        validate={false}
        sizeScalingType={syrupSizeScalingType as SizeScalingTypeRecord}
        handleOnChangeArrayIngredientID={handleOnChangeArrayIngredientID}
        handleOnChangeArraySizeScalingPresetID={
          handleOnChangeArraySizeScalingPresetID
        }
        handleOnChangeArrayNewSizeScalingPresetName={
          handleOnChangeArrayNewSizeScalingPresetName
        }
        handleOnChangeArrayNewSizeScalingPresetCoefficient={
          handleOnChangeArrayNewSizeScalingPresetCoefficient
        }
        isCheckedManualInputs={isCheckedSyrupManualInputs}
        handleOnChangeCheckedManualInputs={handleOnChangeCheckedManualInputs}
        handleOnAddNewRecipeIngredientInput={
          handleOnAddNewRecipeIngredientInput
        }
        handleOnRemoveRecipeIngredientInputs={
          handleOnRemoveRecipeIngredientInputs
        }
      />
      <Divider sx={modalDividerSx} />
      <RecipeIngredientArrayInput
        recipeIngredientInputParams={
          formData.powderRecipeIngredientInputs as RecipeIngredientInputParam[]
        }
        ingredientCategory={recipeSetting.ingredientCategoryType.powder}
        source="powderRecipeIngredientInputs"
        label={recipeSetting.ingredientCategoryType.powder.name}
        buttonLabel="パウダーを追加"
        sizes={recipeSetting.sizes}
        recipeIngredientCategoryType={RecipeIngredientCategoryType.Powder}
        findTargetSizeScalingType={findTargetSizeScalingType}
        validate={false}
        sizeScalingType={powderSizeScalingType as SizeScalingTypeRecord}
        handleOnChangeArrayIngredientID={handleOnChangeArrayIngredientID}
        handleOnChangeArraySizeScalingPresetID={
          handleOnChangeArraySizeScalingPresetID
        }
        handleOnChangeArrayNewSizeScalingPresetName={
          handleOnChangeArrayNewSizeScalingPresetName
        }
        handleOnChangeArrayNewSizeScalingPresetCoefficient={
          handleOnChangeArrayNewSizeScalingPresetCoefficient
        }
        isCheckedManualInputs={isCheckedPowderManualInputs}
        handleOnChangeCheckedManualInputs={handleOnChangeCheckedManualInputs}
        handleOnAddNewRecipeIngredientInput={
          handleOnAddNewRecipeIngredientInput
        }
        handleOnRemoveRecipeIngredientInputs={
          handleOnRemoveRecipeIngredientInputs
        }
      />
      <Divider sx={modalDividerSx} />
      <RecipeIngredientArrayInput
        recipeIngredientInputParams={
          formData.chipRecipeIngredientInputs as RecipeIngredientInputParam[]
        }
        ingredientCategory={recipeSetting.ingredientCategoryType.chip}
        source="chipRecipeIngredientInputs"
        label={recipeSetting.ingredientCategoryType.chip.name}
        buttonLabel="チップを追加"
        sizes={recipeSetting.sizes}
        recipeIngredientCategoryType={RecipeIngredientCategoryType.Chip}
        findTargetSizeScalingType={findTargetSizeScalingType}
        validate={false}
        sizeScalingType={chipSizeScalingType as SizeScalingTypeRecord}
        handleOnChangeArrayIngredientID={handleOnChangeArrayIngredientID}
        handleOnChangeArraySizeScalingPresetID={
          handleOnChangeArraySizeScalingPresetID
        }
        handleOnChangeArrayNewSizeScalingPresetName={
          handleOnChangeArrayNewSizeScalingPresetName
        }
        handleOnChangeArrayNewSizeScalingPresetCoefficient={
          handleOnChangeArrayNewSizeScalingPresetCoefficient
        }
        isCheckedManualInputs={isCheckedChipManualInputs}
        handleOnChangeCheckedManualInputs={handleOnChangeCheckedManualInputs}
        handleOnAddNewRecipeIngredientInput={
          handleOnAddNewRecipeIngredientInput
        }
        handleOnRemoveRecipeIngredientInputs={
          handleOnRemoveRecipeIngredientInputs
        }
      />
      <Divider sx={modalDividerSx} />
      <RecipeIngredientInput
        recipeIngredientInputParam={
          formData.dairyAndJuiceRecipeIngredientInput as RecipeIngredientInputParam
        }
        sizeScalingType={dairyAndJuiceSizeScalingType as SizeScalingTypeRecord}
        findTargetSizeScalingType={findTargetSizeScalingType}
        ingredientCategory={recipeSetting.ingredientCategoryType.dairyAndJuice}
        source="dairyAndJuiceRecipeIngredientInput"
        label={`${recipeSetting.ingredientCategoryType.dairyAndJuice.name} *`}
        sizes={recipeSetting.sizes}
        recipeIngredientCategoryType={
          RecipeIngredientCategoryType.DairyAndJuice
        }
        validate={true}
        handleOnChangeSingleIngredientID={handleOnChangeSingleIngredientID}
        handleOnChangeSingleSizeScalingPresetID={
          handleOnChangeSingleSizeScalingPresetID
        }
        isCheckedManualInput={isCheckedDairyAndJuiceManualInput}
        handleOnChangeSingleCheckedManualInput={
          handleOnChangeSingleCheckedManualInput
        }
        handleOnChangeSingleNewSizeScalingPresetName={
          handleOnChangeSingleNewSizeScalingPresetName
        }
        handleOnChangeSingleNewSizeScalingPresetCoefficient={
          handleOnChangeSingleNewSizeScalingPresetCoefficient
        }
        handleOnResetSingleRecipeIngredientInput={
          handleOnResetSingleRecipeIngredientInput
        }
      />
      <Divider sx={modalDividerSx} />
      <Grid>
        <Typography sx={{ fontSize: '14px', fontWeight: 700, mb: '10px' }}>
          {recipeSetting.ingredientCategoryType.inputManual.name}
        </Typography>
        {formData.manualRecipeIngredientInputs?.map((target, index) => (
          <Grid sx={gridSx} key={`topping_${index}`}>
            <Grid container justifyContent="space-between">
              <InputLabel sx={{ ...labelGrayOutSx, mt: 'auto', mb: 'auto' }}>
                材料を選択
              </InputLabel>
              <Button
                variant="text"
                onClick={() => {
                  handleOnRemoveRecipeIngredientInputs(
                    index,
                    RecipeIngredientCategoryType.ManualInput,
                  )
                }}>
                <CrossIcon className="cross-icon" />
                <Grid sx={{ color: '#C80000' }}>削除</Grid>
              </Button>
            </Grid>
            <CustomPlaceholderSelectInput
              type="formInput"
              placeholder="選択してください"
              source={`manualRecipeIngredientInputs[${index}][ingredientID]`}
              variant="outlined"
              label={false}
              sx={selectInputSx}
              choices={
                recipeSetting.ingredientCategoryType.inputManual.ingredients
              }
              validate={required('エラー選択してください')}
              onChange={(e) => {
                handleOnChangeArrayIngredientID(
                  e,
                  index,
                  RecipeIngredientCategoryType.ManualInput,
                )
                findTargetSizeScalingType(
                  Number(e.target.value),
                  RecipeIngredientCategoryType.ManualInput,
                )
              }}
              defaultValue={target.ingredientID ? target.ingredientID : ''}
              fullWidth
            />
          </Grid>
        ))}
        <Button
          sx={addButtonSx}
          onClick={() =>
            handleOnAddNewRecipeIngredientInput(
              RecipeIngredientCategoryType.ManualInput,
            )
          }>
          <PlusIcon className="icon" />
          項目を追加する
        </Button>
      </Grid>
      <Divider sx={modalDividerSx} />
      <Grid>
        <Typography sx={{ fontSize: '14px', fontWeight: 700, mb: '10px' }}>
          トッピング
        </Typography>
        {formData.toppings?.map((target, index) => (
          <Grid sx={gridSx} key={`topping_${index}`}>
            <Grid container justifyContent="space-between">
              <InputLabel sx={{ ...labelGrayOutSx, mt: 'auto', mb: 'auto' }}>
                説明
              </InputLabel>
              <Button
                variant="text"
                onClick={() => {
                  handleOnRemoveTopping(index)
                }}>
                <CrossIcon className="cross-icon" />
                <Grid sx={{ color: '#C80000' }}>削除</Grid>
              </Button>
            </Grid>
            <TextInput
              source={`toppings[${index}][description]`}
              variant="outlined"
              label={false}
              sx={textInputSx}
              validate={required('エラー入力してください')}
              placeholder="入力してください"
              onChange={(e) => {
                handleOnChangeToppingDescription(e, index)
              }}
              fullWidth
              defaultValue={target.description}
            />
          </Grid>
        ))}
        <Button sx={addButtonSx} onClick={() => handleOnAddNewTopping()}>
          <PlusIcon className="icon" />
          項目を追加する
        </Button>
      </Grid>
    </Grid>
  )
}

export default RecipeModalIngredientInput
