import React from 'react'
import { Grid, Typography, Modal, Box } from '@mui/material'
import CancelButton from '../button/CancelButton'
import DeleteButton from '../button/DeleteButton'
import { cancelButtonWrapperSx, modalWrapperSx } from '../../assets/sx/form'

type Props = {
  targetModalOpen: boolean
  open: boolean
  onConfirm: () => void
  onClose: () => void
}

const CancelInputModal: React.FC<Props> = ({
  targetModalOpen,
  open,
  onConfirm,
  onClose,
}) => {
  return (
    <Modal open={targetModalOpen} sx={{ display: open ? 'block' : 'none' }}>
      <Box sx={modalWrapperSx}>
        <Typography sx={{ fontSize: '16px', fontWeight: 700, mb: '10px' }}>
          入力をキャンセルしてもよろしいですか?
        </Typography>
        <Box sx={{ fontSize: '14px', fontWeight: 400, mb: '40px' }}>
          ※入力は破棄されます
        </Box>
        <Grid container direction="row" justifyContent="flex-end">
          <Box sx={cancelButtonWrapperSx}>
            <CancelButton onClick={() => onClose()}>入力に戻る</CancelButton>
          </Box>
          <Box sx={{ width: '118px' }}>
            <DeleteButton onClick={onConfirm}>キャンセル</DeleteButton>
          </Box>
        </Grid>
      </Box>
    </Modal>
  )
}

export default CancelInputModal
