import React from 'react'
import { Grid } from '@mui/material'

import Logo from '../assets/images/wide_logo.svg'

export const ServiceLogo: React.FC = () => (
  <Grid container alignItems="center" justifyContent="center">
    <img src={Logo} alt="logo" style={{ marginRight: '0px', width: '361px' }} />
  </Grid>
)
