import { SizeScalingPresetRecord } from './recipe-record'

export type UtensilRecord = {
  id: number
  name: string
  sizeScalingType: SizeScalingTypeRecord
  ejectionAmount: number
  ejectionAmountUnit: EjectionAmountUnitRecord
}

export type EjectionAmountUnitRecord = {
  id: number
  name: string
}

export type SizeScalingTypeRecord = {
  id: number
  name: string
  isDouble: boolean
  sizeScalingPresets: SizeScalingPresetRecord[]
  utensils: UtensilRecord[]
}

export const SizeScalingType = {
  ManualInput: '手動投入',
  Pump: 'ポンプ',
  Scoop: 'スクープ',
  ML: 'mL',
  G: 'g',
}

export type SizeScalingType =
  typeof SizeScalingType[keyof typeof SizeScalingType]

export type UtensilParams = {
  name?: string
  sizeScalingTypeID?: number
  ejectionAmountUnitID?: number
  ejectionAmount?: number
}
