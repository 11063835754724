import { IngredientEjectionAdjustmentRecord } from './ingredient-ejection-adjustment-record'
import { UtensilRecord } from './utensil-record'
import { SizeScalingPresetParam } from './recipe-record'

export type IngredientCategoryRecord = {
  id: number
  name: string
  ingredients?: IngredientRecord[]
  isEjectable: boolean
  isChangeable: boolean
  isTunable: boolean
}

export type IngredientItemRecord = {
  id: number
  ingredientCategoryId: number
  name: string
}

export type IngredientItemParams = Partial<
  Pick<IngredientItemRecord, 'name' | 'ingredientCategoryId'>
>

export type IngredientRecord = {
  id: number
  name?: string
  displayName?: string
  containerCapacityAmount: number
  utensil: UtensilRecord
  tuningCustomizePresetID: number
  customizePresetID: number
  sizeScalingPresetID: number
  imageURL?: string
  ingredientCategory?: IngredientCategoryRecord
  ingredientEjectionAdjustment: IngredientEjectionAdjustmentRecord
  offerStartAt: string
  offerEndAt: string
  createdAt: string
  updatedAt: string
}

export type IngredientParams = {
  name?: string
  displayName?: string
  containerCapacityAmount?: number
  utensilID?: number
  tuningCustomizePresetID?: number
  sizeScalingPresetID?: number
  imageURL?: string
  ingredientCategoryID?: number
  offerStartAt?: string
  offerEndAt?: string
  newSizeScalingPreset?: SizeScalingPresetParam
  newTuningCustomizePreset?: NewTuningCustomizePresetParams
}

export type NewCustomizePresetParams = {
  name: string
  customizeCoefficients: CustomizeCoefficientParams[]
}

export type CustomizeCoefficientParams = {
  sizeID: number
  coefficient: number
}

export type NewTuningCustomizePresetParams = {
  name: string
  tuningCustomizeCoefficients: TuningCustomizeCoefficientParams[]
}

export type TuningCustomizeCoefficientParams = {
  coefficient: number
}

export const IngredientCategoryType = {
  SyrupBase: 'シロップベース',
  Roast: 'ロースト',
  Syrup: 'シロップ',
  Powder: 'パウダー',
  Chip: 'チップ',
  ManualInput: '手動投入材料',
  Dairy: 'ミルク (Dairy)',
  Nondairy: 'ミルク (Non)',
  Juice: 'ジュース',
  Tea: 'ティー',
}

export type IngredientCategoryType =
  typeof IngredientCategoryType[keyof typeof IngredientCategoryType]
