import React from 'react'
import {
  Form,
  TextInput,
  SaveButton,
  required,
  email,
  SelectInput,
} from 'react-admin'
import { Grid, Typography, Modal, Box, InputLabel } from '@mui/material'
import CancelInputModal from './CancelInputModal'
import useCancelModal from '../../hooks/useCancelModal'
import { AuthGroupRecord } from '../../types/records/auth-group-record'
import { UserParams, UserRecord } from '../../types/records/user-record'
import CancelButton from '../button/CancelButton'
import {
  gridSx,
  labelSx,
  textInputSx,
  selectInputSx,
  cancelButtonWrapperSx,
  saveButtonSx,
} from '../../assets/sx/form'
import { modalTitleSx } from '../../assets/sx/modalSx'

type Props = {
  authGroups: AuthGroupRecord[]
  user: UserRecord | undefined
  open: boolean
  onClose: () => void
  onSubmit: (id: number, data: UserParams, previousData: UserRecord) => void
}

const AddUserModal: React.FC<Props> = ({
  authGroups,
  user,
  open,
  onClose,
  onSubmit,
}) => {
  const onUpdateSubmit = (data: UserParams) => {
    if (!user || !user.id) {
      return null
    }
    onSubmit(user.id, data, user)
  }

  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onClose)

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box
          sx={{
            width: 398,
            MaxHeight: '80vh',
            backgroundColor: '#FFF',
            borderRadius: 2,
            p: 3,
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            left: '50%',
            overflow: 'scroll',
          }}>
          <Typography sx={modalTitleSx}>ユーザーを編集する</Typography>
          <Form onSubmit={onUpdateSubmit} record={user} defaultValues={user}>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="name" sx={labelSx}>
                ユーザー名 *
              </InputLabel>
              <TextInput
                source="name"
                variant="outlined"
                label={false}
                sx={textInputSx}
                validate={required('エラー入力してください')}
                placeholder="入力してください"
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="email" sx={labelSx}>
                メールアドレス *
              </InputLabel>
              <TextInput
                source="email"
                variant="outlined"
                label={false}
                sx={textInputSx}
                validate={[
                  required('エラー入力してください'),
                  email('メールアドレスを入力してください'),
                ]}
                placeholder="入力してください"
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="department" sx={labelSx}>
                部署 *
              </InputLabel>
              <TextInput
                source="department"
                variant="outlined"
                label={false}
                sx={textInputSx}
                validate={required('エラー入力してください')}
                placeholder="入力してください"
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="role" sx={labelSx}>
                権限 *
              </InputLabel>
              <SelectInput
                source="role"
                variant="outlined"
                label={false}
                sx={selectInputSx}
                validate={required('エラー選択してください')}
                emptyValue={'選択してください'}
                defaultValue={user?.authGroup.id}
                choices={authGroups}
                fullWidth
              />
            </Grid>
            <Grid container direction="row" justifyContent="flex-end">
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton onClick={handleOnCancelModalOpen}>
                  キャンセル
                </CancelButton>
              </Box>
              <SaveButton label="更新する" sx={saveButtonSx} />
            </Grid>
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  )
}

export default AddUserModal
