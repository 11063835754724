import React from 'react'
import { Admin, Resource, CustomRoutes, Authenticated } from 'react-admin'
import { Route } from 'react-router-dom'
import { createTheme } from '@mui/material/styles'
import { dataProvider } from './provider/data-provider'
import { authProvider } from './provider/auth-provider'
import Layout from './layouts/Layout'
import Login from './pages/Login'
import SizeList from './pages/SizeList'
import StoreList from './pages/StoreList'
import IngredientCategoryList from './pages/IngredientCategoryList'
import IngredientList from './pages/IngredientList'
import UserList from './pages/UserList'
import PasswordResetToken from './pages/PasswordResetToken'
import PasswordReset from './pages/PasswordReset'
import OfferGenreList from './pages/OfferGenreList'
import RecipeCategoryList from './pages/RecipeCategoryList'
import RecipeList from './pages/RecipeList'
import './assets/styles/index.scss'
import OrderLogList from './pages/OrderLogList'
import UtensilList from './pages/UtensilList'
import IngredientPlacementList from './pages/IngredientPlacementList'
import CipLogMachineList from './pages/CipLogMachineList'

const theme = createTheme({
  palette: {
    primary: {
      main: '#212121',
    },
    secondary: {
      main: '#005739',
    },
    warning: {
      main: '#CD0000',
    },
    text: {
      primary: '#000',
      secondary: '#757575',
    },
    background: {
      default: '#212121',
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Noto Sans JP',
    fontWeightRegular: 400,
    fontSize: 14,
  },
})

const App: React.FC = () => {
  return (
    <Admin
      loginPage={Login}
      layout={Layout}
      theme={theme}
      dataProvider={dataProvider}
      authProvider={authProvider}>
      <Resource
        name="order-logs"
        list={
          <Authenticated>
            <OrderLogList />
          </Authenticated>
        }
      />
      <Resource
        name="stores"
        list={
          <Authenticated>
            <StoreList />
          </Authenticated>
        }
      />
      <Resource
        name="ingredient-placements"
        list={
          <Authenticated>
            <IngredientPlacementList />
          </Authenticated>
        }
      />
      <Resource
        name="users"
        list={
          <Authenticated>
            <UserList />
          </Authenticated>
        }
      />
      <Resource
        name="ingredient-categories"
        list={
          <Authenticated>
            <IngredientCategoryList />
          </Authenticated>
        }
      />
      <Resource
        name="utensils"
        list={
          <Authenticated>
            <UtensilList />
          </Authenticated>
        }
      />
      <Resource
        name="sizes"
        list={
          <Authenticated>
            <SizeList />
          </Authenticated>
        }
      />
      <Resource
        name="offer-genres"
        list={
          <Authenticated>
            <OfferGenreList />
          </Authenticated>
        }
      />
      <Resource
        name="recipes"
        list={
          <Authenticated>
            <RecipeList />
          </Authenticated>
        }
      />
      <Resource
        name="recipe-categories"
        list={
          <Authenticated>
            <RecipeCategoryList />
          </Authenticated>
        }
      />
      <Resource
        name="ingredients"
        list={
          <Authenticated>
            <IngredientList />
          </Authenticated>
        }
      />
      <Resource
        name="cip-log-machines"
        list={
          <Authenticated>
            <CipLogMachineList />
          </Authenticated>
        }
      />
      <CustomRoutes noLayout>
        <Route path="/password-reset-token" element={<PasswordResetToken />} />
        <Route path="/password-reset" element={<PasswordReset />} />
      </CustomRoutes>
    </Admin>
  )
}

export default App
