import React from 'react'
import {
  RecipeCategoryRecord,
  RecipeParams,
  RecipeSetting,
  SizeScalingPresetRecord,
  RecipeIngredientInputParam,
} from '../../../types/records/recipe-record'
import { Grid, InputLabel, Typography, Divider } from '@mui/material'
import ConfirmIngredientField from './ConfirmIngredientField'
import ConfirmIngredientListField from './ConfirmIngredientListField'
import { IngredientRecord } from '../../../types/records/ingredient-record'
import { OfferGenreRecord } from '../../../types/records/offer-genre-record'
import { SizeScalingTypeRecord } from '../../../types/records/utensil-record'
import { SizeRecord } from '../../../types/records/size-record'
import { formatDate } from '../../../assets/utils/formData'
import {
  confirmGridContainerSx,
  confirmInputGridSx,
  confirmInputLabelSx,
} from '../../../assets/sx/form'
import {
  modalTitleSx,
  modalDividerSx,
  modalLeftItemLabelSx,
  modalRightItemInputSx,
} from '../../../assets/sx/modalSx'
type Props = {
  previewFormData: RecipeParams
  recipeSetting: RecipeSetting
  ingredients: IngredientRecord[]
  file: File
}

const RecipeModalConfirm: React.FC<Props> = ({
  previewFormData,
  recipeSetting,
  ingredients,
  file,
}) => {
  const previewImage = file?.name !== '' ? URL.createObjectURL(file) : ''

  const offerGenreMap = new Map<number, OfferGenreRecord>()
  recipeSetting.offerGenres.forEach((offerGenre) => {
    offerGenreMap.set(offerGenre.id, offerGenre)
  })

  const recipeCategoryMap = new Map<number, RecipeCategoryRecord>()
  recipeSetting.recipeCategories.forEach((recipeCategory) => {
    recipeCategoryMap.set(recipeCategory.id, recipeCategory)
  })

  const sizeScalingPresetMap = new Map<number, SizeScalingPresetRecord>()
  recipeSetting.sizeScalingPresets.forEach((sizeScalingPreset) => {
    sizeScalingPresetMap.set(sizeScalingPreset.id, sizeScalingPreset)
  })

  const sizeMap = new Map<number, SizeRecord>()
  recipeSetting.sizes.forEach((size) => {
    sizeMap.set(size.id, size)
  })

  const ingredientMap = new Map<number, IngredientRecord>()
  ingredients.forEach((ingredient) => {
    ingredientMap.set(ingredient.id, ingredient)
  })

  const sizeScalingTypeMap = new Map<number, SizeScalingTypeRecord>()
  recipeSetting.sizeScalingTypes.forEach((sizeScalingType) => {
    sizeScalingTypeMap.set(sizeScalingType.id, sizeScalingType)
  })

  return (
    previewFormData &&
    recipeSetting &&
    ingredients && (
      <Grid>
        <Typography sx={modalTitleSx}>レシピマスターを確認する</Typography>
        <Grid sx={confirmGridContainerSx} container>
          <InputLabel htmlFor="name" sx={modalLeftItemLabelSx}>
            レシピ名 *
          </InputLabel>
          <Grid sx={confirmInputGridSx}>{previewFormData.name}</Grid>
        </Grid>
        <Grid sx={confirmGridContainerSx} container>
          <InputLabel htmlFor="name" sx={modalLeftItemLabelSx}>
            表示名 *
          </InputLabel>
          <Grid sx={modalRightItemInputSx}>{previewFormData.displayName}</Grid>
        </Grid>
        <Grid sx={confirmGridContainerSx} container>
          <InputLabel htmlFor="name" sx={modalLeftItemLabelSx}>
            レシピカテゴリ *
          </InputLabel>
          <Grid sx={modalRightItemInputSx}>
            {
              recipeCategoryMap.get(previewFormData.recipeCategoryID as number)
                ?.name
            }
          </Grid>
        </Grid>
        <Grid sx={confirmGridContainerSx} container>
          <InputLabel htmlFor="name" sx={modalLeftItemLabelSx}>
            提供種別 *
          </InputLabel>
          <Grid sx={confirmInputGridSx}>
            {offerGenreMap.get(previewFormData.offerGenreID as number)?.name}
          </Grid>
        </Grid>
        <Grid sx={confirmGridContainerSx} container>
          <InputLabel htmlFor="name" sx={modalLeftItemLabelSx}>
            画像
          </InputLabel>
          {previewImage !== '' ? (
            <img
              src={previewImage}
              alt="previewImage"
              width="50%"
              style={{ borderRadius: '5px', border: '1px solid #ccc' }}
            />
          ) : (
            <Grid sx={confirmInputGridSx}> - </Grid>
          )}
        </Grid>
        <Grid sx={confirmGridContainerSx} container>
          <InputLabel htmlFor="name" sx={modalLeftItemLabelSx}>
            説明
          </InputLabel>
          <Grid sx={modalRightItemInputSx}>
            {previewFormData.description ? previewFormData.description : '-'}
          </Grid>
        </Grid>
        <Grid sx={confirmGridContainerSx} container>
          <InputLabel htmlFor="name" sx={modalLeftItemLabelSx}>
            提供開始日
          </InputLabel>
          <Grid sx={modalRightItemInputSx}>
            {formatDate(previewFormData.offerStartAt)}
          </Grid>
        </Grid>
        <Grid sx={confirmGridContainerSx} container>
          <InputLabel htmlFor="name" sx={modalLeftItemLabelSx}>
            提供終了日
          </InputLabel>
          <Grid sx={modalRightItemInputSx}>
            {formatDate(previewFormData.offerEndAt)}
          </Grid>
        </Grid>
        <ConfirmIngredientField
          sizeMap={sizeMap}
          ingredientMap={ingredientMap}
          sizeScalingPresetMap={sizeScalingPresetMap}
          sizeScalingTypeMap={sizeScalingTypeMap}
          ingredientCategoryName="シロップベース"
          recipeIngredientInput={
            previewFormData.syrupBaseRecipeIngredientInput as RecipeIngredientInputParam
          }
        />
        <Divider sx={modalDividerSx} />
        <ConfirmIngredientField
          sizeMap={sizeMap}
          ingredientMap={ingredientMap}
          sizeScalingPresetMap={sizeScalingPresetMap}
          sizeScalingTypeMap={sizeScalingTypeMap}
          ingredientCategoryName="ロースト/ティー"
          recipeIngredientInput={
            previewFormData.roastAndTeaRecipeIngredientInput as RecipeIngredientInputParam
          }
        />
        <Divider sx={modalDividerSx} />
        <ConfirmIngredientListField
          sizeMap={sizeMap}
          ingredientMap={ingredientMap}
          sizeScalingPresetMap={sizeScalingPresetMap}
          sizeScalingTypeMap={sizeScalingTypeMap}
          ingredientCategoryName="シロップ"
          recipeIngredientInputs={
            previewFormData.syrupRecipeIngredientInputs as RecipeIngredientInputParam[]
          }
        />
        <Divider sx={modalDividerSx} />
        <ConfirmIngredientListField
          sizeMap={sizeMap}
          ingredientMap={ingredientMap}
          sizeScalingPresetMap={sizeScalingPresetMap}
          sizeScalingTypeMap={sizeScalingTypeMap}
          ingredientCategoryName="パウダー"
          recipeIngredientInputs={
            previewFormData.powderRecipeIngredientInputs as RecipeIngredientInputParam[]
          }
        />
        <Divider sx={modalDividerSx} />
        <ConfirmIngredientListField
          sizeMap={sizeMap}
          ingredientMap={ingredientMap}
          sizeScalingPresetMap={sizeScalingPresetMap}
          sizeScalingTypeMap={sizeScalingTypeMap}
          ingredientCategoryName="チップ"
          recipeIngredientInputs={
            previewFormData.chipRecipeIngredientInputs as RecipeIngredientInputParam[]
          }
        />
        <Divider sx={modalDividerSx} />
        <ConfirmIngredientField
          sizeMap={sizeMap}
          ingredientMap={ingredientMap}
          sizeScalingPresetMap={sizeScalingPresetMap}
          sizeScalingTypeMap={sizeScalingTypeMap}
          ingredientCategoryName="ミルク/ジュース"
          recipeIngredientInput={
            previewFormData.dairyAndJuiceRecipeIngredientInput as RecipeIngredientInputParam
          }
        />
        <Divider sx={modalDividerSx} />
        <Grid>
          <Typography sx={modalTitleSx}>事前に手動で投入する材料</Typography>
          {previewFormData.manualRecipeIngredientInputs?.length === 0 && (
            <Grid sx={confirmGridContainerSx} container>
              <InputLabel sx={confirmInputLabelSx}>材料</InputLabel>
              <Grid sx={confirmInputGridSx}> - </Grid>
            </Grid>
          )}
          {previewFormData.manualRecipeIngredientInputs &&
            previewFormData.manualRecipeIngredientInputs.length > 0 &&
            previewFormData.manualRecipeIngredientInputs?.map(
              (target, index) => (
                <Grid key={`manualRecipeIngredientInputs_${index}`}>
                  <Grid sx={confirmGridContainerSx} container>
                    <InputLabel sx={confirmInputLabelSx}>材料</InputLabel>
                    <Grid sx={confirmInputGridSx}>
                      {ingredientMap.get(target.ingredientID as number)?.name}
                    </Grid>
                  </Grid>
                </Grid>
              ),
            )}
        </Grid>
        <Grid>
          <Divider sx={modalDividerSx} />
          <Typography sx={modalTitleSx}>ブレンド後のトッピング</Typography>
          {previewFormData.toppings?.length === 0 && (
            <Grid sx={confirmGridContainerSx} container>
              <InputLabel sx={confirmInputLabelSx}>材料</InputLabel>
              <Grid sx={confirmInputGridSx}> - </Grid>
            </Grid>
          )}
          {previewFormData.toppings?.length !== 0 &&
            previewFormData.toppings?.map((topping, index) => (
              <Grid
                sx={confirmGridContainerSx}
                key={`topping_${index}`}
                container>
                <InputLabel sx={confirmInputLabelSx}>材料</InputLabel>
                <Grid sx={confirmInputGridSx}>{topping.description}</Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
    )
  )
}

export default RecipeModalConfirm
