import React from 'react'
import { RecipeCategoryParams } from '../../types/records/recipe-category-record'
import { BlenderPresetRecord } from '../../types/records/blender-preset-record'
import useCancelModal from '../../hooks/useCancelModal'
import {
  Grid,
  Typography,
  Modal,
  Box,
  InputLabel,
  FormControl,
} from '@mui/material'
import CancelInputModal from './CancelInputModal'

import { Form, required, SaveButton, TextInput } from 'react-admin'
import CancelButton from '../button/CancelButton'
import { SizeRecord } from '../../types/records/size-record'
import {
  gridSx,
  labelGrayOutSx,
  textInputSx,
  selectInputSx,
  modalWrapperSx,
  cancelButtonWrapperSx,
  saveButtonSx,
} from '../../assets/sx/form'
import { modalTitleSx } from '../../assets/sx/modalSx'

import CustomPlaceholderSelectInput from '../customComponent/CustomPlaceholderSelectInput'

type Props = {
  blenderPresets: BlenderPresetRecord[]
  sizes: SizeRecord[]
  open: boolean
  onClose: () => void
  onSubmit: (data: RecipeCategoryParams) => void
}

const AddRecipeCategoryModal: React.FC<Props> = ({
  sizes,
  blenderPresets,
  open,
  onClose,
  onSubmit,
}) => {
  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onClose)

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box sx={modalWrapperSx}>
          <Typography sx={modalTitleSx}>レシピカテゴリを追加する</Typography>
          <Form onSubmit={onSubmit}>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="name" sx={labelGrayOutSx}>
                レシピカテゴリ名 *
              </InputLabel>
              <TextInput
                source="name"
                variant="outlined"
                label={false}
                placeholder="入力してください"
                sx={textInputSx}
                validate={required('エラー入力してください')}
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="displayName" sx={labelGrayOutSx}>
                レシピカテゴリ表示名 *
              </InputLabel>
              <TextInput
                source="disPlayName"
                variant="outlined"
                label={false}
                placeholder="入力してください"
                validate={required('エラー入力してください')}
                sx={textInputSx}
                fullWidth
              />
            </Grid>
            <Grid wrap="wrap" container>
              {sizes?.map((size: SizeRecord, index) => (
                <Grid
                  sx={{ ...gridSx, width: '175px', margin: 'auto' }}
                  key={size.name}
                  item>
                  <InputLabel
                    htmlFor="sizeRecipeBlenderLinks"
                    sx={labelGrayOutSx}>
                    プリセット_{size.name} *
                    <CustomPlaceholderSelectInput
                      type="formInput"
                      placeholder="選択してください"
                      source={`sizeRecipeBlenderLinks[${index}][sizeID]`}
                      choices={sizes}
                      defaultValue={size.id}
                      disabled
                      sx={{
                        display: 'none',
                      }}
                    />
                  </InputLabel>
                  <CustomPlaceholderSelectInput
                    type="formInput"
                    placeholder="選択"
                    source={`sizeRecipeBlenderLinks[${index}][blenderPresetID]`}
                    choices={blenderPresets}
                    label={false}
                    variant="outlined"
                    margin="none"
                    fullWidth
                    validate={required('エラー選択してください')}
                    sx={{ ...selectInputSx, maxWidth: '150px' }}
                  />
                </Grid>
              ))}
            </Grid>

            <FormControl fullWidth></FormControl>
            <Grid container direction="row" justifyContent="flex-end">
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton onClick={handleOnCancelModalOpen}>
                  キャンセル
                </CancelButton>
              </Box>
              <SaveButton label="登録する" sx={saveButtonSx} />
            </Grid>
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  )
}

export default AddRecipeCategoryModal
