export const convertFileToBase64 = (file: File) =>
  new Promise<FileReader>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader)
    reader.onerror = reject
    reader.readAsDataURL(file)
  })

export async function convertDataURLToFile(dataURL: string): Promise<File> {
  const urlProps = dataURL.split('/')
  const fileName = urlProps.pop()

  const blob = await (await fetch(dataURL)).blob()
  return new File([blob], fileName as string)
}
