import React from 'react'
import {
  Grid,
  Modal,
  InputLabel,
  Typography,
  Box,
  Divider,
} from '@mui/material'
import { IngredientPlacementRecord } from '../../../types/records/ingredient-placement-record'
import { HopperRecord } from '../../../types/records/hopper_record'
import { IngredientRecord } from '../../../types/records/ingredient-record'
import { StoreTypeRecord } from '../../../types/records/store-record'
import CancelButton from '../../button/CancelButton'
import { formatDate } from '../../../assets/utils/formData'
import {
  labelSx,
  confirmGridContainerSx,
  confirmInputGridSx,
  modalWrapperSx,
  cancelButtonWrapperSx,
} from '../../../assets/sx/form'
import {
  modalTitleSx,
  modalDividerSx,
  modalRightItemInputSx,
} from '../../../assets/sx/modalSx'

type Props = {
  ingredientPlacements: IngredientPlacementRecord
  hoppers: HopperRecord[]
  ingredients: IngredientRecord[]
  storeTypes: StoreTypeRecord[]
  open: boolean
  onClose: () => void
}

const ShowIngredientPlacementModal: React.FC<Props> = ({
  ingredientPlacements,
  hoppers,
  ingredients,
  storeTypes,
  open,
  onClose,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalWrapperSx}>
        <Grid>
          <Typography sx={modalTitleSx}>配置パターンを確認する</Typography>
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel
              sx={{
                ...labelSx,
                mb: 'auto',
                mr: 'auto',
                width: '50%',
                color: '#757575',
              }}>
              配置パターン名
            </InputLabel>
            <Grid sx={confirmInputGridSx}>{ingredientPlacements?.name}</Grid>
          </Grid>
          <Divider sx={modalDividerSx} />
          {/* ホッパーと材料の情報 */}
          {ingredientPlacements?.presetHopperIngredientLinks && (
            <Grid sx={confirmGridContainerSx} container>
              {ingredientPlacements?.presetHopperIngredientLinks
                .filter((ip) => Boolean(ip.ingredientID))
                .map((ip, index) => (
                  <React.Fragment key={index}>
                    <InputLabel
                      sx={{
                        ...labelSx,
                        mb: '24px',
                        width: '50%',
                        size: '14px',
                        color: '#757575',
                      }}>
                      {hoppers?.find((hopper) => hopper.id === ip.hopperID)
                        ?.name || '-'}
                    </InputLabel>
                    <Grid
                      id={`ingredient${index}`}
                      sx={{ mb: '24px', width: '50%', size: '16px' }}>
                      {ingredients.find(
                        (Ingredient) => Ingredient.id === ip.ingredientID,
                      )?.name || '-'}
                    </Grid>
                  </React.Fragment>
                ))}
            </Grid>
          )}
          <Divider sx={{ mt: '-24px', mb: '20px' }} />
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel
              sx={{
                ...labelSx,
                mt: 'auto',
                mb: 'auto',
                width: '50%',
                color: '#757575',
              }}>
              運用開始日時
            </InputLabel>
            <Grid sx={modalRightItemInputSx}>
              {formatDate(ingredientPlacements?.operationStartAt)}
            </Grid>
          </Grid>
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel
              sx={{
                ...labelSx,
                mt: 'auto',
                mb: 'auto',
                width: '50%',
                color: '#757575',
              }}>
              運用終了日時
            </InputLabel>
            <Grid sx={modalRightItemInputSx}>
              {formatDate(ingredientPlacements?.operationEndAt)}
            </Grid>
          </Grid>
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel
              sx={{
                ...labelSx,
                mb: 'auto',
                mr: 'auto',
                width: '50%',
                color: '#757575',
              }}>
              説明
            </InputLabel>
            <Grid sx={confirmInputGridSx}>
              {ingredientPlacements?.description}
            </Grid>
          </Grid>
          <Divider sx={modalDividerSx} />
          <Grid sx={confirmGridContainerSx} container>
            <InputLabel
              sx={{
                ...labelSx,
                mb: 'auto',
                mr: 'auto',
                width: '50%',
                color: '#757575',
              }}>
              対象店舗
            </InputLabel>
            <Grid sx={confirmInputGridSx}>
              {storeTypes?.find(
                (storeType) =>
                  storeType.id === ingredientPlacements?.storeType?.id,
              )?.name || '-'}
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="flex-end">
            <Box sx={cancelButtonWrapperSx}>
              <CancelButton onClick={onClose}>閉じる</CancelButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default ShowIngredientPlacementModal
