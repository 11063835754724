import React, { useEffect } from 'react'
import {
  IngredientCategoryRecord,
  IngredientCategoryType,
} from '../../../types/records/ingredient-record'
import { Grid, Typography, InputLabel, Checkbox } from '@mui/material'
import {
  gridSx,
  labelGrayOutSx,
  textInputSx,
  selectInputSx,
  checkBoxSx,
} from '../../../assets/sx/form'
import { modalTitleSx } from '../../../assets/sx/modalSx'
import { required, TextInput, SelectInput } from 'react-admin'
import { SizeRecord } from '../../../types/records/size-record'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  UtensilRecord,
  SizeScalingTypeRecord,
} from '../../../types/records/utensil-record'
import { TuningCustomizePresetRecord } from '../../../types/records/tuning-customize-preset-record'
import { SizeScalingPresetRecord } from '../../../types/records/recipe-record'
import { SizeScalingInput } from './SizeScalingInput'
import {
  IngredientParams,
  IngredientRecord,
} from '../../../types/records/ingredient-record'

type Props = {
  ingredient: IngredientRecord | undefined
  ingredientCategories: IngredientCategoryRecord[]
  sizes: SizeRecord[]
  utensil: UtensilRecord
  sizeScalingType: SizeScalingTypeRecord
  sizeScalingPreset: SizeScalingPresetRecord
  tuningCustomizePresets: TuningCustomizePresetRecord[]
  isNewSizeScalingPresetChecked: boolean
  isNewTuningCustomizePresetChecked: boolean
  ingredientParams: IngredientParams
  setIngredientParams: React.Dispatch<React.SetStateAction<IngredientParams>>
  setIsNewSizeScalingPresetChecked: React.Dispatch<
    React.SetStateAction<boolean>
  >
  setIsNewTuningCustomizePresetChecked: React.Dispatch<
    React.SetStateAction<boolean>
  >
}

const EditIngredientModalSizeScalingPresetInput: React.FC<Props> = ({
  ingredient,
  ingredientCategories,
  sizes,
  utensil,
  sizeScalingType,
  sizeScalingPreset,
  isNewSizeScalingPresetChecked,
  ingredientParams,
  setIngredientParams,
  setIsNewSizeScalingPresetChecked,
}) => {
  const isSizeScalingTypeSame: () => string | undefined = () => {
    if (utensil && sizeScalingPreset) {
      return utensil.sizeScalingType.id !== sizeScalingPreset.sizeScalingType.id
        ? 'エラー基準投入量と同じ単位のプリセットを選択してください'
        : undefined
    }
  }

  // 材料カテゴリ内の手動投入材料のレコードを取得
  const manualInputCategory: IngredientCategoryRecord | undefined =
    ingredientCategories.find(
      (target) => target.name === IngredientCategoryType.ManualInput,
    )

  useEffect(() => {
    if (ingredientParams.ingredientCategoryID === manualInputCategory?.id) {
      setIsNewSizeScalingPresetChecked(false)
    }
  }, [ingredientParams.ingredientCategoryID])

  return (
    <Grid>
      <Typography sx={modalTitleSx}>材料を編集する 3/4</Typography>
      <FormControlLabel
        checked={isNewSizeScalingPresetChecked}
        value="top"
        control={
          <Checkbox
            disabled={
              ingredientParams.ingredientCategoryID === manualInputCategory?.id
            }
            onClick={() =>
              isNewSizeScalingPresetChecked
                ? setIsNewSizeScalingPresetChecked(false)
                : setIsNewSizeScalingPresetChecked(true)
            }
            sx={checkBoxSx}
          />
        }
        label={<span style={{ fontSize: '14px' }}>手動で入力する</span>}
        sx={{ ...labelGrayOutSx, mt: '-5%' }}
      />
      {!isNewSizeScalingPresetChecked ? (
        <Grid sx={gridSx}>
          <InputLabel htmlFor="sizeScalingPresetID" sx={labelGrayOutSx}>
            材料の投入量を選択 *
          </InputLabel>
          <SelectInput
            source="sizeScalingPresetID"
            variant="outlined"
            label={false}
            placeholder="入力してください"
            sx={selectInputSx}
            choices={sizeScalingType ? sizeScalingType.sizeScalingPresets : []}
            validate={[
              required('エラー入力してください'),
              isSizeScalingTypeSame,
            ]}
            fullWidth
            defaultValue={ingredient?.sizeScalingPresetID}
            onChange={(e) => {
              setIngredientParams({
                ...ingredientParams,
                sizeScalingPresetID: e.target.value,
              })
            }}
          />
        </Grid>
      ) : (
        <Grid>
          <Grid>
            <Grid sx={gridSx}>
              <InputLabel
                htmlFor="newSizeScalingPresetName"
                sx={labelGrayOutSx}>
                投入プリセット名 *
              </InputLabel>
              <TextInput
                source="newSizeScalingPreset[name]]"
                label={false}
                placeholder="入力してください"
                sx={textInputSx}
                validate={required('エラー入力してください')}
                fullWidth
                onChange={(e) =>
                  setIngredientParams({
                    ...ingredientParams,
                    newSizeScalingPreset: {
                      ...ingredientParams.newSizeScalingPreset,
                      name: e.target.value,
                      sizeScalingTypeID:
                        ingredientParams.newSizeScalingPreset
                          ?.sizeScalingTypeID || 0, // type が未定義の場合、デフォルト値を設定
                      ingredientSizeCoefficients:
                        ingredientParams.newSizeScalingPreset
                          ?.ingredientSizeCoefficients || [], // ingredientSizeCoefficients が未定義の場合、空の配列を設定
                    },
                  })
                }
              />
            </Grid>
            <Grid wrap="wrap" container>
              {sizes?.map((size: SizeRecord, index) => (
                <Grid
                  sx={{ ...gridSx, width: '50%' }}
                  item
                  key={size.name}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <SizeScalingInput
                    size={size}
                    sizeScalingType={sizeScalingType}
                    index={index}
                    ingredientParams={ingredientParams}
                    setIngredientParams={setIngredientParams}
                  />
                  <Typography
                    sx={{
                      fontSize: '14px',
                      ml: '8px',
                      color: '#757575',
                      width: '60px',
                    }}>
                    {sizeScalingType?.name}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default EditIngredientModalSizeScalingPresetInput
