import React, { MouseEvent } from 'react'
import { Button, Tooltip } from '@mui/material'
import { iconButtonSx } from '../../assets/sx/field'

type Props<T> = {
  onClick: (e: MouseEvent<HTMLButtonElement>, record: T) => void
  icon: JSX.Element
  tooltipLabel: string
  record: T
}

const IconButton = <T,>({ onClick, icon, tooltipLabel, record }: Props<T>) => {
  return (
    <Tooltip
      title={tooltipLabel}
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            backgroundColor: '#373737',
            marginTop: '8px!important',
            marginBottom: '8px!important',
            fontWeight: '400',
          },
        },
      }}>
      <Button sx={iconButtonSx} onClick={(e) => onClick(e, record)}>
        {icon}
      </Button>
    </Tooltip>
  )
}

export default IconButton
