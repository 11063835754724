import React, { MouseEvent, useState } from 'react'
import { useGetList, useDataProvider, useNotify, useRefresh } from 'react-admin'
import AddButton from '../components/button/AddButton'
import MoreButton from '../components/button/MoreButton'
import AddIngredientModal from '../components/modal/AddIngredientItemModal'
import {
  IngredientCategoryRecord,
  IngredientParams,
} from '../types/records/ingredient-record'
import {
  Container,
  TableContainer,
  Grid,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'

const IngredientItemColumnSx: SxProps<Theme> = {
  bgcolor: '#FBFAF9',
  color: '#757575',
  fontSize: 12,
}

const IngredientCategoryList: React.FC = () => {
  const [openCreateModalFlag, setOpenCreateModalFlag] = useState<boolean>(false)
  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const notify = useNotify()
  const { data } = useGetList('ingredient-categories', {
    pagination: { page: 1, perPage: 6 },
  })
  const [targetCategory, setTargetCategory] =
    useState<IngredientCategoryRecord>()
  const openCreateModal = (
    e: MouseEvent<HTMLButtonElement>,
    targetCategory: IngredientCategoryRecord,
  ) => {
    e.preventDefault()
    setTargetCategory(targetCategory)
    setOpenCreateModalFlag(true)
  }
  // MEMO lintで使用されていないメソッドとして警告されるので一旦コメントアウト
  // const openEditModal = (
  //   e: MouseEvent<HTMLButtonElement>,
  //   targetCategory: IngredientCategoryRecord,
  //   targetIngredientItem: IngredientItemRecord,
  // ) => {
  //   e.preventDefault()
  //   setTargetCategory(targetCategory)
  //   setTargetIngredientItem(targetIngredientItem)
  //   setOpenEditModalFlag(true)
  // }

  const closeModal = () => {
    setOpenCreateModalFlag(false)
  }
  const onCreateSubmit = (data: IngredientParams) => {
    dataProvider
      .create('ingredient-items', { data })
      .then(() => {
        notify('材料を追加しました')
        setOpenCreateModalFlag(false)
        refresh()
      })
      .catch(() => {
        notify('材料の追加に失敗しました')
      })
  }

  return (
    <Paper sx={{ bgcolor: 'transparent', display: 'flex', flexWrap: 'wrap' }}>
      {data?.map((ingredientCategory: IngredientCategoryRecord) => (
        <Container
          key={ingredientCategory.id}
          sx={{
            width: 360,
            height: 434,
            bgcolor: '#FFF',
            color: '#000',
            margin: '8px',
            borderRadius: '8px',
            textAlign: 'left',
            padding: '0 !important',
          }}>
          <Grid
            container
            direction="row"
            alignItems="center"
            sx={{ flexWrap: 'no-wrap', height: '20%', padding: '0 24px' }}>
            <Typography
              sx={{
                bgcolor: '#FFF',
                fontSize: 20,
                fontWeight: 700,
                width: '60%',
              }}>
              材料マスター：
              <br />
              {ingredientCategory.name}
            </Typography>
            <Box sx={{ width: '40%' }}>
              <AddButton
                onClick={(e) => openCreateModal(e, ingredientCategory)}>
                追加する
              </AddButton>
            </Box>
          </Grid>
          <TableContainer
            sx={{
              overflow: 'scroll',
              height: '80%',
              width: '100%',
              borderTop: '1px solid #F1EEE9',
              borderRadius: 2,
            }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ width: '70%' }}
                    sx={IngredientItemColumnSx}>
                    材料名
                  </TableCell>
                  <TableCell
                    style={{ width: '20%' }}
                    sx={IngredientItemColumnSx}>
                    ID
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ width: '10%' }}
                    sx={IngredientItemColumnSx}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ingredientCategory.ingredients?.map((ingredient) => (
                  <TableRow
                    key={ingredient.id}
                    sx={{
                      height: 60,
                    }}>
                    <TableCell>{ingredient.name}</TableCell>
                    <TableCell>{ingredient.id}</TableCell>
                    <TableCell align="right">
                      <MoreButton
                        onClick={() => {
                          return
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      ))}
      <AddIngredientModal
        open={openCreateModalFlag}
        onClose={closeModal}
        onSubmit={onCreateSubmit}
        ingredientCategory={targetCategory}
      />
      {/* <EditIngredientModal
        open={openEditModalFlag}
        onClose={closeModal}
        onSubmit={onUpdateSubmit}
        ingredient={targetIngredient}
        ingredientCategories={data as IngredientCategoryRecord[]}
      /> */}
    </Paper>
  )
}

export default IngredientCategoryList
