import React from 'react'
import { useRecordContext } from 'react-admin'
import { format, parseISO } from 'date-fns'

import { CipLogMachineRecord } from '../../types/records/cip-log-record'

const CipLogMachineLatestApprovedAtField = () => {
  const record = useRecordContext<CipLogMachineRecord>()

  if (!record) {
    return null
  }

  return (
    <>
      {record.latestApprovedAt ? (
        !record.isApproved ? (
          <span style={{ color: '#C82420' }}>
            {format(parseISO(record.latestApprovedAt), 'yyyy/MM/dd HH:mm')}
          </span>
        ) : (
          <span>
            {format(parseISO(record.latestApprovedAt), 'yyyy/MM/dd HH:mm')}
          </span>
        )
      ) : (
        <></>
      )}
    </>
  )
}

export default CipLogMachineLatestApprovedAtField
