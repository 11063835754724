import React, { MouseEvent, useState } from 'react'

import {
  TextField,
  FunctionField,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin'
import { Grid } from '@mui/material'

import ListDatagrid from '../components/ListDatagrid'
import AddButton from '../components/button/AddButton'
import IconButton from '../components/button/IconButton'
import AddOfferGenreModal from '../components/modal/AddOfferGenreModal'
import EditOfferGenreModal from '../components/modal/EditOfferGenreModal'

import {
  OfferGenreParams,
  OfferGenreRecord,
} from '../types/records/offer-genre-record'
import { useAuthorize, useNotifyForbidden } from '../hooks/authorization'
import { ReactComponent as PencilIcon } from '../assets/images/pencil.svg'

import styles from './OfferGenreList.module.scss'
import { firstFieldSx } from '../assets/sx/field'

const OfferGenreList: React.FC = () => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const [targetOfferGenre, setTargetOfferGenre] = useState<OfferGenreRecord>()
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false)
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const canCreateOfferGenre = useAuthorize('offerGenre', 'create')
  const canUpdateOfferGenre = useAuthorize('offerGenre', 'update')
  const notifyForbidden = useNotifyForbidden()

  const openCreateModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setCreateModalOpen(true)
  }

  const openEditModal = (
    e: MouseEvent<HTMLElement>,
    targetOfferGenre: OfferGenreRecord | undefined,
  ) => {
    e.preventDefault()

    if (!canUpdateOfferGenre) {
      notifyForbidden()
      return
    }
    setEditModalOpen(true)
    setTargetOfferGenre(targetOfferGenre)
  }

  const closeCreateModal = () => {
    setCreateModalOpen(false)
  }

  const closeEditModal = () => {
    setEditModalOpen(false)
  }

  const onCreateSubmit = (data: OfferGenreParams) => {
    dataProvider
      .create('offer-genres', { data })
      .then(() => {
        notify('提供種別を追加しました')
        setCreateModalOpen(false)
        refresh()
      })
      .catch((error) => {
        notify(error.message, { type: 'error' })
      })
  }

  const onUpdateSubmit = (
    id: number,
    data: OfferGenreParams,
    previousData: OfferGenreRecord,
  ) => {
    dataProvider
      .update('offer-genres', { id, data, previousData })
      .then(() => {
        notify('提供種別を更新しました')
        setEditModalOpen(false)
        refresh()
      })
      .catch((error) => {
        notify(error.message, { type: 'error' })
      })
  }

  const settingButtonItems = [
    {
      tooltipLabel: '編集',
      icon: <PencilIcon />,
      onClick: openEditModal,
    },
  ]

  return (
    <>
      <ListDatagrid
        title="提供種別"
        filters={[]}
        addButton={
          <AddButton onClick={openCreateModal} disabled={!canCreateOfferGenre}>
            提供種別を追加する
          </AddButton>
        }
        modals={[
          <AddOfferGenreModal
            key="addOfferGenreModal"
            open={createModalOpen}
            onClose={closeCreateModal}
            onSubmit={onCreateSubmit}></AddOfferGenreModal>,
          <EditOfferGenreModal
            key="editOfferGenreModal"
            open={editModalOpen}
            offerGenre={targetOfferGenre}
            onClose={closeEditModal}
            onSubmit={onUpdateSubmit}></EditOfferGenreModal>,
        ]}>
        <TextField
          source="name"
          label="提供種別名"
          sortable={false}
          headerClassName={styles.offerGenreShortField}
          sx={firstFieldSx}
        />
        <TextField
          source="id"
          label="提供種別ID"
          sortable={false}
          headerClassName={styles.offerGenreShortField}
        />
        <TextField
          source="description"
          label="解説"
          sortable={false}
          headerClassName={styles.offerGenreWideField}
        />
        <FunctionField
          sortable={false}
          headerClassName={styles.offerGenreIconButtonField}
          render={(record: OfferGenreRecord) => {
            return (
              <Grid container display="flex" justifyContent="space=around">
                {settingButtonItems.map((item, index) => {
                  return (
                    <IconButton<OfferGenreRecord>
                      key={`iconButton_${index}`}
                      tooltipLabel={item.tooltipLabel}
                      onClick={item.onClick}
                      icon={item.icon}
                      record={record}
                    />
                  )
                })}
              </Grid>
            )
          }}
        />
      </ListDatagrid>
    </>
  )
}

export default OfferGenreList
