import React, { useState } from 'react'
import { Menu, useLogout, useNotify } from 'react-admin'
import { ReactComponent as CookingLogIcon } from '../assets/images/cooking_log.svg'
import { ReactComponent as RecipeIcon } from '../assets/images/recipe.svg'
import { ReactComponent as IngredientIcon } from '../assets/images/ingredient.svg'
import { ReactComponent as StoreIcon } from '../assets/images/store.svg'
import { ReactComponent as UserIcon } from '../assets/images/user.svg'
import { ReactComponent as PinIcon } from '../assets/images/pin.svg'
import { ReactComponent as CipLogIcon } from '../assets/images/cip_log.svg'
import { ReactComponent as LogoutIcon } from '../assets/images/logout.svg'
import LogoutModal from '../components/modal/LogoutModal'
import ToggleMenu from './ToggleMenu'
import Logo from '../assets/images/wide_logo.svg'
import style from './Sidebar.module.scss'
import { Grid } from '@mui/material'
import { sidebarSx } from '../assets/sx/sidebar'

// eslint-disable-next-line
const MySidebar: React.FC<any> = (props) => {
  // サイドバー表示時、アイコンだけになるのを防ぐため、sidebar.openをtrueにする
  localStorage.setItem('RaStore.sidebar.open', 'true')
  const logout = useLogout()
  const notify = useNotify()
  const [logoutModalOpen, setLogoutModalOpen] = useState<boolean>(false)
  const closeLogoutModal = () => {
    setLogoutModalOpen(false)
  }

  const onLogout = () => {
    logout().then(() => {
      notify('ログアウトしました')
    })
  }

  return (
    <Menu className={style['hide-sidebar']} sx={sidebarSx}>
      <Grid
        style={{
          alignItems: 'center',
          padding: '24px',
        }}>
        <img src={Logo} alt="Image" style={{ width: '173px' }} />
      </Grid>
      <Menu.Item
        className={style['sidebar-item']}
        to="/order-logs"
        primaryText="オーダーログ"
        leftIcon={<CookingLogIcon />}
      />
      <ToggleMenu
        menuIcon={<RecipeIcon />}
        menuName="レシピマスター"
        subMenus={[
          {
            path: '/recipes',
            name: 'レシピ',
          },
          {
            path: '/offer-genres',
            name: '提供種別',
          },
          {
            path: '/recipe-categories',
            name: 'レシピカテゴリー',
          },
        ]}
      />
      <ToggleMenu
        menuIcon={<IngredientIcon />}
        menuName="材料マスター"
        subMenus={[
          {
            path: '/ingredients',
            name: '材料',
          },
          {
            path: '/utensils',
            name: '器具',
          },
        ]}
      />
      <Menu.Item
        className={style['sidebar-item']}
        to="/stores"
        primaryText="店舗マスター"
        leftIcon={<StoreIcon />}
      />
      <Menu.Item
        className={style['sidebar-item']}
        to="/ingredient-placements"
        primaryText="材料配置マスター"
        leftIcon={<PinIcon />}
      />
      <Menu.Item
        className={style['sidebar-item']}
        to="/users"
        primaryText="ユーザーマスター"
        leftIcon={<UserIcon />}
      />
      <Menu.Item
        className={style['sidebar-item']}
        to="/cip-log-machines"
        primaryText="CIPログマスター"
        leftIcon={<CipLogIcon />}
      />
      <Grid sx={{ height: '100%', display: 'flex', alignItems: 'flex-end' }}>
        <Menu.DashboardItem
          className={style['sidebar-item']}
          onClick={() => setLogoutModalOpen(true)}
          primaryText="ログアウト"
          leftIcon={<LogoutIcon />}
        />
      </Grid>
      <LogoutModal
        onLogout={onLogout}
        onClose={closeLogoutModal}
        open={logoutModalOpen}
      />
    </Menu>
  )
}

export default MySidebar
