import React, { useEffect, useState } from 'react'
import { format, parseISO } from 'date-fns'
import {
  IngredientRecord,
  IngredientParams,
  IngredientCategoryRecord,
  NewTuningCustomizePresetParams,
  NewCustomizePresetParams,
} from '../../../types/records/ingredient-record'
import { Grid, Modal, Box, Button } from '@mui/material'
import CancelInputModal from '../CancelInputModal'
import useCancelModal from '../../../hooks/useCancelModal'
import { Form, SaveButton } from 'react-admin'
import CancelButton from '../../button/CancelButton'
import { SizeRecord } from '../../../types/records/size-record'
import {
  UtensilRecord,
  SizeScalingTypeRecord,
} from '../../../types/records/utensil-record'
import { SizeScalingPresetRecord } from '../../../types/records/recipe-record'
import { TuningCustomizePresetRecord } from '../../../types/records/tuning-customize-preset-record'
import {
  convertFileToBase64,
  convertDataURLToFile,
} from '../../../service/images'
import { ModalSectionType } from './ModalSectionType'
import {
  cancelButtonWrapperSx,
  saveButtonSx,
  nextButtonSx,
  modalWrapperSx,
} from '../../../assets/sx/form'
import IngredientModalConfirm from './IngredientModalConfirm'
import EditIngredientModalBasicInfo from './EditIngredientModalBasicInfo'
import EditIngredientModalAmountInput from './EditIngredientModalAmountInput'
import EditIngredientModalSizeScalingPresetInput from './EditIngredientModalSizeScalingPreset'
import EditIngredientModalTuningCustomizePresetInput from './EditIngredientModalTuningCustomizeInput'

type Props = {
  ingredient: IngredientRecord | undefined
  ingredientCategories: IngredientCategoryRecord[]
  sizes: SizeRecord[]
  utensils: UtensilRecord[]
  sizeScalingPresets: SizeScalingPresetRecord[]
  sizeScalingTypes: SizeScalingTypeRecord[]
  tuningCustomizePresets: TuningCustomizePresetRecord[]
  open: boolean
  onClose: () => void
  onSubmit: (
    id: number,
    data: IngredientParams,
    previousData: IngredientRecord,
  ) => void
}

const EditIngredientModal: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  ingredientCategories,
  utensils,
  sizeScalingTypes,
  sizes,
  sizeScalingPresets,
  tuningCustomizePresets,
  ingredient,
}) => {
  const [file, setFile] = useState<File>(new File([], ''))
  const [isFileChanged, setIsFileChanged] = useState(false)

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      setFile(file)
      setIsFileChanged(true)
    }
  }

  useEffect(() => {
    if (ingredient?.imageURL) {
      convertDataURLToFile(ingredient.imageURL).then((file: File) => {
        setFile(file)
      })
    }
  }, [ingredient])

  const [previewIngredient, setPreviewIngredient] = useState<IngredientParams>()
  const [previewNewCustomizePreset] = useState<NewCustomizePresetParams>()
  const [previewNewTuningCustomizePreset] =
    useState<NewTuningCustomizePresetParams>()

  const [isNewSizeScalingPresetChecked, setIsNewSizeScalingPresetChecked] =
    useState<boolean>(false)
  const [
    isNewTuningCustomizePresetChecked,
    setIsNewTuningCustomizePresetChecked,
  ] = useState<boolean>(false)

  const [targetSizeScalingType, setTargetSizeScalingType] =
    useState<SizeScalingTypeRecord>()
  const [targetUtensil, setTargetUtensil] = useState<UtensilRecord>()
  const [targetSizeScalingPreset, setTargetSizeScalingPreset] =
    useState<SizeScalingPresetRecord>()

  const updateSizeScalingTypeState = (utensilID: number) => {
    const targetUtensil = utensils.find((utensil) => utensil.id === utensilID)
    targetUtensil && setTargetUtensil({ ...targetUtensil })
    const sizeScalingType = sizeScalingTypes.find(
      (sizeScalingType) =>
        sizeScalingType.id === targetUtensil?.sizeScalingType.id,
    )
    ingredientParams.sizeScalingPresetID = undefined
    sizeScalingType && setTargetSizeScalingType({ ...sizeScalingType })
  }

  const findSizeScalingPreset = (id: number) => {
    const sizeScalingPreset = targetSizeScalingType?.sizeScalingPresets.find(
      (target) => target.id == id,
    )
    sizeScalingPreset && setTargetSizeScalingPreset({ ...sizeScalingPreset })
  }

  const formatDate = (dateString: string): string => {
    const date = parseISO(dateString)
    return format(date, 'yyyy-MM-dd')
  }

  const [ingredientParams, setIngredientParams] = useState<IngredientParams>({})
  const noTuningCustomizePreset: TuningCustomizePresetRecord | undefined =
    tuningCustomizePresets?.find(
      (tuningCustomizePreset) =>
        tuningCustomizePreset.name === '量調整なし (減量:0.0, 増量:0.0)',
    )
  const [targetIngredientCategory, setTargetIngredientCategory] =
    useState<IngredientCategoryRecord>()

  // 既存のレコードの値をparamsに持たせる
  const ingredientRecordToParams = (
    record: IngredientRecord,
  ): IngredientParams => {
    return {
      name: record.name,
      displayName: record.displayName,
      containerCapacityAmount: record.containerCapacityAmount,
      utensilID: record.utensil.id,
      tuningCustomizePresetID: record.tuningCustomizePresetID,
      sizeScalingPresetID: record.sizeScalingPresetID,
      imageURL: record.imageURL,
      ingredientCategoryID: record.ingredientCategory?.id,
      offerStartAt: formatDate(record.offerStartAt),
      offerEndAt: formatDate(record.offerEndAt),
    }
  }

  useEffect(() => {
    if (ingredient) {
      setIngredientParams(ingredientRecordToParams(ingredient))
    } else {
      setIngredientParams({})
    }
  }, [ingredient])

  useEffect(() => {
    if (!open) {
      setModalSection(ModalSectionType.BasicInfo)
    }
  }, [open])

  useEffect(() => {
    ingredient && updateSizeScalingTypeState(ingredient.utensil.id)
    ingredient && findSizeScalingPreset(ingredient?.sizeScalingPresetID)
  }, [open, ingredient])

  useEffect(() => {
    if (ingredientParams.ingredientCategoryID) {
      const targetIngredientCategory = ingredientCategories.find(
        (ingredientCategory) =>
          ingredientCategory.id === ingredientParams.ingredientCategoryID,
      )
      if (!targetIngredientCategory) return
      if (!targetIngredientCategory.isTunable) {
        const newIngredientParams = {
          ...ingredientParams,
          tuningCustomizePresetID: noTuningCustomizePreset?.id,
          newTuningCustomizePreset: undefined,
        }
        setIngredientParams(newIngredientParams)
      }
      setTargetIngredientCategory(targetIngredientCategory)
    }
  }, [ingredientParams.ingredientCategoryID])

  const canMoveToAmountInputSection = () => {
    if (
      ingredientParams.name &&
      ingredientParams.displayName &&
      ingredientParams.ingredientCategoryID &&
      ingredientParams.utensilID &&
      ingredientParams.offerStartAt &&
      ingredientParams.offerEndAt
    ) {
      const startAt = new Date(
        parseISO(ingredientParams.offerStartAt as string),
      )
      const endAt = new Date(parseISO(ingredientParams.offerEndAt as string))

      if (startAt.getTime() < endAt.getTime()) {
        return true
      }
    }
    return false
  }

  const canMoveToSizeScalingInputSection = () => {
    if (
      ingredientParams.containerCapacityAmount &&
      ingredientParams.containerCapacityAmount > 0
    ) {
      return true
    }
    return false
  }

  const canMoveToTuningCustomizeInputSection = () => {
    if (isNewSizeScalingPresetChecked) {
      // サイズスケーリングプリセットの手動入力がチェックされている場合、
      // 必要なパラメータが存在することを確認する
      if (
        ingredientParams.newSizeScalingPreset?.name &&
        ingredientParams.newSizeScalingPreset?.ingredientSizeCoefficients
          ?.length === 4 &&
        ingredientParams.newSizeScalingPreset?.ingredientSizeCoefficients.every(
          (isc) => isc.coefficient && isc.coefficient > 0,
        )
      ) {
        return true
      }
    } else {
      // 既存のサイズスケーリングプリセットが選択されている場合、
      // ingredientParams.sizeScalingPresetID が存在することを確認する
      if (ingredientParams.sizeScalingPresetID && ingredientParams.utensilID) {
        // utensilを取得
        const selectedUtensil = utensils.find(
          (utensil) => utensil.id === ingredientParams.utensilID,
        )
        // sizeScalingPresetを取得
        const selectedSizeScalingPreset = sizeScalingPresets.find(
          (preset) => preset.id === ingredientParams.sizeScalingPresetID,
        )

        if (!selectedUtensil || !selectedSizeScalingPreset) {
          // utensilまたはsizeScalingPresetが見つからなかった場合はエラーハンドリング
          return false
        }

        if (
          selectedUtensil.sizeScalingType.id !==
          selectedSizeScalingPreset.sizeScalingType.id
        ) {
          // selectedUtensilとselectedSizeScalingPresetのsizeScalingTypeが一致しない場合
          return false
        }

        return true
      }
    }
    return false
  }

  const canMoveToConfirmSection = () => {
    if (isNewTuningCustomizePresetChecked) {
      // 量調整カスタマイズプリセットの手動入力がチェックされている場合、
      // 必要なパラメータが存在することを確認する
      if (
        ingredientParams.newTuningCustomizePreset?.name &&
        ingredientParams.newTuningCustomizePreset?.tuningCustomizeCoefficients
          ?.length === 2
      ) {
        return true
      }
    } else {
      // 既存のサイズスケーリングプリセットが選択されている場合、
      // ingredientParams.tuningCustomizePresetID が存在することを確認する
      if (ingredientParams.tuningCustomizePresetID) {
        return true
      }
    }
    return false
  }

  const initialIngredientParams = {
    name: '',
    displayName: '',
    ingredientCategoryID: undefined,
    utensilID: undefined,
    offerStartAt: undefined,
    offerEndAt: undefined,
  }

  const initialization = () => {
    setIngredientParams(initialIngredientParams)
    setIsNewTuningCustomizePresetChecked(false)
    setIsNewSizeScalingPresetChecked(false)
  }

  const onUpdateIngredientSubmit = () => {
    if (!ingredient || !ingredient.id || !previewIngredient) {
      return null
    }

    if (file.name !== '') {
      convertFileToBase64(file as File).then((reader) => {
        previewIngredient.imageURL = reader.result as string
        onSubmit(ingredient.id, previewIngredient, ingredient)
        setFile(new File([], ''))
      })
    } else {
      onSubmit(ingredient.id, previewIngredient, ingredient)
    }

    // submit後にingredientParamsを初期化
    if (!open) {
      initialization()
    }
  }

  // 次のセクションに移動する処理
  const [modalSection, setModalSection] = useState<ModalSectionType>(
    ModalSectionType.BasicInfo,
  )
  const moveToSizeScalingInputSection = () => {
    setModalSection(ModalSectionType.SizeScalingPreset)
  }
  const moveToTuningCustomizeInputSection = () => {
    if (
      !targetIngredientCategory?.isTunable &&
      modalSection === ModalSectionType.SizeScalingPreset
    ) {
      moveToConfirmSection()
      return
    }
    if (
      !targetIngredientCategory?.isTunable &&
      modalSection === ModalSectionType.Confirm
    ) {
      setModalSection(ModalSectionType.SizeScalingPreset)
      return
    }
    setModalSection(ModalSectionType.TuningCustomizePreset)
  }
  const moveToAmountInputSection = () => {
    // 手動投入材料の時はスキップする
    if (ingredientParams.ingredientCategoryID == 1) {
      setModalSection(ModalSectionType.SizeScalingPreset)
    } else {
      setModalSection(ModalSectionType.AmountInput)
    }
  }
  const moveToBasicInfoSection = () => {
    setModalSection(ModalSectionType.BasicInfo)
  }

  const moveToConfirmSection = () => {
    const updatedIngredientParams = { ...ingredientParams }

    // ingredientParamsの値は維持して、送信する値を切り替える
    if (isNewSizeScalingPresetChecked) {
      delete updatedIngredientParams.sizeScalingPresetID
    } else {
      delete updatedIngredientParams.newSizeScalingPreset
    }

    if (isNewTuningCustomizePresetChecked) {
      delete updatedIngredientParams.tuningCustomizePresetID
    } else {
      delete updatedIngredientParams.newTuningCustomizePreset
    }

    // 手動投入材料の時に容器容量を0にする
    if (ingredientParams.ingredientCategoryID == 1) {
      updatedIngredientParams.containerCapacityAmount = 0
    }

    setModalSection(ModalSectionType.Confirm)
    setPreviewIngredient(updatedIngredientParams)
  }

  const onCancel = () => {
    onClose()
    setFile(new File([], ''))
  }

  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onCancel)

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box sx={modalWrapperSx}>
          <Form onSubmit={onUpdateIngredientSubmit} mode="onBlur">
            {modalSection === ModalSectionType.BasicInfo ? (
              <EditIngredientModalBasicInfo
                ingredient={ingredient}
                ingredientCategories={ingredientCategories}
                sizes={sizes}
                utensils={utensils}
                file={file}
                ingredientParams={ingredientParams}
                setIngredientParams={setIngredientParams}
                updateSizeScalingTypeState={updateSizeScalingTypeState}
                onChangeFile={onChangeFile}
              />
            ) : (
              <></>
            )}
            {modalSection === ModalSectionType.AmountInput ? (
              <EditIngredientModalAmountInput
                ingredient={ingredient}
                ingredientParams={ingredientParams}
                setIngredientParams={setIngredientParams}
              />
            ) : (
              <></>
            )}
            {modalSection === ModalSectionType.SizeScalingPreset ? (
              <EditIngredientModalSizeScalingPresetInput
                ingredient={ingredient}
                ingredientCategories={ingredientCategories}
                sizes={sizes}
                utensil={targetUtensil as UtensilRecord}
                sizeScalingType={targetSizeScalingType as SizeScalingTypeRecord}
                sizeScalingPreset={
                  targetSizeScalingPreset as SizeScalingPresetRecord
                }
                tuningCustomizePresets={tuningCustomizePresets}
                isNewSizeScalingPresetChecked={isNewSizeScalingPresetChecked}
                isNewTuningCustomizePresetChecked={
                  isNewTuningCustomizePresetChecked
                }
                setIsNewSizeScalingPresetChecked={
                  setIsNewSizeScalingPresetChecked
                }
                setIsNewTuningCustomizePresetChecked={
                  setIsNewTuningCustomizePresetChecked
                }
                ingredientParams={ingredientParams}
                setIngredientParams={setIngredientParams}
              />
            ) : (
              <></>
            )}
            {modalSection === ModalSectionType.TuningCustomizePreset ? (
              <EditIngredientModalTuningCustomizePresetInput
                ingredient={ingredient}
                ingredientCategories={ingredientCategories}
                sizes={sizes}
                utensil={targetUtensil as UtensilRecord}
                sizeScalingType={targetSizeScalingType as SizeScalingTypeRecord}
                sizeScalingPreset={
                  targetSizeScalingPreset as SizeScalingPresetRecord
                }
                tuningCustomizePresets={tuningCustomizePresets}
                isNewSizeScalingPresetChecked={isNewSizeScalingPresetChecked}
                isNewTuningCustomizePresetChecked={
                  isNewTuningCustomizePresetChecked
                }
                setIsNewSizeScalingPresetChecked={
                  setIsNewSizeScalingPresetChecked
                }
                setIsNewTuningCustomizePresetChecked={
                  setIsNewTuningCustomizePresetChecked
                }
                findSizeScalingPreset={findSizeScalingPreset}
                ingredientParams={ingredientParams}
                setIngredientParams={setIngredientParams}
              />
            ) : (
              <></>
            )}
            {modalSection === ModalSectionType.Confirm ? (
              <IngredientModalConfirm
                ingredient={previewIngredient as IngredientParams}
                ingredientCategories={ingredientCategories}
                sizes={sizes}
                utensils={utensils}
                sizeScalingPresets={
                  targetSizeScalingType?.sizeScalingPresets as SizeScalingPresetRecord[]
                }
                tuningCustomizePresets={tuningCustomizePresets}
                newSizeScalingPreset={
                  previewNewCustomizePreset as NewCustomizePresetParams
                }
                newTuningCustomizePreset={
                  previewNewTuningCustomizePreset as NewTuningCustomizePresetParams
                }
                file={file}
              />
            ) : (
              <></>
            )}
            <Grid container direction="row" justifyContent="flex-end">
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton
                  onClick={() => {
                    switch (modalSection) {
                      case ModalSectionType.BasicInfo:
                        handleOnCancelModalOpen()
                        break
                      case ModalSectionType.AmountInput:
                        moveToBasicInfoSection()
                        break
                      // 手動投入材料の時はスキップする
                      case ModalSectionType.SizeScalingPreset:
                        if (ingredientParams.ingredientCategoryID == 1) {
                          moveToBasicInfoSection()
                        } else {
                          moveToAmountInputSection()
                        }
                        break
                      case ModalSectionType.TuningCustomizePreset:
                        moveToSizeScalingInputSection()
                        break
                      case ModalSectionType.Confirm:
                        moveToTuningCustomizeInputSection()
                    }
                  }}>
                  {modalSection == ModalSectionType.BasicInfo
                    ? 'キャンセル'
                    : '戻る'}
                </CancelButton>
              </Box>
              {modalSection == ModalSectionType.BasicInfo && (
                <Button
                  sx={nextButtonSx}
                  onClick={() => {
                    moveToAmountInputSection()
                  }}
                  disabled={!canMoveToAmountInputSection()}>
                  次へ
                </Button>
              )}
              {modalSection == ModalSectionType.AmountInput && (
                <Button
                  sx={nextButtonSx}
                  onClick={() => {
                    moveToSizeScalingInputSection()
                  }}
                  disabled={!canMoveToSizeScalingInputSection()}>
                  次へ
                </Button>
              )}
              {modalSection == ModalSectionType.SizeScalingPreset && (
                <Button
                  sx={nextButtonSx}
                  onClick={() => {
                    moveToTuningCustomizeInputSection()
                  }}
                  disabled={!canMoveToTuningCustomizeInputSection()}>
                  次へ
                </Button>
              )}
              {modalSection == ModalSectionType.TuningCustomizePreset && (
                <Button
                  sx={nextButtonSx}
                  onClick={() => {
                    moveToConfirmSection()
                  }}
                  disabled={!canMoveToConfirmSection()}>
                  次へ
                </Button>
              )}
              {modalSection == ModalSectionType.Confirm && (
                <SaveButton
                  label="更新する"
                  sx={saveButtonSx}
                  alwaysEnable={isFileChanged}
                />
              )}
            </Grid>
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  )
}

export default EditIngredientModal
