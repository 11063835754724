import React from 'react'
import { Box, Grid } from '@mui/material'
import CancelButton from '../../button/CancelButton'
import { SaveButton } from 'react-admin'
import { CipLogRecord } from '../../../types/records/cip-log-record'
import { format, parseISO } from 'date-fns'
import { cancelButtonWrapperSx, saveButtonSx } from '../../../assets/sx/form'

type Props = {
  cipLogs: CipLogRecord[]
  isAllApproved: boolean
  onClose: () => void
}

const ApproveCipLogButton: React.FC<Props> = ({
  onClose,
  isAllApproved,
  cipLogs,
}) => {
  const latestApprovedCipLog = cipLogs.find((cl) => cl.approvedAt !== undefined)
  const cancelApproveLabel = latestApprovedCipLog?.approvedAt
    ? `承認取り消し（承認日：${format(
        parseISO(latestApprovedCipLog.approvedAt),
        'yyyy/MM/dd',
      )}）`
    : ''

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      sx={{ padding: '24px' }}>
      <Box sx={cancelButtonWrapperSx}>
        <CancelButton onClick={() => onClose()}>閉じる</CancelButton>
      </Box>
      {cipLogs.length !== 0 && isAllApproved && (
        <SaveButton
          label={cancelApproveLabel}
          sx={{
            ...saveButtonSx,
            backgroundColor: '#CD0000',
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: '#CD0000',
            },
          }}
          alwaysEnable
        />
      )}
      {!isAllApproved && (
        <SaveButton label="承認する" sx={saveButtonSx} alwaysEnable />
      )}
    </Grid>
  )
}

export default ApproveCipLogButton
