import React, { useEffect, useState } from 'react'
import {
  IngredientParams,
  IngredientRecord,
} from '../../../types/records/ingredient-record'
import { Grid, Modal, Box } from '@mui/material'
import CancelInputModal from '../CancelInputModal'
import useCancelModal from '../../../hooks/useCancelModal'
import { Form, SaveButton } from 'react-admin'
import CancelButton from '../../button/CancelButton'

import { StoreTypeRecord } from '../../../types/records/store-record'
import { HopperRecord } from '../../../types/records/hopper_record'
import { IngredientPlacementParams } from '../../../types/records/ingredient-placement-record'

import { ModalSectionType } from './ModalSectionType'
import {
  saveButtonSx,
  modalWrapperSx,
  cancelButtonWrapperSx,
} from '../../../assets/sx/form'

import IngredientPlacementModalConfirm from './IngredientPlacementModalConfirm'
import AddIngredientPlacementModalInput from './AddIngredientPlacementModalInput'

type Props = {
  open: boolean
  storeTypes: StoreTypeRecord[]
  hoppers: HopperRecord[]
  ingredients: IngredientRecord[]
  onClose: () => void
  onSubmit: (data: IngredientPlacementParams) => void
}

const AddIngredientPlacementModal: React.FC<Props> = ({
  open,
  storeTypes,
  hoppers,
  ingredients,
  onClose,
  onSubmit,
}) => {
  const [previewIngredientPlacement, setPreviewIngredientPlacement] =
    useState<IngredientPlacementParams>()

  useEffect(() => {
    if (!open) {
      setModalSection(ModalSectionType.IngredientPlacementInput)
    }
  }, [open])

  const onCreateSubmit = (data: IngredientPlacementParams) => {
    if (modalSection === ModalSectionType.IngredientPlacementInput) {
      setPreviewIngredientPlacement({ ...data })
      setModalSection(ModalSectionType.Confirm)
    } else {
      onSubmit(data)
    }
  }

  const [modalSection, setModalSection] = useState<ModalSectionType>(
    ModalSectionType.IngredientPlacementInput,
  )
  const moveToIngredientPlacementInputSection = () => {
    setModalSection(ModalSectionType.IngredientPlacementInput)
  }

  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onClose)

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box sx={modalWrapperSx}>
          <Form onSubmit={onCreateSubmit}>
            {modalSection === ModalSectionType.IngredientPlacementInput ? (
              <AddIngredientPlacementModalInput
                storeTypes={storeTypes}
                hoppers={hoppers}
              />
            ) : (
              <></>
            )}
            {modalSection === ModalSectionType.Confirm ? (
              <IngredientPlacementModalConfirm
                ingredients={ingredients}
                hoppers={hoppers}
                storeTypes={storeTypes}
                ingredientPlacements={
                  previewIngredientPlacement as IngredientParams
                }
              />
            ) : (
              <></>
            )}
            <Grid container direction="row" justifyContent="flex-end">
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton
                  onClick={() => {
                    switch (modalSection) {
                      case ModalSectionType.IngredientPlacementInput:
                        handleOnCancelModalOpen()
                        break
                      case ModalSectionType.Confirm:
                        moveToIngredientPlacementInputSection()
                    }
                  }}>
                  {modalSection == ModalSectionType.IngredientPlacementInput
                    ? 'キャンセル'
                    : '戻る'}
                </CancelButton>
              </Box>
              {modalSection == ModalSectionType.IngredientPlacementInput && (
                <SaveButton label="次へ" sx={saveButtonSx} />
              )}
              {modalSection == ModalSectionType.Confirm && (
                <SaveButton label="追加する" sx={saveButtonSx} />
              )}
            </Grid>
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  )
}

export default AddIngredientPlacementModal
