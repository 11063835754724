/**
 * SelectInputの選択肢のスタイルを追加する
 * @param targetID プルダウンメニューを開いた時に生成される、role="presentation"のdivエレメントのid。
 */
export const AddSelectInputChoisesStyle = (targetID: string) => {
  // SelectInputの選択肢エレメントは、rootエレメント配下にいないため、cssの追加でスタイルを編集する
  const style = `<style class="selectInputChoisesStyle">
      #${targetID} ul.MuiList-root {
        background-color: #323232;
        color: #fff;
      }
      #${targetID} ul.MuiList-root li {
        padding-left: 42px
      }
      #${targetID} ul.MuiList-root li[aria-selected="true"]{
        padding-left: 20px
      }
      #${targetID} ul.MuiList-root li[aria-selected="true"]::before{
        content: url(./tick_mark.svg);
        vertical-align: middle;
        padding-right: 22px;
        width: 10px
      }
      </style>`
  document.querySelector(`head`)?.insertAdjacentHTML('beforeend', style)
}

/**
 * SelectInputの選択肢のスタイルを削除する
 */
export const DeleteSelectInputChoicesStyle = () => {
  // onFocusが複数回働き、複数個作成されていることがあるため、全て取得・削除する
  document.querySelectorAll('.selectInputChoisesStyle').forEach((style) => {
    style.remove()
  })
}
