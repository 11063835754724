import React from 'react'
import { Grid, Typography, Modal, Box } from '@mui/material'
import CancelButton from '../../button/CancelButton'
import DeleteButton from '../../button/DeleteButton'
import { StoreRecord } from '../../../types/records/store-record'
import { cancelButtonWrapperSx } from '../../../assets/sx/form'
import { deleteModalSx } from '../../../assets/sx/modalSx'
type Props = {
  store: StoreRecord | undefined
  open: boolean
  onClose: () => void
  onSubmit: (data: StoreRecord) => void
}

const DeleteStoreModal: React.FC<Props> = ({
  store,
  open,
  onClose,
  onSubmit,
}) => {
  const onDeleteSubmit = () => {
    if (!store) {
      return null
    }
    onSubmit(store)
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={deleteModalSx}>
        <Typography sx={{ fontSize: '16px', fontWeight: 700, mb: '10px' }}>
          店舗を削除してもよろしいですか?
        </Typography>
        <Box sx={{ fontSize: '14px', fontWeight: 400, mb: '40px' }}>
          一度店舗を削除するともとに戻すことはできません
        </Box>
        <Grid container direction="row" justifyContent="flex-end">
          <Box sx={cancelButtonWrapperSx}>
            <CancelButton onClick={onClose}>キャンセル</CancelButton>
          </Box>
          <Box sx={{ width: '118px' }}>
            <DeleteButton onClick={onDeleteSubmit}>削除</DeleteButton>
          </Box>
        </Grid>
      </Box>
    </Modal>
  )
}

export default DeleteStoreModal
