import React, { useState, useEffect } from 'react'
import {
  RecipeSetting,
  RecipeRecord,
  SizeScalingPresetRecord,
  RecipeIngredientInputRecord,
  RecipeIngredientCategoryType,
} from '../../../types/records/recipe-record'
import {
  Grid,
  Modal,
  InputLabel,
  Typography,
  Box,
  Divider,
} from '@mui/material'
import ShowRecipeModalIngredientField from './ShowRecipeModalIngredientField'
import ShowRecipeModalIngredientListField from './ShowRecipeModalIngredientListField'
import CancelButton from '../../button/CancelButton'
import { formatDate } from '../../../assets/utils/formData'
import {
  modalWrapperSx,
  labelGrayOutSx,
  confirmGridContainerSx,
  confirmInputGridSx,
  confirmInputLabelSx,
  cancelButtonWrapperSx,
} from '../../../assets/sx/form'
import {
  modalTitleSx,
  modalLeftItemLabelSx,
  modalRightItemInputSx,
} from '../../../assets/sx/modalSx'
import {
  IngredientRecord,
  IngredientCategoryType,
} from '../../../types/records/ingredient-record'

type Props = {
  open: boolean
  recipeSetting: RecipeSetting
  ingredients: IngredientRecord[]
  recipe: RecipeRecord
  onClose: () => void
}

const ShowRecipeModal: React.FC<Props> = ({
  recipe,
  recipeSetting,
  open,
  ingredients,
  onClose,
}) => {
  const ingredientMap = new Map<number, IngredientRecord>()
  ingredients.forEach((ingredient) => {
    ingredientMap.set(ingredient.id as number, ingredient)
  })
  const sizeScalingPresetMap = new Map<number, SizeScalingPresetRecord>()
  recipeSetting?.sizeScalingPresets?.forEach((sizeScalingPreset) => {
    sizeScalingPresetMap.set(sizeScalingPreset.id as number, sizeScalingPreset)
  })

  const whichRecipeIngredientCategoryType = (ingredientID: number) => {
    const ingredient = ingredientMap.get(ingredientID)
    if (ingredient) {
      switch (ingredient.ingredientCategory?.name) {
        case IngredientCategoryType.SyrupBase:
          return RecipeIngredientCategoryType.SyrupBase
        case IngredientCategoryType.Roast:
          return RecipeIngredientCategoryType.RoastAndTea
        case IngredientCategoryType.Tea:
          return RecipeIngredientCategoryType.RoastAndTea
        case IngredientCategoryType.Syrup:
          return RecipeIngredientCategoryType.Syrup
        case IngredientCategoryType.Powder:
          return RecipeIngredientCategoryType.Powder
        case IngredientCategoryType.Chip:
          return RecipeIngredientCategoryType.Chip
        case IngredientCategoryType.Dairy:
          return RecipeIngredientCategoryType.DairyAndJuice
        case IngredientCategoryType.Nondairy:
          return RecipeIngredientCategoryType.DairyAndJuice
        case IngredientCategoryType.Juice:
          return RecipeIngredientCategoryType.DairyAndJuice
        case IngredientCategoryType.ManualInput:
          return RecipeIngredientCategoryType.ManualInput
      }
    }
  }

  const [syrupRecipeIngredientInputs, setSyrupRecipeIngredientInputs] =
    useState<RecipeIngredientInputRecord[]>([])
  const [powderRecipeIngredientInputs, setPowderRecipeIngredientInputs] =
    useState<RecipeIngredientInputRecord[]>([])
  const [chipRecipeIngredientInputs, setChipRecipeIngredientInputs] = useState<
    RecipeIngredientInputRecord[]
  >([])
  const [manualRecipeIngredientInputs, setManualInputRecipeIngredientInputs] =
    useState<RecipeIngredientInputRecord[]>([])
  const [syrupBaseRecipeIngredientInput, setSyrupBaseRecipeIngredientInput] =
    useState<RecipeIngredientInputRecord | undefined>(undefined)
  const [
    roastAndTeaRecipeIngredientInput,
    setRoastAndTeaRecipeIngredientInput,
  ] = useState<RecipeIngredientInputRecord | undefined>(undefined)
  const [
    dairyAndJuiceRecipeIngredientInput,
    setDairyAndJuiceRecipeIngredientInput,
  ] = useState<RecipeIngredientInputRecord | undefined>(undefined)

  useEffect(() => {
    if (!open) {
      setSyrupRecipeIngredientInputs([])
      setPowderRecipeIngredientInputs([])
      setChipRecipeIngredientInputs([])
      setManualInputRecipeIngredientInputs([])
      setSyrupBaseRecipeIngredientInput(undefined)
      setRoastAndTeaRecipeIngredientInput(undefined)
      setDairyAndJuiceRecipeIngredientInput(undefined)
    }
  }, [open])

  useEffect(() => {
    recipe?.recipeIngredientInputs?.forEach((recipeIngredientInput) => {
      const recipeIngredientCategoryType = whichRecipeIngredientCategoryType(
        recipeIngredientInput.ingredientID,
      )
      switch (recipeIngredientCategoryType) {
        case RecipeIngredientCategoryType.SyrupBase:
          setSyrupBaseRecipeIngredientInput(recipeIngredientInput)
          break
        case RecipeIngredientCategoryType.RoastAndTea:
          setRoastAndTeaRecipeIngredientInput(recipeIngredientInput)
          break
        case RecipeIngredientCategoryType.Syrup:
          {
            const newSyrupRecipeIngredientInputs = [
              ...syrupRecipeIngredientInputs,
            ]
            newSyrupRecipeIngredientInputs.push(recipeIngredientInput)
            setSyrupRecipeIngredientInputs(newSyrupRecipeIngredientInputs)
          }
          break
        case RecipeIngredientCategoryType.Powder:
          {
            const newPowderRecipeIngredientInputs = [
              ...powderRecipeIngredientInputs,
            ]
            newPowderRecipeIngredientInputs.push(recipeIngredientInput)
            setPowderRecipeIngredientInputs(newPowderRecipeIngredientInputs)
          }
          break
        case RecipeIngredientCategoryType.Chip:
          {
            const newChipRecipeIngredientInputs = [
              ...chipRecipeIngredientInputs,
            ]
            newChipRecipeIngredientInputs.push(recipeIngredientInput)
            setChipRecipeIngredientInputs(newChipRecipeIngredientInputs)
          }
          break
        case RecipeIngredientCategoryType.DairyAndJuice:
          setDairyAndJuiceRecipeIngredientInput(recipeIngredientInput)
          break
        case RecipeIngredientCategoryType.ManualInput:
          {
            const newManualRecipeIngredientInputs = [
              ...manualRecipeIngredientInputs,
            ]
            newManualRecipeIngredientInputs.push(recipeIngredientInput)
            setManualInputRecipeIngredientInputs(
              newManualRecipeIngredientInputs,
            )
          }
          break
      }
    })
  }, [recipe])

  return (
    recipe && (
      <Modal open={open} onClose={onClose}>
        <Box sx={modalWrapperSx}>
          <Grid>
            <Typography sx={modalTitleSx}>レシピマスターを確認する</Typography>
            <Grid sx={confirmGridContainerSx} container>
              <InputLabel
                htmlFor="name"
                sx={{
                  ...labelGrayOutSx,
                  mb: 'auto',
                  mr: 'auto',
                  width: '50%',
                }}>
                レシピ名
              </InputLabel>
              <Grid sx={confirmInputGridSx}>{recipe.name}</Grid>
            </Grid>
            <Grid sx={confirmGridContainerSx} container>
              <InputLabel htmlFor="name" sx={modalLeftItemLabelSx}>
                表示名
              </InputLabel>
              <Grid sx={modalRightItemInputSx}>{recipe.displayName}</Grid>
            </Grid>
            <Grid sx={confirmGridContainerSx} container>
              <InputLabel htmlFor="name" sx={modalLeftItemLabelSx}>
                レシピカテゴリ
              </InputLabel>
              <Grid sx={modalRightItemInputSx}>
                {recipe.recipeCategory.name}
              </Grid>
            </Grid>
            <Grid sx={confirmGridContainerSx} container>
              <InputLabel htmlFor="name" sx={modalLeftItemLabelSx}>
                提供種別
              </InputLabel>
              <Grid sx={confirmInputGridSx}>{recipe.offerGenre.name}</Grid>
            </Grid>
            <Grid sx={confirmGridContainerSx} container>
              <InputLabel htmlFor="name" sx={modalLeftItemLabelSx}>
                画像
              </InputLabel>
              {recipe.imageURL ? (
                <img
                  src={recipe.imageURL}
                  alt="previewImage"
                  width="50%"
                  style={{ borderRadius: '5px', border: '1px solid #ccc' }}
                />
              ) : (
                <Grid sx={confirmInputGridSx}> - </Grid>
              )}
            </Grid>
            <Grid sx={confirmGridContainerSx} container>
              <InputLabel htmlFor="name" sx={modalLeftItemLabelSx}>
                説明
              </InputLabel>
              <Grid sx={modalRightItemInputSx}>
                {recipe.description ? recipe.description : '-'}
              </Grid>
            </Grid>
            <Grid sx={confirmGridContainerSx} container>
              <InputLabel htmlFor="name" sx={modalLeftItemLabelSx}>
                提供開始日
              </InputLabel>
              <Grid sx={modalRightItemInputSx}>
                {formatDate(recipe?.offerStartAt)}
              </Grid>
            </Grid>
            <Grid sx={confirmGridContainerSx} container>
              <InputLabel htmlFor="name" sx={modalLeftItemLabelSx}>
                提供終了日
              </InputLabel>
              <Grid sx={modalRightItemInputSx}>
                {formatDate(recipe?.offerEndAt)}
              </Grid>
            </Grid>
            <Divider sx={{ mt: '40px', mb: '40px' }} />
            <ShowRecipeModalIngredientField
              ingredientMap={ingredientMap}
              sizeScalingPresetMap={sizeScalingPresetMap}
              ingredientCategoryName="シロップベース"
              recipeIngredientInput={
                syrupBaseRecipeIngredientInput as RecipeIngredientInputRecord
              }
            />
            <Divider sx={{ mt: '40px', mb: '40px' }} />
            <ShowRecipeModalIngredientField
              ingredientMap={ingredientMap}
              sizeScalingPresetMap={sizeScalingPresetMap}
              ingredientCategoryName="ロースト/ティー"
              recipeIngredientInput={
                roastAndTeaRecipeIngredientInput as RecipeIngredientInputRecord
              }
            />
            <Divider sx={{ mt: '40px', mb: '40px' }} />
            <ShowRecipeModalIngredientListField
              ingredientMap={ingredientMap}
              sizeScalingPresetMap={sizeScalingPresetMap}
              ingredientCategoryName="シロップ"
              recipeIngredientInputs={
                syrupRecipeIngredientInputs as RecipeIngredientInputRecord[]
              }
            />
            <Divider sx={{ mt: '40px', mb: '40px' }} />
            <ShowRecipeModalIngredientListField
              ingredientMap={ingredientMap}
              sizeScalingPresetMap={sizeScalingPresetMap}
              ingredientCategoryName="パウダー"
              recipeIngredientInputs={
                powderRecipeIngredientInputs as RecipeIngredientInputRecord[]
              }
            />
            <Divider sx={{ mt: '40px', mb: '40px' }} />
            <ShowRecipeModalIngredientListField
              ingredientMap={ingredientMap}
              sizeScalingPresetMap={sizeScalingPresetMap}
              ingredientCategoryName="チップ"
              recipeIngredientInputs={
                chipRecipeIngredientInputs as RecipeIngredientInputRecord[]
              }
            />
            <Divider sx={{ mt: '40px', mb: '40px' }} />
            <ShowRecipeModalIngredientField
              ingredientMap={ingredientMap}
              sizeScalingPresetMap={sizeScalingPresetMap}
              ingredientCategoryName="ミルク/ジュース"
              recipeIngredientInput={
                dairyAndJuiceRecipeIngredientInput as RecipeIngredientInputRecord
              }
            />
            <Divider sx={{ mt: '40px', mb: '40px' }} />
            <ShowRecipeModalIngredientListField
              ingredientMap={ingredientMap}
              sizeScalingPresetMap={sizeScalingPresetMap}
              ingredientCategoryName="事前に手動で投入する材料"
              recipeIngredientInputs={
                manualRecipeIngredientInputs as RecipeIngredientInputRecord[]
              }
            />
            <Divider sx={{ mt: '40px', mb: '40px' }} />
            <Grid>
              <Typography sx={modalTitleSx}>ブレンド後のトッピング</Typography>
              {recipe.toppings.length === 0 && (
                <Grid sx={confirmGridContainerSx} container>
                  <InputLabel sx={confirmInputLabelSx}>材料</InputLabel>
                  <Grid sx={confirmInputGridSx}> - </Grid>
                </Grid>
              )}
              {recipe.toppings.length !== 0 &&
                recipe.toppings.map((topping, index) => (
                  <Grid
                    sx={confirmGridContainerSx}
                    key={`topping_${index}`}
                    container>
                    <InputLabel sx={confirmInputLabelSx}>材料</InputLabel>
                    <Grid sx={confirmInputGridSx}>{topping.description}</Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Box sx={cancelButtonWrapperSx}>
              <CancelButton onClick={() => onClose()}>閉じる</CancelButton>
            </Box>
          </Grid>
        </Box>
      </Modal>
    )
  )
}

export default ShowRecipeModal
