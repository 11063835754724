import React, { useState, useEffect, MouseEvent } from 'react'
import { format, parseISO } from 'date-fns'
import {
  useDataProvider,
  useNotify,
  useRefresh,
  TextField,
  FunctionField,
  TextInput,
  RadioButtonGroupInput,
  WrapperField,
  useGetList,
} from 'react-admin'
import { useAuthorize, useNotifyForbidden } from '../hooks/authorization'
import { InputAdornment, Box, Grid } from '@mui/material'
import ListDatagrid from '../components/ListDatagrid'
import IngredientPlacementStatusField from '../components/field/IngredientPlacementStoreTypeField'
import IconButton from '../components/button/IconButton'

import { ReactComponent as SearchIcon } from '../assets/images/search.svg'
import { ReactComponent as TrashIcon } from '../assets/images/trash.svg'
import { ReactComponent as DetailsIcon } from '../assets/images/details.svg'

import {
  IngredientPlacementParams,
  IngredientPlacementRecord,
  PresetHopperIngredientLinkParams,
} from '../types/records/ingredient-placement-record'
import { useIngredientPlacementListParamsState } from '../hooks/useIngredientPlacementListParams'
import { RadioButtonGroupInputSx } from '../assets/sx/filterSx'
import { TextInputSx } from '../assets/sx/filterSx'
import { StoreTypeRecord } from '../types/records/store-record'
import AddButton from '../components/button/AddButton'
import { HopperRecord } from '../types/records/hopper_record'
import { IngredientRecord } from '../types/records/ingredient-record'

import { ReactComponent as PencilIcon } from '../assets/images/pencil.svg'

import AddIngredientPlacementModal from '../components/modal/ingredientPlacement/AddIngredientPlacementModal'
import EditIngredientPlacementModal from '../components/modal/ingredientPlacement/EditIngredientPlacementModal'
import DeleteIngredientPlacementModal from '../components/modal/DeleteIngredientPlacementModal'
import ShowIngredientPlacementModal from '../components/modal/ingredientPlacement/ShowIngredientPlacementModal'
import style from './IngredientPlacementField.module.scss'

const IngredientPlacementList: React.FC = () => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const notifyForbidden = useNotifyForbidden()
  const hopper = useGetList('hoppers')
  const ingredient = useGetList('ingredients')

  const filters = [
    <RadioButtonGroupInput
      key="operationStatus"
      label={false}
      source="operationStatus"
      alwaysOn
      margin="none"
      sx={RadioButtonGroupInputSx}
      choices={[
        { id: 'offering', name: '提供中' },
        { id: 'beforeOffering', name: '提供前' },
        { id: 'archived', name: 'アーカイブ' },
      ]}
    />,
    <TextInput
      key="name"
      label={false}
      source="name"
      placeholder="配置パターン名で検索"
      alwaysOn
      fullWidth
      resettable
      sx={{ ...TextInputSx, borderLeft: '1px solid #EDEAE3' }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />,
  ]

  const canCreateIngredientPlacement = useAuthorize(
    'ingredientPlacement',
    'create',
  )
  const canUpdateIngredientPlacement = useAuthorize(
    'ingredientPlacement',
    'update',
  )

  const [targetIngredientPlacement, setTargetIngredientPlacement] =
    useState<IngredientPlacementRecord>()
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false)
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [showModalOpen, setShowModalOpen] = useState<boolean>(false)
  const storeTypes = useGetList('store-types')

  const [listParams, setListParams] = useIngredientPlacementListParamsState()
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const canDeleteUser = useAuthorize('ingredientPlacement', 'delete')

  const openDeleteModal = (
    e: MouseEvent<HTMLElement>,
    targetIngredientPlacement: IngredientPlacementRecord | undefined,
  ) => {
    e.preventDefault()
    if (!canDeleteUser) {
      notifyForbidden()
      return
    }

    setDeleteModalOpen(true)
    setTargetIngredientPlacement(targetIngredientPlacement)
  }
  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
  }

  const onDeleteSubmit = (ingredientPlacement: IngredientPlacementRecord) => {
    dataProvider
      .delete('ingredient-placements', {
        id: ingredientPlacement.id,
        previousData: ingredientPlacement,
      })
      .then(() => {
        notify('材料配置を削除しました')
        setDeleteModalOpen(false)
        refresh()
      })
      .catch(() => {
        notify('材料配置の削除に失敗しました')
      })
  }

  //初回読み込み時に提供中が選択される
  useEffect(() => {
    setListParams({
      displayedFilters: {},
      filter: {
        operationStatus: 'offering',
        name: '',
      },
      order: 'ASC',
      page: 1,
      perPage: 10,
      sort: 'id',
    })
  }, [])

  const openCreateModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setCreateModalOpen(true)

    if (!canCreateIngredientPlacement) {
      notifyForbidden()
      return
    }
  }

  const openEditModal = (
    e: MouseEvent<HTMLElement>,
    targetIngredientPlacement: IngredientPlacementRecord | undefined,
  ) => {
    e.preventDefault()

    if (!canUpdateIngredientPlacement) {
      notifyForbidden()
      return
    }

    setEditModalOpen(true)
    setTargetIngredientPlacement(targetIngredientPlacement)
  }

  const openShowModal = (
    e: React.MouseEvent,
    record: IngredientPlacementRecord,
  ) => {
    setTargetIngredientPlacement(record)
    setShowModalOpen(true)
  }

  const closeCreateModal = () => {
    setCreateModalOpen(false)
  }

  const closeEditModal = () => {
    setEditModalOpen(false)
  }

  const closeShowModal = () => {
    setShowModalOpen(false)
  }

  const onCreateSubmit = (data: IngredientPlacementParams) => {
    // 材料が選択されていないオブジェクトを排除する
    let newData: PresetHopperIngredientLinkParams[] = []
    if (data.newPresetHopperIngredientLinks) {
      newData = data.newPresetHopperIngredientLinks.filter((item) => {
        if (item.ingredientID) return item
      })
    }
    data.newPresetHopperIngredientLinks = newData

    dataProvider
      .create('ingredient-placements', { data })
      .then(() => {
        notify('材料配置を追加しました')
        setCreateModalOpen(false)
        refresh()
      })
      .catch(() => {
        notify('材料配置の追加に失敗しました')
      })
  }

  const onUpdateSubmit = (
    id: number,
    data: IngredientPlacementParams,
    previousData: IngredientPlacementRecord,
  ) => {
    // 材料が選択されていないオブジェクトを排除する
    let newData: PresetHopperIngredientLinkParams[] = []
    if (data.newPresetHopperIngredientLinks) {
      newData = data.newPresetHopperIngredientLinks.filter((item) => {
        if (item.ingredientID) return item
      })
    }
    data.newPresetHopperIngredientLinks = newData

    dataProvider
      .update('ingredient-placements', { id, data, previousData })
      .then(() => {
        notify('材料配置を更新しました')
        setEditModalOpen(false)
        refresh()
      })
      .catch(() => {
        notify('材料配置の更新に失敗しました')
      })
  }
  const settingButtonItems = [
    { tooltipLabel: '詳細', icon: <DetailsIcon />, onClick: openShowModal },
    {
      tooltipLabel: '編集',
      icon: <PencilIcon />,
      onClick: openEditModal,
    },
    {
      tooltipLabel: '削除',
      icon: <TrashIcon />,
      onClick: openDeleteModal,
    },
  ]

  const settingButtonItemsWithoutDelete = settingButtonItems.filter(
    (item) => item.tooltipLabel !== '削除',
  )

  const iconButtonsFieldTSX = (record: IngredientPlacementRecord) => {
    let items = settingButtonItems
    if (listParams && listParams.filter.operationStatus === 'offering') {
      items = settingButtonItemsWithoutDelete
    }
    return (
      <Grid container display="flex" justifyContent="right">
        {items.map((item, index) => {
          return (
            <IconButton<IngredientPlacementRecord>
              key={`iconButton_${index}`}
              tooltipLabel={item.tooltipLabel}
              onClick={item.onClick}
              icon={item.icon}
              record={record}
            />
          )
        })}
      </Grid>
    )
  }

  return (
    <>
      <ListDatagrid
        title="材料配置マスター"
        filters={filters}
        addButton={
          <AddButton
            onClick={openCreateModal}
            disabled={!canCreateIngredientPlacement}>
            配置パターンを追加する
          </AddButton>
        }
        modals={[
          <AddIngredientPlacementModal
            key="addIngredientPlacementModal"
            hoppers={hopper.data as HopperRecord[]}
            storeTypes={storeTypes.data as StoreTypeRecord[]}
            ingredients={ingredient.data as IngredientRecord[]}
            open={createModalOpen}
            onClose={closeCreateModal}
            onSubmit={onCreateSubmit}
          />,
          <EditIngredientPlacementModal
            key="editIngredientPlacementModal"
            hoppers={hopper.data as HopperRecord[]}
            storeTypes={storeTypes.data as StoreTypeRecord[]}
            ingredients={ingredient.data as IngredientRecord[]}
            ingredientPlacement={
              targetIngredientPlacement as IngredientPlacementRecord
            }
            open={editModalOpen}
            onClose={closeEditModal}
            onSubmit={onUpdateSubmit}
          />,
          <DeleteIngredientPlacementModal
            key="deleteIngredientPlacementModal"
            ingredient={targetIngredientPlacement}
            open={deleteModalOpen}
            onClose={closeDeleteModal}
            onSubmit={onDeleteSubmit}
          />,
          <ShowIngredientPlacementModal
            key="ingredientPlacementDetailModal"
            hoppers={hopper.data as HopperRecord[]}
            storeTypes={storeTypes.data as StoreTypeRecord[]}
            ingredients={ingredient.data as IngredientRecord[]}
            ingredientPlacements={
              targetIngredientPlacement as IngredientPlacementRecord
            }
            open={showModalOpen}
            onClose={closeShowModal}
          />,
        ]}>
        <WrapperField
          label="材料配置種別"
          headerClassName={style.storeTypeField}>
          <Grid style={{ paddingLeft: '10px' }}>
            <IngredientPlacementStatusField />
          </Grid>
        </WrapperField>
        <FunctionField
          headerClassName={style.placementPatternField}
          label="配置パターン名"
          render={(record: IngredientPlacementRecord) => (
            <div>
              <TextField
                record={record}
                source="name"
                sx={{ fontWeight: 'bold', fontSize: '15px' }}
              />
              <br />
              <Box sx={{ marginTop: '5px' }}>
                <TextField
                  record={record}
                  source="description"
                  label="説明"
                  sx={{ color: '#757575', whiteSpace: 'brake-spaces' }}
                />
              </Box>
            </div>
          )}
          sortable={false}
        />
        <FunctionField
          headerClassName={style.dateAndFunctionField}
          render={(record: IngredientPlacementRecord) =>
            record.operationStartAt &&
            format(parseISO(record.operationStartAt), 'yyyy/MM/dd')
          }
          label="使用開始日"
          sortable={false}
        />
        <FunctionField
          headerClassName={style.dateAndFunctionField}
          render={(record: IngredientPlacementRecord) =>
            record.operationEndAt &&
            format(parseISO(record.operationEndAt), 'yyyy/MM/dd')
          }
          label="使用終了日"
          sortable={false}
        />
        <FunctionField
          headerClassName={
            listParams && listParams.filter.operationStatus !== 'offering'
              ? style.iconButtonsFieldMaxWidth
              : style.iconButtonsFieldMinWidth
          }
          sortable={false}
          render={(record: IngredientPlacementRecord) =>
            iconButtonsFieldTSX(record)
          }
        />
      </ListDatagrid>
    </>
  )
}

export default IngredientPlacementList
