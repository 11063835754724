import React from 'react'
import { TextInput } from 'react-admin'
import {
  Container,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'

import { format, parseISO } from 'date-fns'
import CipLogStatusField from '../../field/CipLogStatusField'
import {
  CipLogRecord,
  UpdateTargetsParam,
} from '../../../types/records/cip-log-record'
import { cipLogItemColumnSx, cipLogTextInputSx } from '../../../assets/sx/form'

type Props = {
  cipLogs: CipLogRecord[] | undefined
  cipLogParam: UpdateTargetsParam[] | undefined
  isEditable: boolean
}

const CipLogListInfo: React.FC<Props> = ({
  cipLogs,
  cipLogParam,
  isEditable,
}) => {
  return (
    <Container key={'cipLog'} style={{ paddingLeft: 0, paddingRight: 0 }}>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ ...cipLogItemColumnSx, paddingLeft: '24px' }}>
                日付
              </TableCell>
              <TableCell sx={cipLogItemColumnSx}>開店オペ時間</TableCell>
              <TableCell sx={cipLogItemColumnSx}>閉店オペ時間</TableCell>
              <TableCell sx={cipLogItemColumnSx}>ステータス</TableCell>
              <TableCell sx={cipLogItemColumnSx}>店舗責任者</TableCell>
              <TableCell sx={{ ...cipLogItemColumnSx, paddingRight: '24px' }}>
                特記事項
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cipLogs?.map((cipLog: CipLogRecord) => (
              <TableRow key={cipLog.id} sx={{ height: 60 }}>
                <TableCell sx={{ paddingLeft: '24px' }}>
                  {format(
                    parseISO(cipLog.openingOperationCompletedAt),
                    'yyyy/MM/dd',
                  )}
                </TableCell>
                <TableCell>
                  {format(
                    parseISO(cipLog.openingOperationCompletedAt),
                    'HH:mm',
                  )}
                </TableCell>
                <TableCell>
                  {cipLog.closingOperationCompletedAt
                    ? format(
                        parseISO(cipLog.closingOperationCompletedAt),
                        'HH:mm',
                      )
                    : ''}
                </TableCell>
                <TableCell>
                  <CipLogStatusField cipLog={cipLog} />
                </TableCell>
                <TableCell>{cipLog.storeManager}</TableCell>
                <TableCell sx={{ paddingRight: '24px' }}>
                  {!isEditable ? (
                    cipLog.description ? (
                      cipLog.description
                    ) : (
                      '-'
                    )
                  ) : (
                    <TextInput
                      source={`description_${cipLog.id}`}
                      variant="outlined"
                      label={false}
                      placeholder="入力してください"
                      sx={cipLogTextInputSx}
                      defaultValue={cipLog.description}
                      fullWidth
                      onChange={(e) => {
                        cipLogParam?.find((param) => {
                          if (param.id === cipLog.id) {
                            param.description = e.target.value
                          }
                        })
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

export default CipLogListInfo
