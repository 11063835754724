import React from 'react'
import {
  DisassociateMachineParams,
  StoreRecord,
} from '../../../types/records/store-record'
import { Grid, Typography, Modal, Box, InputLabel } from '@mui/material'
import CancelInputModal from '../CancelInputModal'
import useCancelModal from '../../../hooks/useCancelModal'
import {
  Form,
  SaveButton,
  CheckboxGroupInput,
  required,
  useRecordContext,
} from 'react-admin'
import CancelButton from '../../button/CancelButton'
import {
  gridSx,
  labelSx,
  confirmWithLineBreak,
  cancelButtonWrapperSx,
  saveButtonSx,
  modalWrapperSx,
} from '../../../assets/sx/form'

type Props = {
  open: boolean
  store: StoreRecord | undefined
  onClose: () => void
  onSubmit: (data: DisassociateMachineParams) => void
}

const DisassociateMachineModal: React.FC<Props> = ({
  open,
  store,
  onClose,
  onSubmit,
}) => {
  const validateCheck = [required('エラー解除にはチェックが必要です')]

  const CheckboxLabel = () => {
    const record = useRecordContext()
    return (
      <Box>
        <span style={{ color: '#757575', fontSize: '14px' }}>
          マシン シリアル
        </span>
        <br />
        {record.serial}
      </Box>
    )
  }

  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onClose)

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box sx={modalWrapperSx}>
          <Typography sx={{ fontSize: '16px', fontWeight: 700, mb: '10px' }}>
            マシン紐付け解除
          </Typography>
          <Form onSubmit={onSubmit}>
            <Grid sx={gridSx} key={1}>
              <InputLabel
                htmlFor={`serials[machine.serial]`}
                sx={labelSx}></InputLabel>
              <CheckboxGroupInput
                source={`serials[]`}
                variant="outlined"
                label={false}
                sx={confirmWithLineBreak}
                row={false}
                optionText={<CheckboxLabel />}
                choices={store?.machines.map((machine) => ({
                  id: machine.serial,
                  serial: machine.serial,
                }))}
                fullWidth
                validate={validateCheck}
              />
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              sx={{ marginTop: '10px' }}>
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton onClick={handleOnCancelModalOpen}>
                  キャンセル
                </CancelButton>
              </Box>
              <SaveButton label="解除する" sx={saveButtonSx} />
            </Grid>
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  )
}

export default DisassociateMachineModal
