import React from 'react'
import { Form, TextInput, NumberInput, SaveButton, required } from 'react-admin'
import {
  Grid,
  Typography,
  Modal,
  Box,
  InputLabel,
  SxProps,
  Theme,
} from '@mui/material'

import {
  IngredientCategoryRecord,
  IngredientItemParams,
} from '../../types/records/ingredient-record'
import CancelButton from '../button/CancelButton'
import { cancelButtonWrapperSx } from '../../assets/sx/form'
import { modalTitleSx } from '../../assets/sx/modalSx'

type Props = {
  ingredientCategory: IngredientCategoryRecord | undefined
  open: boolean
  onClose: () => void
  onSubmit: (data: IngredientItemParams) => void
}

const labelSx: SxProps<Theme> = {
  color: '#757575',
  mb: '8px',
  display: 'inline-block',
  fontSize: '14px',
  lineHeight: '14px',
}

const commonInputSx: SxProps<Theme> = {
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: '#005739',
    },
  },
  legend: {
    display: 'none',
  },
}

const textInputSx: SxProps<Theme> = {
  ...commonInputSx,
  '& .MuiInputBase-input': {
    padding: '0 12px',
    height: '44px',
    boxSizing: 'border-box',
    marginTop: '-5px',
  },
  fieldset: {
    height: '44px',
  },
}

const AddIngredientItemModal: React.FC<Props> = ({
  ingredientCategory,
  open,
  onClose,
  onSubmit,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      {ingredientCategory ? (
        <Box
          sx={{
            width: 398,
            backgroundColor: '#FFF',
            borderRadius: 2,
            p: 3,
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            left: '50%',
          }}>
          <Typography sx={modalTitleSx}>
            {ingredientCategory.name}カテゴリーに材料を追加する
          </Typography>
          <Form onSubmit={onSubmit}>
            <NumberInput
              label="材料カテゴリーID"
              source="ingredientCategoryId"
              sx={{ display: 'none' }}
              defaultValue={ingredientCategory.id}
              variant="outlined"
              fullWidth
              disabled={true}
            />
            <InputLabel htmlFor="name" sx={labelSx}>
              材料名
            </InputLabel>
            <TextInput
              source="name"
              variant="outlined"
              label={false}
              sx={textInputSx}
              validate={required('エラー入力してください')}
              fullWidth
            />
            <Grid container direction="row" justifyContent="flex-end">
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton onClick={onClose}>キャンセル</CancelButton>
              </Box>
              <SaveButton
                label="追加する"
                sx={{
                  width: '118px',
                  height: '36px',
                  borderRadius: '20px',
                  color: '#fff !important',
                  boxShadow: 'none',
                  backgroundColor: 'secondary.main',
                  '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: 'secondary.main',
                  },
                  '& .MuiSvgIcon-root': {
                    display: 'none',
                  },
                }}
              />
            </Grid>
          </Form>
        </Box>
      ) : (
        <></>
      )}
    </Modal>
  )
}

export default AddIngredientItemModal
