import React, { useEffect, useRef, useState } from 'react'
import { Form, SaveButton, useDataProvider, useNotify } from 'react-admin'
import { Modal, Box, Typography, Grid, Divider } from '@mui/material'
import CancelInputModal from '../CancelInputModal'
import useCancelModal from '../../../hooks/useCancelModal'
import {
  cipLogModalSx,
  cancelButtonWrapperSx,
  saveButtonSx,
} from '../../../assets/sx/form'
import CancelButton from '../../button/CancelButton'
import {
  CipLogMachineRecord,
  CipLogRecord,
  UpdateCipLogParam,
  UpdateTargetsParam,
} from '../../../types/records/cip-log-record'

import CipLogMachineInfo from './CipLogMachineInfo'
import CipLogSelectDateButton from './CipLogSelectDateButton'
import CipLogListInfo from './CipLogListInfo'

type Props = {
  targetCipLogMachine: CipLogMachineRecord | undefined
  startYear: number
  startMonth: number
  open: boolean
  onClose: () => void
  onSubmit: (
    updateCipLogParam: UpdateCipLogParam,
    callback: (data: CipLogRecord[]) => void,
  ) => void
}

const EditCipLogModal: React.FC<Props> = ({
  targetCipLogMachine,
  startYear,
  startMonth,
  open,
  onClose,
  onSubmit,
}) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const modalRef = useRef<HTMLDivElement>(null)

  const [cipLogs, setCipLogs] = useState<CipLogRecord[]>([])
  const [cipLogParam, setCipLogParam] = useState<UpdateTargetsParam[]>()
  const [targetDate, setTargetDate] = useState<string>()
  const [today, setToday] = useState<Date>()
  const [isEditable, setIsEditable] = useState<boolean>(false)

  useEffect(() => {
    if (!targetCipLogMachine) return

    if (open) {
      const today = new Date()
      setToday(today)

      // 初期値設定
      getCipLogs(
        `${today.getFullYear()}-${(today.getMonth() + 1)
          .toString()
          .padStart(2, '0')}`,
      )
    }

    if (!open) {
      // モーダルを閉じた場合には初期化する
      setCipLogs([])
      setTargetDate(undefined)
      setCipLogParam([])
      setIsEditable(false)
    }
  }, [open])

  const getCipLogs = (date: string) => {
    if (!targetCipLogMachine) return

    dataProvider
      .getList('cip-logs', {
        pagination: { page: 1, perPage: 31 },
        sort: { field: 'id', order: 'ASC' },
        filter: {
          targetDate: `${date}`,
          machineID: targetCipLogMachine.machineID,
        },
      })
      .then((res) => {
        setData(res.data as CipLogRecord[])
        setTargetDate(date)
      })
      .catch((e) => {
        notify(e.message, { type: 'error' })
      })
  }

  const onUpdateSubmit = () => {
    if (!cipLogParam) return

    const updateCipLogParam: UpdateCipLogParam = {
      updateTargets: cipLogParam,
    }
    onSubmit(updateCipLogParam, (cipLogRecords) => {
      modalRef.current?.scrollTo(0, 0)
      setData(cipLogRecords)
    })
  }

  const setData = (cipLogRecords: CipLogRecord[]) => {
    setCipLogs(cipLogRecords)
    const cipLogParams: UpdateTargetsParam[] = cipLogRecords.map((record) => {
      return {
        id: record.id,
        description: record.description,
      }
    })
    setCipLogParam(cipLogParams)

    // Adminユーザーが承認したレコードが存在する場合は編集不可
    const adminUserApprovedCipLog = cipLogRecords.find(
      (cl) => cl.isApprovedAdminUser === true,
    )
    setIsEditable(adminUserApprovedCipLog === undefined ? true : false)
  }

  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onClose)

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box sx={cipLogModalSx} ref={modalRef}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 700,
              mb: '20px',
              padding: '24px',
            }}>
            承認状況を確認するページ
          </Typography>
          <CipLogMachineInfo targetCipLogMachine={targetCipLogMachine} />
          <Divider sx={{ mt: '20px', mb: '20px', mr: '24px', ml: '24px' }} />
          <CipLogSelectDateButton
            today={today}
            selectedDate={targetDate}
            startYear={startYear}
            startMonth={startMonth}
            onClick={getCipLogs}
          />
          <Form onSubmit={onUpdateSubmit}>
            <CipLogListInfo
              cipLogs={cipLogs}
              cipLogParam={cipLogParam}
              isEditable={isEditable}
            />
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              sx={{ padding: '24px' }}>
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton onClick={handleOnCancelModalOpen}>
                  閉じる
                </CancelButton>
              </Box>
              {cipLogs.length != 0 && isEditable && (
                <SaveButton label="保存する" sx={saveButtonSx} alwaysEnable />
              )}
            </Grid>
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  )
}

export default EditCipLogModal
