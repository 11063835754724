import { useStore } from 'react-admin'

export const useIngredientPlacementListParamsState =
  (): useIngredientPlacementListParamsStateResult =>
    useStore<ListParamsType>('ingredient-placements.listParams')

export type useIngredientPlacementListParamsStateResult = [
  ListParamsType,
  (listParams: ListParamsType) => void,
]

export type ListParamsType = {
  displayedFilters: object
  filter: {
    operationStatus: 'offering' | 'beforeOffering' | 'archived'
    name: string
  }
  order: string
  page: number
  perPage: number
  sort: string
}
