import React from 'react'
import { Grid, Typography, InputLabel } from '@mui/material'
import { required, TextInput } from 'react-admin'
import { labelSx, gridSx } from '../../../assets/sx/form'
import { modalTitleSx } from '../../../assets/sx/modalSx'
import { IngredientParams } from '../../../types/records/ingredient-record'

type Props = {
  ingredientParams: IngredientParams
  setIngredientParams: React.Dispatch<React.SetStateAction<IngredientParams>>
}

const AddIngredientModalAmountInput: React.FC<Props> = ({
  ingredientParams,
  setIngredientParams,
}) => {
  return (
    <Grid>
      <Typography sx={modalTitleSx}>材料マスターを追加する 2/4</Typography>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="containerCapacityAmount" sx={labelSx}>
          容器容量（g）*
        </InputLabel>
        <TextInput
          source="containerCapacityAmount"
          label={false}
          placeholder="入力してください"
          parse={(value) => (isNaN(Number(value)) ? '' : Number(value))}
          fullWidth
          validate={required('エラー入力してください')}
          onChange={(e) =>
            setIngredientParams({
              ...ingredientParams,
              containerCapacityAmount: Number(e.target.value),
            })
          }
        />
      </Grid>
    </Grid>
  )
}

export default AddIngredientModalAmountInput
