import React from 'react'
import {
  UtensilParams,
  SizeScalingTypeRecord,
  EjectionAmountUnitRecord,
} from '../../types/records/utensil-record'
import useCancelModal from '../../hooks/useCancelModal'
import { Grid, Typography, Modal, Box, InputLabel } from '@mui/material'
import CancelInputModal from './CancelInputModal'
import {
  Form,
  NumberInput,
  required,
  number,
  SaveButton,
  TextInput,
} from 'react-admin'
import CancelButton from '../button/CancelButton'
import {
  cancelButtonWrapperSx,
  gridSx,
  labelGrayOutSx,
  textInputSx,
  selectInputSx,
  numberInputSx,
} from '../../assets/sx/form'
import { modalTitleSx } from '../../assets/sx/modalSx'
import CustomPlaceholderSelectInput from '../customComponent/CustomPlaceholderSelectInput'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: (data: UtensilParams) => void
  sizeScalingTypes: SizeScalingTypeRecord[]
  ejectionAmountUnits: EjectionAmountUnitRecord[]
}

const AddUtensilModal: React.FC<Props> = ({
  open,
  sizeScalingTypes,
  ejectionAmountUnits,
  onClose,
  onSubmit,
}) => {
  const validateName = [required('エラー入力してください')]
  const validateGreaterThanZero = (value: number) => {
    return 0 < value ? undefined : '0より大きい値を入力してください'
  }
  const validateEjectionAmount = [
    required('エラー入力してください'),
    number('エラー数値を入力してください'),
    validateGreaterThanZero,
  ]
  const validateEjectionAmountUnit = [required('エラー選択してください')]
  const validateSizeScalingType = [required('エラー選択してください')]

  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onClose)

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box
          sx={{
            width: 398,
            MaxHeight: '80vh',
            backgroundColor: '#FFF',
            borderRadius: 2,
            p: 3,
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            left: '50%',
            overflow: 'scroll',
          }}>
          <Typography sx={modalTitleSx}>器具を追加する</Typography>
          <Form onSubmit={onSubmit}>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="name" sx={labelGrayOutSx}>
                器具名*
              </InputLabel>
              <TextInput
                source="name"
                variant="outlined"
                label={false}
                placeholder="CBS用シロップポンプ"
                sx={textInputSx}
                validate={validateName}
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="ejectionAmount" sx={labelGrayOutSx}>
                投入量*
              </InputLabel>
              <NumberInput
                source="ejectionAmount"
                variant="outlined"
                label={false}
                placeholder="3.75"
                sx={numberInputSx}
                validate={validateEjectionAmount}
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="ejectionAmountUnitID" sx={labelGrayOutSx}>
                投入量単位（調理用）*
              </InputLabel>
              <CustomPlaceholderSelectInput
                type="formInput"
                placeholder="選択してください"
                source="ejectionAmountUnitID"
                variant="outlined"
                label={false}
                sx={selectInputSx}
                validate={validateEjectionAmountUnit}
                choices={ejectionAmountUnits}
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="sizeScalingTypeID" sx={labelGrayOutSx}>
                投入量単位（表示用）*
              </InputLabel>
              <CustomPlaceholderSelectInput
                type="formInput"
                placeholder="選択してください"
                source="sizeScalingTypeID"
                variant="outlined"
                label={false}
                sx={selectInputSx}
                validate={validateSizeScalingType}
                choices={sizeScalingTypes}
                fullWidth
              />
            </Grid>
            <Grid container direction="row" justifyContent="flex-end">
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton onClick={handleOnCancelModalOpen}>
                  キャンセル
                </CancelButton>
              </Box>
              <SaveButton
                label="追加する"
                sx={{
                  width: '118px',
                  height: '36px',
                  borderRadius: '20px',
                  color: '#fff !important',
                  boxShadow: 'none',
                  backgroundColor: 'secondary.main',
                  '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: 'secondary.main',
                  },
                  '& .MuiSvgIcon-root': {
                    display: 'none',
                  },
                }}
              />
            </Grid>
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  )
}

export default AddUtensilModal
