import { AuthProvider, UserIdentity } from 'react-admin'
import { fetchUtils } from 'ra-core'
import jwt_decode from 'jwt-decode'

import { Payload } from '../types/jwt'
import { LoginParams, User, ErrorResponse, showMeResponseBody } from './types'

const authServerProvider = (apiURL: string): AuthProvider => {
  return {
    login: (params: LoginParams) => {
      return fetchUtils
        .fetchJson(`${apiURL}/login`, {
          method: 'POST',
          body: JSON.stringify(params),
        })
        .then((res) => {
          const body = JSON.parse(res.body)
          const accessToken = body.accessToken
          const payload = jwt_decode<Payload>(accessToken)
          if (
            payload.passwordResetToken !== null &&
            payload.confirmedAt === null
          ) {
            return Promise.resolve({
              redirectTo: `/password-reset?password-reset-token=${payload.passwordResetToken}`,
            })
          } else {
            localStorage.setItem('accessToken', accessToken)
            const user: User = {
              authenticated: true,
              token: `Bearer ${accessToken}`,
            }
            fetchUtils
              .fetchJson(`${apiURL}/me`, {
                method: 'GET',
                user,
              })
              .then(() => {
                const body = JSON.parse(res.body) as showMeResponseBody
                localStorage.setItem(
                  'permissions',
                  JSON.stringify(body.authPermissions),
                )
                return Promise.resolve()
              })
          }
        })
        .catch(() => {
          throw new Error('ログインに失敗しました')
        })
    },
    logout: () => {
      // ページ高速リロード時にはログアウト処理を行わない
      const navigationType = performance
        .getEntriesByType('navigation')[0]
        .toJSON().type
      if (navigationType !== 'reload') {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('permissions')
      }
      return Promise.resolve()
    },
    checkAuth: () => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken !== null) {
        const user: User = {
          authenticated: true,
          token: `Bearer ${accessToken}`,
        }
        return fetchUtils
          .fetchJson(`${apiURL}/me`, {
            method: 'GET',
            user,
          })
          .then((res) => {
            const body = JSON.parse(res.body) as showMeResponseBody
            localStorage.setItem(
              'permissions',
              JSON.stringify(body.authPermissions),
            )
            return Promise.resolve()
          })
          .catch(() => {
            return Promise.reject({ message: 'ログインしてください' })
          })
      } else {
        return Promise.reject({ message: 'ログインしてください' })
      }
    },
    checkError: (error: ErrorResponse) => {
      const status = error.status
      if (status === 401) {
        localStorage.removeItem('accessToken')
        return Promise.reject({
          redirectTo: '/login',
          message: 'ログインしてください',
        })
      }
      return Promise.resolve()
    },
    getIdentity: (): Promise<UserIdentity> => {
      // TODO: 実装
      const getIdentity = async (): Promise<UserIdentity> => {
        return {
          id: 1,
        }
      }
      return getIdentity()
    },
    getPermissions: () => {
      const permissions = localStorage.getItem('permissions')
      return permissions
        ? Promise.resolve(JSON.parse(permissions))
        : Promise.reject()
    },
  }
}

export const authProvider = authServerProvider(
  `${process.env.REACT_APP_API_ENDPOINT}`,
)
