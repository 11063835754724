import React from 'react'
import { Grid, InputLabel } from '@mui/material'
import { required } from 'react-admin'
import { RaRecord } from 'ra-core'
import CustomPlaceholderSelectInput from '../../customComponent/CustomPlaceholderSelectInput'
import { selectInputSx, labelGrayOutSx, gridSx } from '../../../assets/sx/form'

type TuningCustomizePreset = {
  label: string
  placeholder: string
  validateMsg: string
  source: string
  choices: Array<{ id: number | string; name: string }>
  defaultValue: number | string
  onChange: (event: React.ChangeEvent<HTMLInputElement> | RaRecord) => void
}

export const TuningCustomizePresetComponents: React.FC<
  TuningCustomizePreset
> = ({
  label,
  placeholder,
  validateMsg,
  source,
  choices,
  defaultValue,
  onChange,
}) => (
  <Grid sx={gridSx}>
    <InputLabel htmlFor={source} sx={labelGrayOutSx}>
      {label}
    </InputLabel>
    <CustomPlaceholderSelectInput
      type="formInput"
      placeholder={placeholder}
      source={source}
      variant="outlined"
      label={false}
      sx={selectInputSx}
      choices={choices}
      validate={required(validateMsg)}
      fullWidth
      defaultValue={defaultValue}
      onChange={onChange}
    />
  </Grid>
)
