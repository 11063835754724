import React, { ReactNode, MouseEvent } from 'react'
import Button from '@mui/material/Button'

type Props = {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  children: ReactNode
}

const DeleteButton: React.FC<Props> = ({ onClick, children }) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={onClick}
      disableElevation
      fullWidth
      sx={{
        width: '118px',
        height: '36px',
        borderRadius: '20px',
        color: '#fff !important',
        boxShadow: 'none',
        backgroundColor: 'warning.main',
        letterSpacing: '0.1em',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: 'warning.main',
        },
        '& .MuiSvgIcon-root': {
          display: 'none',
        },
      }}>
      {children}
    </Button>
  )
}

export default DeleteButton
