import React, { useEffect, useState } from 'react'
import { Box, Grid, Button } from '@mui/material'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import { DateData } from '../../../types/records/cip-log-record'
import { ReactComponent as DisableArrowIcon } from '../../../assets/images/arrow_right_gray.svg'
import { ReactComponent as ArrowIcon } from '../../../assets/images/arrow_gray.svg'

type Props = {
  today: Date | undefined
  selectedDate: string | undefined
  startYear: number
  startMonth: number
  onClick: (date: string) => void
}

const buttonSx: SxProps<Theme> = {
  height: '48px',
  width: '117px',
  borderRadius: '8px',
  fontWeight: 700,
  color: '#757575',
  padding: '0 12px',
}

const selectedButtonSx: SxProps<Theme> = {
  ...buttonSx,
  backgroundColor: '#F8F8F8',
  color: '#000000',
  '&:hover': {
    backgroundColor: '#F8F8F8',
  },
}

const arrowButtonSx: SxProps<Theme> = {
  ...buttonSx,
  width: '48px',
  minWidth: '48px',
}

// ボタンの最大表示数
const maxShowCount = 6

const CipLogSelectDateButton: React.FC<Props> = ({
  today,
  selectedDate,
  startYear,
  startMonth,
  onClick,
}) => {
  const [showCount, setShowCount] = useState<number>(maxShowCount)
  const [showDateList, setShowDateList] = useState<DateData[]>([])
  const [showLastIndex, setShowLastIndex] = useState<number>(0)
  const [dateList, setDateList] = useState<DateData[]>([])

  useEffect(() => {
    if (!today) return

    const todayMonth = today.getMonth() + 1
    const todayYear = today.getFullYear()

    // 日付リスト作成
    // システム開始年月〜モーダルを開いた年月まで
    let year = startYear
    let month = startMonth

    const dateList: DateData[] = []
    while (todayYear !== year || todayMonth >= month) {
      if (month === 13) {
        year++
        month = 1
      }
      const dateData: DateData = {
        date: `${year}-${month.toString().padStart(2, '0')}`,
        displayName: `${year}年${month}月`,
      }
      dateList.push(dateData)
      month++
    }

    setDateList(dateList)

    // 表示したいボタンの数が最大表示数より少ない場合を考慮
    const showCount =
      dateList.length < maxShowCount ? dateList.length : maxShowCount
    setShowCount(showCount)
    setShowLastIndex(dateList.length - 1)
    setShowDateList(
      dateList.slice(dateList.length - showCount, dateList.length),
    )
  }, [today])

  const onClickNext = () => {
    const newLastIndex = showLastIndex + 1
    if (newLastIndex >= dateList.length) return

    setShowLastIndex(newLastIndex)
    setShowDateList(
      dateList.slice(newLastIndex - showCount + 1, newLastIndex + 1),
    )
  }

  const onClickPrev = () => {
    const newLastIndex = showLastIndex - 1
    if (newLastIndex + 1 < showCount) return

    setShowLastIndex(newLastIndex)
    setShowDateList(
      dateList.slice(newLastIndex - showCount + 1, newLastIndex + 1),
    )
  }

  return (
    <Grid container direction="row" justifyContent="center">
      <Box sx={{ paddingBottom: '24px', paddingRight: '10px' }}>
        <Button
          sx={arrowButtonSx}
          onClick={onClickPrev}
          disabled={showLastIndex + 1 === showCount}>
          {showLastIndex + 1 === showCount ? (
            <DisableArrowIcon
              style={{ marginRight: '4px', transform: 'scale(-1, 1)' }}
            />
          ) : (
            <ArrowIcon
              style={{ marginRight: '4px', transform: 'scale(-1,1)' }}
            />
          )}
          前
        </Button>
      </Box>
      {showDateList.map((date, index) => (
        <Box
          key={`button_${index}`}
          sx={{ paddingBottom: '24px', paddingRight: '10px' }}>
          <Button
            sx={selectedDate === date.date ? selectedButtonSx : buttonSx}
            onClick={() => onClick(date.date)}>
            {date.displayName}
          </Button>
        </Box>
      ))}
      <Box sx={{ paddingBottom: '24px' }}>
        <Button
          sx={arrowButtonSx}
          onClick={onClickNext}
          disabled={showLastIndex + 1 === dateList.length}>
          次
          {showLastIndex + 1 === dateList.length ? (
            <DisableArrowIcon style={{ marginLeft: '4px' }} />
          ) : (
            <ArrowIcon style={{ marginLeft: '4px' }} />
          )}
        </Button>
      </Box>
    </Grid>
  )
}

export default CipLogSelectDateButton
