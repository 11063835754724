import React from 'react'
import { Grid, InputLabel, Typography } from '@mui/material'
import {
  SizeScalingPresetRecord,
  RecipeIngredientInputRecord,
} from '../../../types/records/recipe-record'
import { IngredientRecord } from '../../../types/records/ingredient-record'
import { confirmGridContainerSx } from '../../../assets/sx/form'
import { modalTitleSx, modalLeftItemLabelSx } from '../../../assets/sx/modalSx'

type Props = {
  ingredientMap: Map<number, IngredientRecord>
  sizeScalingPresetMap: Map<number, SizeScalingPresetRecord>
  ingredientCategoryName: string
  recipeIngredientInput: RecipeIngredientInputRecord
}

const ShowRecipeModalIngredientField: React.FC<Props> = ({
  ingredientMap,
  sizeScalingPresetMap,
  ingredientCategoryName,
  recipeIngredientInput,
}) => {
  let ingredientName: string | undefined = undefined
  if (recipeIngredientInput) {
    ingredientName = ingredientMap.get(
      recipeIngredientInput.ingredientID as number,
    )?.name
  }
  let sizeScalingPreset: SizeScalingPresetRecord | undefined = undefined
  if (recipeIngredientInput) {
    sizeScalingPreset = sizeScalingPresetMap.get(
      recipeIngredientInput.sizeScalingPresetID as number,
    )
  }

  return (
    <Grid>
      <Typography sx={modalTitleSx}>{ingredientCategoryName}</Typography>
      <Grid sx={confirmGridContainerSx} container>
        <InputLabel sx={modalLeftItemLabelSx}>材料</InputLabel>
        <Grid sx={{ m: 'auto', width: '50%' }}>
          {ingredientName ? ingredientName : '-'}
        </Grid>
      </Grid>
      {sizeScalingPreset && (
        <Grid>
          {sizeScalingPreset.ingredientSizeCoefficients.map(
            (ingredientSizeCoefficient) => (
              <Grid
                sx={confirmGridContainerSx}
                container
                key={`size_${ingredientSizeCoefficient.size.name}`}>
                <InputLabel sx={modalLeftItemLabelSx}>
                  投入量_{ingredientSizeCoefficient.size.name}
                </InputLabel>
                <Grid sx={{ mt: 'auto', mb: 'auto' }}>
                  {ingredientSizeCoefficient.coefficient}
                  {sizeScalingPreset?.sizeScalingType.name}
                </Grid>
              </Grid>
            ),
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default ShowRecipeModalIngredientField
