import React from 'react'
import { useRecordContext } from 'react-admin'
import { IngredientPlacementRecord } from '../../types/records/ingredient-placement-record'

import styles from './IngredientPlacementStoreTypeField.module.scss'

const IngredientPlacementStatusField = () => {
  const record = useRecordContext<IngredientPlacementRecord>()

  if (!record) {
    return null
  }

  return (
    <>
      {record.storeType?.id && (
        <span
          className={`${styles.base} `}
          style={{
            color: record.storeType.displayColor.displayFontColor,
            background: record.storeType.displayColor.displayBackgroundColor,
          }}>
          {record.storeType.displayName}
        </span>
      )}
    </>
  )
}

export default IngredientPlacementStatusField
