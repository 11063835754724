import React, { MouseEvent } from 'react'
import Button from '@mui/material/Button'
import { ReactComponent as MoreIcon } from '../../assets/images/more.svg'

type Props = {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
}

const MoreButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      color="inherit"
      sx={{
        height: '40px',
        borderRadius: '20px',
        fontWeight: 700,
        backgroundColor: '#FFF',
      }}
      disableElevation
      fullWidth>
      <MoreIcon />
    </Button>
  )
}

export default MoreButton
