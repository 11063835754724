import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  InputLabel,
  Divider,
  InputAdornment,
} from '@mui/material'
import { parseISO } from 'date-fns'
import {
  gridSx,
  textInputSx,
  choicesSx,
  labelGrayOutSx,
  longTextInputSx,
  dateInputSx,
  selectInputSx,
} from '../../../assets/sx/form'
import {
  required,
  TextInput,
  DateInput,
  RadioButtonGroupInput,
  NumberInput,
} from 'react-admin'
import { ReactComponent as CalenderIcon } from '../../../assets/images/calender.svg'
import { StoreTypeRecord } from '../../../types/records/store-record'
import { HopperRecord } from '../../../types/records/hopper_record'
import CustomPlaceholderSelectInput from '../../customComponent/CustomPlaceholderSelectInput'

type Props = {
  storeTypes: StoreTypeRecord[]
  hoppers: HopperRecord[]
}

const AddIngredientPlacementModalInput: React.FC<Props> = ({
  storeTypes,
  hoppers,
}) => {
  const validateName = [required('エラー入力してください')]

  const [operationStartAt, setOperationStartAt] = useState<string>('')
  const [operationEndAt, setOperationEndAt] = useState<string>('')

  const validateBeforeOperationEndAt = (startAt: Date) => {
    startAt = new Date(startAt)
    const endAt = new Date(parseISO(operationEndAt))
    if (startAt.getTime() < endAt.getTime()) {
      return undefined
    }
    return 'エラー運用終了日より前の日付を選択してください'
  }

  const validateAfterOperationStartAt = (endAt: Date) => {
    endAt = new Date(endAt)
    const startAt = new Date(parseISO(operationStartAt))
    if (startAt.getTime() < endAt.getTime()) {
      return undefined
    }
    return 'エラー運用開始日より後の日付を選択してください'
  }

  const [presetHopperIngredientLinks, setPresetHopperIngredientLinks] =
    useState<{ hopperID: number; ingredientID?: number }[]>([])
  useEffect(() => {
    if (hoppers.length == presetHopperIngredientLinks.length) {
      return
    }
    if (hoppers) {
      hoppers.forEach((hopper) => {
        const validationPresetHopperIngredientLink = { hopperID: hopper.id }
        presetHopperIngredientLinks.push(validationPresetHopperIngredientLink)
      })
    }
  }, [hoppers])

  const updateIngredientID = (
    index: number,
    ingredientID: number | undefined,
  ) => {
    const presetHopperIngredientLinksState = presetHopperIngredientLinks
    presetHopperIngredientLinksState[index].ingredientID = ingredientID
    setPresetHopperIngredientLinks([...presetHopperIngredientLinksState])
  }

  const validatePresetHopperIngredientLinks = () => {
    let ingredientCount = 0
    presetHopperIngredientLinks.forEach((item) => {
      if (item.ingredientID) {
        ingredientCount++
      }
    })
    if (ingredientCount === 0) {
      return 'エラーホッパーに材料が1つも選択されていません'
    }
    return undefined
  }

  return (
    <Grid>
      <Typography
        sx={{ fontSize: '16px', fontWeight: 700, mb: '40px', display: 'flex' }}>
        配置パターンを追加する
      </Typography>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="name" sx={labelGrayOutSx}>
          名前 *
        </InputLabel>
        <TextInput
          source="name"
          variant="outlined"
          label={false}
          sx={textInputSx}
          validate={validateName}
          fullWidth
        />
      </Grid>
      <Divider sx={{ mt: '-3%', mb: '5%' }} />
      <Grid wrap="wrap" container>
        {hoppers?.map((hopper: HopperRecord, index) => (
          <Grid width={'100%'} key={hopper.name} item>
            <InputLabel htmlFor="hopperRecipeBlenderLinks" sx={labelGrayOutSx}>
              {hopper.name}
              <NumberInput
                source={`newPresetHopperIngredientLinks[${index}][hopperID]]`}
                defaultValue={hopper.id}
                disabled
                sx={{
                  display: 'none',
                }}
              />
            </InputLabel>
            <CustomPlaceholderSelectInput
              type="formInput"
              className={`newPresetHopperIngredientLinks[${index}][ingredientID]]`}
              source={`newPresetHopperIngredientLinks[${index}][ingredientID]]`}
              choices={hopper.ingredients}
              label={false}
              variant="outlined"
              margin="none"
              placeholder="選択してください"
              validate={validatePresetHopperIngredientLinks}
              onChange={(e) => updateIngredientID(index, e.target.value)}
              sx={selectInputSx}
              fullWidth
            />
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ mt: '1%', mb: '7%' }} />
      <Grid sx={gridSx}>
        <InputLabel htmlFor="operationStartAt" sx={labelGrayOutSx}>
          運用開始日 *
        </InputLabel>
        <DateInput
          source="operationStartAt"
          variant="outlined"
          label={false}
          sx={dateInputSx}
          validate={[
            required('エラー選択してください'),
            validateBeforeOperationEndAt,
          ]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalenderIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setOperationStartAt(e.target.value)
          }}
          fullWidth
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="operationEndAt" sx={labelGrayOutSx}>
          運用終了日 *
        </InputLabel>
        <DateInput
          source="operationEndAt"
          variant="outlined"
          label={false}
          sx={dateInputSx}
          validate={[
            required('エラー選択してください'),
            validateAfterOperationStartAt,
          ]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalenderIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setOperationEndAt(e.target.value)
          }}
          fullWidth
        />
      </Grid>
      <Grid sx={{ mb: '8px', mt: '8px' }}>
        <InputLabel htmlFor="description" sx={labelGrayOutSx}>
          説明
        </InputLabel>
        <TextInput
          source={`description`}
          variant="outlined"
          label={false}
          sx={longTextInputSx}
          placeholder={'入力してください'}
          multiline={true}
          fullWidth
        />
      </Grid>
      <Divider sx={{ mb: '6%' }} />
      <Grid sx={gridSx} item>
        <InputLabel
          htmlFor="storeTypeID"
          sx={{ fontSize: '16px', fontWeight: 700, color: 'black' }}>
          対象店舗を選ぶ*
        </InputLabel>
        <RadioButtonGroupInput
          source="storeTypeID"
          variant="outlined"
          label={false}
          sx={choicesSx}
          choices={storeTypes}
          validate={required('エラー選択してください')}
          parse={(storeTypes) => parseInt(storeTypes)}
        />
      </Grid>
      <Divider sx={{ mt: '-7%', mb: '12%' }} />
    </Grid>
  )
}

export default AddIngredientPlacementModalInput
