import React, { MouseEvent, useState } from 'react'
import {
  TextField,
  FunctionField,
  useDataProvider,
  useGetList,
  useNotify,
  useRefresh,
  NumberField,
} from 'react-admin'
import { Grid } from '@mui/material'

import AddButton from '../components/button/AddButton'
import IconButton from '../components/button/IconButton'
import AddUtensilModal from '../components/modal/AddUtensilModal'
import EditUtensilModal from '../components/modal/EditUtensilModal'
import ListDatagrid from '../components/ListDatagrid'

import {
  UtensilRecord,
  UtensilParams,
  SizeScalingTypeRecord,
  EjectionAmountUnitRecord,
} from '../types/records/utensil-record'
import { useAuthorize, useNotifyForbidden } from '../hooks/authorization'

import { ReactComponent as PencilIcon } from '../assets/images/pencil.svg'
import { firstFieldSx } from '../assets/sx/field'

import style from './UtensilList.module.scss'

const UtensilList: React.FC = () => {
  const dataProvider = useDataProvider()
  const sizeScalingTypes = useGetList('size-scaling-types')
  const ejectionAmountUnits = useGetList('ejection-amount-units')
  const notify = useNotify()
  const refresh = useRefresh()
  const [targetUtensil, setTargetUtensil] = useState<UtensilRecord>()
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false)
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const canCreateUtensil = useAuthorize('utensil', 'create')
  const canUpdateUtensil = useAuthorize('utensil', 'update')
  const notifyForbidden = useNotifyForbidden()

  const openCreateModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setCreateModalOpen(true)
  }

  const openEditModal = (
    e: MouseEvent<HTMLElement>,
    targetUtensil: UtensilRecord | undefined,
  ) => {
    e.preventDefault()

    if (!canUpdateUtensil) {
      notifyForbidden()
      return
    }

    setEditModalOpen(true)
    setTargetUtensil(targetUtensil)
  }

  const closeCreateModal = () => {
    setCreateModalOpen(false)
  }

  const closeEditModal = () => {
    setEditModalOpen(false)
  }

  const onCreateSubmit = (data: UtensilParams) => {
    dataProvider
      .create('utensils', { data })
      .then(() => {
        notify('器具を追加しました')
        setCreateModalOpen(false)
        refresh()
      })
      .catch((error) => {
        notify(error.message, { type: 'error' })
      })
  }

  const onUpdateSubmit = (
    id: number,
    data: UtensilParams,
    previousData: UtensilRecord,
  ) => {
    dataProvider
      .update('utensils', { id, data, previousData })
      .then(() => {
        notify('器具を更新しました')
        setEditModalOpen(false)
        refresh()
      })
      .catch((error) => {
        notify(error.message, { type: 'error' })
      })
  }

  const settingButtonItems = [
    { tooltipLabel: '編集', icon: <PencilIcon />, onClick: openEditModal },
  ]

  return (
    <>
      <ListDatagrid
        title="器具マスター"
        filters={[]}
        addButton={
          <AddButton onClick={openCreateModal} disabled={!canCreateUtensil}>
            器具を追加する
          </AddButton>
        }
        modals={[
          <AddUtensilModal
            key="addUtensilModal"
            open={createModalOpen}
            sizeScalingTypes={sizeScalingTypes.data as SizeScalingTypeRecord[]}
            ejectionAmountUnits={
              ejectionAmountUnits.data as EjectionAmountUnitRecord[]
            }
            onClose={closeCreateModal}
            onSubmit={onCreateSubmit}
          />,
          <EditUtensilModal
            key="editUtensilModal"
            open={editModalOpen}
            sizeScalingTypes={sizeScalingTypes.data as SizeScalingTypeRecord[]}
            ejectionAmountUnits={
              ejectionAmountUnits.data as EjectionAmountUnitRecord[]
            }
            utensil={targetUtensil}
            onClose={closeEditModal}
            onSubmit={onUpdateSubmit}
          />,
        ]}>
        <TextField
          source="id"
          label="器具ID"
          sortable={false}
          headerClassName={style.utensilIdField}
          sx={firstFieldSx}
        />
        <TextField
          source="name"
          label="器具名"
          headerClassName={style.utensilNameField}
          sortable={false}
        />
        <NumberField
          source="ejectionAmount"
          label="投入量"
          headerClassName={style.utensilEjectionAmountField}
          sortable={false}
        />
        <TextField
          source="ejectionAmountUnit[name]"
          label="投入量単位（調理用）"
          headerClassName={style.utensilEjectionTypeField}
          sortable={false}
        />
        <TextField
          source="sizeScalingType[name]"
          label="投入量単位（表示用）"
          headerClassName={style.utensilEjectionTypeField}
          sortable={false}
        />
        <FunctionField
          sortable={false}
          render={(record: UtensilRecord) => {
            return (
              <Grid container display="flex" justifyContent="right">
                {settingButtonItems.map((item, index) => {
                  return (
                    <IconButton<UtensilRecord>
                      key={`iconButton_${index}`}
                      tooltipLabel={item.tooltipLabel}
                      onClick={item.onClick}
                      icon={item.icon}
                      record={record}
                    />
                  )
                })}
              </Grid>
            )
          }}
          headerClassName={style.moreButtonField}
        />
      </ListDatagrid>
    </>
  )
}

export default UtensilList
