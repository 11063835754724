import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'

export const gridSx: SxProps<Theme> = {
  mb: '8px',
}

export const labelSx: SxProps<Theme> = {
  color: 'primary.main',
  mb: '8px',
  display: 'inline-block',
  fontSize: '14px',
  lineHeight: '18px',
}

export const labelGrayOutSx: SxProps<Theme> = {
  ...labelSx,
  color: '#757575',
}

export const commonInputSx: SxProps<Theme> = {
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: '#005739',
    },
  },
  legend: {
    display: 'none',
  },
}

export const textInputSx: SxProps<Theme> = {
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0.5px!important',
      borderColor: '#005739',
    },
  },
  '& .MuiInputBase-root': {
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputBase-input': {
      padding: '0 12px',
      height: '44px',
      boxSizing: 'border-box',
      margin: '0',
    },
  },
}

export const longTextInputSx: SxProps<Theme> = {
  ...commonInputSx,
  '& .MuiInputBase-input': {
    boxSizing: 'border-box',
  },
  '& .MuiInputBase-root': {
    minHeight: '180px',
    alignItems: 'flex-start',
  },
  fieldset: {
    height: 'auto',
  },
}

export const numberInputSx: SxProps<Theme> = {
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0.5px!important',
      borderColor: '#005739',
    },
  },
  '& .MuiInputBase-root': {
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputBase-input': {
      padding: '12px',
      boxSizing: 'border-box',
    },
  },
  '& .MuiOutlinedInput-root legend': {
    display: 'none',
  },
  fieldset: {
    position: 'absolute',
    top: '-1px!important',
    margin: '0',
  },
}

export const selectInputSx: SxProps<Theme> = {
  marginTop: '0!important',
  marginBottom: '0!important',
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0.5px!important',
      borderColor: '#005739',
    },
  },
  '& .MuiInputBase-root': {
    height: '40px',
    margin: '0',
    borderWidth: 0.5,
    '& .MuiInputBase-input': {
      padding: '12px',
      boxSizing: 'border-box',
      position: 'relative',
      bottom: '0',
      right: '0',
      top: '-3px',
      left: '0',
      margin: '0',
    },
  },
  '& .MuiOutlinedInput-root legend': {
    display: 'none',
  },
}

export const cancelButtonWrapperSx: SxProps<Theme> = {
  width: '118px',
  marginRight: '8px',
}

export const saveButtonSx: SxProps<Theme> = {
  height: '36px',
  borderRadius: '20px',
  color: '#fff !important',
  boxShadow: 'none',
  backgroundColor: 'secondary.main',
  letterSpacing: '0.1em',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: 'secondary.main',
  },
  '& .MuiSvgIcon-root': {
    display: 'none',
  },
}

export const nextButtonSx: SxProps<Theme> = {
  ...saveButtonSx,
  '&.Mui-disabled': {
    background: '#eaeaea',
    color: '#c0c0c0',
  },
}

export const addButtonSx: SxProps<Theme> = {
  height: '40px',
  borderRadius: '20px',
  fontWeight: 700,
  color: '#005739',
  '& svg': {
    marginRight: '10px',
  },
}

export const dateInputSx: SxProps<Theme> = {
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0.5px!important',
      borderColor: '#005739',
    },
  },
  '& .MuiInputBase-root': {
    height: '40px',
    margin: '0',
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputBase-input': {
      padding: '12px',
      boxSizing: 'border-box',
      position: 'relative',
      top: '-3px',
      height: '100%',
    },
  },
  '& .MuiInputAdornment-root': {
    position: 'relative',
    top: '-3px',
  },
  '& .MuiOutlinedInput-root legend': {
    display: 'none',
  },
  'input[type="date" i]::-webkit-calendar-picker-indicator': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: '0',
  },
}

export const confirmGridContainerSx: SxProps<Theme> = {
  width: '100%',
  mt: '20px',
  mb: '20px',
}

export const confirmInputLabelSx: SxProps<Theme> = {
  ...labelSx,
  mt: 'auto',
  mb: 'auto',
  width: '50%',
}

export const confirmInputGridSx: SxProps<Theme> = {
  m: 'auto',
  width: '50%',
}

export const confirmWithLineBreak: SxProps<Theme> = {
  '& .MuiFormControlLabel-label': {
    marginTop: '20px',
    whiteSpace: 'pre-line',
  },
}

export const modalWrapperSx: SxProps<Theme> = {
  width: 398,
  maxHeight: '80vh',
  backgroundColor: '#FFF',
  borderRadius: 2,
  p: 3,
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  top: '50%',
  left: '50%',
  overflow: 'scroll',
}

export const choicesSx: SxProps<Theme> = {
  '.MuiRadio-colorPrimary': {
    color: '#005739',
  },
  display: 'inline-block',
  fontSize: '12px',
  lineHeight: '14px',
  color: '#757575',
}

export const cipLogModalSx: SxProps<Theme> = {
  ...modalWrapperSx,
  width: 918,
  padding: 0,
  overflowX: 'hidden',
}

export const cipLogItemColumnSx: SxProps<Theme> = {
  bgcolor: '#FBFAF9',
  color: '#757575',
  fontSize: 12,
}

export const cipLogTextInputSx: SxProps<Theme> = {
  ...textInputSx,
  marginTop: '5px',
  '& .MuiFormHelperText-root': {
    display: 'none',
  },
}

export const checkBoxSx: SxProps<Theme> = {
  color: '#005739',
  '&.Mui-checked': {
    color: '#005739',
  },
}

export const validationErrorSx: SxProps<Theme> = {
  fontSize: '12px',
  color: '#d32f2f',
  mt: '-18px',
  ml: '14px',
  mr: '14px',
}
