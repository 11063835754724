import React from 'react'
import { Grid, InputLabel, Typography } from '@mui/material'
import {
  SizeScalingPresetRecord,
  RecipeIngredientInputParam,
  SizeScalingPresetParam,
} from '../../../types/records/recipe-record'
import { IngredientRecord } from '../../../types/records/ingredient-record'
import { SizeScalingTypeRecord } from '../../../types/records/utensil-record'
import { SizeRecord } from '../../../types/records/size-record'
import {
  confirmGridContainerSx,
  confirmInputGridSx,
} from '../../../assets/sx/form'
import { modalTitleSx, modalLeftItemLabelSx } from '../../../assets/sx/modalSx'
type Props = {
  sizeMap: Map<number, SizeRecord>
  ingredientMap: Map<number, IngredientRecord>
  sizeScalingPresetMap: Map<number, SizeScalingPresetRecord>
  sizeScalingTypeMap: Map<number, SizeScalingTypeRecord>
  ingredientCategoryName: string
  recipeIngredientInputs: RecipeIngredientInputParam[]
}

const ConfirmIngredientListField: React.FC<Props> = ({
  sizeMap,
  ingredientMap,
  sizeScalingPresetMap,
  sizeScalingTypeMap,
  ingredientCategoryName,
  recipeIngredientInputs,
}) => {
  const sizeScalingPresetTsx = (sizeScalingPreset: SizeScalingPresetRecord) => {
    return (
      <Grid>
        {sizeScalingPreset.ingredientSizeCoefficients.map(
          (ingredientSizeCoefficient) => (
            <Grid
              sx={confirmGridContainerSx}
              container
              key={`size_${ingredientSizeCoefficient.size.name}`}>
              <InputLabel sx={modalLeftItemLabelSx}>
                投入量_{ingredientSizeCoefficient.size.name}
              </InputLabel>
              <Grid sx={{ mt: 'auto', mb: 'auto' }}>
                {ingredientSizeCoefficient.coefficient}
                {sizeScalingPreset.sizeScalingType.name}
              </Grid>
            </Grid>
          ),
        )}
      </Grid>
    )
  }

  const newSizeScalingPresetTsx = (
    newSizeScalingPreset: SizeScalingPresetParam,
    sizeScalingType: SizeScalingTypeRecord,
  ) => {
    return (
      <Grid>
        {newSizeScalingPreset.ingredientSizeCoefficients.map(
          (ingredientSizeCoefficient) => (
            <Grid
              sx={confirmGridContainerSx}
              container
              key={`size_${
                sizeMap.get(ingredientSizeCoefficient.sizeID as number)?.name
              }`}>
              <InputLabel sx={modalLeftItemLabelSx}>
                投入量_
                {sizeMap.get(ingredientSizeCoefficient.sizeID as number)?.name}
              </InputLabel>
              <Grid sx={{ mt: 'auto', mb: 'auto' }}>
                {ingredientSizeCoefficient.coefficient}
                {sizeScalingType.name}
              </Grid>
            </Grid>
          ),
        )}
      </Grid>
    )
  }

  const whichSizeScalingPresetTsx = (
    recipeIngredientInputParam: RecipeIngredientInputParam,
  ) => {
    if (
      recipeIngredientInputParam &&
      recipeIngredientInputParam.newSizeScalingPreset
    ) {
      const sizeScalingType = sizeScalingTypeMap.get(
        recipeIngredientInputParam.newSizeScalingPreset
          .sizeScalingTypeID as number,
      )
      return newSizeScalingPresetTsx(
        recipeIngredientInputParam.newSizeScalingPreset,
        sizeScalingType as SizeScalingTypeRecord,
      )
    } else if (
      recipeIngredientInputParam &&
      recipeIngredientInputParam.sizeScalingPresetID
    ) {
      const sizeScalingPreset = sizeScalingPresetMap.get(
        recipeIngredientInputParam.sizeScalingPresetID as number,
      )
      return sizeScalingPresetTsx(sizeScalingPreset as SizeScalingPresetRecord)
    } else {
      return <></>
    }
  }

  return (
    <Grid>
      <Typography sx={modalTitleSx}>{ingredientCategoryName}</Typography>
      {recipeIngredientInputs.length === 0 && (
        <Grid sx={confirmGridContainerSx} container>
          <InputLabel sx={modalLeftItemLabelSx}>材料</InputLabel>
          <Grid sx={confirmInputGridSx}> - </Grid>
        </Grid>
      )}
      {recipeIngredientInputs.length > 0 &&
        recipeIngredientInputs.map((target, index) => (
          <Grid key={`${ingredientCategoryName}_${index}`}>
            <Grid sx={confirmGridContainerSx} container>
              <InputLabel sx={modalLeftItemLabelSx}>材料</InputLabel>
              <Grid sx={confirmInputGridSx}>
                {ingredientMap.get(target.ingredientID as number)?.name}
              </Grid>
            </Grid>
            {whichSizeScalingPresetTsx(target)}
          </Grid>
        ))}
    </Grid>
  )
}

export default ConfirmIngredientListField
