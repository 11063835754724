import React, { useState, useEffect } from 'react'
import { Grid, InputLabel, Typography } from '@mui/material'
import { IngredientParams } from '../../../types/records/ingredient-record'
import { IngredientCategoryRecord } from '../../../types/records/ingredient-record'
import { UtensilRecord } from '../../../types/records/utensil-record'
import { TuningCustomizePresetRecord } from '../../../types/records/tuning-customize-preset-record'
import { NewTuningCustomizePresetParams } from '../../../types/records/ingredient-record'
import { NewCustomizePresetParams } from '../../../types/records/ingredient-record'
import { SizeScalingPresetRecord } from '../../../types/records/recipe-record'
import { SizeRecord } from '../../../types/records/size-record'
import { formatDate } from '../../../assets/utils/formData'
import {
  labelSx,
  confirmGridContainerSx,
  confirmInputGridSx,
} from '../../../assets/sx/form'
import {
  modalTitleSx,
  modalLeftItemLabelSx,
  modalRightItemInputSx,
} from '../../../assets/sx/modalSx'

type Props = {
  ingredient: IngredientParams
  ingredientCategories: IngredientCategoryRecord[]
  sizes: SizeRecord[]
  utensils: UtensilRecord[]
  sizeScalingPresets: SizeScalingPresetRecord[]
  tuningCustomizePresets: TuningCustomizePresetRecord[]
  newSizeScalingPreset: NewCustomizePresetParams
  newTuningCustomizePreset: NewTuningCustomizePresetParams
  file: File
}

const IngredientModalConfirm: React.FC<Props> = ({
  ingredient,
  ingredientCategories,
  utensils,
  sizes,
  sizeScalingPresets,
  tuningCustomizePresets,
  file,
}) => {
  const [targetIngredientCategory, setTargetIngredientCategory] =
    useState<IngredientCategoryRecord>()

  const [TargetUtensils, setTargetUtensils] = useState<UtensilRecord>()
  const [targetSizeScalingPreset, setTargetSizeScalingPreset] =
    useState<SizeScalingPresetRecord>()

  const [targetTuningCustomizePreset, setTargetTuningCustomizePreset] =
    useState<TuningCustomizePresetRecord>()

  const [previewImage, setPreviewImage] = useState<string>('')

  useEffect(() => {
    setTargetIngredientCategory(
      ingredientCategories.find(
        (ingredientCategory) =>
          ingredientCategory.id == ingredient?.ingredientCategoryID,
      ),
    )
    setTargetUtensils(
      utensils.find((utensil) => utensil.id == ingredient?.utensilID),
    )
    setTargetSizeScalingPreset(
      sizeScalingPresets.find(
        (sizeScalingPreset) =>
          sizeScalingPreset.id == ingredient?.sizeScalingPresetID,
      ),
    )
    setTargetTuningCustomizePreset(
      tuningCustomizePresets.find(
        (TuningCustomizePresets) =>
          TuningCustomizePresets.id == ingredient?.tuningCustomizePresetID,
      ),
    )

    file.name != '' && setPreviewImage(URL.createObjectURL(file))
  }, [])

  return (
    <Grid>
      <Typography sx={modalTitleSx}>材料を確認する</Typography>
      <Grid sx={confirmGridContainerSx} container>
        <InputLabel sx={modalLeftItemLabelSx}>材料 *</InputLabel>
        <Grid sx={confirmInputGridSx}>{ingredient?.name}</Grid>
      </Grid>
      <Grid sx={confirmGridContainerSx} container>
        <InputLabel sx={modalLeftItemLabelSx}>表示名 *</InputLabel>
        <Grid sx={modalRightItemInputSx}>{ingredient?.displayName}</Grid>
      </Grid>
      <Grid sx={confirmGridContainerSx} container>
        <InputLabel sx={modalLeftItemLabelSx}>材料カテゴリ *</InputLabel>
        <Grid sx={modalRightItemInputSx}>{targetIngredientCategory?.name}</Grid>
      </Grid>
      <Grid sx={confirmGridContainerSx} container>
        <InputLabel sx={modalLeftItemLabelSx}>容器容量 *</InputLabel>
        <Grid sx={confirmInputGridSx}>
          {ingredient?.containerCapacityAmount + 'g'}
        </Grid>
      </Grid>
      <Grid sx={confirmGridContainerSx} container>
        <InputLabel sx={modalLeftItemLabelSx}>画像</InputLabel>
        {previewImage != '' ? (
          <img
            src={previewImage}
            alt="previewImage"
            width="64"
            height="64"
            style={{ borderRadius: '5px', border: '1px solid #ccc' }}
          />
        ) : (
          <Grid sx={confirmInputGridSx}> - </Grid>
        )}
      </Grid>
      <Grid sx={confirmGridContainerSx} container>
        <InputLabel sx={modalLeftItemLabelSx}>投入器具 *</InputLabel>
        <Grid sx={confirmInputGridSx}>{TargetUtensils?.name}</Grid>
      </Grid>
      <Grid sx={confirmGridContainerSx} container>
        <InputLabel sx={modalLeftItemLabelSx}>提供開始日</InputLabel>
        <Grid sx={modalRightItemInputSx}>
          {formatDate(ingredient?.offerStartAt)}
        </Grid>
      </Grid>
      <Grid sx={confirmGridContainerSx} container>
        <InputLabel sx={modalLeftItemLabelSx}>提供終了日</InputLabel>
        <Grid sx={modalRightItemInputSx}>
          {formatDate(ingredient?.offerEndAt)}
        </Grid>
      </Grid>
      {targetSizeScalingPreset?.name && (
        <Grid sx={confirmGridContainerSx} container>
          <InputLabel sx={modalLeftItemLabelSx}>追加カスタマイズ *</InputLabel>
          <Grid sx={modalRightItemInputSx}>
            {targetSizeScalingPreset?.name}
          </Grid>
        </Grid>
      )}
      {ingredient?.newSizeScalingPreset && (
        <Grid sx={confirmGridContainerSx} container>
          <InputLabel sx={modalLeftItemLabelSx}>
            追加カスタマイズ名 *
          </InputLabel>
          <Grid sx={modalRightItemInputSx}>
            {ingredient?.newSizeScalingPreset?.name}
          </Grid>
          {ingredient?.newSizeScalingPreset.ingredientSizeCoefficients?.map(
            (coefficient, index) => {
              const size = sizes.find((size) => size.id === coefficient?.sizeID)
              const sizeName = size ? size.name : ''
              const utensil = utensils.find(
                (utensil) => utensil.id === ingredient.utensilID,
              )

              return (
                <Grid container key={index}>
                  <InputLabel sx={{ ...labelSx, mt: '20px', width: '50%' }}>
                    {`投入量_${sizeName} *`}
                  </InputLabel>
                  <Grid>
                    <Grid
                      id={`size-${index}`}
                      sx={{ mt: '20px', width: '50%' }}></Grid>
                    <Grid>
                      <Grid container>
                        {coefficient?.coefficient || '-'}
                        {utensil?.sizeScalingType?.name ? (
                          <Typography
                            sx={{
                              fontSize: '14px',
                              color: '#757575',
                              // m: '8px',
                              marginLeft: '8px', // 適宜マージンを調整
                            }}>
                            {utensil.sizeScalingType.name}
                          </Typography>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            },
          )}
        </Grid>
      )}

      {targetTuningCustomizePreset?.name && (
        <Grid sx={confirmGridContainerSx} container>
          <InputLabel sx={modalLeftItemLabelSx}>
            量調整カスタマイズ *
          </InputLabel>
          <Grid sx={modalRightItemInputSx}>
            {targetTuningCustomizePreset?.name}
          </Grid>
        </Grid>
      )}

      {ingredient?.newTuningCustomizePreset && (
        <Grid sx={confirmGridContainerSx} container>
          <InputLabel sx={modalLeftItemLabelSx}>
            量調整カスタマイズ名 *
          </InputLabel>
          <Grid sx={modalRightItemInputSx}>
            {ingredient?.newTuningCustomizePreset?.name}
          </Grid>
          {ingredient?.newTuningCustomizePreset?.tuningCustomizeCoefficients?.map(
            (coefficient, index) => (
              <React.Fragment key={index}>
                <InputLabel sx={modalLeftItemLabelSx}>
                  {index % 2 === 0 ? '減量 *' : '増量 *'}
                </InputLabel>
                <Grid id={`size-${index}`} sx={modalRightItemInputSx}>
                  {coefficient?.coefficient + '倍' || '-'}
                </Grid>
              </React.Fragment>
            ),
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default IngredientModalConfirm
