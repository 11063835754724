import React, { useState, useEffect } from 'react'
import {
  IngredientCategoryRecord,
  IngredientParams,
  IngredientCategoryType,
} from '../../../types/records/ingredient-record'
import { parseISO } from 'date-fns'
import { Grid, Typography, InputLabel, InputAdornment } from '@mui/material'
import { required, TextInput, DateInput } from 'react-admin'
import { SizeRecord } from '../../../types/records/size-record'
import ImageInput from '../../field/ImageInput'
import { UtensilRecord } from '../../../types/records/utensil-record'
import { ReactComponent as CalenderIcon } from '../../../assets/images/calender.svg'
import CustomPlaceholderSelectInput from '../../customComponent/CustomPlaceholderSelectInput'
import {
  labelGrayOutSx,
  textInputSx,
  selectInputSx,
  gridSx,
  dateInputSx,
} from '../../../assets/sx/form'
import { modalTitleSx } from '../../../assets/sx/modalSx'

type Props = {
  ingredientCategories: IngredientCategoryRecord[]
  sizes: SizeRecord[]
  utensils: UtensilRecord[]
  file: File
  ingredientParams: IngredientParams
  setIngredientParams: React.Dispatch<React.SetStateAction<IngredientParams>>
  updateSizeScalingTypeState: (utensilID: number) => void
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const AddIngredientModalBasicInfo: React.FC<Props> = ({
  ingredientCategories,
  utensils,
  file,
  ingredientParams,
  setIngredientParams,
  updateSizeScalingTypeState,
  onChangeFile,
}) => {
  const validateBeforeOfferEndAt = (startAt: Date) => {
    startAt = new Date(startAt)
    const endAt = new Date(parseISO(ingredientParams.offerEndAt as string))
    if (startAt.getTime() < endAt.getTime()) {
      return undefined
    }
    return 'エラー提供終了日より前の日付を選択してください'
  }

  const validateAfterOfferStartAt = (endAt: Date) => {
    endAt = new Date(endAt)
    const startAt = new Date(parseISO(ingredientParams.offerStartAt as string))
    if (startAt.getTime() < endAt.getTime()) {
      return undefined
    }
    return 'エラー提供開始日より後の日付を選択してください'
  }

  const [filteredUtensils, setFilteredUtensils] = useState(utensils)

  // 材料カテゴリ内の手動投入材料のレコードを取得
  const manualInputCategory: IngredientCategoryRecord | undefined =
    ingredientCategories.find(
      (target) => target.name === IngredientCategoryType.ManualInput,
    )

  // 投入器具の手動投入のレコードを取得
  const manualInputUtensil: UtensilRecord | undefined = utensils.find(
    (target) => target.name === '手動投入',
  )

  useEffect(() => {
    if (ingredientParams.ingredientCategoryID === manualInputCategory?.id) {
      setFilteredUtensils(
        utensils.filter((utensil) => utensil.id === manualInputUtensil?.id),
      )
      // カテゴリと器具が不整合の場合は初期化する
      if (ingredientParams.utensilID !== manualInputUtensil?.id) {
        setIngredientParams({
          ...ingredientParams,
          utensilID: 0,
        })
      }
    } else {
      setFilteredUtensils(
        utensils.filter((utensil) => utensil.id !== manualInputUtensil?.id),
      )
      // カテゴリと器具が不整合の場合は初期化する
      if (ingredientParams.utensilID === manualInputUtensil?.id) {
        setIngredientParams({
          ...ingredientParams,
          utensilID: 0,
        })
      }
    }
  }, [ingredientParams.ingredientCategoryID, ingredientParams.utensilID])

  return (
    <Grid>
      <Typography sx={modalTitleSx}>材料マスターを追加する 1/4</Typography>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="name" sx={labelGrayOutSx}>
          材料名 *
        </InputLabel>
        <TextInput
          source="name"
          label={false}
          placeholder="入力してください"
          sx={textInputSx}
          validate={required('エラー入力してください')}
          fullWidth
          onChange={(e) =>
            setIngredientParams({ ...ingredientParams, name: e.target.value })
          }
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="displayName" sx={labelGrayOutSx}>
          表示名 *
        </InputLabel>
        <TextInput
          source="displayName"
          label={false}
          placeholder="入力してください"
          sx={textInputSx}
          validate={required('エラー入力してください')}
          fullWidth
          onChange={(e) =>
            setIngredientParams({
              ...ingredientParams,
              displayName: e.target.value,
            })
          }
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="ingredientCategoryID" sx={labelGrayOutSx}>
          材料カテゴリ *
        </InputLabel>
        <CustomPlaceholderSelectInput
          type="formInput"
          source="ingredientCategoryID"
          variant="outlined"
          label={false}
          placeholder="選択してください"
          validate={required('エラー選択してください')}
          sx={selectInputSx}
          choices={ingredientCategories}
          fullWidth
          onChange={(e) =>
            setIngredientParams({
              ...ingredientParams,
              ingredientCategoryID: e.target.value,
            })
          }
        />
      </Grid>
      <ImageInput file={file} onChangeFile={onChangeFile} />
      <Grid sx={gridSx}>
        <InputLabel htmlFor="utensilsID" sx={labelGrayOutSx}>
          投入器具 *
        </InputLabel>
        <CustomPlaceholderSelectInput
          type="formInput"
          source="utensilID"
          variant="outlined"
          label={false}
          placeholder="選択してください"
          sx={selectInputSx}
          choices={filteredUtensils}
          validate={required('エラー選択してください')}
          fullWidth
          onChange={(e) => {
            const selectedUtensilID = e.target.value
            setIngredientParams({
              ...ingredientParams,
              utensilID: selectedUtensilID,
            })
            updateSizeScalingTypeState(selectedUtensilID)
          }}
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="offerStartAt" sx={labelGrayOutSx}>
          提供開始日 *
        </InputLabel>
        <DateInput
          label={false}
          source="offerStartAt"
          fullWidth
          sx={dateInputSx}
          validate={[
            required('エラー選択してください'),
            validateBeforeOfferEndAt,
          ]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalenderIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) =>
            setIngredientParams({
              ...ingredientParams,
              offerStartAt: e.target.value,
            })
          }
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="offerEndAt" sx={labelGrayOutSx}>
          提供終了日 *
        </InputLabel>
        <DateInput
          label={false}
          source="offerEndAt"
          fullWidth
          sx={dateInputSx}
          validate={[
            required('エラー選択してください'),
            validateAfterOfferStartAt,
          ]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalenderIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) =>
            setIngredientParams({
              ...ingredientParams,
              offerEndAt: e.target.value,
            })
          }
        />
      </Grid>
    </Grid>
  )
}

export default AddIngredientModalBasicInfo
