import React from 'react'
import { Grid, Typography, Modal, Box } from '@mui/material'
import CancelButton from '../button/CancelButton'
import DeleteButton from '../button/DeleteButton'
import { modalWrapperSx, cancelButtonWrapperSx } from '../../assets/sx/form'

type Props = {
  open: boolean
  onClose: () => void
  onLogout: () => void
}

const LogoutModal: React.FC<Props> = ({ open, onClose, onLogout }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalWrapperSx}>
        <Typography sx={{ fontSize: '16px', fontWeight: 700, mb: '10px' }}>
          ログアウトしてもよろしいですか?
        </Typography>
        <Grid container direction="row" justifyContent="flex-end">
          <Box sx={cancelButtonWrapperSx}>
            <CancelButton onClick={onClose}>キャンセル</CancelButton>
          </Box>
          <Box sx={{ width: '118px' }}>
            <DeleteButton onClick={onLogout}>ログアウト</DeleteButton>
          </Box>
        </Grid>
      </Box>
    </Modal>
  )
}

export default LogoutModal
