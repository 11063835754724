export const sidebarSx = {
  color: '#fff',
  '.MuiMenuItem-root': {
    opacity: 0.64,
    fontFamily: 'Noto Sans JP',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    padding: '0',
    lineHeight: '20px',
    minHeight: '48px',
    a: {
      height: '48px',
    },
  },
  '.MuiMenuItem-gutters': {
    color: '#fff',
    letterSpacing: '0.1em',
  },
  '.MuiListItemIcon-root': {
    padding: '0 26px',
    width: '72px',
  },
  '.RaMenuItemLink-icon': {
    color: '#fff',
    opacity: 0.64,
  },
  '.RaMenuItemLink-active': {
    opacity: 1,
    color: '#fff !important',
    background: '#000',
    borderRadius: '0px 8px 8px 0px',
    '.RaMenuItemLink-icon': {
      color: '#fff',
      opacity: 1,
    },
  },
}
