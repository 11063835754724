import React, { useState, useRef, useEffect } from 'react'
import { format, parseISO } from 'date-fns'
import { Form, SaveButton } from 'react-admin'
import { Grid, Modal, Box, Button } from '@mui/material'
import CancelInputModal from '../CancelInputModal'
import useCancelModal from '../../../hooks/useCancelModal'
import CancelButton from '../../button/CancelButton'
import RecipeModalBasicInfo from './RecipeModalBasicInfo'
import RecipeModalIngredientInput from './RecipeModalIngredientInput'
import RecipeModalConfirm from './RecipeModalConfirm'
import {
  RecipeParams,
  RecipeRecord,
  RecipeIngredientInputRecord,
  RecipeIngredientInputParam,
  SizeScalingPresetParam,
  IngredientSizeCoefficientParam,
  ToppingParam,
  InputTimingRecord,
  RecipeSetting,
  RecipeIngredientCategoryType,
} from '../../../types/records/recipe-record'
import {
  convertFileToBase64,
  convertDataURLToFile,
} from '../../../service/images'
import {
  IngredientRecord,
  IngredientCategoryType,
} from '../../../types/records/ingredient-record'
import { ModalSectionType } from './ModalSectionType'
import {
  modalWrapperSx,
  cancelButtonWrapperSx,
  nextButtonSx,
  saveButtonSx,
} from '../../../assets/sx/form'

type Props = {
  open: boolean
  recipe: RecipeRecord | undefined
  recipeSetting: RecipeSetting
  ingredients: IngredientRecord[]
  onClose: () => void
  onSubmit: (id: number, data: RecipeParams, previousData: RecipeRecord) => void
}

const EditRecipeModal: React.FC<Props> = ({
  open,
  recipe,
  recipeSetting,
  ingredients,
  onClose,
  onSubmit,
}) => {
  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onClose)

  const inputTimingBeforeBlending: InputTimingRecord | undefined =
    recipeSetting.inputTimings?.find(
      (target) => target.name === 'ブレンダー動作前',
    )
  const inputTimingBeforeSetPitcher: InputTimingRecord | undefined =
    recipeSetting.inputTimings.find(
      (target) => target.name === 'ピッチャーセット前',
    )

  const [formData, setFormData] = useState<RecipeParams>({})

  // make map of ingredients
  const ingredientMap = new Map<number, IngredientRecord>()
  ingredients.forEach((ingredient) => {
    ingredientMap.set(ingredient.id, ingredient)
  })

  const whichRecipeIngredientCategoryType = (ingredientID: number) => {
    const ingredient = ingredientMap.get(ingredientID)
    if (ingredient) {
      switch (ingredient.ingredientCategory?.name) {
        case IngredientCategoryType.SyrupBase:
          return RecipeIngredientCategoryType.SyrupBase
        case IngredientCategoryType.Roast:
          return RecipeIngredientCategoryType.RoastAndTea
        case IngredientCategoryType.Tea:
          return RecipeIngredientCategoryType.RoastAndTea
        case IngredientCategoryType.Syrup:
          return RecipeIngredientCategoryType.Syrup
        case IngredientCategoryType.Powder:
          return RecipeIngredientCategoryType.Powder
        case IngredientCategoryType.Chip:
          return RecipeIngredientCategoryType.Chip
        case IngredientCategoryType.Dairy:
          return RecipeIngredientCategoryType.DairyAndJuice
        case IngredientCategoryType.Nondairy:
          return RecipeIngredientCategoryType.DairyAndJuice
        case IngredientCategoryType.Juice:
          return RecipeIngredientCategoryType.DairyAndJuice
        case IngredientCategoryType.ManualInput:
          return RecipeIngredientCategoryType.ManualInput
      }
    }
  }

  const assignRecipeIngredientInput = (
    RecipeIngredientInput: RecipeIngredientInputRecord,
    newFormData: RecipeParams,
  ) => {
    const recipeIngredientInput: RecipeIngredientInputParam = {
      ingredientID: RecipeIngredientInput.ingredientID,
      inputTimingID: RecipeIngredientInput.inputTimingID,
      sizeScalingPresetID: RecipeIngredientInput.sizeScalingPresetID,
    }
    const recipeIngredientCategoryType = whichRecipeIngredientCategoryType(
      RecipeIngredientInput.ingredientID,
    )
    switch (recipeIngredientCategoryType) {
      case RecipeIngredientCategoryType.Syrup:
        {
          newFormData.syrupRecipeIngredientInputs?.push(recipeIngredientInput)
        }
        break
      case RecipeIngredientCategoryType.Chip:
        {
          newFormData.chipRecipeIngredientInputs?.push(recipeIngredientInput)
        }
        break
      case RecipeIngredientCategoryType.Powder:
        {
          newFormData.powderRecipeIngredientInputs?.push(recipeIngredientInput)
        }
        break
      case RecipeIngredientCategoryType.ManualInput:
        {
          newFormData.manualRecipeIngredientInputs?.push(recipeIngredientInput)
        }
        break
      case RecipeIngredientCategoryType.RoastAndTea:
        {
          newFormData.roastAndTeaRecipeIngredientInput = recipeIngredientInput
        }
        break
      case RecipeIngredientCategoryType.DairyAndJuice:
        {
          newFormData.dairyAndJuiceRecipeIngredientInput = recipeIngredientInput
        }
        break
      case RecipeIngredientCategoryType.SyrupBase:
        {
          newFormData.syrupBaseRecipeIngredientInput = recipeIngredientInput
        }
        break
    }
    return newFormData
  }

  const createNewFormData = (imageURL?: string) => {
    const toppings: ToppingParam[] = []
    recipe?.toppings?.forEach((topping) => {
      toppings.push({
        description: topping.description as string,
      })
    })
    const offerStartAt = format(
      parseISO(recipe?.offerStartAt as string),
      'yyyy-MM-dd',
    )
    const offerEndAt = format(
      parseISO(recipe?.offerEndAt as string),
      'yyyy-MM-dd',
    )
    const newFormData: RecipeParams = {
      name: recipe?.name,
      displayName: recipe?.displayName,
      recipeCategoryID: recipe?.recipeCategory.id,
      offerGenreID: recipe?.offerGenre.id,
      offerStartAt: offerStartAt,
      offerEndAt: offerEndAt,
      description: recipe?.description,
      toppings: toppings,
      syrupRecipeIngredientInputs: [],
      chipRecipeIngredientInputs: [],
      powderRecipeIngredientInputs: [],
      manualRecipeIngredientInputs: [],
    }
    recipe?.recipeIngredientInputs?.forEach((recipeIngredientInput) => {
      assignRecipeIngredientInput(recipeIngredientInput, newFormData)
    })

    let base64ImageURL: string | undefined = undefined
    if (imageURL) {
      convertDataURLToFile(imageURL).then((file) => {
        setFile(file)
        convertFileToBase64(file).then((reader) => {
          base64ImageURL = reader.result as string
          newFormData.imageURL = base64ImageURL
          return newFormData
        })
      })
    }
    return newFormData
  }

  useEffect(() => {
    if (open) {
      const newFormData = createNewFormData(recipe?.imageURL)
      setFormData({
        ...formData,
        ...newFormData,
      })
    }
  }, [open, ingredients])

  const [file, setFile] = useState<File>(new File([], ''))
  const handleOnChangeImageURL = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      setFile(file)
      convertFileToBase64(file as File).then((reader) => {
        setFormData({
          ...formData,
          imageURL: reader.result as string,
        })
      })
    }
  }

  const [
    isCheckedDairyAndJuiceManualInput,
    setIsCheckedDairyAndJuiceManualInput,
  ] = useState<boolean>(false)
  const [isCheckedRoastAndTeaManualInput, setIsCheckedRoastAndTeaManualInput] =
    useState<boolean>(false)
  const [isCheckedSyrupBaseManualInput, setIsCheckedSyrupBaseManualInput] =
    useState<boolean>(false)

  const createNewSizeScalingPreset = () => {
    const ingredientSizeCoefficients: IngredientSizeCoefficientParam[] =
      recipeSetting.sizes.map((size) => {
        return {
          sizeID: size.id,
        }
      })
    const newSizeScalingPreset: SizeScalingPresetParam = {
      name: '',
      sizeScalingTypeID: 0,
      ingredientSizeCoefficients: ingredientSizeCoefficients,
    }
    return newSizeScalingPreset
  }

  const resetIsCheckedManualInput = (recipeIngredientCategoryType: string) => {
    switch (recipeIngredientCategoryType) {
      case RecipeIngredientCategoryType.DairyAndJuice:
        setIsCheckedDairyAndJuiceManualInput(false)
        break
      case RecipeIngredientCategoryType.RoastAndTea:
        setIsCheckedRoastAndTeaManualInput(false)
        break
      case RecipeIngredientCategoryType.SyrupBase:
        setIsCheckedSyrupBaseManualInput(false)
        break
    }
  }

  const handleOnChangeSingleCheckedManualInput = (
    recipeIngredientCategoryType: string,
  ) => {
    switch (recipeIngredientCategoryType) {
      case RecipeIngredientCategoryType.DairyAndJuice:
        if (
          !isCheckedDairyAndJuiceManualInput &&
          !formData.dairyAndJuiceRecipeIngredientInput?.newSizeScalingPreset
        ) {
          const recipeIngredientInputParam: RecipeIngredientInputParam =
            formData.dairyAndJuiceRecipeIngredientInput as RecipeIngredientInputParam
          recipeIngredientInputParam.newSizeScalingPreset =
            createNewSizeScalingPreset()
          setFormData({
            ...formData,
            dairyAndJuiceRecipeIngredientInput: recipeIngredientInputParam,
          })
        }
        setIsCheckedDairyAndJuiceManualInput(
          isCheckedDairyAndJuiceManualInput ? false : true,
        )
        break
      case RecipeIngredientCategoryType.RoastAndTea:
        if (
          !isCheckedRoastAndTeaManualInput &&
          !formData.roastAndTeaRecipeIngredientInput?.newSizeScalingPreset
        ) {
          const recipeIngredientInputParam: RecipeIngredientInputParam =
            formData.roastAndTeaRecipeIngredientInput as RecipeIngredientInputParam
          recipeIngredientInputParam.newSizeScalingPreset =
            createNewSizeScalingPreset()
          setFormData({
            ...formData,
            roastAndTeaRecipeIngredientInput: recipeIngredientInputParam,
          })
        }
        setIsCheckedRoastAndTeaManualInput(
          isCheckedRoastAndTeaManualInput ? false : true,
        )
        break
      case RecipeIngredientCategoryType.SyrupBase:
        if (
          !isCheckedSyrupBaseManualInput &&
          !formData.syrupBaseRecipeIngredientInput?.newSizeScalingPreset
        ) {
          const recipeIngredientInputParam: RecipeIngredientInputParam =
            formData.syrupBaseRecipeIngredientInput as RecipeIngredientInputParam
          recipeIngredientInputParam.newSizeScalingPreset =
            createNewSizeScalingPreset()
          setFormData({
            ...formData,
            syrupBaseRecipeIngredientInput: recipeIngredientInputParam,
          })
        }
        setIsCheckedSyrupBaseManualInput(
          isCheckedSyrupBaseManualInput ? false : true,
        )
    }
  }

  const [isCheckedSyrupManualInputs, setIsCheckedSyrupManualInputs] = useState<
    boolean[]
  >([])
  const [isCheckedPowderManualInputs, setIsCheckedPowderManualInputs] =
    useState<boolean[]>([])
  const [isCheckedChipManualInputs, setIsCheckedChipManualInputs] = useState<
    boolean[]
  >([])

  const handleOnChangeCheckedManualInputs = (
    recipeIngredientCategoryType: string,
    index: number,
  ) => {
    const postFormData = formData
    switch (recipeIngredientCategoryType) {
      case RecipeIngredientCategoryType.Syrup:
        // checkが falseからtrueになる際に、newSizeScalingPresetを作成する
        if (
          !isCheckedSyrupManualInputs[index] &&
          postFormData.syrupRecipeIngredientInputs &&
          !postFormData.syrupRecipeIngredientInputs[index].newSizeScalingPreset
        ) {
          const recipeIngredientInputParam: RecipeIngredientInputParam =
            postFormData.syrupRecipeIngredientInputs[
              index
            ] as RecipeIngredientInputParam
          recipeIngredientInputParam.newSizeScalingPreset =
            createNewSizeScalingPreset()
          postFormData.syrupRecipeIngredientInputs[index] =
            recipeIngredientInputParam
          setFormData({
            ...postFormData,
          })
        }
        isCheckedSyrupManualInputs[index] = isCheckedSyrupManualInputs[index]
          ? false
          : true
        setIsCheckedSyrupManualInputs([...isCheckedSyrupManualInputs])
        break
      case RecipeIngredientCategoryType.Powder:
        if (
          !isCheckedPowderManualInputs[index] &&
          postFormData.powderRecipeIngredientInputs &&
          !postFormData.powderRecipeIngredientInputs[index].newSizeScalingPreset
        ) {
          const recipeIngredientInputParam: RecipeIngredientInputParam =
            postFormData.powderRecipeIngredientInputs[
              index
            ] as RecipeIngredientInputParam
          recipeIngredientInputParam.newSizeScalingPreset =
            createNewSizeScalingPreset()
          postFormData.powderRecipeIngredientInputs[index] =
            recipeIngredientInputParam
          setFormData({
            ...postFormData,
          })
        }
        isCheckedPowderManualInputs[index] = isCheckedPowderManualInputs[index]
          ? false
          : true
        setIsCheckedPowderManualInputs([...isCheckedPowderManualInputs])
        break
      case RecipeIngredientCategoryType.Chip:
        if (
          !isCheckedChipManualInputs[index] &&
          postFormData.chipRecipeIngredientInputs &&
          !postFormData.chipRecipeIngredientInputs[index].newSizeScalingPreset
        ) {
          const recipeIngredientInputParam: RecipeIngredientInputParam =
            postFormData.chipRecipeIngredientInputs[
              index
            ] as RecipeIngredientInputParam
          recipeIngredientInputParam.newSizeScalingPreset =
            createNewSizeScalingPreset()
          postFormData.chipRecipeIngredientInputs[index] =
            recipeIngredientInputParam
          setFormData({
            ...postFormData,
          })
        }
        isCheckedChipManualInputs[index] = isCheckedChipManualInputs[index]
          ? false
          : true
        setIsCheckedChipManualInputs([...isCheckedChipManualInputs])
        break
    }
  }

  const addNewCheckedManualInputs = (recipeIngredientCategoryType: string) => {
    switch (recipeIngredientCategoryType) {
      case RecipeIngredientCategoryType.Syrup:
        setIsCheckedSyrupManualInputs([...isCheckedSyrupManualInputs, false])
        break
      case RecipeIngredientCategoryType.Powder:
        setIsCheckedPowderManualInputs([...isCheckedPowderManualInputs, false])
        break
      case RecipeIngredientCategoryType.Chip:
        setIsCheckedChipManualInputs([...isCheckedChipManualInputs, false])
        break
    }
  }

  const removeCheckedManualInputs = (
    index: number,
    recipeIngredientCategoryType: string,
  ) => {
    switch (recipeIngredientCategoryType) {
      case RecipeIngredientCategoryType.Syrup:
        isCheckedSyrupManualInputs.splice(index, 1)
        setIsCheckedSyrupManualInputs([...isCheckedSyrupManualInputs])
        break
      case RecipeIngredientCategoryType.Powder:
        isCheckedPowderManualInputs.splice(index, 1)
        setIsCheckedPowderManualInputs([...isCheckedPowderManualInputs])
        break
      case RecipeIngredientCategoryType.Chip:
        isCheckedChipManualInputs.splice(index, 1)
        setIsCheckedChipManualInputs([...isCheckedChipManualInputs])
        break
    }
  }

  const fetchRecipeIngredientInputParam = (
    recipeIngredientInputParam: RecipeIngredientInputParam,
    isManualInput: boolean,
  ) => {
    if (isManualInput) {
      recipeIngredientInputParam.sizeScalingPresetID = undefined
    } else {
      recipeIngredientInputParam.newSizeScalingPreset = undefined
    }
    return recipeIngredientInputParam
  }

  const [previewFormData, setPreviewFormData] = useState<RecipeParams>()
  const createPreviewRecipe = () => {
    const postFormData = formData
    if (postFormData.dairyAndJuiceRecipeIngredientInput) {
      postFormData.dairyAndJuiceRecipeIngredientInput =
        fetchRecipeIngredientInputParam(
          postFormData.dairyAndJuiceRecipeIngredientInput,
          isCheckedDairyAndJuiceManualInput,
        )
    }
    if (postFormData.roastAndTeaRecipeIngredientInput) {
      postFormData.roastAndTeaRecipeIngredientInput =
        fetchRecipeIngredientInputParam(
          postFormData.roastAndTeaRecipeIngredientInput,
          isCheckedRoastAndTeaManualInput,
        )
    }
    if (postFormData.syrupBaseRecipeIngredientInput) {
      postFormData.syrupBaseRecipeIngredientInput =
        fetchRecipeIngredientInputParam(
          postFormData.syrupBaseRecipeIngredientInput,
          isCheckedSyrupBaseManualInput,
        )
    }
    postFormData.syrupRecipeIngredientInputs?.forEach(
      (recipeIngredientInputParam, index) => {
        if (postFormData.syrupRecipeIngredientInputs) {
          postFormData.syrupRecipeIngredientInputs[index] =
            fetchRecipeIngredientInputParam(
              recipeIngredientInputParam,
              isCheckedSyrupManualInputs[index],
            )
        }
      },
    )
    postFormData.powderRecipeIngredientInputs?.forEach(
      (recipeIngredientInputParam, index) => {
        if (postFormData.powderRecipeIngredientInputs) {
          postFormData.powderRecipeIngredientInputs[index] =
            fetchRecipeIngredientInputParam(
              recipeIngredientInputParam,
              isCheckedPowderManualInputs[index],
            )
        }
      },
    )
    postFormData.chipRecipeIngredientInputs?.forEach(
      (recipeIngredientInputParam, index) => {
        if (postFormData.chipRecipeIngredientInputs) {
          postFormData.chipRecipeIngredientInputs[index] =
            fetchRecipeIngredientInputParam(
              recipeIngredientInputParam,
              isCheckedChipManualInputs[index],
            )
        }
      },
    )
    setPreviewFormData(postFormData)
  }

  const resetFormData = () => {
    setFormData({
      name: undefined,
      description: undefined,
      imageURL: undefined,
      displayName: undefined,
      recipeCategoryID: undefined,
      offerGenreID: undefined,
      offerStartAt: undefined,
      offerEndAt: undefined,
      dairyAndJuiceRecipeIngredientInput: undefined,
      roastAndTeaRecipeIngredientInput: undefined,
      syrupBaseRecipeIngredientInput: undefined,
      syrupRecipeIngredientInputs: [],
      powderRecipeIngredientInputs: [],
      chipRecipeIngredientInputs: [],
      manualRecipeIngredientInputs: [],
      toppings: [],
    })
    setFile(new File([], ''))
    setIsCheckedDairyAndJuiceManualInput(false)
    setIsCheckedRoastAndTeaManualInput(false)
    setIsCheckedSyrupBaseManualInput(false)
    setIsCheckedSyrupManualInputs([])
    setIsCheckedChipManualInputs([])
    setIsCheckedPowderManualInputs([])
  }

  const onUpdateSubmit = () => {
    if (!recipe || !recipe?.id) {
      return
    }
    onSubmit(recipe?.id, previewFormData as RecipeParams, recipe)
  }

  useEffect(() => {
    if (!open) {
      moveToBasicInfoSection()
      setValidateMassages([])
      resetFormData()
    }
  }, [open])

  const modalRef = useRef<HTMLDivElement>(null)
  const [modalSection, setModalSection] = useState<ModalSectionType>(
    ModalSectionType.BasicInfo,
  )

  const moveToBasicInfoSection = () => {
    setModalSection(ModalSectionType.BasicInfo)
    modalRef.current?.scrollTo(0, 0)
  }

  const moveToIngredientInputSection = () => {
    const ok = checkRecipeBasicInfoValidation()
    if (!ok) {
      modalRef.current?.scrollTo(0, 0)
      return
    }
    setValidateMassages([])
    setModalSection(ModalSectionType.IngredientInput)
    modalRef.current?.scrollTo(0, 0)
  }

  const moveToConfirmSection = () => {
    const ok = checkEachRecipeCategoryValidation()
    if (!ok) {
      modalRef.current?.scrollTo(0, 0)
      return
    }
    setValidateMassages([])
    createPreviewRecipe()
    setModalSection(ModalSectionType.Confirm)
    modalRef.current?.scrollTo(0, 0)
  }

  const [validateMassages, setValidateMassages] = useState<string[]>([])

  const checkRecipeBasicInfoValidation = () => {
    const messages: string[] = []
    if (!formData.name) {
      messages.push('・レシピ名を入力してください')
    }
    if (!formData.displayName) {
      messages.push('・表示名を入力してください')
    }
    if (!formData.offerGenreID) {
      messages.push('・提供種別を選択してください')
    }
    if (!formData.recipeCategoryID) {
      messages.push('・レシピカテゴリーを選択してください')
    }
    if (!formData.offerStartAt) {
      messages.push('・提供開始日時を選択してください')
    }
    if (!formData.offerEndAt) {
      messages.push('・提供終了日時を選択してください')
    }
    const startAt = new Date(formData.offerStartAt as string)
    const endAt = new Date(formData.offerEndAt as string)
    if (endAt.getTime() < startAt.getTime()) {
      messages.push('・提供開始日時は提供終了日時よりも前に設定してください')
    }
    if (messages.length > 0) {
      setValidateMassages(messages)
      return false
    }
    return true
  }

  const checkRecipeIngredientInputValidation = (
    recipeIngredientInputParam: RecipeIngredientInputParam,
    isCheckedManualInput: boolean,
  ) => {
    if (!recipeIngredientInputParam.ingredientID) return false
    if (!recipeIngredientInputParam.inputTimingID) return false
    if (
      !recipeIngredientInputParam.sizeScalingPresetID &&
      !isCheckedManualInput
    ) {
      return false
    }
    if (
      recipeIngredientInputParam.newSizeScalingPreset &&
      isCheckedManualInput
    ) {
      if (!recipeIngredientInputParam.newSizeScalingPreset.name) return false
      let ok = true
      recipeIngredientInputParam.newSizeScalingPreset.ingredientSizeCoefficients.forEach(
        (ingredientSizeCoefficient) => {
          if (!ingredientSizeCoefficient.sizeID) {
            ok = false
            return
          }
          if (!ingredientSizeCoefficient.coefficient) {
            ok = false
            return
          }
        },
      )
      if (!ok) return false
    }
    return true
  }

  const checkEachRecipeCategoryValidation = () => {
    const messages: string[] = []
    if (formData.dairyAndJuiceRecipeIngredientInput) {
      !checkRecipeIngredientInputValidation(
        formData.dairyAndJuiceRecipeIngredientInput,
        isCheckedDairyAndJuiceManualInput,
      ) &&
        messages.push('・ミルク/ジュースカテゴリーの入力内容に不備があります')
    } else {
      messages.push('・ミルク/ジュースカテゴリーは必須です')
    }
    if (formData.roastAndTeaRecipeIngredientInput) {
      !checkRecipeIngredientInputValidation(
        formData.roastAndTeaRecipeIngredientInput,
        isCheckedRoastAndTeaManualInput,
      ) &&
        messages.push('・ロースト/ティーカテゴリーの入力内容に不備があります')
    }
    if (formData.syrupBaseRecipeIngredientInput) {
      !checkRecipeIngredientInputValidation(
        formData.syrupBaseRecipeIngredientInput,
        isCheckedSyrupBaseManualInput,
      ) && messages.push('・シロップベースカテゴリーの入力内容に不備があります')
    }
    formData.syrupRecipeIngredientInputs?.forEach(
      (recipeIngredientInputParam, index) => {
        const ok = checkRecipeIngredientInputValidation(
          recipeIngredientInputParam,
          isCheckedSyrupManualInputs[index],
        )
        if (!ok) {
          messages.push(`・シロップカテゴリーの入力内容に不備があります`)
          return
        }
      },
    )
    if (formData.powderRecipeIngredientInputs) {
      formData.powderRecipeIngredientInputs.forEach(
        (recipeIngredientInputParam, index) => {
          const ok = checkRecipeIngredientInputValidation(
            recipeIngredientInputParam,
            isCheckedPowderManualInputs[index],
          )
          if (!ok) {
            messages.push(`・パウダーカテゴリーの入力内容に不備があります`)
            return
          }
        },
      )
    }
    if (formData.chipRecipeIngredientInputs) {
      formData.chipRecipeIngredientInputs.forEach(
        (recipeIngredientInputParam, index) => {
          const ok = checkRecipeIngredientInputValidation(
            recipeIngredientInputParam,
            isCheckedChipManualInputs[index],
          )
          if (!ok) {
            messages.push(`・チップカテゴリーの入力内容に不備があります`)
            return
          }
        },
      )
    }
    if (formData.manualRecipeIngredientInputs) {
      formData.manualRecipeIngredientInputs.forEach(
        (recipeIngredientInputParam) => {
          const ok = checkRecipeIngredientInputValidation(
            recipeIngredientInputParam,
            false,
          )
          if (!ok) {
            messages.push(`・手動投入材料カテゴリーの入力内容に不備があります`)
            return
          }
        },
      )
    }
    if (formData.toppings) {
      formData.toppings.forEach((topping) => {
        const ok = topping.description ? true : false
        if (!ok) {
          messages.push(`・トッピングの入力内容に不備があります`)
          return
        }
      })
    }
    if (messages.length > 0) {
      setValidateMassages(messages)
      return false
    }
    return true
  }

  const switchCancelButton = () => {
    switch (modalSection) {
      case ModalSectionType.BasicInfo:
        handleOnCancelModalOpen()
        break
      case ModalSectionType.IngredientInput:
        moveToBasicInfoSection()
        break
      case ModalSectionType.Confirm:
        moveToIngredientInputSection()
    }
  }

  return recipe ? (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box sx={modalWrapperSx} ref={modalRef}>
          <Form onSubmit={onUpdateSubmit} mode="onBlur" record={formData}>
            {modalSection === ModalSectionType.BasicInfo && (
              <RecipeModalBasicInfo
                action="update"
                formData={formData}
                setFormData={setFormData}
                offerGenres={recipeSetting.offerGenres}
                recipeCategories={recipeSetting.recipeCategories}
                file={file}
                handleOnChangeImageURL={handleOnChangeImageURL}
                validateMassages={validateMassages}
              />
            )}
            {modalSection === ModalSectionType.IngredientInput && (
              <RecipeModalIngredientInput
                action="update"
                formData={formData}
                setFormData={setFormData}
                inputTimingBeforeBlending={
                  inputTimingBeforeBlending as InputTimingRecord
                }
                inputTimingBeforeSetPitcher={
                  inputTimingBeforeSetPitcher as InputTimingRecord
                }
                recipeSetting={recipeSetting}
                ingredients={ingredients}
                isCheckedDairyAndJuiceManualInput={
                  isCheckedDairyAndJuiceManualInput
                }
                isCheckedRoastAndTeaManualInput={
                  isCheckedRoastAndTeaManualInput
                }
                isCheckedSyrupBaseManualInput={isCheckedSyrupBaseManualInput}
                handleOnChangeSingleCheckedManualInput={
                  handleOnChangeSingleCheckedManualInput
                }
                isCheckedSyrupManualInputs={isCheckedSyrupManualInputs}
                isCheckedPowderManualInputs={isCheckedPowderManualInputs}
                isCheckedChipManualInputs={isCheckedChipManualInputs}
                handleOnChangeCheckedManualInputs={
                  handleOnChangeCheckedManualInputs
                }
                resetIsCheckedManualInput={resetIsCheckedManualInput}
                addNewCheckedManualInputs={addNewCheckedManualInputs}
                removeCheckedManualInputs={removeCheckedManualInputs}
                validateMassages={validateMassages}
              />
            )}
            {modalSection === ModalSectionType.Confirm && (
              <RecipeModalConfirm
                previewFormData={previewFormData as RecipeParams}
                recipeSetting={recipeSetting}
                ingredients={ingredients}
                file={file}
              />
            )}
            <Grid container direction="row" justifyContent="flex-end">
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton onClick={switchCancelButton}>
                  {modalSection === ModalSectionType.BasicInfo
                    ? 'キャンセル'
                    : '戻る'}
                </CancelButton>
              </Box>
              {modalSection === ModalSectionType.BasicInfo && (
                <Button
                  sx={nextButtonSx}
                  onClick={moveToIngredientInputSection}>
                  次へ
                </Button>
              )}
              {modalSection === ModalSectionType.IngredientInput && (
                <Button sx={nextButtonSx} onClick={moveToConfirmSection}>
                  次へ
                </Button>
              )}
              {modalSection === ModalSectionType.Confirm && (
                <SaveButton label="更新する" sx={saveButtonSx} alwaysEnable />
              )}
            </Grid>
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  ) : (
    <></>
  )
}

export default EditRecipeModal
