import React from 'react'
import { TextField } from 'react-admin'
import ListDatagrid from '../components/ListDatagrid'

const SizeList: React.FC = () => {
  return (
    <ListDatagrid title="サイズマスター" filters={[]} modals={[]}>
      <TextField source="id" label="サイズID" sortable={false} />
      <TextField source="name" label="名前" sortable={false} />
      <TextField source="displayName" label="表示名" sortable={false} />
    </ListDatagrid>
  )
}

export default SizeList
