import React from 'react'
import { useRecordContext } from 'react-admin'
import { OrderLogRecord } from '../../types/records/order-log-record'

import styles from './OrderLogStatusField.module.scss'

const OrderLogStatusField = () => {
  const record = useRecordContext<OrderLogRecord>()

  if (!record) {
    return null
  }

  const orderStatusType = record.orderStatus.orderStatusType

  return (
    <span
      className={styles.base}
      style={{
        backgroundColor: orderStatusType.displayColor.displayBackgroundColor,
        color: orderStatusType.displayColor.displayFontColor,
      }}>
      {orderStatusType.name}
    </span>
  )
}

export default OrderLogStatusField
