import React, { FC, useEffect, useState } from 'react'
import { SelectInputProps, SelectInput } from 'react-admin'
interface BaseCustomPlaceholderSelectInputProps extends SelectInputProps {
  placeholder: string
}

interface FilterProps extends BaseCustomPlaceholderSelectInputProps {
  type: 'filter'
  localStorageKey: string
}

interface FormInputProps extends BaseCustomPlaceholderSelectInputProps {
  type: 'formInput'
  localStorageKey?: never
}

// 特殊文字をエスケープする
function cssEscape(value: string) {
  if (value.charAt(0) >= '0' && value.charAt(0) <= '9') {
    return `\\3${value.charAt(0)} ${value.slice(1)}`
  }
  const invalidCssChars = /([!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~])/g
  return value.replace(invalidCssChars, '\\$1')
}

type CustomPlaceholderSelectInputProps = FilterProps | FormInputProps

const CustomPlaceholderSelectInput: FC<CustomPlaceholderSelectInputProps> = ({
  placeholder,
  localStorageKey,
  type,
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState(
    localStorageKey ? localStorage.getItem(localStorageKey) || '' : '',
  )

  useEffect(() => {
    // localStorageKeyが存在する時だけlocalStorageにアクセス
    if (localStorageKey) {
      localStorage.setItem(localStorageKey, selectedValue)
    }
  }, [selectedValue, localStorageKey])

  useEffect(() => {
    const parents = document.querySelectorAll(
      `#${cssEscape(props.source as string)}`,
    )

    parents.forEach((parent) => {
      let span = parent.querySelector('.placeholder-span') as HTMLElement

      if (type === 'filter') {
        if (!span) {
          span = document.createElement('span')
          span.className = 'placeholder-span'
          span.appendChild(document.createTextNode(placeholder))
          span.style.color = '#000000'
          span.style.position = 'absolute'

          const container = document.createElement('div')
          container.style.position = 'relative'
          container.appendChild(span)
          parent.insertBefore(container, parent.firstChild)
        }

        span.style.display = selectedValue === '' ? 'block' : 'none'
      } else if (type === 'formInput' && (parent as HTMLElement).innerText) {
        // 値が空の時にplaceholderを表示するため、デフォルトで含まれるゼロ幅スペースを削除する
        const cleanedInnerText = (parent as HTMLElement).innerText.replace(
          /\u200B/g,
          '',
        )

        if (!span && cleanedInnerText.length === 0) {
          span = document.createElement('span')
          span.className = 'placeholder-span'
          span.appendChild(document.createTextNode(placeholder))
          span.style.color = '#9f9f9f'
          span.style.position = 'absolute'
          span.style.padding = '12px'

          const container = document.createElement('div')
          container.style.display = 'flex'
          container.style.justifyContent = 'left'
          container.style.alignItems = 'center'
          container.style.position = 'absolute'
          container.style.top = '0'
          container.style.bottom = '0'
          container.style.left = '0'
          container.style.right = '0'
          container.appendChild(span)

          parent.insertBefore(container, parent.firstChild)
        }
      }
    })
  }, [props.className, placeholder, type, selectedValue])

  return (
    <SelectInput
      {...props}
      className={props.className}
      value={selectedValue}
      onChange={(event) => {
        setSelectedValue(event.target.value)
        if (props.onChange) {
          props.onChange(event)
        }
      }}
    />
  )
}

export default CustomPlaceholderSelectInput
