import React from 'react'
import { Box, Grid } from '@mui/material'
import { CipLogMachineRecord } from '../../../types/records/cip-log-record'

type Props = {
  targetCipLogMachine: CipLogMachineRecord | undefined
}

const CipLogMachineInfo: React.FC<Props> = ({ targetCipLogMachine }) => {
  return (
    <Grid container direction="row" sx={{ mr: '24px', ml: '24px' }}>
      <Box sx={{ marginRight: '100px' }}>
        <span style={{ color: '#787878', fontSize: '14px' }}>店舗</span>
        <br></br>
        {targetCipLogMachine?.storeName}
      </Box>
      <Box>
        <span style={{ color: '#787878', fontSize: '14px' }}>
          マシン シリアル
        </span>
        <br></br>
        {targetCipLogMachine?.machineSerial}
      </Box>
    </Grid>
  )
}

export default CipLogMachineInfo
