import React, { useEffect } from 'react'
import {
  IngredientCategoryRecord,
  IngredientCategoryType,
} from '../../../types/records/ingredient-record'
import { Grid, Typography, InputLabel, Checkbox } from '@mui/material'
import {
  gridSx,
  labelGrayOutSx,
  textInputSx,
  selectInputSx,
  checkBoxSx,
} from '../../../assets/sx/form'
import { required, TextInput } from 'react-admin'
import { SizeRecord } from '../../../types/records/size-record'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  UtensilRecord,
  SizeScalingTypeRecord,
} from '../../../types/records/utensil-record'
import { TuningCustomizePresetRecord } from '../../../types/records/tuning-customize-preset-record'
import { SizeScalingPresetRecord } from '../../../types/records/recipe-record'
import { IngredientParams } from '../../../types/records/ingredient-record'
import CustomPlaceholderSelectInput from '../../customComponent/CustomPlaceholderSelectInput'
import { SizeScalingInput } from './SizeScalingInput'

type Props = {
  ingredientCategories: IngredientCategoryRecord[]
  sizes: SizeRecord[]
  utensil: UtensilRecord
  sizeScalingType: SizeScalingTypeRecord
  sizeScalingPreset: SizeScalingPresetRecord
  tuningCustomizePresets: TuningCustomizePresetRecord[]
  isNewSizeScalingPresetChecked: boolean
  isNewTuningCustomizePresetChecked: boolean
  ingredientParams: IngredientParams
  setIngredientParams: React.Dispatch<React.SetStateAction<IngredientParams>>
  setIsNewSizeScalingPresetChecked: React.Dispatch<
    React.SetStateAction<boolean>
  >
  setIsNewTuningCustomizePresetChecked: React.Dispatch<
    React.SetStateAction<boolean>
  >
  findSizeScalingPreset: (sizeScalingPresetID: number) => void
}

const AddIngredientModalSizeScalingPresetInput: React.FC<Props> = ({
  sizes,
  utensil,
  sizeScalingType,
  sizeScalingPreset,
  isNewSizeScalingPresetChecked,
  ingredientParams,
  ingredientCategories,
  setIngredientParams,
  setIsNewSizeScalingPresetChecked,
  findSizeScalingPreset,
}) => {
  const isSizeScalingTypeSame: () => string | undefined = () => {
    if (utensil && sizeScalingPreset) {
      return utensil.sizeScalingType.id !== sizeScalingPreset.sizeScalingType.id
        ? 'エラー投入器具と同じ単位のプリセットを選択してください'
        : undefined
    }
  }

  // 材料カテゴリ内の手動投入材料のレコードを取得
  const manualInputCategory: IngredientCategoryRecord | undefined =
    ingredientCategories.find(
      (target) => target.name === IngredientCategoryType.ManualInput,
    )

  useEffect(() => {
    if (ingredientParams.ingredientCategoryID === manualInputCategory?.id) {
      setIsNewSizeScalingPresetChecked(false)
    }
  }, [ingredientParams.ingredientCategoryID])

  return (
    <Grid>
      <Typography
        sx={{ fontSize: '16px', fontWeight: 700, mb: '40px', ml: '24px' }}>
        材料マスターを追加する 3/4
      </Typography>

      <FormControlLabel
        checked={
          ingredientParams.ingredientCategoryID !== manualInputCategory &&
          isNewSizeScalingPresetChecked
        }
        value="top"
        control={
          <Checkbox
            disabled={
              ingredientParams.ingredientCategoryID === manualInputCategory?.id
            }
            onClick={() =>
              isNewSizeScalingPresetChecked
                ? setIsNewSizeScalingPresetChecked(false)
                : setIsNewSizeScalingPresetChecked(true)
            }
            sx={checkBoxSx}
          />
        }
        label={<span style={{ fontSize: '14px' }}>手動で入力する</span>}
        labelPlacement="end"
        sx={{ ...labelGrayOutSx, mt: '-5%' }}
      />

      {!isNewSizeScalingPresetChecked ? (
        <Grid sx={gridSx}>
          <InputLabel htmlFor="sizeScalingPresetID" sx={labelGrayOutSx}>
            材料の投入量を選択 *
          </InputLabel>
          <CustomPlaceholderSelectInput
            type="formInput"
            source="sizeScalingPresetID"
            variant="outlined"
            label={false}
            placeholder="選択してください"
            sx={selectInputSx}
            choices={sizeScalingType ? sizeScalingType.sizeScalingPresets : []}
            validate={[
              required('エラー選択してください'),
              isSizeScalingTypeSame,
            ]}
            fullWidth
            onChange={(e) => {
              const sizeScalingPresetID = Number(e.target.value)
              findSizeScalingPreset(sizeScalingPresetID)
              // ingredientParams に選択したIDを渡す
              setIngredientParams({
                ...ingredientParams,
                sizeScalingPresetID: sizeScalingPresetID,
              })
            }}
          />
        </Grid>
      ) : (
        <Grid>
          <Grid>
            <Grid sx={gridSx}>
              <InputLabel
                htmlFor="newSizeScalingPresetName"
                sx={labelGrayOutSx}>
                投入プリセット名 *
              </InputLabel>
              <TextInput
                source="newSizeScalingPreset[name]]"
                label={false}
                placeholder="入力してください"
                sx={textInputSx}
                validate={required('エラー入力してください')}
                fullWidth
                onChange={(e) =>
                  setIngredientParams({
                    ...ingredientParams,
                    newSizeScalingPreset: {
                      ...ingredientParams.newSizeScalingPreset,
                      name: e.target.value,
                      sizeScalingTypeID:
                        ingredientParams.newSizeScalingPreset
                          ?.sizeScalingTypeID || 0, // type が未定義の場合、デフォルト値を設定
                      ingredientSizeCoefficients:
                        ingredientParams.newSizeScalingPreset
                          ?.ingredientSizeCoefficients || [], // ingredientSizeCoefficients が未定義の場合、空の配列を設定
                    },
                  })
                }
              />
            </Grid>
            <Grid wrap="wrap" container>
              {sizes?.map((size: SizeRecord, index) => (
                <Grid
                  sx={{ ...gridSx, width: '50%', margin: 'auto' }}
                  item
                  key={size.name}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <SizeScalingInput
                    size={size}
                    sizeScalingType={sizeScalingType}
                    index={index}
                    ingredientParams={ingredientParams}
                    setIngredientParams={setIngredientParams}
                  />
                  <Typography
                    sx={{
                      fontSize: '14px',
                      ml: '8px',
                      color: '#757575',
                      width: '60px',
                    }}>
                    {sizeScalingType?.name}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default AddIngredientModalSizeScalingPresetInput
