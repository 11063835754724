import React, { MouseEvent, useState } from 'react'
import {
  TextField,
  useDataProvider,
  useNotify,
  useRefresh,
  useGetList,
  FunctionField,
} from 'react-admin'
import { Grid } from '@mui/material'

import AddButton from '../components/button/AddButton'
import IconButton from '../components/button/IconButton'
import AddRecipeCategoryModal from '../components/modal/AddRecipeCategoryModal'
import EditRecipeCategoryModal from '../components/modal/EditRecipeCategoryModal'
import ListDatagrid from '../components/ListDatagrid'

import {
  RecipeCategoryRecord,
  RecipeCategoryParams,
} from '../types/records/recipe-category-record'
import { BlenderPresetRecord } from '../types/records/blender-preset-record'
import { SizeRecord } from '../types/records/size-record'
import { useAuthorize, useNotifyForbidden } from '../hooks/authorization'

import { ReactComponent as PencilIcon } from '../assets/images/pencil.svg'
import style from './RecipeCategoryList.module.scss'
import { firstFieldSx } from '../assets/sx/field'

const RecipeCategoryList: React.FC = () => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const blenderPresets = useGetList('blender-presets')
  const canCreateRecipeCategory = useAuthorize('recipeCategory', 'create')
  const canUpdateRecipeCategory = useAuthorize('recipeCategory', 'update')
  const notifyForbidden = useNotifyForbidden()
  const size = useGetList('sizes')
  const [targetRecipeCategory, setTargetRecipeCategory] =
    useState<RecipeCategoryRecord>()
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false)
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)

  const openCreateModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setCreateModalOpen(true)
  }

  const openEditModal = (
    e: MouseEvent<HTMLElement>,
    targetRecipeCategory: RecipeCategoryRecord | undefined,
  ) => {
    e.preventDefault()
    if (!canUpdateRecipeCategory) {
      notifyForbidden()
      return
    }
    setEditModalOpen(true)
    setTargetRecipeCategory(targetRecipeCategory)
  }

  const closeCreateModal = () => {
    setCreateModalOpen(false)
  }

  const closeEditModal = () => {
    setEditModalOpen(false)
  }

  const onCreateSubmit = (data: RecipeCategoryParams) => {
    dataProvider
      .create('recipe-categories', { data })
      .then(() => {
        notify('レシピカテゴリを追加しました')
        setCreateModalOpen(false)
        refresh()
      })
      .catch((error) => {
        notify(error.message, { type: 'error' })
      })
  }

  const onUpdateSubmit = (
    id: number,
    data: RecipeCategoryParams,
    previousData: RecipeCategoryRecord,
  ) => {
    dataProvider
      .update('recipe-categories', {
        id,
        data,
        previousData,
      })
      .then(() => {
        notify('レシピカテゴリ更新しました')
        setEditModalOpen(false)

        refresh()
      })
      .catch((error) => {
        notify(error.message, { type: 'error' })
      })
  }

  const settingButtonItems = [
    {
      tooltipLabel: '編集',
      icon: <PencilIcon />,
      onClick: openEditModal,
    },
  ]

  return (
    <>
      <ListDatagrid
        title="レシピカテゴリマスター"
        filters={[]}
        addButton={
          <AddButton
            onClick={openCreateModal}
            disabled={!canCreateRecipeCategory}>
            レシピカテゴリを追加する
          </AddButton>
        }
        modals={[
          <AddRecipeCategoryModal
            key="addRecipeCategoryModal"
            blenderPresets={blenderPresets.data as BlenderPresetRecord[]}
            sizes={size.data as SizeRecord[]}
            open={createModalOpen}
            onClose={closeCreateModal}
            onSubmit={onCreateSubmit}></AddRecipeCategoryModal>,
          <EditRecipeCategoryModal
            key="editRecipeCategoryModal"
            recipeCategory={targetRecipeCategory}
            blenderPresets={blenderPresets.data as BlenderPresetRecord[]}
            sizes={size.data as SizeRecord[]}
            open={editModalOpen}
            onClose={closeEditModal}
            onSubmit={onUpdateSubmit}></EditRecipeCategoryModal>,
        ]}>
        <TextField
          source="displayName"
          label="レシピカテゴリ表示名"
          sortable={false}
          headerClassName={style.recipeCategoryDisplayNameField}
          sx={firstFieldSx}
        />
        <TextField
          source="name"
          label="レシピカテゴリ名"
          sortable={false}
          headerClassName={style.recipeCategoryNameField}
        />
        <TextField
          source="id"
          label="レシピカテゴリID"
          sortable={false}
          headerClassName={style.recipeCategoryIDField}
        />
        <FunctionField
          label="ブレンダーのプリセット情報"
          sortable={false}
          render={(record: RecipeCategoryRecord) => {
            return record.sizeRecipeBlenderLinks
              ?.map((sizeRecipeBlenderLink) => {
                return (
                  sizeRecipeBlenderLink.size.name +
                  ': ' +
                  sizeRecipeBlenderLink.blenderPreset.name
                )
              })
              .join(' ')
          }}
          headerClassName={style.recipeCategoryBlenderPresetField}
        />
        <FunctionField
          sortable={false}
          headerClassName={style.recipeCategoryIconButtonsField}
          render={(record: RecipeCategoryRecord) => {
            return (
              <Grid container display="flex" justifyContent="right">
                {settingButtonItems.map((item, index) => {
                  return (
                    <IconButton<RecipeCategoryRecord>
                      key={`iconButton_${index}`}
                      tooltipLabel={item.tooltipLabel}
                      onClick={item.onClick}
                      icon={item.icon}
                      record={record}
                    />
                  )
                })}
              </Grid>
            )
          }}
        />
      </ListDatagrid>
    </>
  )
}

export default RecipeCategoryList
