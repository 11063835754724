import React, { MouseEvent, useState } from 'react'
import {
  TextField,
  FunctionField,
  RadioButtonGroupInput,
  useDataProvider,
  useNotify,
  useRefresh,
  ImageField,
} from 'react-admin'
import { Grid } from '@mui/material'

import ListDatagrid from '../components/ListDatagrid'
import { useGetList } from 'react-admin'
import {
  IngredientRecord,
  IngredientParams,
} from '../types/records/ingredient-record'
import { IngredientCategoryRecord } from '../types/records/ingredient-record'
import { useAuthorize, useNotifyForbidden } from '../hooks/authorization'
import EditIngredientEjectionAdjustmentModal from '../components/modal/EditIngredientEjectionAdjustmentModal'

import AddButton from '../components/button/AddButton'
import IconButton from '../components/button/IconButton'

import { ReactComponent as RulerIcon } from '../assets/images/ruler.svg'
import { ReactComponent as PencilIcon } from '../assets/images/pencil.svg'
import { ReactComponent as TrashIcon } from '../assets/images/trash.svg'
import { ReactComponent as DetailsIcon } from '../assets/images/details.svg'
import {
  FilterSelectInputSx,
  RadioButtonGroupInputSx,
} from '../assets/sx/filterSx'

import { IngredientEjectionAdjustmentParams } from '../types/records/ingredient-ejection-adjustment-record'
import { format, parseISO } from 'date-fns'
import { SizeRecord } from '../types/records/size-record'
import {
  UtensilRecord,
  SizeScalingTypeRecord,
} from '../types/records/utensil-record'
import { TuningCustomizePresetRecord } from '../types/records/tuning-customize-preset-record'
import { SizeScalingPresetRecord } from '../types/records/recipe-record'

import AddIngredientModal from '../components/modal/ingredients/AddIngredientModal'
import EditIngredientModal from '../components/modal/ingredients/EditIngredientModal'
import DeleteIngredientModal from '../components/modal/DeleteIngredientModal'
import ShowIngredientModal from '../components/modal/ingredients/ShowIngredientModal'
import {
  AddSelectInputChoisesStyle,
  DeleteSelectInputChoicesStyle,
} from '../components/SelectInputChoisesStyle'

import style from './IngredientList.module.scss'
import { firstFieldSx } from '../assets/sx/field'
import CustomPlaceholderSelectInput from '../components/customComponent/CustomPlaceholderSelectInput'

const IngredientList: React.FC = () => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  const ingredientCategory = useGetList('ingredient-categories')
  const utensils = useGetList('utensils')
  const size = useGetList('sizes')
  const sizeScalingTypes = useGetList('size-scaling-types')
  const sizeScalingPresets = useGetList('size-scaling-presets')
  const tuningCustomizePresets = useGetList('tuning-customize-presets')

  const ingredientCategoryChoices: { id: number; name: string }[] = []

  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false)
  const [showModalOpen, setShowModalOpen] = useState<boolean>(false)
  const [targetIngredient, setTargetIngredient] = useState<IngredientRecord>()
  const [editIngredinetModalOpen, setEditIngredientModalOpen] =
    useState<boolean>(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [EjectionAdjustmentModalOpen, setEjectionAdjustmentModalOpen] =
    useState<boolean>(false)

  const canCreateIngredient = useAuthorize('ingredient', 'create')
  const canUpdateIngredient = useAuthorize('ingredient', 'update')
  const canDeleteUser = useAuthorize('ingredient', 'delete')
  const notifyForbidden = useNotifyForbidden()

  // 既存のローカルストレージの値を取得する
  const existingData = JSON.parse(
    localStorage.getItem('RaStore.ingredients.listParams') || '{}',
  )

  // 既存のデータに新しいデータを追加、または更新する
  const updatedData = {
    ...existingData,
    displayedFilters: {},
    filter: {
      ...existingData.filter,
      offerStatus: 'all',
    },
    order: 'ASC',
    page: 1,
    perPage: 10,
    sort: 'id',
  }

  //  更新したデータを再度localStorageに保存する
  localStorage.setItem(
    'RaStore.ingredients.listParams',
    JSON.stringify(updatedData),
  )

  const openCreateModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setCreateModalOpen(true)
  }

  const closeCreateModal = () => {
    setCreateModalOpen(false)
  }

  const onCreateSubmit = (data: IngredientParams) => {
    dataProvider
      .create('ingredients', { data })
      .then(() => {
        notify('材料を追加しました')
        setCreateModalOpen(false)
        refresh()
      })
      .catch((error) => {
        notify(error.message, { type: 'error' })
      })
  }

  const openEditEjectionAdjustmentModal = (
    e: MouseEvent<HTMLElement>,
    targetIngredient: IngredientRecord | undefined,
  ) => {
    e.preventDefault()

    if (!canUpdateIngredient) {
      notifyForbidden()
      return
    }

    setEjectionAdjustmentModalOpen(true)
    setTargetIngredient(targetIngredient)
  }
  const closeEditEjectionAdjustmetModal = () => {
    setEjectionAdjustmentModalOpen(false)
  }

  const openEditIngredientModal = (
    e: MouseEvent<HTMLElement>,
    targetIngredient: IngredientRecord | undefined,
  ) => {
    e.preventDefault()

    if (!canUpdateIngredient) {
      notifyForbidden()
      return
    }

    setEditIngredientModalOpen(true)
    setTargetIngredient(targetIngredient)
  }

  const openShowModal = (e: React.MouseEvent, record: IngredientRecord) => {
    setTargetIngredient(record)
    setShowModalOpen(true)
  }

  const closeShowModal = () => {
    setShowModalOpen(false)
  }

  const closeEditIngredientModal = () => {
    setEditIngredientModalOpen(false)
  }

  const onUpdateEjectionAdjustmentSubmit = (
    id: number,
    data: IngredientEjectionAdjustmentParams,
  ) => {
    dataProvider
      .create(`ingredients/${id}/ingredient-ejection-adjustments`, {
        data,
      })
      .then(() => {
        notify('材料排出量調整を更新しました')
        setEjectionAdjustmentModalOpen(false)
        refresh()
      })
      .catch(() => {
        notify('材料排出量調整の更新に失敗しました')
      })
  }

  const onUpdateIngredientSubmit = (
    id: number,
    data: IngredientParams,
    previousData: IngredientRecord,
  ) => {
    dataProvider
      .update('ingredients', { id, data, previousData })
      .then(() => {
        notify('材料を更新しました')
        setEditIngredientModalOpen(false)
        refresh()
      })
      .catch((error) => {
        notify(error.message, { type: 'error' })
      })
  }

  const onDeleteSubmit = (ingredient: IngredientRecord) => {
    dataProvider
      .delete('ingredients', { id: ingredient.id, previousData: ingredient })
      .then(() => {
        notify('材料を削除しました')
        setDeleteModalOpen(false)
        refresh()
      })
      .catch(() => {
        notify('材料の削除に失敗しました')
      })
  }

  const openDeleteModal = (
    e: MouseEvent<HTMLElement>,
    targetIngredient: IngredientRecord | undefined,
  ) => {
    e.preventDefault()
    if (!canDeleteUser) {
      notifyForbidden()
      return
    }

    setDeleteModalOpen(true)
    setTargetIngredient(targetIngredient)
  }

  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
  }
  ingredientCategory.data?.map(
    (ingredientCategory: IngredientCategoryRecord) => {
      ingredientCategoryChoices.push({
        id: ingredientCategory.id,
        name: ingredientCategory.name,
      })
    },
  )

  const filters = [
    <RadioButtonGroupInput
      key="offerStatus"
      label={false}
      source="offerStatus"
      alwaysOn
      margin="none"
      sx={RadioButtonGroupInputSx}
      choices={[
        { id: 'all', name: 'すべて' },
        { id: 'offering', name: '提供中' },
        { id: 'archived', name: 'アーカイブ' },
      ]}
    />,
    <CustomPlaceholderSelectInput
      type="filter"
      className="ingredientCategoryID"
      localStorageKey="ingredientCategoryID"
      key="ingredientCategoryID"
      label={false}
      source="ingredientCategoryID"
      alwaysOn
      margin="none"
      sx={FilterSelectInputSx}
      choices={ingredientCategoryChoices}
      emptyText="すべて"
      emptyValue=""
      value=""
      onFocus={() => AddSelectInputChoisesStyle('menu-ingredientCategoryID')}
      onBlur={DeleteSelectInputChoicesStyle}
      placeholder="すべて"
    />,
  ]

  const settingButtonItems = [
    { tooltipLabel: '詳細', icon: <DetailsIcon />, onClick: openShowModal },
    {
      tooltipLabel: '編集',
      icon: <PencilIcon />,
      onClick: openEditIngredientModal,
    },
    {
      tooltipLabel: '排出量調整',
      icon: <RulerIcon />,
      onClick: openEditEjectionAdjustmentModal,
    },
    { tooltipLabel: '削除', icon: <TrashIcon />, onClick: openDeleteModal },
  ]

  return (
    <>
      <ListDatagrid
        title="材料マスター"
        filters={filters}
        addButton={
          <AddButton onClick={openCreateModal} disabled={!canCreateIngredient}>
            材料を追加する
          </AddButton>
        }
        modals={[
          <AddIngredientModal
            key="addIngredientModal"
            ingredientCategories={
              ingredientCategory.data as IngredientCategoryRecord[]
            }
            sizes={size.data as SizeRecord[]}
            utensils={utensils.data as UtensilRecord[]}
            sizeScalingPresets={
              sizeScalingPresets.data as SizeScalingPresetRecord[]
            }
            sizeScalingTypes={sizeScalingTypes.data as SizeScalingTypeRecord[]}
            tuningCustomizePresets={
              tuningCustomizePresets.data as TuningCustomizePresetRecord[]
            }
            open={createModalOpen}
            onClose={closeCreateModal}
            onSubmit={onCreateSubmit}
          />,
          <EditIngredientModal
            key="editIngredientModal"
            ingredient={targetIngredient}
            ingredientCategories={
              ingredientCategory.data as IngredientCategoryRecord[]
            }
            sizes={size.data as SizeRecord[]}
            utensils={utensils.data as UtensilRecord[]}
            sizeScalingPresets={
              sizeScalingPresets.data as SizeScalingPresetRecord[]
            }
            sizeScalingTypes={sizeScalingTypes.data as SizeScalingTypeRecord[]}
            tuningCustomizePresets={
              tuningCustomizePresets.data as TuningCustomizePresetRecord[]
            }
            open={editIngredinetModalOpen}
            onClose={closeEditIngredientModal}
            onSubmit={onUpdateIngredientSubmit}
          />,
          <EditIngredientEjectionAdjustmentModal
            key="editIngredientEjectionAdjustmentModal"
            open={EjectionAdjustmentModalOpen}
            ingredient={targetIngredient}
            onClose={closeEditEjectionAdjustmetModal}
            onSubmit={onUpdateEjectionAdjustmentSubmit}
          />,
          <DeleteIngredientModal
            key="deleteIngredientModal"
            ingredient={targetIngredient}
            open={deleteModalOpen}
            onClose={closeDeleteModal}
            onSubmit={onDeleteSubmit}
          />,
          <ShowIngredientModal
            key="showIngredientModal"
            ingredient={targetIngredient as IngredientRecord}
            ingredientCategories={
              ingredientCategory.data as IngredientCategoryRecord[]
            }
            sizes={size.data as SizeRecord[]}
            utensils={utensils.data as UtensilRecord[]}
            sizeScalingPresets={
              sizeScalingPresets.data as SizeScalingPresetRecord[]
            }
            tuningCustomizePresets={
              tuningCustomizePresets.data as TuningCustomizePresetRecord[]
            }
            open={showModalOpen}
            onClose={closeShowModal}
          />,
        ]}>
        <TextField
          source="id"
          label="材料ID"
          sortable={false}
          headerClassName={style.ingredientIDField}
          sx={firstFieldSx}
        />
        <TextField
          source="ingredientCategory.name"
          label="材料カテゴリ"
          sortable={false}
          headerClassName={style.ingredientCategoryNameField}
        />
        <ImageField
          source="imageURL"
          title="ImageURL"
          label="材料画像"
          sortable={false}
          sx={{
            '& .RaImageField-image': {
              borderRadius: '4px',
              border: '1px solid #F1EEE9',
            },
            '& img': { maxWidth: 64, maxHeight: 64 },
          }}
          headerClassName={style.ingredientImageField}
        />
        <TextField
          source="name"
          label="材料名"
          sortable={false}
          headerClassName={style.ingredientNameFiled}
        />
        <FunctionField
          render={(record: IngredientRecord) =>
            record && format(parseISO(record.offerStartAt), 'yyyy/MM/dd HH:mm')
          }
          label="提供開始日"
          sortable={false}
          headerClassName={style.ingredientOfferDateField}
        />
        <FunctionField
          render={(record: IngredientRecord) =>
            record && format(parseISO(record.offerEndAt), 'yyyy/MM/dd HH:mm')
          }
          label="提供終了日"
          sortable={false}
          headerClassName={style.ingredientOfferDateField}
        />
        <FunctionField
          sortable={false}
          render={(record: IngredientRecord) => {
            return (
              <Grid container display="flex" justifyContent="right">
                {settingButtonItems.map((item, index) => {
                  return (
                    <IconButton<IngredientRecord>
                      key={`iconButton_${index}`}
                      tooltipLabel={item.tooltipLabel}
                      onClick={item.onClick}
                      icon={item.icon}
                      record={record}
                    />
                  )
                })}
              </Grid>
            )
          }}
          headerClassName={style.ingredientMoreButtonField}
        />
      </ListDatagrid>
    </>
  )
}

export default IngredientList
