import React, { useState, MouseEvent, useEffect } from 'react'
import {
  AssociateMachineParams,
  StoreRecord,
} from '../../../types/records/store-record'
import { Grid, Typography, Modal, Box, InputLabel, Button } from '@mui/material'
import { Form, required, SaveButton, TextInput } from 'react-admin'
import CancelButton from '../../button/CancelButton'
import { TextAddButton } from '../../button/AddButton'
import CancelInputModal from '../CancelInputModal'
import useCancelModal from '../../../hooks/useCancelModal'
import { ReactComponent as CrossIcon } from '../../../assets/images/cross.svg'
import {
  gridSx,
  labelGrayOutSx,
  modalWrapperSx,
  saveButtonSx,
  textInputSx,
  cancelButtonWrapperSx,
} from '../../../assets/sx/form'
import ValidateMessageInfo from '../../field/ValidateMessageInfo'

type Props = {
  open: boolean
  store: StoreRecord | undefined
  onClose: () => void
  onSubmit: (data: AssociateMachineParams) => void
  validateMassages: string[]
}

const AssociateMachineModal: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  validateMassages,
}) => {
  const [formData, setFormData] = useState<AssociateMachineParams>({})

  const onClickAddButton = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const newSerials = formData.serials ? formData.serials.concat('') : ['']
    setFormData({ serials: newSerials })
  }

  const onClickDeleteButton = (
    e: MouseEvent<HTMLButtonElement>,
    index: number,
  ) => {
    e.preventDefault()
    if (!formData.serials) return

    const newSerials = formData.serials.filter((_, i) => i !== index)
    setFormData({ serials: newSerials })
  }

  useEffect(() => {
    if (open) {
      const formData: AssociateMachineParams = {
        serials: [''],
      }
      setFormData(formData)
    }
    if (!open) {
      setFormData({})
    }
  }, [open])

  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onClose)

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box sx={modalWrapperSx}>
          <Typography sx={{ fontSize: '16px', fontWeight: 700, mb: '30px' }}>
            マシン紐付け
          </Typography>
          <ValidateMessageInfo
            validateMassages={validateMassages}
            sx={{ paddingBottom: '24px' }}
          />
          <Form onSubmit={onSubmit} record={formData}>
            {formData.serials?.map((_, i) => (
              <Grid key={i}>
                <Grid container justifyContent="space-between" sx={gridSx}>
                  <InputLabel
                    htmlFor={`serials[${i}]`}
                    sx={{
                      ...labelGrayOutSx,
                      mt: 'auto',
                      mb: '0px',
                      minHeight: '25px',
                    }}>
                    マシン シリアル
                  </InputLabel>
                  {i > 0 && (
                    <Button
                      variant="text"
                      onClick={(e) => {
                        onClickDeleteButton(e, i)
                      }}>
                      <CrossIcon className="cross-icon" />
                      <Grid sx={{ color: '#C80000' }}>削除</Grid>
                    </Button>
                  )}
                </Grid>
                <TextInput
                  source={`serials[${i}]`}
                  variant="outlined"
                  label={false}
                  sx={textInputSx}
                  validate={required('エラー入力してください')}
                  fullWidth
                  onChange={(e) => {
                    if (!formData.serials) return
                    formData.serials[i] = e.target.value
                  }}
                />
              </Grid>
            ))}
            <TextAddButton onClick={onClickAddButton}>
              マシンを追加する
            </TextAddButton>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              sx={{ marginTop: '40px' }}>
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton onClick={handleOnCancelModalOpen}>
                  キャンセル
                </CancelButton>
              </Box>
              <SaveButton label="追加する" sx={saveButtonSx} alwaysEnable />
            </Grid>
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  )
}

export default AssociateMachineModal
