import React, { MouseEvent, useState } from 'react'
import {
  TextField,
  WrapperField,
  FunctionField,
  TextInput,
  useDataProvider,
  useNotify,
  useRefresh,
  useGetList,
} from 'react-admin'
import { InputAdornment, Grid } from '@mui/material'

import AddButton from '../components/button/AddButton'
import IconButton from '../components/button/IconButton'
import AddStoreModal from '../components/modal/stores/AddStoreModal'
import DeleteStoreModal from '../components/modal/stores/DeleteStoreModal'
import EditStoreModal from '../components/modal/stores/EditStoreModal'
import ShowStoreModal from '../components/modal/stores/ShowStoreModal'
import ListDatagrid from '../components/ListDatagrid'

import {
  StoreRecord,
  StoreParams,
  AssociateMachineParams,
  DisassociateMachineParams,
} from '../types/records/store-record'
import { useAuthorize, useNotifyForbidden } from '../hooks/authorization'

import { ReactComponent as SearchIcon } from '../assets/images/search.svg'
import { ReactComponent as TrashIcon } from '../assets/images/trash.svg'
import { ReactComponent as PencilIcon } from '../assets/images/pencil.svg'
import { ReactComponent as DetailsIcon } from '../assets/images/details.svg'
import { ReactComponent as AssociatingIcon } from '../assets/images/associating.svg'
import { ReactComponent as DisassociatingIcon } from '../assets/images/disassociating.svg'
import AssociateMachineModal from '../components/modal/stores/AssociateMachinModal'
import DisassociateMachineModal from '../components/modal/stores/DisassociateMachineModal'

import style from './StoreList.module.scss'
import StoreTypeField from '../components/field/StoreTypeField'
import { StoreTypeRecord } from '../types/records/store-type-record'
import { TextInputSx } from '../assets/sx/filterSx'

const StoreList: React.FC = () => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const [targetStore, setTargetStore] = useState<StoreRecord>()
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [showModalOpen, setShowModalOpen] = useState<boolean>(false)
  const [associateMachineModalOpen, setAssociateMachineModal] =
    useState<boolean>(false)
  const [disAssociateMachineModalOpen, setDisassociateMachineModal] =
    useState<boolean>(false)
  const [validateMassages, setValidateMassages] = useState<string[]>([])

  const canCreateStore = useAuthorize('store', 'create')
  const canUpdateStore = useAuthorize('store', 'update')
  const canDeleteStore = useAuthorize('store', 'delete')
  const canAssociateMachines = useAuthorize('storeMachineAssociation', 'create')
  const canDisassociateMachines = useAuthorize(
    'storeMachineAssociation',
    'delete',
  )
  const notifyForbidden = useNotifyForbidden()
  const storeTypes = useGetList('store-types')

  const openCreateModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setCreateModalOpen(true)
  }

  const openDeleteModal = (
    e: MouseEvent<HTMLElement>,
    targetStore: StoreRecord | undefined,
  ) => {
    e.preventDefault()

    if (!canDeleteStore) {
      notifyForbidden()
      return
    }

    setDeleteModalOpen(true)
    setTargetStore(targetStore)
  }

  const openEditModal = (
    e: MouseEvent<HTMLElement>,
    targetStore: StoreRecord | undefined,
  ) => {
    e.preventDefault()

    if (!canUpdateStore) {
      notifyForbidden()
      return
    }

    setEditModalOpen(true)
    setTargetStore(targetStore)
  }

  const openShowModal = (
    e: MouseEvent<HTMLElement>,
    targetStore: StoreRecord | undefined,
  ) => {
    e.preventDefault()
    setShowModalOpen(true)
    setTargetStore(targetStore)
  }

  const openAssociateMachineModal = (
    e: MouseEvent<HTMLElement>,
    targetStore: StoreRecord | undefined,
  ) => {
    e.preventDefault()

    if (!canAssociateMachines) {
      notifyForbidden()
      return
    }

    setAssociateMachineModal(true)
    setTargetStore(targetStore)
  }

  const openDisassociateMachineModal = (
    e: MouseEvent<HTMLElement>,
    targetStore: StoreRecord | undefined,
  ) => {
    e.preventDefault()

    if (!canDisassociateMachines) {
      notifyForbidden()
      return
    }

    setDisassociateMachineModal(true)
    setTargetStore(targetStore)
  }

  const closeCreateModal = () => {
    setCreateModalOpen(false)
  }

  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
  }

  const closeEditModal = () => {
    setEditModalOpen(false)
  }

  const closeShowModal = () => {
    setShowModalOpen(false)
  }

  const closeAssociateMachineModal = () => {
    setAssociateMachineModal(false)
    setValidateMassages([])
  }

  const closeDisassociateMachineModal = () => {
    setDisassociateMachineModal(false)
  }

  const onCreateSubmit = (data: StoreParams) => {
    dataProvider
      .create('stores', { data })
      .then(() => {
        notify('店舗を追加しました')
        setCreateModalOpen(false)
        refresh()
      })
      .catch(() => {
        notify('店舗の追加に失敗しました')
      })
  }

  const onUpdateSubmit = (
    id: number,
    data: StoreParams,
    previousData: StoreRecord,
  ) => {
    dataProvider
      .update('stores', { id, data, previousData })
      .then(() => {
        notify('店舗を更新しました')
        setEditModalOpen(false)
        refresh()
      })
      .catch(() => {
        notify('店舗の更新に失敗しました')
      })
  }

  const onDeleteSubmit = (store: StoreRecord) => {
    dataProvider
      .delete('stores', { id: store.id, previousData: store })
      .then(() => {
        notify('店舗を削除しました')
        setDeleteModalOpen(false)
        refresh()
      })
      .catch(() => {
        notify('店舗の削除に失敗しました')
      })
  }

  const settingButtonItems = [
    { tooltipLabel: '詳細', icon: <DetailsIcon />, onClick: openShowModal },
    {
      tooltipLabel: '編集',
      icon: <PencilIcon />,
      onClick: openEditModal,
    },
    {
      tooltipLabel: 'マシン紐付け',
      icon: <AssociatingIcon />,
      onClick: openAssociateMachineModal,
    },
    {
      tooltipLabel: 'マシン紐付け解除',
      icon: <DisassociatingIcon />,
      onClick: openDisassociateMachineModal,
    },
    { tooltipLabel: '削除', icon: <TrashIcon />, onClick: openDeleteModal },
  ]

  const onAssociateMachineSubmit = (data: AssociateMachineParams) => {
    dataProvider
      .create(`stores/${targetStore?.id}/machines`, { data })
      .then(() => {
        notify('マシンと紐付けました')
        setAssociateMachineModal(false)
        setValidateMassages([])
        refresh()
      })
      .catch((error) => {
        notify('マシンとの紐付けに失敗しました')
        if (!error.body.errors) {
          setValidateMassages([])
          return
        }
        setValidateMassages(error.body.errors as string[])
      })
  }

  const onDisassociateMachineSubmit = (data: DisassociateMachineParams) => {
    dataProvider
      .deleteMany(`stores/${targetStore?.id}/machines`, {
        ids: data.serials as string[],
      })
      .then(() => {
        notify('マシンと紐付けを解除しました')
        setDisassociateMachineModal(false)
        refresh()
      })
      .catch(() => {
        notify('マシンとの紐付けの解除に失敗しました')
      })
  }

  const filters = [
    <TextInput
      key="name"
      label={false}
      source="name"
      placeholder="店舗名で検索"
      alwaysOn
      fullWidth
      resettable
      sx={TextInputSx}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />,
  ]

  return (
    <>
      <ListDatagrid
        title="店舗マスター"
        filters={filters}
        addButton={
          <AddButton onClick={openCreateModal} disabled={!canCreateStore}>
            店舗を追加する
          </AddButton>
        }
        modals={[
          <AddStoreModal
            key="addStoreModal"
            open={createModalOpen}
            storeTypes={storeTypes.data as StoreTypeRecord[]}
            onClose={closeCreateModal}
            onSubmit={onCreateSubmit}
          />,
          <DeleteStoreModal
            key="deleteStoreModal"
            store={targetStore}
            open={deleteModalOpen}
            onClose={closeDeleteModal}
            onSubmit={onDeleteSubmit}
          />,
          <EditStoreModal
            key="editStoreModal"
            open={editModalOpen}
            store={targetStore}
            storeTypes={storeTypes.data as StoreTypeRecord[]}
            onClose={closeEditModal}
            onSubmit={onUpdateSubmit}
          />,
          <ShowStoreModal
            key="showStoreModal"
            open={showModalOpen}
            store={targetStore as StoreRecord}
            onClose={closeShowModal}
          />,
          <AssociateMachineModal
            key="associateMachineModal"
            open={associateMachineModalOpen}
            onClose={closeAssociateMachineModal}
            store={targetStore}
            onSubmit={onAssociateMachineSubmit}
            validateMassages={validateMassages}
          />,
          <DisassociateMachineModal
            key="disassociateMachineModal"
            open={disAssociateMachineModalOpen}
            onClose={closeDisassociateMachineModal}
            store={targetStore}
            onSubmit={onDisassociateMachineSubmit}
          />,
        ]}>
        <WrapperField label="店舗種別" headerClassName={style.storeTypeField}>
          <StoreTypeField storeTypes={storeTypes.data as StoreTypeRecord[]} />
        </WrapperField>
        <WrapperField
          label="店舗名 / 住所"
          sortable={false}
          headerClassName={style.storeNameField}>
          <TextField
            source="name"
            sortable={false}
            sx={{
              fontWeight: 700,
            }}
          />
          <br></br>
          <FunctionField
            sortable={false}
            render={(record: StoreRecord) =>
              record && `〒${record.zipcode} ${record.address}`
            }
            sx={{
              color: '#757575 !important',
            }}
          />
        </WrapperField>
        <TextField
          source="id"
          label="店舗ID"
          sortable={false}
          headerClassName={style.storeIdField}
        />
        <TextField
          source="tel"
          label="電話番号"
          sortable={false}
          headerClassName={style.storeTelField}
        />
        <TextField
          source="storeManager"
          label="店舗責任者"
          sortable={false}
          headerClassName={style.storeManagerField}
        />
        <FunctionField
          sortable={false}
          render={(record: StoreRecord) => {
            return (
              <Grid container display="flex" justifyContent="right">
                {settingButtonItems.map((item, index) => {
                  return (
                    <IconButton<StoreRecord>
                      key={`iconButton_${index}`}
                      tooltipLabel={item.tooltipLabel}
                      onClick={item.onClick}
                      icon={item.icon}
                      record={record}
                    />
                  )
                })}
              </Grid>
            )
          }}
          headerClassName={style.userMoreButtonField}
        />
      </ListDatagrid>
    </>
  )
}

export default StoreList
