import React, { MouseEvent, useState, useEffect } from 'react'
import { format, parseISO } from 'date-fns'
import {
  useDataProvider,
  useGetList,
  useNotify,
  useRefresh,
  TextField,
  FunctionField,
  ImageField,
  RadioButtonGroupInput,
  useListController,
} from 'react-admin'
import { Grid } from '@mui/material'
import AddButton from '../components/button/AddButton'
import IconButton from '../components/button/IconButton'
import AddRecipeModal from '../components/modal/recipes/AddRecipeModal'
import EditRecipeModal from '../components/modal/recipes/EditRecipeModal'
import DeleteRecipeModal from '../components/modal/DeleteRecipeModal'
import ShowRecipeModal from '../components/modal/recipes/ShowRecipeModal'
import {
  RecipeParams,
  RecipeCategoryRecord,
  RecipeRecord,
  OfferStatus,
  RecipeSetting,
} from '../types/records/recipe-record'
import { useAuthorize, useNotifyForbidden } from '../hooks/authorization'
import ListDatagrid from '../components/ListDatagrid'
import {
  RadioButtonGroupInputSx,
  FilterSelectInputSx,
} from '../assets/sx/filterSx'
import { ReactComponent as DetailIcon } from '../assets/images/details.svg'
import { ReactComponent as PencilIcon } from '../assets/images/pencil.svg'
import { ReactComponent as TrashIcon } from '../assets/images/trash.svg'
import { IngredientRecord } from '../types/records/ingredient-record'
import {
  AddSelectInputChoisesStyle,
  DeleteSelectInputChoicesStyle,
} from '../components/SelectInputChoisesStyle'
import style from './RecipeList.module.scss'
import { firstFieldSx } from '../assets/sx/field'
import CustomPlaceholderSelectInput from '../components/customComponent/CustomPlaceholderSelectInput'

const RecipeList: React.FC = () => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const canCreateRecipe = useAuthorize('recipe', 'create')
  const canUpdateRecipe = useAuthorize('recipe', 'update')
  const canDeleteUser = useAuthorize('recipe', 'delete')
  const notifyForbidden = useNotifyForbidden()
  const records = useListController().data as RecipeRecord[]

  const [targetRecipe, setTargetRecipe] = useState<RecipeRecord>()
  const [recipeSetting, setRecipeSetting] = useState<RecipeSetting>()
  const getRecipesSetting = () => {
    dataProvider.getRecipesSetting().then((res: { data: RecipeSetting }) => {
      setRecipeSetting(res.data)
    })
  }
  const ingredients = useGetList('ingredients')
  const recipeCategoryChoices: { id: number; name: string }[] = []

  useEffect(() => {
    getRecipesSetting()
  }, [])

  if (recipeSetting) {
    recipeSetting.recipeCategories.map(
      (recipeCategory: RecipeCategoryRecord) => {
        recipeCategoryChoices.push({
          id: recipeCategory.id,
          name: recipeCategory.name,
        })
      },
    )
  }

  // 既存のローカルストレージの値を取得する
  const existingData = JSON.parse(
    localStorage.getItem('RaStore.recipes.listParams') || '{}',
  )

  // 既存のデータに新しいデータを追加、または更新する
  const updatedData = {
    ...existingData,
    displayedFilters: {},
    filter: {
      ...existingData.filter,
      offerStatus: 'all',
    },
    order: 'ASC',
    page: 1,
    perPage: 10,
    sort: 'id',
  }

  //  更新したデータを再度localStorageに保存する
  localStorage.setItem(
    'RaStore.recipes.listParams',
    JSON.stringify(updatedData),
  )

  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false)
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [showModalOpen, setShowModalOpen] = useState<boolean>(false)
  const filterOfferQueryStatusList = [
    OfferStatus.All,
    OfferStatus.Offering,
    OfferStatus.Archived,
  ]
  const openCreateRecipeModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!canCreateRecipe) {
      notifyForbidden()
      return
    }
    setCreateModalOpen(true)
  }

  const openEditModal = (
    e: MouseEvent<HTMLElement>,
    targetRecipe: RecipeRecord | undefined,
  ) => {
    e.preventDefault()
    if (!canUpdateRecipe) {
      notifyForbidden()
      return
    }
    setEditModalOpen(true)
    setTargetRecipe(targetRecipe)
  }

  const openDeleteModal = (
    e: MouseEvent<HTMLElement>,
    targetRecipe: RecipeRecord | undefined,
  ) => {
    e.preventDefault()
    if (!canDeleteUser) {
      notifyForbidden()
      return
    }

    setDeleteModalOpen(true)
    setTargetRecipe(targetRecipe)
  }

  const openShowModal = (
    e: MouseEvent<HTMLElement>,
    targetRecipe: RecipeRecord | undefined,
  ) => {
    e.preventDefault()
    setShowModalOpen(true)
    setTargetRecipe(targetRecipe)
  }

  const closeCreateModal = () => {
    setCreateModalOpen(false)
  }
  const closeEditModal = () => {
    setEditModalOpen(false)
  }
  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
  }
  const closeShowModal = () => {
    setShowModalOpen(false)
  }
  const onCreateSubmit = (data: RecipeParams) => {
    dataProvider
      .create('recipes', { data })
      .then(() => {
        notify('レシピを追加しました')
        setCreateModalOpen(false)
        getRecipesSetting()
        refresh()
      })
      .catch((error) => {
        notify(error.message, { type: 'error' })
      })
  }

  const onUpdateSubmit = (
    id: number,
    data: RecipeParams,
    previousData: RecipeRecord,
  ) => {
    dataProvider
      .update('recipes', {
        id: id,
        data: data,
        previousData: previousData,
      })
      .then(() => {
        notify('レシピを更新しました')
        setEditModalOpen(false)
        getRecipesSetting()
        refresh()
      })
      .catch((error) => {
        notify(error.message, { type: 'error' })
      })
  }

  const onDeleteSubmit = (recipe: RecipeRecord) => {
    dataProvider
      .delete('recipes', { id: recipe.id, previousData: recipe })
      .then(() => {
        notify('レシピを削除しました')
        setDeleteModalOpen(false)

        refresh()
      })
      .catch(() => {
        notify('レシピの削除に失敗しました')
      })
  }

  const setModals = () => {
    if (recipeSetting) {
      return [
        <AddRecipeModal
          key="addRecipeModal"
          recipeSetting={recipeSetting}
          ingredients={ingredients.data as IngredientRecord[]}
          open={createModalOpen}
          onClose={closeCreateModal}
          onSubmit={onCreateSubmit}
          recipes={records}
        />,
        <EditRecipeModal
          key="editRecipeModal"
          recipe={targetRecipe as RecipeRecord}
          recipeSetting={recipeSetting}
          ingredients={ingredients.data as IngredientRecord[]}
          onClose={closeEditModal}
          onSubmit={onUpdateSubmit}
          open={editModalOpen}
        />,
        <DeleteRecipeModal
          key="deleteRecipeModal"
          recipe={targetRecipe}
          open={deleteModalOpen}
          onClose={closeDeleteModal}
          onSubmit={onDeleteSubmit}
        />,
        <ShowRecipeModal
          key="showRecipeModal"
          open={showModalOpen}
          ingredients={ingredients.data as IngredientRecord[]}
          recipe={targetRecipe as RecipeRecord}
          recipeSetting={recipeSetting}
          onClose={closeShowModal}
        />,
      ]
    }
    return []
  }

  const settingButtonItems = [
    { tooltipLabel: '詳細', icon: <DetailIcon />, onClick: openShowModal },
    {
      tooltipLabel: '編集',
      icon: <PencilIcon />,
      onClick: openEditModal,
    },
    { tooltipLabel: '削除', icon: <TrashIcon />, onClick: openDeleteModal },
  ]

  return (
    <>
      <ListDatagrid
        title="レシピマスター"
        filters={[
          <RadioButtonGroupInput
            key="offerStatus"
            label={false}
            source="offerStatus"
            alwaysOn
            margin="none"
            sx={RadioButtonGroupInputSx}
            choices={filterOfferQueryStatusList}
          />,
          <CustomPlaceholderSelectInput
            type="filter"
            className="recipeCategoryID"
            key="recipeCategoryID"
            label={false}
            source="recipeCategoryID"
            alwaysOn
            margin="none"
            emptyText="すべて"
            emptyValue={''}
            defaultValue={''}
            placeholder="すべて"
            localStorageKey="recipeCategoryID"
            sx={FilterSelectInputSx}
            choices={recipeCategoryChoices}
            onFocus={() => AddSelectInputChoisesStyle('menu-recipeCategoryID')}
            onBlur={DeleteSelectInputChoicesStyle}
          />,
        ]}
        modals={setModals()}
        addButton={
          <AddButton
            onClick={openCreateRecipeModal}
            disabled={!canCreateRecipe}>
            レシピを追加する
          </AddButton>
        }>
        <TextField
          source="offerGenre.name"
          label="提供種別"
          sortable={false}
          headerClassName={style.recipeOfferGenreField}
          sx={firstFieldSx}
        />
        <TextField
          source="recipeCategory.name"
          label="レシピカテゴリ"
          sortable={false}
          headerClassName={style.recipeCategoryField}
        />
        <ImageField
          source="imageURL"
          title="RecipeImageURL"
          label="レシピ画像"
          sortable={false}
          sx={{
            '& .RaImageField-image': {
              borderRadius: '4px',
              border: '1px solid #F1EEE9',
            },
            '& img': { maxWidth: 64, maxHeight: 64 },
          }}
          headerClassName={style.recipeImageField}
        />
        <TextField
          source="id"
          label="レシピID"
          sortable={false}
          headerClassName={style.recipeIdField}
        />
        <TextField
          source="name"
          label="レシピ名"
          sortable={false}
          headerClassName={style.recipeNameField}
        />
        <FunctionField
          render={(record: RecipeRecord) =>
            record && format(parseISO(record.offerStartAt), 'yyyy/MM/dd HH:mm')
          }
          label="提供開始日"
          sortable={false}
          headerClassName={style.recipeOfferStartAtField}
        />
        <FunctionField
          render={(record: RecipeRecord) =>
            record && format(parseISO(record.offerEndAt), 'yyyy/MM/dd HH:mm')
          }
          label="提供終了日"
          sortable={false}
          headerClassName={style.recipeOfferEndAtField}
        />
        <FunctionField
          sortable={false}
          render={(record: RecipeRecord) => {
            return (
              <Grid container display="flex" justifyContent="right">
                {settingButtonItems.map((item, index) => {
                  return (
                    <IconButton<RecipeRecord>
                      key={`iconButton_${index}`}
                      tooltipLabel={item.tooltipLabel}
                      onClick={item.onClick}
                      icon={item.icon}
                      record={record}
                    />
                  )
                })}
              </Grid>
            )
          }}
          headerClassName={style.recipeIconButtonsField}
        />
      </ListDatagrid>
    </>
  )
}

export default RecipeList
