import React from 'react'
import {
  IngredientCategoryRecord,
  IngredientRecord,
} from '../../../types/records/ingredient-record'
import { Grid, Typography, InputLabel, Checkbox } from '@mui/material'
import {
  gridSx,
  labelGrayOutSx,
  textInputSx,
  selectInputSx,
} from '../../../assets/sx/form'
import { modalTitleSx } from '../../../assets/sx/modalSx'
import { required, TextInput } from 'react-admin'
import { SizeRecord } from '../../../types/records/size-record'
import {
  UtensilRecord,
  SizeScalingTypeRecord,
} from '../../../types/records/utensil-record'
import { TuningCustomizePresetRecord } from '../../../types/records/tuning-customize-preset-record'
import { SizeScalingPresetRecord } from '../../../types/records/recipe-record'
import { IngredientParams } from '../../../types/records/ingredient-record'
import { TuningCustomizePresetComponents } from './IngredientModalTuningCustomizeField'
import CustomPlaceholderSelectInput from '../../customComponent/CustomPlaceholderSelectInput'
import FormControlLabel from '@mui/material/FormControlLabel'
import { checkBoxSx } from '../../../assets/sx/form'

type Props = {
  ingredient: IngredientRecord | undefined
  ingredientCategories: IngredientCategoryRecord[]
  sizes: SizeRecord[]
  utensil: UtensilRecord
  sizeScalingType: SizeScalingTypeRecord
  sizeScalingPreset: SizeScalingPresetRecord
  tuningCustomizePresets: TuningCustomizePresetRecord[]
  isNewSizeScalingPresetChecked: boolean
  ingredientParams: IngredientParams
  isNewTuningCustomizePresetChecked: boolean
  setIngredientParams: React.Dispatch<React.SetStateAction<IngredientParams>>
  setIsNewSizeScalingPresetChecked: React.Dispatch<
    React.SetStateAction<boolean>
  >
  setIsNewTuningCustomizePresetChecked: React.Dispatch<
    React.SetStateAction<boolean>
  >
  findSizeScalingPreset: (sizeScalingPresetID: number) => void
}

const EditIngredientModalTuningCustomizePreset: React.FC<Props> = ({
  ingredient,
  tuningCustomizePresets,
  ingredientParams,
  isNewTuningCustomizePresetChecked,
  setIngredientParams,
  setIsNewTuningCustomizePresetChecked,
}) => {
  // onChangeメソッドの切り出し
  const handleOnChangePresetName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIngredientParams({
      ...ingredientParams,
      newTuningCustomizePreset: {
        ...ingredientParams.newTuningCustomizePreset,
        name: e.target.value, // 更新された name をセット
        tuningCustomizeCoefficients:
          ingredientParams.newTuningCustomizePreset
            ?.tuningCustomizeCoefficients || [], // tuningCustomizeCoefficients が未定義の場合、空の配列を設定
      },
    })
  }

  const handleOnChangeCoefficient = (
    e: React.ChangeEvent<{ value: unknown }>,
    index: number,
  ) => {
    const selectedCoefficient = Number(e.target.value)
    const updatedCoefficients = [
      ...(ingredientParams.newTuningCustomizePreset
        ?.tuningCustomizeCoefficients || []),
    ]
    updatedCoefficients[index] = {
      ...updatedCoefficients[index],
      coefficient: selectedCoefficient,
    }

    setIngredientParams({
      ...ingredientParams,
      newTuningCustomizePreset: {
        ...ingredientParams.newTuningCustomizePreset,
        name: ingredientParams.newTuningCustomizePreset?.name || '',
        tuningCustomizeCoefficients: updatedCoefficients,
      },
    })
  }

  return (
    <Grid>
      <Typography sx={modalTitleSx}>材料を編集する 4/4</Typography>
      <FormControlLabel
        value="top"
        control={
          <Checkbox
            checked={isNewTuningCustomizePresetChecked}
            onClick={() =>
              setIsNewTuningCustomizePresetChecked(
                !isNewTuningCustomizePresetChecked,
              )
            }
            sx={checkBoxSx}
          />
        }
        label={<span style={{ fontSize: '14px' }}>手動で入力する</span>}
        sx={{ ...labelGrayOutSx, mt: '-5%' }}
      />
      {!isNewTuningCustomizePresetChecked ? (
        <TuningCustomizePresetComponents
          label="量調整カスタマイズの投入量を選択 *"
          placeholder="入力してください"
          validateMsg="入力してください"
          source="tuningCustomizePresetID"
          choices={tuningCustomizePresets}
          defaultValue={ingredient?.tuningCustomizePresetID || ''}
          onChange={(e) => {
            const tuningCustomizePresetID = Number(e.target.value)
            setIngredientParams({
              ...ingredientParams,
              tuningCustomizePresetID: tuningCustomizePresetID,
            })
          }}
        />
      ) : (
        <Grid>
          <Grid>
            <Grid sx={gridSx}>
              <InputLabel
                htmlFor="newTuningCustomizePreset"
                sx={labelGrayOutSx}>
                投入プリセット名 *
              </InputLabel>
              <TextInput
                source={`newTuningCustomizePreset[name]]`}
                label={false}
                placeholder="入力してください"
                sx={textInputSx}
                validate={required('エラー入力してください')}
                fullWidth
                onChange={handleOnChangePresetName}
              />
            </Grid>
            <Grid container>
              <Grid sx={{ width: '160px' }} item>
                <InputLabel
                  htmlFor="newTuningCustomizePreset"
                  sx={labelGrayOutSx}>
                  減量 *
                </InputLabel>
                <CustomPlaceholderSelectInput
                  type="formInput"
                  source={`newTuningCustomizePreset[tuningCustomizeCoefficients[0][coefficient]]`}
                  label={false}
                  placeholder="選択"
                  sx={selectInputSx}
                  choices={[
                    { displayName: '0.5倍', name: 0.5 },
                    { displayName: '0.75倍', name: 0.75 },
                  ]}
                  optionValue="name"
                  optionText="displayName"
                  validate={required('エラー選択してください')}
                  fullWidth
                  onChange={(e) => handleOnChangeCoefficient(e, 0)}
                />
              </Grid>
              <Grid sx={{ width: '160px', marginLeft: '30px' }} item>
                <InputLabel
                  htmlFor="newTuningCustomizePreset"
                  sx={labelGrayOutSx}>
                  増量 *
                </InputLabel>
                <CustomPlaceholderSelectInput
                  type="formInput"
                  source={`newTuningCustomizePreset[tuningCustomizeCoefficients[1][coefficient]]`}
                  label={false}
                  placeholder="選択"
                  sx={selectInputSx}
                  choices={[
                    { displayName: '1.5倍', name: 1.5 },
                    { displayName: '2.0倍', name: 2.0 },
                  ]}
                  optionValue="name"
                  optionText="displayName"
                  validate={required('エラー選択してください')}
                  fullWidth
                  onChange={(e) => handleOnChangeCoefficient(e, 1)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default EditIngredientModalTuningCustomizePreset
