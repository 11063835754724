import React, { useEffect, useState } from 'react'
import { parseISO } from 'date-fns'
import {
  IngredientParams,
  IngredientCategoryRecord,
  NewTuningCustomizePresetParams,
  NewCustomizePresetParams,
} from '../../../types/records/ingredient-record'
import { Grid, Modal, Box, Button } from '@mui/material'
import CancelInputModal from '../CancelInputModal'
import useCancelModal from '../../../hooks/useCancelModal'
import { Form, SaveButton } from 'react-admin'
import CancelButton from '../../button/CancelButton'
import { SizeRecord } from '../../../types/records/size-record'
import {
  UtensilRecord,
  SizeScalingTypeRecord,
} from '../../../types/records/utensil-record'
import { SizeScalingPresetRecord } from '../../../types/records/recipe-record'
import { TuningCustomizePresetRecord } from '../../../types/records/tuning-customize-preset-record'
import { convertFileToBase64 } from '../../../service/images'
import AddIngredientModalBasicInfo from './AddIngredientModalBasicInfo'
import { ModalSectionType } from './ModalSectionType'
import {
  cancelButtonWrapperSx,
  saveButtonSx,
  nextButtonSx,
} from '../../../assets/sx/form'
import IngredientModalConfirm from './IngredientModalConfirm'
import AddIngredientModalAmountInput from './AddIngredientModalAmountInput'
import AddIngredientModalSizeScalingPresetInput from './AddIngredientModalSizeScalingInput'
import AddIngredientModalTuningCustomizePreset from './AddIngredientModalTuningCustomizeInput'

type Props = {
  ingredientCategories: IngredientCategoryRecord[]
  sizes: SizeRecord[]
  utensils: UtensilRecord[]
  sizeScalingPresets: SizeScalingPresetRecord[]
  sizeScalingTypes: SizeScalingTypeRecord[]
  tuningCustomizePresets: TuningCustomizePresetRecord[]
  open: boolean
  onClose: () => void
  onSubmit: (data: IngredientParams) => void
}

const AddIngredientModal: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  ingredientCategories,
  utensils,
  sizeScalingPresets,
  sizeScalingTypes,
  sizes,
  tuningCustomizePresets,
}) => {
  const [file, setFile] = useState<File>(new File([], ''))
  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0])
    }
  }

  const noTuningCustomizePreset: TuningCustomizePresetRecord | undefined =
    tuningCustomizePresets?.find(
      (tuningCustomizePreset) =>
        tuningCustomizePreset.name === '量調整なし (減量:0.0, 増量:0.0)',
    )

  const [previewIngredient, setPreviewIngredient] = useState<IngredientParams>()
  const [previewNewCustomizePreset] = useState<NewCustomizePresetParams>()
  const [previewNewTuningCustomizePreset] =
    useState<NewTuningCustomizePresetParams>()

  const [isNewSizeScalingPresetChecked, setIsNewSizeScalingPresetChecked] =
    useState<boolean>(false)
  const [
    isNewTuningCustomizePresetChecked,
    setIsNewTuningCustomizePresetChecked,
  ] = useState<boolean>(false)

  const [targetSizeScalingType, setTargetSizeScalingType] =
    useState<SizeScalingTypeRecord>()
  const [targetUtensil, setTargetUtensil] = useState<UtensilRecord>()
  const [targetSizeScalingPreset, setTargetSizeScalingPreset] =
    useState<SizeScalingPresetRecord>()
  const [targetIngredientCategory, setTargetIngredientCategory] =
    useState<IngredientCategoryRecord>()

  const updateSizeScalingTypeState = (utensilID: number) => {
    const targetUtensil = utensils.find((utensil) => utensil.id === utensilID)
    targetUtensil && setTargetUtensil({ ...targetUtensil })
    const sizeScalingType = sizeScalingTypes.find(
      (sizeScalingType) =>
        sizeScalingType.id === targetUtensil?.sizeScalingType.id,
    )
    sizeScalingType && setTargetSizeScalingType({ ...sizeScalingType })
  }

  const findSizeScalingPreset = (id: number) => {
    const sizeScalingPreset = targetSizeScalingType?.sizeScalingPresets.find(
      (target) => target.id == id,
    )
    sizeScalingPreset && setTargetSizeScalingPreset({ ...sizeScalingPreset })
  }

  const [ingredientParams, setIngredientParams] = useState<IngredientParams>({})

  useEffect(() => {
    if (!open) {
      setModalSection(ModalSectionType.BasicInfo)
      initialization()
    }
  }, [open])

  // 初期値として容器容量を0にする
  useEffect(() => {
    ingredientParams.containerCapacityAmount = 0
  }, [open])

  useEffect(() => {
    if (ingredientParams.ingredientCategoryID) {
      const targetIngredientCategory = ingredientCategories.find(
        (ingredientCategory) =>
          ingredientCategory.id === ingredientParams.ingredientCategoryID,
      )
      if (!targetIngredientCategory) return
      if (!targetIngredientCategory.isTunable) {
        const newIngredientParams = {
          ...ingredientParams,
          tuningCustomizePresetID: noTuningCustomizePreset?.id,
          newTuningCustomizePreset: undefined,
        }
        setIngredientParams(newIngredientParams)
      }
      setTargetIngredientCategory(targetIngredientCategory)
    }
  }, [ingredientParams.ingredientCategoryID])

  const canMoveToAmountInputSection = () => {
    if (
      ingredientParams.name &&
      ingredientParams.displayName &&
      ingredientParams.ingredientCategoryID &&
      ingredientParams.utensilID &&
      ingredientParams.offerStartAt &&
      ingredientParams.offerEndAt
    ) {
      const startAt = new Date(
        parseISO(ingredientParams.offerStartAt as string),
      )
      const endAt = new Date(parseISO(ingredientParams.offerEndAt as string))

      if (startAt.getTime() < endAt.getTime()) {
        return true
      }
    }
    return false
  }

  const canMoveToSizeScalingInputSection = () => {
    if (
      ingredientParams.containerCapacityAmount &&
      ingredientParams.containerCapacityAmount > 0
    ) {
      return true
    }
    return false
  }

  const canMoveToTuningCustomizeInputSection = () => {
    if (isNewSizeScalingPresetChecked) {
      // サイズスケーリングプリセットの手動入力がチェックされている場合、
      // 必要なパラメータが存在することを確認する
      if (
        ingredientParams.newSizeScalingPreset?.name &&
        ingredientParams.newSizeScalingPreset?.ingredientSizeCoefficients
          ?.length === 4 &&
        ingredientParams.newSizeScalingPreset?.ingredientSizeCoefficients.every(
          (isc) => isc.coefficient && isc.coefficient > 0,
        )
      ) {
        return true
      }
    } else {
      // 既存のサイズスケーリングプリセットが選択されている場合、
      // ingredientParams.sizeScalingPresetID が存在することを確認する
      if (ingredientParams.sizeScalingPresetID && ingredientParams.utensilID) {
        // utensilを取得
        const selectedUtensil = utensils.find(
          (utensil) => utensil.id === ingredientParams.utensilID,
        )
        // sizeScalingPresetを取得
        const selectedSizeScalingPreset = sizeScalingPresets.find(
          (preset) => preset.id === ingredientParams.sizeScalingPresetID,
        )

        if (!selectedUtensil || !selectedSizeScalingPreset) {
          // utensilまたはsizeScalingPresetが見つからなかった場合はエラーハンドリング
          return false
        }

        if (
          selectedUtensil.sizeScalingType.id !==
          selectedSizeScalingPreset.sizeScalingType.id
        ) {
          // selectedUtensilとselectedSizeScalingPresetのsizeScalingTypeが一致しない場合
          return false
        }

        return true
      }
    }
    return false
  }

  const initialIngredientParams = {
    name: '',
    displayName: '',
    imageURL: undefined,
    ingredientCategoryID: undefined,
    utensilID: undefined,
    offerStartAt: undefined,
    offerEndAt: undefined,
  }

  const initialization = () => {
    setIngredientParams(initialIngredientParams)
    setIsNewTuningCustomizePresetChecked(false)
    setIsNewSizeScalingPresetChecked(false)
  }

  const canMoveToConfirmSection = () => {
    if (isNewTuningCustomizePresetChecked) {
      // 量調整カスタマイズプリセットの手動入力がチェックされている場合、
      // 必要なパラメータが存在することを確認する
      if (
        ingredientParams.newTuningCustomizePreset?.name &&
        ingredientParams.newTuningCustomizePreset?.tuningCustomizeCoefficients
          ?.length === 2
      ) {
        return true
      }
    } else {
      // 既存のサイズスケーリングプリセットが選択されている場合、
      // ingredientParams.tuningCustomizePresetID が存在することを確認する
      if (ingredientParams.tuningCustomizePresetID) {
        return true
      }
    }
    return false
  }

  const onCreateSubmit = () => {
    if (!previewIngredient) {
      return null
    }

    if (file.name !== '') {
      convertFileToBase64(file as File).then((reader) => {
        ingredientParams.imageURL = reader.result as string
        onSubmit(ingredientParams)
        setFile(new File([], ''))
      })
    } else {
      onSubmit(previewIngredient)
    }

    if (!open) {
      initialization()
    }
  }

  // 次のセクションに移動する処理
  const [modalSection, setModalSection] = useState<ModalSectionType>(
    ModalSectionType.BasicInfo,
  )
  const moveToSizeScalingInputSection = () => {
    setModalSection(ModalSectionType.SizeScalingPreset)
  }
  const moveToTuningCustomizeInputSection = () => {
    if (
      !targetIngredientCategory?.isTunable &&
      modalSection === ModalSectionType.SizeScalingPreset
    ) {
      moveToConfirmSection()
      return
    }
    if (
      !targetIngredientCategory?.isTunable &&
      modalSection === ModalSectionType.Confirm
    ) {
      setModalSection(ModalSectionType.SizeScalingPreset)
      return
    }
    setModalSection(ModalSectionType.TuningCustomizePreset)
  }

  const moveToAmountInputSection = () => {
    // 手動投入材料の時はスキップする
    if (ingredientParams.ingredientCategoryID == 1) {
      setModalSection(ModalSectionType.SizeScalingPreset)
    } else {
      setModalSection(ModalSectionType.AmountInput)
    }
  }
  const moveToBasicInfoSection = () => {
    setModalSection(ModalSectionType.BasicInfo)
  }

  const moveToConfirmSection = () => {
    const updatedIngredientParams = { ...ingredientParams }

    // ingredientParamsの値は維持して、送信する値を切り替える
    if (isNewSizeScalingPresetChecked) {
      delete updatedIngredientParams.sizeScalingPresetID
    } else {
      delete updatedIngredientParams.newSizeScalingPreset
    }

    if (isNewTuningCustomizePresetChecked) {
      delete updatedIngredientParams.tuningCustomizePresetID
    } else {
      delete updatedIngredientParams.newTuningCustomizePreset
    }

    // 手動投入材料の時に容器容量を0にする
    if (ingredientParams.ingredientCategoryID == 1) {
      updatedIngredientParams.containerCapacityAmount = 0
    }

    setModalSection(ModalSectionType.Confirm)
    setPreviewIngredient(updatedIngredientParams)
  }

  const onCancel = () => {
    onClose()
    setFile(new File([], ''))
  }

  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onCancel)

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box
          sx={{
            width: 398,
            maxHeight: '80vh',
            backgroundColor: '#FFF',
            borderRadius: 2,
            p: 3,
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            left: '50%',
            overflow: 'scroll',
          }}>
          <Form onSubmit={onCreateSubmit} mode="onBlur">
            {modalSection === ModalSectionType.BasicInfo ? (
              <AddIngredientModalBasicInfo
                ingredientCategories={ingredientCategories}
                sizes={sizes}
                utensils={utensils}
                file={file}
                ingredientParams={ingredientParams}
                setIngredientParams={setIngredientParams}
                updateSizeScalingTypeState={updateSizeScalingTypeState}
                onChangeFile={onChangeFile}
              />
            ) : (
              <></>
            )}
            {modalSection === ModalSectionType.AmountInput ? (
              <AddIngredientModalAmountInput
                ingredientParams={ingredientParams}
                setIngredientParams={setIngredientParams}
              />
            ) : (
              <></>
            )}
            {modalSection === ModalSectionType.SizeScalingPreset ? (
              <AddIngredientModalSizeScalingPresetInput
                ingredientCategories={ingredientCategories}
                sizes={sizes}
                utensil={targetUtensil as UtensilRecord}
                sizeScalingType={targetSizeScalingType as SizeScalingTypeRecord}
                sizeScalingPreset={
                  targetSizeScalingPreset as SizeScalingPresetRecord
                }
                tuningCustomizePresets={tuningCustomizePresets}
                isNewSizeScalingPresetChecked={isNewSizeScalingPresetChecked}
                isNewTuningCustomizePresetChecked={
                  isNewTuningCustomizePresetChecked
                }
                setIsNewSizeScalingPresetChecked={
                  setIsNewSizeScalingPresetChecked
                }
                setIsNewTuningCustomizePresetChecked={
                  setIsNewTuningCustomizePresetChecked
                }
                findSizeScalingPreset={findSizeScalingPreset}
                ingredientParams={ingredientParams}
                setIngredientParams={setIngredientParams}
              />
            ) : (
              <></>
            )}
            {modalSection === ModalSectionType.TuningCustomizePreset ? (
              <AddIngredientModalTuningCustomizePreset
                ingredientCategories={ingredientCategories}
                sizes={sizes}
                utensil={targetUtensil as UtensilRecord}
                sizeScalingType={targetSizeScalingType as SizeScalingTypeRecord}
                sizeScalingPreset={
                  targetSizeScalingPreset as SizeScalingPresetRecord
                }
                tuningCustomizePresets={tuningCustomizePresets}
                isNewSizeScalingPresetChecked={isNewSizeScalingPresetChecked}
                isNewTuningCustomizePresetChecked={
                  isNewTuningCustomizePresetChecked
                }
                setIsNewSizeScalingPresetChecked={
                  setIsNewSizeScalingPresetChecked
                }
                setIsNewTuningCustomizePresetChecked={
                  setIsNewTuningCustomizePresetChecked
                }
                findSizeScalingPreset={findSizeScalingPreset}
                ingredientParams={ingredientParams}
                setIngredientParams={setIngredientParams}
              />
            ) : (
              <></>
            )}
            {modalSection === ModalSectionType.Confirm ? (
              <IngredientModalConfirm
                ingredient={previewIngredient as IngredientParams}
                ingredientCategories={ingredientCategories}
                sizes={sizes}
                utensils={utensils}
                sizeScalingPresets={
                  targetSizeScalingType?.sizeScalingPresets as SizeScalingPresetRecord[]
                }
                tuningCustomizePresets={tuningCustomizePresets}
                newSizeScalingPreset={
                  previewNewCustomizePreset as NewCustomizePresetParams
                }
                newTuningCustomizePreset={
                  previewNewTuningCustomizePreset as NewTuningCustomizePresetParams
                }
                file={file}
              />
            ) : (
              <></>
            )}
            <Grid container direction="row" justifyContent="flex-end">
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton
                  onClick={() => {
                    switch (modalSection) {
                      case ModalSectionType.BasicInfo:
                        handleOnCancelModalOpen()
                        break
                      case ModalSectionType.AmountInput:
                        moveToBasicInfoSection()
                        break
                      // 手動投入材料の時はスキップする
                      case ModalSectionType.SizeScalingPreset:
                        if (ingredientParams.ingredientCategoryID == 1) {
                          moveToBasicInfoSection()
                        } else {
                          moveToAmountInputSection()
                        }
                        break
                      case ModalSectionType.TuningCustomizePreset:
                        moveToSizeScalingInputSection()
                        break
                      case ModalSectionType.Confirm:
                        moveToTuningCustomizeInputSection()
                    }
                  }}>
                  {modalSection == ModalSectionType.BasicInfo
                    ? 'キャンセル'
                    : '戻る'}
                </CancelButton>
              </Box>
              {modalSection == ModalSectionType.BasicInfo && (
                <Button
                  sx={nextButtonSx}
                  onClick={() => {
                    moveToAmountInputSection()
                  }}
                  disabled={!canMoveToAmountInputSection()}>
                  次へ
                </Button>
              )}
              {modalSection == ModalSectionType.AmountInput && (
                <Button
                  sx={nextButtonSx}
                  onClick={() => {
                    moveToSizeScalingInputSection()
                  }}
                  disabled={!canMoveToSizeScalingInputSection()}>
                  次へ
                </Button>
              )}
              {modalSection == ModalSectionType.SizeScalingPreset && (
                <Button
                  sx={nextButtonSx}
                  onClick={() => {
                    moveToTuningCustomizeInputSection()
                  }}
                  disabled={!canMoveToTuningCustomizeInputSection()}>
                  次へ
                </Button>
              )}
              {modalSection == ModalSectionType.TuningCustomizePreset && (
                <Button
                  sx={nextButtonSx}
                  onClick={() => {
                    moveToConfirmSection()
                  }}
                  disabled={!canMoveToConfirmSection()}>
                  次へ
                </Button>
              )}
              {modalSection == ModalSectionType.Confirm && (
                <SaveButton label="追加する" sx={saveButtonSx} />
              )}
            </Grid>
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  )
}

export default AddIngredientModal
