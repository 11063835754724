import { useState } from 'react'

const useCancelModal = (onClose: () => void) => {
  const [cancelModalOpen, setCancelModalOpen] = useState(false)

  const handleOnCancelModalOpen = () => {
    setCancelModalOpen(true)
  }

  const handleOnCancelModalClose = () => {
    setCancelModalOpen(false)
  }

  const handleOnCancelConfirm = () => {
    onClose()
    setCancelModalOpen(false)
  }

  return {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  }
}

export default useCancelModal
