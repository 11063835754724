import React from 'react'
import { Grid, InputLabel, Typography } from '@mui/material'
import {
  SizeScalingPresetRecord,
  RecipeIngredientInputRecord,
} from '../../../types/records/recipe-record'
import { IngredientRecord } from '../../../types/records/ingredient-record'
import { SizeScalingType } from '../../../types/records/utensil-record'
import {
  confirmGridContainerSx,
  confirmInputGridSx,
} from '../../../assets/sx/form'
import { modalTitleSx, modalLeftItemLabelSx } from '../../../assets/sx/modalSx'

type Props = {
  ingredientMap: Map<number, IngredientRecord>
  sizeScalingPresetMap: Map<number, SizeScalingPresetRecord>
  ingredientCategoryName: string
  recipeIngredientInputs: RecipeIngredientInputRecord[]
}

const ShowRecipeModalIngredientListField: React.FC<Props> = ({
  ingredientMap,
  sizeScalingPresetMap,
  ingredientCategoryName,
  recipeIngredientInputs,
}) => {
  const sizeScalingPresetTsx = (sizeScalingPresetID: number) => {
    const sizeScalingPreset = sizeScalingPresetMap.get(
      sizeScalingPresetID,
    ) as SizeScalingPresetRecord
    return (
      <Grid>
        {sizeScalingPreset.sizeScalingType.name ===
        SizeScalingType.ManualInput ? (
          <></>
        ) : (
          sizeScalingPreset.ingredientSizeCoefficients.map(
            (ingredientSizeCoefficient) => (
              <Grid
                sx={confirmGridContainerSx}
                container
                key={`size_${ingredientSizeCoefficient.size.name}`}>
                <InputLabel sx={modalLeftItemLabelSx}>
                  投入量_{ingredientSizeCoefficient.size.name}
                </InputLabel>
                <Grid sx={{ mt: 'auto', mb: 'auto' }}>
                  {ingredientSizeCoefficient.coefficient}
                  {sizeScalingPreset.sizeScalingType.name}
                </Grid>
              </Grid>
            ),
          )
        )}
      </Grid>
    )
  }

  return (
    <Grid>
      <Typography sx={modalTitleSx}>{ingredientCategoryName}</Typography>
      {recipeIngredientInputs.length === 0 && (
        <Grid sx={confirmGridContainerSx} container>
          <InputLabel sx={modalLeftItemLabelSx}>材料</InputLabel>
          <Grid sx={confirmInputGridSx}> - </Grid>
        </Grid>
      )}
      {recipeIngredientInputs.length > 0 &&
        recipeIngredientInputs.map((target, index) => (
          <Grid key={`${ingredientCategoryName}_${index}`}>
            <Grid sx={confirmGridContainerSx} container>
              <InputLabel sx={modalLeftItemLabelSx}>材料</InputLabel>
              <Grid sx={confirmInputGridSx}>
                {ingredientMap.get(target.ingredientID as number)?.name}
              </Grid>
            </Grid>
            {sizeScalingPresetTsx(target.sizeScalingPresetID)}
          </Grid>
        ))}
    </Grid>
  )
}

export default ShowRecipeModalIngredientListField
