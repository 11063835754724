import React from 'react'
import {
  Form,
  SaveButton,
  TextInput,
  required,
  useDataProvider,
  useNotify,
  email,
} from 'react-admin'
import {
  Container,
  Typography,
  Grid,
  Box,
  Stack,
  InputLabel,
} from '@mui/material'

import { ServiceLogo } from '../components/ServiceLogo'

import { gridSx, labelSx, textInputSx, saveButtonSx } from '../assets/sx/form'

const PasswordResetToken: React.FC = () => {
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const onSubmit = (data: { email?: string }) => {
    dataProvider
      .resetPasswordToken(data?.email)
      .then(() => {
        notify(
          'パスワード設定のためのメールを送信しました\nメールを確認してください',
          {
            type: 'success',
            undoable: false,
            multiLine: true,
          },
        )
      })
      .catch(() => {
        notify('問題が発生しました', { type: 'error' })
      })
  }

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{ backgroundColor: 'primary.main' }}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}>
        <Stack sx={{ maxWidth: '398px' }}>
          <Box sx={{ mb: 5 }}>
            <ServiceLogo />
          </Box>
          <Box
            sx={{
              backgroundColor: '#FFF',
              p: 3,
              borderRadius: 1,
              mb: 3,
            }}>
            <Box sx={{ mb: 2 }}>
              <Typography sx={{ fontWeight: 700 }}>
                パスワードの再設定
              </Typography>
            </Box>
            <Box sx={{ mb: 5 }}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: 'text.secondary',
                }}>
                ご登録時のメールアドレスを入力して「パスワードを再設定する」ボタンをクリックしてください。パスワード再発行メールを送信します。メールの内容をご確認の上、新しいパスワードを登録してください。
              </Typography>
            </Box>
            <Form onSubmit={onSubmit}>
              <Grid sx={gridSx}>
                <InputLabel htmlFor="name" sx={labelSx}>
                  メールアドレス
                </InputLabel>
                <TextInput
                  source="email"
                  variant="outlined"
                  label={false}
                  sx={textInputSx}
                  validate={[
                    required('エラー入力してください'),
                    email('エラーメールアドレスを入力してください'),
                  ]}
                  fullWidth
                />
              </Grid>
              <Grid container direction="row" justifyContent="flex-end">
                <SaveButton
                  label="パスワードを再設定する"
                  sx={{ ...saveButtonSx, width: '202px' }}
                />
              </Grid>
            </Form>
          </Box>
        </Stack>
      </Grid>
    </Container>
  )
}

export default PasswordResetToken
