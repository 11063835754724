import React from 'react'

import { numberInputSx, selectInputSx } from '../../../assets/sx/form'
import { required, NumberInput } from 'react-admin'
import { SizeRecord } from '../../../types/records/size-record'
import {
  SizeScalingTypeRecord,
  SizeScalingType,
} from '../../../types/records/utensil-record'
import { IngredientParams } from '../../../types/records/ingredient-record'
import CustomPlaceholderSelectInput from '../../customComponent/CustomPlaceholderSelectInput'

type Props = {
  size: SizeRecord
  index: number
  sizeScalingType: SizeScalingTypeRecord
  ingredientParams: IngredientParams
  setIngredientParams: React.Dispatch<React.SetStateAction<IngredientParams>>
}

export const SizeScalingInput: React.FC<Props> = (props) => {
  const {
    size,
    index,
    ingredientParams,
    setIngredientParams,
    sizeScalingType,
    ...otherProps
  } = props

  const handleChangeCoefficient = (value: string | number) => {
    const updatedCoefficients = [
      ...(ingredientParams.newSizeScalingPreset?.ingredientSizeCoefficients ||
        []),
    ]
    updatedCoefficients[index] = {
      sizeID: size.id,
      coefficient: Number(value),
    }

    setIngredientParams({
      ...ingredientParams,
      newSizeScalingPreset: {
        ...ingredientParams.newSizeScalingPreset,
        name: ingredientParams.newSizeScalingPreset?.name || '',
        ingredientSizeCoefficients: updatedCoefficients,
        sizeScalingTypeID:
          ingredientParams.newSizeScalingPreset?.sizeScalingTypeID || 0,
      },
    })
  }

  if (
    [SizeScalingType.Pump, SizeScalingType.Scoop].includes(sizeScalingType.name)
  ) {
    const choices = Array.from({ length: 10 }, (_, i) => ({
      id: String(i + 1),
      name: String(i + 1),
    }))

    return (
      <CustomPlaceholderSelectInput
        type="formInput"
        placeholder="選択"
        source={`newSizeScalingPreset[ingredientSizeCoefficients[${index}][coefficient]]`}
        style={{ width: '100px' }}
        label={false}
        choices={choices}
        validate={[required('選択してください')]}
        sx={{
          ...selectInputSx,
          flexGrow: 1,
          minWidth: '90px',
          maxWidth: '90px',
          ...otherProps,
        }}
        fullWidth
        onChange={(e) => handleChangeCoefficient(e.target.value)}
      />
    )
  }

  if ([SizeScalingType.ML, SizeScalingType.G].includes(sizeScalingType.name)) {
    return (
      <NumberInput
        source={`newSizeScalingPreset[ingredientSizeCoefficients[${index}][coefficient]]`}
        label={false}
        step={1.0}
        min={0}
        placeholder="入力してください"
        validate={[required('入力してください')]}
        sx={{ ...numberInputSx, flexGrow: 1, width: '100px', ...otherProps }}
        onChange={(e) => handleChangeCoefficient(e.target.value)}
      />
    )
  }

  return null
}
