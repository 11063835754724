import React from 'react'
import { TextInput, required, DateInput } from 'react-admin'
import {
  RecipeCategoryRecord,
  RecipeParams,
} from '../../../types/records/recipe-record'
import { Grid, InputLabel, Typography, InputAdornment } from '@mui/material'
import { OfferGenreRecord } from '../../../types/records/offer-genre-record'
import ImageInput from '../../field/ImageInput'
import {
  gridSx,
  labelGrayOutSx,
  textInputSx,
  selectInputSx,
  dateInputSx,
} from '../../../assets/sx/form'
import { modalTitleSx } from '../../../assets/sx/modalSx'
import { ReactComponent as CalenderIcon } from '../../../assets/images/calender.svg'
import ValidateMessageInfo from '../../field/ValidateMessageInfo'
import CustomPlaceholderSelectInput from '../../customComponent/CustomPlaceholderSelectInput'

type Props = {
  action: 'create' | 'update'
  formData: RecipeParams
  setFormData: React.Dispatch<React.SetStateAction<RecipeParams>>
  offerGenres: OfferGenreRecord[]
  recipeCategories: RecipeCategoryRecord[]
  file: File
  handleOnChangeImageURL: (e: React.ChangeEvent<HTMLInputElement>) => void
  validateMassages: string[]
}

const RecipeModalBasicInfo: React.FC<Props> = ({
  action,
  formData,
  setFormData,
  offerGenres,
  recipeCategories,
  file,
  handleOnChangeImageURL,
  validateMassages,
}) => {
  const modalTitle: string =
    action === 'create'
      ? 'レシピマスターを追加する'
      : 'レシピマスターを更新する'
  const handleOnChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      name: e.target.value,
    })
  }
  const handleOnChangeDisplayName = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFormData({
      ...formData,
      displayName: e.target.value,
    })
  }
  const handleOnChangeDescription = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFormData({
      ...formData,
      description: e.target.value,
    })
  }
  const handleOnChangeRecipeCategoryID = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFormData({
      ...formData,
      recipeCategoryID: Number(e.target.value),
    })
  }
  const handleOnChangeOfferGenreID = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFormData({
      ...formData,
      offerGenreID: Number(e.target.value),
    })
  }
  const handleOnChangeOfferStartAt = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFormData({
      ...formData,
      offerStartAt: e.target.value,
    })
  }
  const handleOnChangeOfferEndAt = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      offerEndAt: e.target.value,
    })
  }

  return (
    <Grid>
      <Typography sx={modalTitleSx}>{modalTitle} 1/2</Typography>
      <ValidateMessageInfo
        validateMassages={validateMassages}
        sx={{ mb: '20px' }}
      />
      <Grid sx={gridSx}>
        <InputLabel htmlFor="name" sx={labelGrayOutSx}>
          レシピ名 *
        </InputLabel>
        <TextInput
          source="name"
          variant="outlined"
          label={false}
          sx={textInputSx}
          placeholder="入力してください"
          onChange={(e) => handleOnChangeName(e)}
          fullWidth
          defaultValue={formData.name}
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="displayName" sx={labelGrayOutSx}>
          表示名 *
        </InputLabel>
        <TextInput
          source="displayName"
          variant="outlined"
          label={false}
          sx={textInputSx}
          placeholder="入力してください"
          onChange={(e) => handleOnChangeDisplayName(e)}
          defaultValue={formData.displayName}
          fullWidth
        />
      </Grid>
      <Grid sx={gridSx} item>
        <InputLabel htmlFor="recipeCategoryID" sx={labelGrayOutSx}>
          レシピカテゴリーを選択 *
        </InputLabel>
        <CustomPlaceholderSelectInput
          type="formInput"
          placeholder="選択してください"
          source="recipeCategoryID"
          variant="outlined"
          label={false}
          sx={selectInputSx}
          choices={recipeCategories}
          validate={required('エラー選択してください')}
          onChange={(e) => handleOnChangeRecipeCategoryID(e)}
          defaultValue={formData.recipeCategoryID}
          fullWidth
        />
      </Grid>
      <Grid sx={gridSx} item>
        <InputLabel htmlFor="offerGenreID" sx={labelGrayOutSx}>
          提供種別を選択 *
        </InputLabel>
        <CustomPlaceholderSelectInput
          type="formInput"
          placeholder="選択してください"
          source="offerGenreID"
          variant="outlined"
          label={false}
          sx={selectInputSx}
          choices={offerGenres}
          onChange={(e) => handleOnChangeOfferGenreID(e)}
          defaultValue={formData.offerGenreID}
          fullWidth
        />
      </Grid>
      <ImageInput file={file} onChangeFile={handleOnChangeImageURL} />
      <Grid sx={gridSx}>
        <InputLabel htmlFor="description" sx={labelGrayOutSx}>
          説明
        </InputLabel>
        <TextInput
          source="description"
          variant="outlined"
          label={false}
          sx={textInputSx}
          placeholder="入力してください"
          onChange={(e) => handleOnChangeDescription(e)}
          defaultValue={formData.description}
          fullWidth
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="offerStartAt" sx={labelGrayOutSx}>
          提供開始日 *
        </InputLabel>
        <DateInput
          label={false}
          source="offerStartAt"
          fullWidth
          sx={dateInputSx}
          onChange={(e) => {
            handleOnChangeOfferStartAt(e)
          }}
          defaultValue={formData.offerStartAt}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalenderIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="offerStartAt" sx={labelGrayOutSx}>
          提供終了日 *
        </InputLabel>
        <DateInput
          label={false}
          source="offerEndAt"
          fullWidth
          sx={dateInputSx}
          onChange={(e) => {
            handleOnChangeOfferEndAt(e)
          }}
          defaultValue={formData.offerEndAt}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalenderIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  )
}

export default RecipeModalBasicInfo
