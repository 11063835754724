import React from 'react'
import { Grid, InputLabel, Typography, Divider } from '@mui/material'
import { IngredientPlacementParams } from '../../../types/records/ingredient-placement-record'
import { HopperRecord } from '../../../types/records/hopper_record'
import { IngredientRecord } from '../../../types/records/ingredient-record'
import { StoreTypeRecord } from '../../../types/records/store-record'
import { formatDate } from '../../../assets/utils/formData'
import {
  confirmGridContainerSx,
  confirmInputGridSx,
} from '../../../assets/sx/form'
import {
  modalTitleSx,
  modalLeftItemLabelSx,
  modalRightItemInputSx,
  modalDividerSx,
} from '../../../assets/sx/modalSx'

type Props = {
  ingredientPlacements: IngredientPlacementParams
  hoppers: HopperRecord[]
  ingredients: IngredientRecord[]
  storeTypes: StoreTypeRecord[]
}

const IngredientPlacementModalConfirm: React.FC<Props> = ({
  ingredientPlacements,
  hoppers,
  ingredients,
  storeTypes,
}) => {
  return (
    <Grid>
      <Typography sx={modalTitleSx}>配置パターンを確認する</Typography>
      <Grid sx={confirmGridContainerSx} container>
        <InputLabel sx={modalLeftItemLabelSx}>配置パターン名 *</InputLabel>
        <Grid sx={confirmInputGridSx}>{ingredientPlacements.name}</Grid>
      </Grid>
      <Divider sx={modalDividerSx} />
      {/* ホッパーと材料の情報 */}
      {ingredientPlacements?.newPresetHopperIngredientLinks && (
        <Grid sx={confirmGridContainerSx} container>
          {ingredientPlacements.newPresetHopperIngredientLinks
            .filter((ip) => Boolean(ip.ingredientID))
            .map((ip, index) => (
              <React.Fragment key={index}>
                <InputLabel sx={modalLeftItemLabelSx}>
                  {hoppers?.find((hopper) => hopper.id === ip.hopperID)?.name ||
                    '-'}
                </InputLabel>
                <Grid
                  id={`ingredient${index}`}
                  sx={{ mb: '24px', width: '50%', size: '16px' }}>
                  {ingredients.find(
                    (Ingredient) => Ingredient.id === ip.ingredientID,
                  )?.name || '-'}
                </Grid>
              </React.Fragment>
            ))}
        </Grid>
      )}
      <Divider sx={{ mt: '-24px', mb: '20px' }} />
      <Grid sx={confirmGridContainerSx} container>
        <InputLabel sx={modalLeftItemLabelSx}>運用開始日時</InputLabel>
        <Grid sx={modalRightItemInputSx}>
          {formatDate(ingredientPlacements?.operationStartAt)}
        </Grid>
      </Grid>
      <Grid sx={confirmGridContainerSx} container>
        <InputLabel sx={modalLeftItemLabelSx}>運用終了日時</InputLabel>
        <Grid sx={modalRightItemInputSx}>
          {formatDate(ingredientPlacements?.operationEndAt)}
        </Grid>
      </Grid>
      <Grid sx={confirmGridContainerSx} container>
        <InputLabel sx={modalLeftItemLabelSx}>説明</InputLabel>
        <Grid sx={confirmInputGridSx}>{ingredientPlacements.description}</Grid>
      </Grid>
      <Divider sx={modalDividerSx} />
      <Grid sx={confirmGridContainerSx} container>
        <InputLabel sx={modalLeftItemLabelSx}>対象店舗</InputLabel>
        <Grid sx={confirmInputGridSx}>
          {storeTypes.find(
            (storeType) => storeType.id === ingredientPlacements.storeTypeID,
          )?.name || undefined}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default IngredientPlacementModalConfirm
