import React from 'react'
import { StoreRecord, StoreParams } from '../../../types/records/store-record'
import { StoreTypeRecord } from '../../../types/records/store-type-record'
import { Grid, Typography, Modal, Box, InputLabel } from '@mui/material'
import CancelInputModal from '../CancelInputModal'
import useCancelModal from '../../../hooks/useCancelModal'
import {
  Form,
  regex,
  required,
  SaveButton,
  TextInput,
  SelectInput,
} from 'react-admin'
import CancelButton from '../../button/CancelButton'
import {
  gridSx,
  labelGrayOutSx,
  textInputSx,
  selectInputSx,
  modalWrapperSx,
  cancelButtonWrapperSx,
  saveButtonSx,
} from '../../../assets/sx/form'
import { modalTitleSx } from '../../../assets/sx/modalSx'

type Props = {
  open: boolean
  store: StoreRecord | undefined
  storeTypes: StoreTypeRecord[]
  onClose: () => void
  onSubmit: (id: number, data: StoreParams, previousData: StoreRecord) => void
}

const EditStoreModal: React.FC<Props> = ({
  open,
  store,
  storeTypes,
  onClose,
  onSubmit,
}) => {
  const validateZipcode = [
    required('エラー入力してください'),
    regex(/^\d{3}-\d{4}$/, 'エラーハイフンあり数字7桁で入力してください'),
  ]
  const validateTel = [
    required('エラー入力してください'),
    regex(
      /^0(\d-\d{4}|\d{2}-\d{3}|\d{3}-\d{2}|\d{4}-\d)-\d{4}$|^0[789]0-\d{4}-\d{4}$/,
      'エラーハイフンあり数字10~11桁で入力してください',
    ),
  ]

  const onUpdateSubmit = (data: StoreParams) => {
    if (!store || !store.id) {
      return null
    }
    onSubmit(store.id, data, store)
  }

  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onClose)

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box sx={modalWrapperSx}>
          <Typography sx={modalTitleSx}>店舗を更新する</Typography>
          <Form onSubmit={onUpdateSubmit} record={store} defaultValues={store}>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="name" sx={labelGrayOutSx}>
                店舗名 *
              </InputLabel>
              <TextInput
                source="name"
                variant="outlined"
                label={false}
                placeholder="入力してください"
                sx={textInputSx}
                validate={required('エラー入力してください')}
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="zipcode" sx={labelGrayOutSx}>
                郵便番号 *
              </InputLabel>
              <TextInput
                source="zipcode"
                variant="outlined"
                label={false}
                placeholder="入力してください"
                sx={textInputSx}
                validate={validateZipcode}
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="address" sx={labelGrayOutSx}>
                住所 *
              </InputLabel>
              <TextInput
                source="address"
                variant="outlined"
                label={false}
                placeholder="入力してください"
                sx={textInputSx}
                validate={required('エラー入力してください')}
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="tel" sx={labelGrayOutSx}>
                電話番号 *
              </InputLabel>
              <TextInput
                source="tel"
                variant="outlined"
                label={false}
                placeholder="入力してください"
                sx={textInputSx}
                validate={validateTel}
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx} item>
              <InputLabel htmlFor="storeTypeID" sx={labelGrayOutSx}>
                店舗タイプを選択 *
              </InputLabel>
              <SelectInput
                source="storeTypeID"
                variant="outlined"
                label={false}
                sx={selectInputSx}
                choices={storeTypes}
                defaultValue={store && store.storeType.id}
                validate={required('エラー選択してください')}
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="tel" sx={labelGrayOutSx}>
                店舗責任者
              </InputLabel>
              <TextInput
                source="storeManager"
                variant="outlined"
                label={false}
                placeholder="入力してください"
                sx={textInputSx}
                fullWidth
              />
            </Grid>
            <Grid container direction="row" justifyContent="flex-end">
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton onClick={handleOnCancelModalOpen}>
                  キャンセル
                </CancelButton>
              </Box>
              <SaveButton label="更新する" sx={saveButtonSx} />
            </Grid>
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  )
}

export default EditStoreModal
