import React, { MouseEvent, useState } from 'react'
import {
  TextField,
  WrapperField,
  FunctionField,
  TextInput,
  RadioButtonGroupInput,
  DateInput,
  useNotify,
  useDataProvider,
  useRefresh,
} from 'react-admin'
import { InputAdornment, Grid } from '@mui/material'
import { ReactComponent as SearchIcon } from '../assets/images/search.svg'
import { ReactComponent as CalenderIcon } from '../assets/images/calender.svg'
import { ReactComponent as PencilIcon } from '../assets/images/pencil.svg'
import { ReactComponent as DetailsIcon } from '../assets/images/details.svg'
import IconButton from '../components/button/IconButton'
import ListDatagrid from '../components/ListDatagrid'

import {
  ApproveCipLogParam,
  CipLogMachineRecord,
  CipLogRecord,
  UpdateCipLogParam,
} from '../types/records/cip-log-record'
import CipLogMachineStatusField from '../components/field/CipLogMachineStatusField'
import CipLogMachineLatestApprovedAtField from '../components/field/CipLogMachineLatestApprovedAtField'
import {
  EndDateInputSx,
  StartDateInputSx,
  LogRadioButtonGroupInputSx,
  TextInputSx,
} from '../assets/sx/filterSx'
import EditCipLogModal from '../components/modal/cipLog/EditCipLogModal'
import ApproveCipLogModal from '../components/modal/cipLog/ApproveCipLogModal'

import { useAuthorize, useNotifyForbidden } from '../hooks/authorization'
import style from './CipLogMachineList.module.scss'

// システム開始日
const startYear = 2022
const startMonth = 11

const filters = [
  <RadioButtonGroupInput
    key="approveStatus"
    label={false}
    source="approveStatus"
    alwaysOn
    margin="none"
    sx={LogRadioButtonGroupInputSx}
    choices={[
      { id: 'all', name: 'すべて' },
      { id: 'notApproved', name: '承認前' },
    ]}
  />,
  <TextInput
    key="storeName"
    label={false}
    source="storeName"
    placeholder="店舗名"
    alwaysOn
    fullWidth
    resettable
    sx={TextInputSx}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <TextInput
    key="machineSerial"
    label={false}
    source="machineSerial"
    placeholder="マシン シリアル"
    alwaysOn
    fullWidth
    resettable
    sx={TextInputSx}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <DateInput
    key="latestApprovedAtStart"
    label={false}
    source="latestApprovedAtStart"
    alwaysOn
    fullWidth
    sx={StartDateInputSx}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <CalenderIcon />
          <span
            style={{
              color: '#757575',
              fontWeight: 'bold',
              fontSize: 14,
              paddingLeft: 10,
              paddingRight: 20,
            }}>
            承認年月日
          </span>
        </InputAdornment>
      ),
    }}
  />,
  <DateInput
    key="latestApprovedAtEnd"
    label={false}
    source="latestApprovedAtEnd"
    alwaysOn
    fullWidth
    sx={EndDateInputSx}
  />,
]

const CipLogMachineList: React.FC = () => {
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [approveModalOpen, setApproveModalOpen] = useState<boolean>(false)
  const [targetCipLogMachine, setTargetCipLogMachine] =
    useState<CipLogMachineRecord>()
  const canEditCipLog = useAuthorize('cipLog', 'update')
  const canApproveCipLog = useAuthorize('cipLog', 'approve')
  const notifyForbidden = useNotifyForbidden()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const refresh = useRefresh()

  // 既存のローカルストレージの値を取得する
  const existingData = JSON.parse(
    localStorage.getItem('RaStore.cip-log-machines.listParams') || '{}',
  )

  // 既存のデータに新しいデータを追加、または更新する
  const updatedData = {
    ...existingData,
    displayedFilters: {},
    filter: {
      ...existingData.filter,
      approveStatus: 'all',
    },
  }

  //  更新したデータを再度localStorageに保存する
  localStorage.setItem(
    'RaStore.cip-log-machines.listParams',
    JSON.stringify(updatedData),
  )

  const openEditModal = (
    e: MouseEvent<HTMLElement>,
    targetCipLogMachine: CipLogMachineRecord | undefined,
  ) => {
    e.preventDefault()

    if (!canEditCipLog) {
      notifyForbidden()
      return
    }

    setEditModalOpen(true)
    setTargetCipLogMachine(targetCipLogMachine)
  }

  const closeEditModal = () => {
    setEditModalOpen(false)
  }

  const onEditSubmit = (
    updateCipLogParam: UpdateCipLogParam,
    callback: (data: CipLogRecord[]) => void,
  ) => {
    dataProvider
      .updateCipLogs(updateCipLogParam)
      .then((res: { data: CipLogRecord[] }) => {
        notify('CIPログを更新しました')
        callback(res.data)
        refresh()
      })
  }

  const openApproveModal = (
    e: MouseEvent<HTMLElement>,
    targetCipLogMachine: CipLogMachineRecord | undefined,
  ) => {
    e.preventDefault()

    if (!canApproveCipLog) {
      notifyForbidden()
      return
    }

    setApproveModalOpen(true)
    setTargetCipLogMachine(targetCipLogMachine)
  }

  const closeApproveModal = () => {
    setApproveModalOpen(false)
  }

  const onApproveSubmit = (
    approveCipLogParam: ApproveCipLogParam,
    callback: (data: CipLogRecord[]) => void,
  ) => {
    dataProvider
      .approveCipLogs(approveCipLogParam)
      .then((res: { data: CipLogRecord[] }) => {
        notify('CIPログを更新しました')
        callback(res.data)
        refresh()
      })
  }

  const settingButtonItems = [
    { tooltipLabel: '詳細', icon: <DetailsIcon />, onClick: openEditModal },
    { tooltipLabel: '編集', icon: <PencilIcon />, onClick: openApproveModal },
  ]

  return (
    <>
      <ListDatagrid
        title="CIPログマスター"
        filters={filters}
        modals={[
          <EditCipLogModal
            key="cipLogEditModal"
            targetCipLogMachine={targetCipLogMachine}
            startYear={startYear}
            startMonth={startMonth}
            open={editModalOpen}
            onClose={closeEditModal}
            onSubmit={onEditSubmit}
          />,
          <ApproveCipLogModal
            key="cipLogApproveModal"
            targetCipLogMachine={targetCipLogMachine}
            startYear={startYear}
            startMonth={startMonth}
            open={approveModalOpen}
            onClose={closeApproveModal}
            onSubmit={onApproveSubmit}
          />,
        ]}>
        <WrapperField
          label="ステータス"
          headerClassName={style.cipLogMachineStatusField}
          sortable={false}>
          <CipLogMachineStatusField />
        </WrapperField>
        <WrapperField label="最終承認日時" sortable={false}>
          <CipLogMachineLatestApprovedAtField />
        </WrapperField>
        <WrapperField label="店舗名" sortable={false}>
          <TextField
            source="storeName"
            sortable={false}
            sx={{
              fontWeight: 700,
            }}
          />
          <br></br>
          <FunctionField
            sortable={false}
            render={(record: CipLogMachineRecord) =>
              record && `〒${record.storeZipcode} ${record.storeAddress}`
            }
            sx={{
              color: '#757575 !important',
            }}
          />
        </WrapperField>
        <TextField source="storeTel" label="電話番号" sortable={false} />
        <TextField source="storeID" label="店舗ID" sortable={false} />
        <TextField source="machineID" label="マシンID" sortable={false} />
        <FunctionField
          sortable={false}
          render={(record: CipLogMachineRecord) => {
            return (
              <Grid container display="flex" justifyContent="right">
                {settingButtonItems.map((item, index) => {
                  return (
                    <IconButton<CipLogMachineRecord>
                      key={`iconButton_${index}`}
                      tooltipLabel={item.tooltipLabel}
                      onClick={item.onClick}
                      icon={item.icon}
                      record={record}
                    />
                  )
                })}
              </Grid>
            )
          }}
        />
      </ListDatagrid>
    </>
  )
}

export default CipLogMachineList
