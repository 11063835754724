import React from 'react'
import {
  RecipeCategoryParams,
  RecipeCategoryRecord,
} from '../../types/records/recipe-category-record'
import { BlenderPresetRecord } from '../../types/records/blender-preset-record'
import {
  Grid,
  Typography,
  Modal,
  Box,
  InputLabel,
  FormControl,
} from '@mui/material'
import CancelInputModal from './CancelInputModal'
import {
  Form,
  SaveButton,
  TextInput,
  SelectInput,
  NumberInput,
  required,
} from 'react-admin'
import useCancelModal from '../../hooks/useCancelModal'
import CancelButton from '../button/CancelButton'
import { SizeRecord } from '../../types/records/size-record'
import { SizeRecipeBlenderLinksRecord } from '../../types/records/size-recipe-blender-links-record'
import {
  gridSx,
  labelGrayOutSx,
  textInputSx,
  selectInputSx,
  modalWrapperSx,
  cancelButtonWrapperSx,
  saveButtonSx,
} from '../../assets/sx/form'
import { modalTitleSx } from '../../assets/sx/modalSx'

type Props = {
  recipeCategory: RecipeCategoryRecord | undefined
  blenderPresets: BlenderPresetRecord[]
  sizes: SizeRecord[]
  open: boolean
  onClose: () => void
  onSubmit: (
    id: number,
    data: RecipeCategoryParams,
    previousData: RecipeCategoryRecord,
  ) => void
}

const EditRecipeCategoryModal: React.FC<Props> = ({
  open,
  recipeCategory,
  blenderPresets,
  sizes,
  onClose,
  onSubmit,
}) => {
  const onUpdateSubmit = (data: RecipeCategoryParams) => {
    if (!recipeCategory || !recipeCategory.id) {
      return null
    }
    onSubmit(recipeCategory.id, data, recipeCategory)
  }

  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onClose)

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box sx={modalWrapperSx}>
          <Typography sx={modalTitleSx}>レシピカテゴリを更新する</Typography>
          <Form
            onSubmit={onUpdateSubmit}
            record={recipeCategory}
            defaultValues={recipeCategory}>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="name" sx={labelGrayOutSx}>
                レシピカテゴリ名 *
              </InputLabel>
              <TextInput
                source="name"
                variant="outlined"
                label={false}
                placeholder="入力してください"
                validate={required('エラー入力してください')}
                sx={textInputSx}
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="displayName" sx={labelGrayOutSx}>
                レシピカテゴリ表示名 *
              </InputLabel>
              <TextInput
                source="displayName"
                variant="outlined"
                label={false}
                placeholder="入力してください"
                validate={required('エラー入力してください')}
                sx={textInputSx}
                fullWidth
              />
            </Grid>
            <Grid wrap="wrap" container>
              {recipeCategory?.sizeRecipeBlenderLinks?.map(
                (
                  sizeRecipeBlenderLink: SizeRecipeBlenderLinksRecord,
                  index,
                ) => (
                  <Grid
                    sx={{ ...gridSx, width: '175px', margin: 'auto' }}
                    key={sizeRecipeBlenderLink.size.id}
                    item>
                    <InputLabel
                      htmlFor="sizeRecipeBlenderLinks"
                      sx={labelGrayOutSx}>
                      {`プリセット_${
                        sizes.find(
                          (target) =>
                            target.id == sizeRecipeBlenderLink.size.id,
                        )?.name
                      } *`}
                      <SelectInput
                        source={`sizeRecipeBlenderLinks[${index}][sizeID]`}
                        choices={sizes}
                        defaultValue={sizeRecipeBlenderLink.size.id}
                        disabled
                        sx={{
                          display: 'none',
                        }}
                      />
                    </InputLabel>
                    <NumberInput
                      source={`sizeRecipeBlenderLinks[${index}][ID]`}
                      defaultValue={sizeRecipeBlenderLink.id}
                      disabled
                      sx={{
                        display: 'none',
                      }}
                      fullWidth
                    />
                    <SelectInput
                      source={`sizeRecipeBlenderLinks[${index}][blenderPresetID]`}
                      defaultValue={sizeRecipeBlenderLink.blenderPreset.id}
                      choices={blenderPresets}
                      label={false}
                      variant="outlined"
                      margin="none"
                      fullWidth
                      validate={required('エラー選択してください')}
                      sx={{ ...selectInputSx, maxWidth: '150px' }}
                    />
                  </Grid>
                ),
              )}
            </Grid>
            <FormControl fullWidth></FormControl>
            <Grid container direction="row" justifyContent="flex-end">
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton onClick={handleOnCancelModalOpen}>
                  キャンセル
                </CancelButton>
              </Box>
              <SaveButton label="更新する" sx={saveButtonSx} />
            </Grid>
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  )
}

export default EditRecipeCategoryModal
