import React, {
  useState,
  ReactElement,
  JSXElementConstructor,
  SVGProps,
} from 'react'
import { ReactComponent as BulletPoint } from '../assets/images/bullet_point.svg'
import { DashboardMenuItem, MenuItemLink } from 'react-admin'
import { Box } from '@mui/material'
import style from './Sidebar.module.scss'

type Props = {
  menuIcon: ReactElement<JSXElementConstructor<SVGProps<SVGSVGElement>>>
  menuName: string
  subMenus: { path: string; name: string }[]
}

const ToggleMenu: React.FC<Props> = ({ menuIcon, menuName, subMenus }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [menuBgColor, setMenuBgColor] = useState<string>('#212121')
  const [focusMenuPath, setFocusMenuPath] = useState<string>(subMenus[0].path)
  const onChangeMenuOpen = (open: boolean) => {
    if (open) {
      setFocusMenuPath(subMenus[0].path)
      setMenuOpen(false)
      setMenuBgColor('#212121')
    } else {
      setMenuOpen(true)
      setMenuBgColor('#000000')
    }
  }

  const onChangeFocusMenu = (index: number) => {
    setMenuBgColor('#000000')
    setFocusMenuPath(subMenus[index].path)
  }

  return (
    <Box sx={{ backgroundColor: menuBgColor }}>
      <DashboardMenuItem
        className={style['sidebar-item']}
        onClick={() => onChangeMenuOpen(menuOpen)}
        leftIcon={menuIcon}
        primaryText={menuName}
        to={menuOpen ? focusMenuPath : subMenus[0].path}
        sidebarIsOpen={menuOpen}
      />
      {menuOpen &&
        subMenus.map((subMenu, index) => (
          <MenuItemLink
            key={`subMenu_${index}`}
            className={style['sidebar-item']}
            to={subMenu.path}
            primaryText={subMenu.name}
            leftIcon={<BulletPoint />}
            onClick={() => onChangeFocusMenu(index)}
            onBlur={() => setMenuBgColor('#212121')}
          />
        ))}
    </Box>
  )
}

export default ToggleMenu
