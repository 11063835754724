import React from 'react'
import { Grid, Typography, InputLabel } from '@mui/material'
import { required, TextInput } from 'react-admin'
import { labelGrayOutSx, gridSx } from '../../../assets/sx/form'
import { modalTitleSx } from '../../../assets/sx/modalSx'
import {
  IngredientParams,
  IngredientRecord,
} from '../../../types/records/ingredient-record'

type Props = {
  ingredient: IngredientRecord | undefined
  ingredientParams: IngredientParams
  setIngredientParams: React.Dispatch<React.SetStateAction<IngredientParams>>
}

const EditIngredientModalAmountInput: React.FC<Props> = ({
  ingredient,
  ingredientParams,
  setIngredientParams,
}) => {
  return (
    <Grid>
      <Typography sx={modalTitleSx}>材料を編集する 2/4</Typography>
      <Grid sx={gridSx}>
        <InputLabel htmlFor="containerCapacityAmount" sx={labelGrayOutSx}>
          容器容量（g）*
        </InputLabel>
        <TextInput
          source="containerCapacityAmount"
          label={false}
          placeholder="入力してください"
          parse={(value) => (isNaN(Number(value)) ? '' : Number(value))}
          fullWidth
          defaultValue={ingredient?.containerCapacityAmount}
          validate={required('エラー入力してください')}
          onChange={(e) =>
            setIngredientParams({
              ...ingredientParams,
              containerCapacityAmount: Number(e.target.value),
            })
          }
        />
      </Grid>
    </Grid>
  )
}

export default EditIngredientModalAmountInput
