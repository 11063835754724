import React from 'react'
import { StoreRecord } from '../../../types/records/store-record'
import { Grid, Typography, Modal, Box, InputLabel } from '@mui/material'
import CancelButton from '../../button/CancelButton'
import {
  labelGrayOutSx,
  modalWrapperSx,
  confirmGridContainerSx,
  confirmInputGridSx,
  confirmInputLabelSx,
  cancelButtonWrapperSx,
} from '../../../assets/sx/form'
import { modalTitleSx } from '../../../assets/sx/modalSx'

type Props = {
  open: boolean
  store: StoreRecord
  onClose: () => void
}

const ShowStoreModal: React.FC<Props> = ({ open, store, onClose }) => {
  return (
    store && (
      <Modal open={open} onClose={onClose}>
        <Box sx={modalWrapperSx}>
          <Typography sx={modalTitleSx}>店舗詳細</Typography>
          <Grid>
            <Grid sx={confirmGridContainerSx} container>
              <InputLabel
                sx={{
                  ...labelGrayOutSx,
                  mb: 'auto',
                  mr: 'auto',
                  width: '50%',
                }}>
                店舗名
              </InputLabel>
              <Grid sx={confirmInputGridSx}>{store?.name}</Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid sx={confirmGridContainerSx} container>
              <InputLabel
                sx={{
                  ...labelGrayOutSx,
                  mb: 'auto',
                  mr: 'auto',
                  width: '50%',
                }}>
                郵便番号
              </InputLabel>
              <Grid sx={confirmInputGridSx}>〒{store?.zipcode}</Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid sx={confirmGridContainerSx} container>
              <InputLabel
                sx={{
                  ...labelGrayOutSx,
                  mb: 'auto',
                  mr: 'auto',
                  width: '50%',
                }}>
                住所
              </InputLabel>
              <Grid sx={confirmInputGridSx}>{store?.address}</Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid sx={confirmGridContainerSx} container>
              <InputLabel
                sx={{
                  ...labelGrayOutSx,
                  mb: 'auto',
                  mr: 'auto',
                  width: '50%',
                }}>
                電話番号
              </InputLabel>
              <Grid sx={confirmInputGridSx}>{store?.tel}</Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid sx={confirmGridContainerSx} container>
              <InputLabel
                sx={{
                  ...labelGrayOutSx,
                  mb: 'auto',
                  mr: 'auto',
                  width: '50%',
                }}>
                店舗タイプ
              </InputLabel>
              <Grid sx={confirmInputGridSx}>{store?.storeType.name}</Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid sx={confirmGridContainerSx} container>
              <InputLabel
                sx={{
                  ...labelGrayOutSx,
                  mb: 'auto',
                  mr: 'auto',
                  width: '50%',
                }}>
                店舗責任者
              </InputLabel>
              <Grid sx={confirmInputGridSx}>{store?.storeManager}</Grid>
            </Grid>
          </Grid>
          <Grid>
            {store.machines.length === 0 && (
              <Grid sx={confirmGridContainerSx} container>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    mt: 'auto',
                    mb: 'auto',
                    width: '50%',
                  }}>
                  店舗所有マシン
                </Typography>

                <Grid sx={confirmInputGridSx}> なし </Grid>
              </Grid>
            )}
            {store.machines.length > 0 && (
              <Grid>
                <Typography sx={modalTitleSx}>店舗所有マシン</Typography>
                {store.machines.map((machine, index) => (
                  <Grid key={`machine_${index}`}>
                    <Grid sx={confirmGridContainerSx} container>
                      <InputLabel
                        sx={{ ...confirmInputLabelSx, color: '#757575' }}>
                        マシン シリアル
                      </InputLabel>
                      <Grid sx={{ ...confirmInputGridSx }}>
                        {' '}
                        {machine.serial}{' '}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
          <Grid container justifyContent="flex-end">
            <Box sx={cancelButtonWrapperSx}>
              <CancelButton onClick={() => onClose()}>閉じる</CancelButton>
            </Box>
          </Grid>
        </Box>
      </Modal>
    )
  )
}

export default ShowStoreModal
