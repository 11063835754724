import React from 'react'
import { useRecordContext } from 'react-admin'

import styles from './StoreTypeField.module.scss'
import { StoreTypeRecord } from '../../types/records/store-type-record'
import { StoreRecord } from '../../types/records/store-record'

type Props = {
  storeTypes: StoreTypeRecord[] | undefined
}

const StoreTypeField: React.FC<Props> = ({ storeTypes }) => {
  const record = useRecordContext<StoreRecord>()

  if (!record) return null

  const storeType = storeTypes?.find(
    (storeType) => storeType.id === record.storeType?.id,
  )
  if (!storeType) return null

  return (
    <>
      <span
        className={`${styles.base} `}
        style={{
          color: storeType.displayColor.displayFontColor,
          background: storeType.displayColor.displayBackgroundColor,
          marginLeft: '10px',
        }}>
        {record.storeType.displayName}
      </span>
    </>
  )
}

export default StoreTypeField
