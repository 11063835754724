import React, { useEffect } from 'react'
import { IngredientCategoryRecord } from '../../../types/records/ingredient-record'
import { RecipeIngredientInputParam } from '../../../types/records/recipe-record'
import { SizeRecord } from '../../../types/records/size-record'
import {
  SizeScalingTypeRecord,
  SizeScalingType,
} from '../../../types/records/utensil-record'
import { Grid, Typography, InputLabel, Checkbox, Button } from '@mui/material'
import { SelectInput, TextInput, NumberInput, required } from 'react-admin'
import {
  labelGrayOutSx,
  textInputSx,
  numberInputSx,
  selectInputSx,
  addButtonSx,
} from '../../../assets/sx/form'
import { ReactComponent as PlusIcon } from '../../../assets/images/plus_black.svg'
import { ReactComponent as CrossIcon } from '../../../assets/images/cross.svg'
import CustomPlaceholderSelectInput from '../../customComponent/CustomPlaceholderSelectInput'

type Props = {
  recipeIngredientInputParams: RecipeIngredientInputParam[]
  ingredientCategory: IngredientCategoryRecord
  source: string
  label: string
  buttonLabel: string
  sizes: SizeRecord[]
  recipeIngredientCategoryType: string
  validate: boolean
  sizeScalingType: SizeScalingTypeRecord
  findTargetSizeScalingType: (
    ingredientID: number,
    recipeIngredientCategoryType: string,
  ) => void
  handleOnChangeArrayIngredientID: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    recipeIngredientCategoryType: string,
  ) => void
  handleOnChangeArraySizeScalingPresetID: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    recipeIngredientCategoryType: string,
  ) => void
  handleOnChangeArrayNewSizeScalingPresetName: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    recipeIngredientCategoryType: string,
  ) => void
  handleOnChangeArrayNewSizeScalingPresetCoefficient: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    sizeIndex: number,
    recipeIngredientCategoryType: string,
  ) => void
  isCheckedManualInputs: boolean[]
  handleOnChangeCheckedManualInputs: (
    recipeIngredientCategoryType: string,
    index: number,
  ) => void
  handleOnAddNewRecipeIngredientInput: (
    recipeIngredientCategoryType: string,
  ) => void
  handleOnRemoveRecipeIngredientInputs: (
    index: number,
    recipeIngredientCategoryType: string,
  ) => void
}

const RecipeIngredientArrayInput: React.FC<Props> = ({
  recipeIngredientInputParams,
  ingredientCategory,
  source,
  label,
  buttonLabel,
  sizes,
  recipeIngredientCategoryType,
  sizeScalingType,
  findTargetSizeScalingType,
  handleOnChangeArrayIngredientID,
  handleOnChangeArraySizeScalingPresetID,
  handleOnChangeArrayNewSizeScalingPresetName,
  handleOnChangeArrayNewSizeScalingPresetCoefficient,
  isCheckedManualInputs,
  handleOnChangeCheckedManualInputs,
  handleOnAddNewRecipeIngredientInput,
  handleOnRemoveRecipeIngredientInputs,
}) => {
  useEffect(() => {
    if (
      recipeIngredientInputParams.length > 0 &&
      recipeIngredientInputParams[0].ingredientID
    ) {
      findTargetSizeScalingType(
        recipeIngredientInputParams[0].ingredientID,
        recipeIngredientCategoryType,
      )
    }
  }, [recipeIngredientInputParams])

  const pumpAndScoopList = [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' },
    { id: 8, name: '8' },
  ]

  const coefficientSelectInputTSX = (
    presetIndex: number,
    sizeIndex: number,
  ) => {
    if (!recipeIngredientInputParams) {
      return <></>
    }
    const recipeIngredientInputParam =
      recipeIngredientInputParams[presetIndex].newSizeScalingPreset
    if (!recipeIngredientInputParam) {
      return <></>
    }

    return (
      <Grid container>
        <CustomPlaceholderSelectInput
          type="formInput"
          placeholder="選択"
          source={`${source}[${presetIndex}][newSizeScalingPreset[ingredientSizeCoefficients[${sizeIndex}][coefficient]]]`}
          variant="outlined"
          label={false}
          sx={{ ...selectInputSx, minWidth: '90px' }}
          validate={required('エラー入力してください')}
          choices={pumpAndScoopList}
          defaultValue={
            recipeIngredientInputParam.ingredientSizeCoefficients[sizeIndex]
              .coefficient
              ? recipeIngredientInputParam.ingredientSizeCoefficients[sizeIndex]
                  .coefficient
              : ''
          }
          onChange={(event) => {
            handleOnChangeArrayNewSizeScalingPresetCoefficient(
              event,
              presetIndex,
              sizeIndex,
              recipeIngredientCategoryType,
            )
          }}
        />
        <Grid
          sx={{
            fontSize: '14px',
            color: '#757575',
            mt: '20px',
            ml: '10px',
            width: '60px',
          }}>
          <Grid>{sizeScalingType.name}</Grid>
        </Grid>
      </Grid>
    )
  }

  const coefficientNumberInputTSX = (
    presetIndex: number,
    sizeIndex: number,
  ) => {
    if (!recipeIngredientInputParams) {
      return <></>
    }
    const recipeIngredientInputParam =
      recipeIngredientInputParams[presetIndex].newSizeScalingPreset
    if (!recipeIngredientInputParam) {
      return <></>
    }

    return (
      <Grid container>
        <NumberInput
          source={`${source}[${presetIndex}][newSizeScalingPreset[ingredientSizeCoefficients[${sizeIndex}][coefficient]]]`}
          variant="outlined"
          label={false}
          sx={{ ...numberInputSx, width: '100px' }}
          validate={required('エラー入力してください')}
          fullWidth
          min={1}
          defaultValue={
            recipeIngredientInputParam.ingredientSizeCoefficients[sizeIndex]
              .coefficient
              ? recipeIngredientInputParam.ingredientSizeCoefficients[sizeIndex]
                  .coefficient
              : ''
          }
          onChange={(event) => {
            handleOnChangeArrayNewSizeScalingPresetCoefficient(
              event,
              presetIndex,
              sizeIndex,
              recipeIngredientCategoryType,
            )
          }}
        />
        <Grid
          sx={{
            fontSize: '14px',
            color: '#757575',
            m: '8px',
          }}>
          {sizeScalingType.name}
        </Grid>
      </Grid>
    )
  }

  const whichNewSizeScalingPresetInputTSX = (
    presetIndex: number,
    sizeIndex: number,
    sizeScalingTypeName: string,
  ) => {
    switch (sizeScalingTypeName) {
      case SizeScalingType.G:
        return coefficientNumberInputTSX(presetIndex, sizeIndex)
      case SizeScalingType.ML:
        return coefficientNumberInputTSX(presetIndex, sizeIndex)
      case SizeScalingType.Pump:
        return coefficientSelectInputTSX(presetIndex, sizeIndex)
      case SizeScalingType.Scoop:
        return coefficientSelectInputTSX(presetIndex, sizeIndex)
      default:
        return <></>
    }
  }

  const newSizeScalingPresetInputTSX = (index: number) => {
    return (
      <Grid>
        <Grid sx={{ mb: '8px', mt: '8px' }}>
          <InputLabel sx={labelGrayOutSx}>投入プリセット名</InputLabel>
          <TextInput
            source={`${source}[${index}][newSizeScalingPreset[name]]`}
            variant="outlined"
            label={false}
            sx={textInputSx}
            validate={required('エラー入力してください')}
            placeholder="入力してください"
            fullWidth
            onChange={(event) => {
              handleOnChangeArrayNewSizeScalingPresetName(
                event,
                index,
                recipeIngredientCategoryType,
              )
            }}
          />
        </Grid>
        <Grid wrap="wrap" container>
          {sizes.map((size, sizeIndex) => (
            <Grid key={`size_{${size.name}}`} sx={{ width: '175px' }}>
              <InputLabel sx={labelGrayOutSx}>
                <Grid>{size.name}</Grid>
              </InputLabel>
              <>
                {whichNewSizeScalingPresetInputTSX(
                  index,
                  sizeIndex,
                  sizeScalingType.name,
                )}
              </>
            </Grid>
          ))}
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid>
      <Typography sx={{ fontSize: '14px', fontWeight: 700, mb: '10px' }}>
        {label}
      </Typography>
      {recipeIngredientInputParams.map((target, index) => (
        <Grid key={`${source}_${index}`}>
          <Grid>
            <Grid container justifyContent="space-between">
              <InputLabel sx={{ ...labelGrayOutSx, mt: 'auto', mb: 'auto' }}>
                材料を選択
              </InputLabel>
              <Button
                variant="text"
                onClick={() => {
                  handleOnRemoveRecipeIngredientInputs(
                    index,
                    recipeIngredientCategoryType,
                  )
                }}>
                <CrossIcon className="cross-icon" />
                <Grid sx={{ color: '#C80000' }}>削除</Grid>
              </Button>
            </Grid>
            <SelectInput
              source={`${source}[${index}][ingredientID]`}
              variant="outlined"
              label={false}
              sx={{ ...selectInputSx }}
              choices={ingredientCategory?.ingredients}
              validate={required('エラー選択してください')}
              onChange={(e) => {
                handleOnChangeArrayIngredientID(
                  e,
                  index,
                  recipeIngredientCategoryType,
                )
                findTargetSizeScalingType(
                  Number(e.target.value),
                  recipeIngredientCategoryType,
                )
              }}
              defaultValue={target.ingredientID ? target.ingredientID : ''}
              fullWidth
            />
            {recipeIngredientInputParams[index].ingredientID &&
              sizeScalingType && (
                <Grid>
                  <InputLabel sx={labelGrayOutSx}>投入量</InputLabel>
                  <Grid>
                    <Grid display="flex">
                      <Checkbox
                        onClick={() => {
                          handleOnChangeCheckedManualInputs(
                            recipeIngredientCategoryType,
                            index,
                          )
                        }}
                        checked={isCheckedManualInputs[index]}
                      />
                      <Grid
                        sx={{
                          fontSize: '14px',
                          color: '#757575',
                          marginTop: 'auto',
                          marginBottom: 'auto',
                        }}>
                        手動で入力
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid>
                    {isCheckedManualInputs[index] ? (
                      newSizeScalingPresetInputTSX(index)
                    ) : (
                      <SelectInput
                        source={`${source}[${index}][sizeScalingPresetID]`}
                        variant="outlined"
                        label={false}
                        sx={selectInputSx}
                        choices={
                          sizeScalingType
                            ? sizeScalingType.sizeScalingPresets
                            : []
                        }
                        fullWidth
                        defaultValue={
                          target.sizeScalingPresetID
                            ? target.sizeScalingPresetID
                            : ''
                        }
                        validate={required('エラー選択してください')}
                        onChange={(e) => {
                          handleOnChangeArraySizeScalingPresetID(
                            e,
                            index,
                            recipeIngredientCategoryType,
                          )
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
          </Grid>
        </Grid>
      ))}
      <Button
        sx={addButtonSx}
        onClick={() =>
          handleOnAddNewRecipeIngredientInput(recipeIngredientCategoryType)
        }>
        <PlusIcon className="plus-icon" />
        {buttonLabel}
      </Button>
    </Grid>
  )
}

export default RecipeIngredientArrayInput
