import { OfferGenreRecord } from './offer-genre-record'
import { SizeRecord } from './size-record'
import { SizeScalingTypeRecord } from './utensil-record'
import { IngredientCategoryRecord } from './ingredient-record'

export type RecipeRecord = {
  id: number
  name: string
  displayName: string
  imageURL?: string
  recipeCategory: RecipeCategoryRecord
  offerGenre: OfferGenreRecord
  description?: string
  isDeleted: boolean
  recipeIngredientInputs?: RecipeIngredientInputRecord[]
  toppings: ToppingRecord[]
  offerStartAt: string
  offerEndAt: string
  createdAt: string
  updatedAt: string
}

export type RecipeSetting = {
  ingredientCategoryType: IngredientCategoryForSettingRecipe
  sizes: SizeRecord[]
  offerGenres: OfferGenreRecord[]
  recipeCategories: RecipeCategoryRecord[]
  sizeScalingTypes: SizeScalingTypeRecord[]
  sizeScalingPresets: SizeScalingPresetRecord[]
  inputTimings: InputTimingRecord[]
}

export type IngredientCategoryForSettingRecipe = {
  syrupBase: IngredientCategoryRecord
  roastAndTea: IngredientCategoryRecord
  chip: IngredientCategoryRecord
  powder: IngredientCategoryRecord
  syrup: IngredientCategoryRecord
  dairyAndJuice: IngredientCategoryRecord
  inputManual: IngredientCategoryRecord
}

export type RecipeCategoryRecord = {
  id: number
  name: string
  displayName: string
}

export type RecipeIngredientInputRecord = {
  id: number
  recipeID: number
  ingredientID: number
  inputTimingID: number
  sizeScalingPresetID: number
}

export type ToppingRecord = {
  id?: number
  recipeID?: number
  description?: string
}

export type SizeScalingPresetRecord = {
  id: number
  name: string
  sizeScalingType: SizeScalingTypeRecord
  ingredientSizeCoefficients: IngredientSizeCoefficientRecord[]
}

export enum SizeScalingPresetType {
  SizeScalingPresetTypePumpOrScoop = 'pump_or_scoop',
  SizeScalingPresetTypeMilliliterOrGram = 'mL_or_g',
  SizeScalingPresetTypeManualInput = 'manual_input',
}

export const OfferStatus = {
  All: { id: 'all', name: 'すべて' },
  Offering: { id: 'offering', name: '提供中' },
  Archived: { id: 'archived', name: 'アーカイブ' },
}

export type IngredientSizeCoefficientRecord = {
  id: number
  size: SizeRecord
  sizeScalingPresetID: number
  coefficient: number
}

export type InputTimingRecord = {
  id: number
  name: string
}

export type RecipeParams = {
  name?: string
  displayName?: string
  imageURL?: string
  recipeCategoryID?: number
  offerGenreID?: number
  description?: string
  offerStartAt?: string
  offerEndAt?: string
  syrupBaseRecipeIngredientInput?: RecipeIngredientInputParam
  // TODO レシピモーダル改修が終わったら、roastRecipeIngredientInputは削除する
  roastRecipeIngredientInput?: RecipeIngredientInputParam
  roastAndTeaRecipeIngredientInput?: RecipeIngredientInputParam
  dairyAndJuiceRecipeIngredientInput?: RecipeIngredientInputParam
  syrupRecipeIngredientInputs?: RecipeIngredientInputParam[]
  powderRecipeIngredientInputs?: RecipeIngredientInputParam[]
  chipRecipeIngredientInputs?: RecipeIngredientInputParam[]
  manualRecipeIngredientInputs?: RecipeIngredientInputParam[]
  toppings?: ToppingParam[]
}

export type RecipeIngredientInputParam = {
  recipeID?: number
  ingredientID?: number
  inputTimingID?: number
  sizeScalingPresetID?: number
  newSizeScalingPreset?: SizeScalingPresetParam
}
export type ToppingParam = {
  description: string
}

export type SizeScalingPresetParam = {
  name: string
  type?: string
  sizeScalingTypeID?: number
  ingredientSizeCoefficients: IngredientSizeCoefficientParam[]
}

export type IngredientSizeCoefficientParam = {
  sizeID?: number
  coefficient?: number
}

export const RecipeIngredientCategoryType = {
  SyrupBase: 'syrupBase',
  RoastAndTea: 'roastAndTea',
  Syrup: 'syrup',
  Powder: 'powder',
  Chip: 'chip',
  ManualInput: 'manualInput',
  DairyAndJuice: 'dairyAndJuice',
}

export type RecipeIngredientCategoryType =
  typeof RecipeIngredientCategoryType[keyof typeof RecipeIngredientCategoryType]
