import React from 'react'
import { Grid, InputLabel, Typography } from '@mui/material'
import {
  SizeScalingPresetRecord,
  RecipeIngredientInputParam,
  SizeScalingPresetParam,
} from '../../../types/records/recipe-record'
import { IngredientRecord } from '../../../types/records/ingredient-record'
import { SizeScalingTypeRecord } from '../../../types/records/utensil-record'
import { SizeRecord } from '../../../types/records/size-record'
import { confirmGridContainerSx } from '../../../assets/sx/form'
import { modalTitleSx, modalLeftItemLabelSx } from '../../../assets/sx/modalSx'
type Props = {
  sizeMap: Map<number, SizeRecord>
  ingredientMap: Map<number, IngredientRecord>
  sizeScalingPresetMap: Map<number, SizeScalingPresetRecord>
  sizeScalingTypeMap: Map<number, SizeScalingTypeRecord>
  ingredientCategoryName: string
  recipeIngredientInput: RecipeIngredientInputParam
}

const ConfirmIngredientField: React.FC<Props> = ({
  sizeMap,
  ingredientMap,
  sizeScalingPresetMap,
  sizeScalingTypeMap,
  ingredientCategoryName,
  recipeIngredientInput,
}) => {
  let ingredientName: string | undefined = undefined
  if (recipeIngredientInput) {
    ingredientName = ingredientMap.get(
      recipeIngredientInput.ingredientID as number,
    )?.name
  }
  let sizeScalingPreset: SizeScalingPresetRecord | undefined = undefined
  if (recipeIngredientInput) {
    sizeScalingPreset = sizeScalingPresetMap.get(
      recipeIngredientInput.sizeScalingPresetID as number,
    )
  }
  let sizeScalingType: SizeScalingTypeRecord | undefined = undefined
  if (recipeIngredientInput && recipeIngredientInput.newSizeScalingPreset) {
    sizeScalingType = sizeScalingTypeMap.get(
      recipeIngredientInput.newSizeScalingPreset.sizeScalingTypeID as number,
    )
  }

  const sizeScalingPresetTsx = (sizeScalingPreset: SizeScalingPresetRecord) => {
    return (
      <Grid>
        {sizeScalingPreset.ingredientSizeCoefficients.map(
          (ingredientSizeCoefficient) => (
            <Grid
              sx={confirmGridContainerSx}
              container
              key={`size_${ingredientSizeCoefficient.size.name}`}>
              <InputLabel sx={modalLeftItemLabelSx}>
                投入量_{ingredientSizeCoefficient.size.name}
              </InputLabel>
              <Grid sx={{ mt: 'auto', mb: 'auto' }}>
                {ingredientSizeCoefficient.coefficient}
                {sizeScalingPreset.sizeScalingType.name}
              </Grid>
            </Grid>
          ),
        )}
      </Grid>
    )
  }

  const newSizeScalingPresetTsx = (
    newSizeScalingPreset: SizeScalingPresetParam,
  ) => {
    return (
      <Grid>
        {newSizeScalingPreset.ingredientSizeCoefficients.map(
          (ingredientSizeCoefficient) => (
            <Grid
              sx={confirmGridContainerSx}
              container
              key={`size_${
                sizeMap.get(ingredientSizeCoefficient.sizeID as number)?.name
              }`}>
              <InputLabel sx={modalLeftItemLabelSx}>
                投入量_
                {sizeMap.get(ingredientSizeCoefficient.sizeID as number)?.name}
              </InputLabel>
              <Grid sx={{ mt: 'auto', mb: 'auto' }}>
                {ingredientSizeCoefficient.coefficient}
                {sizeScalingType?.name}
              </Grid>
            </Grid>
          ),
        )}
      </Grid>
    )
  }

  const whichSizeScalingPresetTsx = () => {
    if (recipeIngredientInput && recipeIngredientInput.newSizeScalingPreset) {
      return newSizeScalingPresetTsx(recipeIngredientInput.newSizeScalingPreset)
    } else if (sizeScalingPreset) {
      return sizeScalingPresetTsx(sizeScalingPreset as SizeScalingPresetRecord)
    } else {
      return <></>
    }
  }

  return (
    <Grid>
      <Typography sx={modalTitleSx}>{ingredientCategoryName}</Typography>
      <Grid sx={confirmGridContainerSx} container>
        <InputLabel sx={modalLeftItemLabelSx}>材料</InputLabel>
        <Grid sx={{ m: 'auto', width: '50%' }}>
          {ingredientName ? ingredientName : '-'}
        </Grid>
      </Grid>
      <>{whichSizeScalingPresetTsx()}</>
    </Grid>
  )
}

export default ConfirmIngredientField
