import React, { useEffect, useRef, useState } from 'react'
import { Form, useDataProvider, useNotify } from 'react-admin'
import { Modal, Box, Typography, Divider } from '@mui/material'
import CancelInputModal from '../CancelInputModal'
import useCancelModal from '../../../hooks/useCancelModal'
import { format, parseISO } from 'date-fns'
import { cipLogModalSx } from '../../../assets/sx/form'
import {
  CipLogMachineRecord,
  UpdateTargetsParam,
  CipLogRecord,
  ApproveCipLogParam,
} from '../../../types/records/cip-log-record'
import ApproveCipLogButton from './ApproveCipLogButton'
import CipLogMachineInfo from './CipLogMachineInfo'
import CipLogSelectDateButton from './CipLogSelectDateButton'
import CipLogListInfo from './CipLogListInfo'
import ValidateMessageInfo from '../../field/ValidateMessageInfo'

type Props = {
  targetCipLogMachine: CipLogMachineRecord | undefined
  startYear: number
  startMonth: number
  open: boolean
  onClose: () => void
  onSubmit: (
    approveCipLogParam: ApproveCipLogParam,
    callback: (data: CipLogRecord[]) => void,
  ) => void
}

const ApproveCipLogModal: React.FC<Props> = ({
  targetCipLogMachine,
  startYear,
  startMonth,
  open,
  onClose,
  onSubmit,
}) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const modalRef = useRef<HTMLDivElement>(null)

  const [cipLogs, setCipLogs] = useState<CipLogRecord[]>([])
  const [cipLogParam, setCipLogParam] = useState<UpdateTargetsParam[]>()
  const [isAllApproved, setIsAllApproved] = useState<boolean>(false)
  const [validateMessage, setValidateMessage] = useState<string[]>([])
  const [targetDate, setTargetDate] = useState<string>()
  const [today, setToday] = useState<Date>()

  useEffect(() => {
    if (!targetCipLogMachine) return

    if (open) {
      const today = new Date()
      setToday(today)

      // 初期値設定
      getCipLogs(
        `${today.getFullYear()}-${(today.getMonth() + 1)
          .toString()
          .padStart(2, '0')}`,
      )
    }

    if (!open) {
      // モーダルを閉じた場合には初期化する
      setValidateMessage([])
      setCipLogParam([])
      setCipLogs([])
      setIsAllApproved(false)
      setTargetDate(undefined)
    }
  }, [open])

  const getCipLogs = (date: string) => {
    if (!targetCipLogMachine) return

    dataProvider
      .getList('cip-logs', {
        pagination: { page: 1, perPage: 31 },
        sort: { field: 'id', order: 'ASC' },
        filter: {
          targetDate: `${date}`,
          machineID: targetCipLogMachine.machineID,
        },
      })
      .then((res) => {
        setData(res.data as CipLogRecord[])
        setTargetDate(date)
      })
      .catch((e) => {
        notify(e.message, { type: 'error' })
      })
  }

  const onUpdateSubmit = () => {
    if (!cipLogParam) return

    const messages: string[] = []
    // 未承認で閉店オペ未の場合には特記事項は必須
    cipLogs.forEach((cipLog) => {
      if (!cipLog.approvedAt && !cipLog.closingOperationCompletedAt) {
        cipLogParam.forEach((param) => {
          if (param.id === cipLog.id && !param.description) {
            const message = `・${format(
              parseISO(cipLog.openingOperationCompletedAt),
              'yyyy/MM/dd',
            )}の「特記事項」を入力してください`
            messages.push(message)
          }
        })
      }
    })

    if (messages.length > 0) {
      setValidateMessage(messages)
      modalRef.current?.scrollTo(0, 0)
      return
    }

    const approveCipLogParam: ApproveCipLogParam = {
      updateTargets: cipLogParam,
      isApprove: !isAllApproved,
    }
    onSubmit(approveCipLogParam, (cipLogRecords) => {
      modalRef.current?.scrollTo(0, 0)
      setData(cipLogRecords)
    })
  }

  const setData = (cipLogRecords: CipLogRecord[]) => {
    setCipLogs(cipLogRecords)
    const cipLogParams: UpdateTargetsParam[] = cipLogRecords.map((record) => {
      return {
        id: record.id,
        description: record.description,
      }
    })
    setCipLogParam(cipLogParams)

    // 全件承認済みかチェック
    const notApprovedCipLog = cipLogRecords.find(
      (cl) => cl.approvedAt === undefined,
    )
    setIsAllApproved(notApprovedCipLog === undefined ? true : false)
    setValidateMessage([])
  }

  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onClose)

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box sx={cipLogModalSx} ref={modalRef}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 700,
              mb: '20px',
              paddingTop: '24px',
              paddingRight: '24px',
              paddingLeft: '24px',
            }}>
            承認状況を確認するページ
          </Typography>
          <ValidateMessageInfo
            validateMassages={validateMessage}
            sx={{ padding: '24px' }}
          />
          <CipLogMachineInfo targetCipLogMachine={targetCipLogMachine} />
          <Divider sx={{ mt: '20px', mb: '20px', mr: '24px', ml: '24px' }} />
          <CipLogSelectDateButton
            today={today}
            selectedDate={targetDate}
            startYear={startYear}
            startMonth={startMonth}
            onClick={getCipLogs}
          />
          <Form onSubmit={onUpdateSubmit}>
            <CipLogListInfo
              cipLogs={cipLogs}
              cipLogParam={cipLogParam}
              isEditable={!isAllApproved}
            />
            <ApproveCipLogButton
              onClose={handleOnCancelModalOpen}
              cipLogs={cipLogs}
              isAllApproved={isAllApproved}
            />
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  )
}

export default ApproveCipLogModal
