import React from 'react'
import { OfferGenreParams } from '../../types/records/offer-genre-record'
import useCancelModal from '../../hooks/useCancelModal'
import { Grid, Typography, Modal, Box, InputLabel } from '@mui/material'
import CancelInputModal from './CancelInputModal'
import { Form, required, SaveButton, TextInput } from 'react-admin'
import CancelButton from '../button/CancelButton'
import {
  gridSx,
  labelGrayOutSx,
  textInputSx,
  modalWrapperSx,
  cancelButtonWrapperSx,
  saveButtonSx,
} from '../../assets/sx/form'
import { modalTitleSx } from '../../assets/sx/modalSx'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: (data: OfferGenreParams) => void
}

const AddOfferGenreModal: React.FC<Props> = ({ open, onClose, onSubmit }) => {
  const validateName = [required('エラー入力してください')]
  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onClose)

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box sx={modalWrapperSx}>
          <Typography sx={modalTitleSx}>提供種別を追加する</Typography>
          <Form onSubmit={onSubmit}>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="name" sx={labelGrayOutSx}>
                提供種別 *
              </InputLabel>
              <TextInput
                source="name"
                variant="outlined"
                label={false}
                placeholder="入力してください"
                sx={textInputSx}
                validate={validateName}
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="description" sx={labelGrayOutSx}>
                解説
              </InputLabel>
              <TextInput
                source="description"
                variant="outlined"
                label={false}
                placeholder="入力してください"
                sx={textInputSx}
                fullWidth
              />
            </Grid>
            <Grid container direction="row" justifyContent="flex-end">
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton onClick={handleOnCancelModalOpen}>
                  キャンセル
                </CancelButton>
              </Box>
              <SaveButton label="追加する" sx={saveButtonSx} />
            </Grid>
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  )
}

export default AddOfferGenreModal
