import React from 'react'
import { Grid, InputLabel, Button, TextField } from '@mui/material'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'

const gridSx: SxProps<Theme> = {
  mb: '30px',
}

const labelSx: SxProps<Theme> = {
  color: '#757575',
  mb: '8px',
  display: 'inline-block',
  fontSize: '14px',
  lineHeight: '14px',
}

type Props = {
  file: File | undefined
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const ImageInput: React.FC<Props> = ({ onChangeFile, file }) => {
  return (
    <Grid sx={gridSx}>
      <InputLabel htmlFor="imageURL" sx={labelSx}>
        画像
      </InputLabel>
      <Grid>
        <label>
          <input
            type="file"
            accept="image/*"
            hidden
            defaultValue={undefined}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangeFile(e)
            }}
          />
          <TextField
            value={file?.name}
            size="small"
            disabled
            placeholder="選択してください"
            sx={{
              width: '65%',
              marginRight: '5%',
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#212121',
              },
            }}>
            {file?.name}
          </TextField>
          <Button
            variant="contained"
            component="span"
            sx={{
              width: '30%',
              height: '36px',
              marginTop: '2px',
              borderRadius: '20px',
              color: '#fff !important',
              boxShadow: 'none',
              backgroundColor: 'secondary.main',
              letterSpacing: '0.1em',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: 'secondary.main',
              },
              '& .MuiSvgIcon-root': {
                display: 'none',
              },
            }}>
            画像選択
          </Button>
        </label>
      </Grid>
    </Grid>
  )
}

export default ImageInput
