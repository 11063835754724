export const deleteModalSx = {
  width: 'auto',
  maxHeight: '20vh',
  backgroundColor: '#FFF',
  borderRadius: 2,
  p: 3,
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  top: '50%',
  left: '50%',
  overflow: 'hidden',
}

export const modalTitleSx = {
  fontSize: '16px',
  fontWeight: 700,
  mb: '40px',
}

export const modalSubtitleSx = {
  fontSize: '14px',
  fontWeight: 700,
  mb: '20px',
}

export const modalDividerSx = {
  mt: '20px',
  mb: '20px',
}

export const modalLeftItemLabelSx = {
  color: '#757575',
  display: 'inline-block',
  fontSize: '14px',
  lineHeight: '18px',
  mb: 'auto',
  width: '50%',
}

export const modalRightItemInputSx = {
  mt: 'auto',
  mb: 'auto',
  width: '50%',
}
