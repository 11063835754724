import React from 'react'
import useCancelModal from '../../hooks/useCancelModal'
import { IngredientEjectionAdjustmentParams } from '../../types/records/ingredient-ejection-adjustment-record'
import { Grid, Typography, Modal, Box, InputLabel } from '@mui/material'
import CancelInputModal from './CancelInputModal'
import { Form, required, number, SaveButton, NumberInput } from 'react-admin'
import CancelButton from '../button/CancelButton'
import { IngredientRecord } from '../../types/records/ingredient-record'
import {
  cancelButtonWrapperSx,
  gridSx,
  labelGrayOutSx,
  numberInputSx,
} from '../../assets/sx/form'
import { modalTitleSx } from '../../assets/sx/modalSx'

type Props = {
  open: boolean
  ingredient: IngredientRecord | undefined
  onClose: () => void
  onSubmit: (id: number, data: IngredientEjectionAdjustmentParams) => void
}

const EditIngredientEjectionAdjustmentModal: React.FC<Props> = ({
  open,
  ingredient,
  onClose,
  onSubmit,
}) => {
  const validateGreaterThanZero = (value: number) => {
    return 0 < value ? undefined : 'エラー0より大きい値を入力してください'
  }
  const validateSlopeCoefficient = [
    required('エラー入力してください'),
    number('エラー数値を入力してください'),
    validateGreaterThanZero,
  ]
  const validateInterceptCoefficient = [
    required('エラー入力してください'),
    number('エラー数値を入力してください'),
    validateGreaterThanZero,
  ]

  const onUpdateSubmit = (data: IngredientEjectionAdjustmentParams) => {
    if (!ingredient || !ingredient.id) {
      return null
    }
    onSubmit(ingredient.id, data)
  }

  const {
    cancelModalOpen,
    handleOnCancelModalOpen,
    handleOnCancelModalClose,
    handleOnCancelConfirm,
  } = useCancelModal(onClose)

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnCancelModalOpen}
        sx={{ display: cancelModalOpen ? 'none' : 'block' }}>
        <Box
          sx={{
            width: 398,
            MaxHeight: '80vh',
            backgroundColor: '#FFF',
            borderRadius: 2,
            p: 3,
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            left: '50%',
            overflow: 'scroll',
          }}>
          <Typography sx={modalTitleSx}>材料排出量調整を更新する</Typography>
          <Form
            onSubmit={onUpdateSubmit}
            record={ingredient?.ingredientEjectionAdjustment}>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="slopeCoefficient" sx={labelGrayOutSx}>
                傾き係数
              </InputLabel>
              <NumberInput
                source="slopeCoefficient"
                variant="outlined"
                label={false}
                placeholder="1"
                sx={numberInputSx}
                validate={validateSlopeCoefficient}
                fullWidth
              />
            </Grid>
            <Grid sx={gridSx}>
              <InputLabel htmlFor="interceptCoefficient" sx={labelGrayOutSx}>
                切片係数
              </InputLabel>
              <NumberInput
                source="interceptCoefficient"
                variant="outlined"
                label={false}
                placeholder="1"
                sx={numberInputSx}
                validate={validateInterceptCoefficient}
                fullWidth
              />
            </Grid>
            <Grid container direction="row" justifyContent="flex-end">
              <Box sx={cancelButtonWrapperSx}>
                <CancelButton onClick={handleOnCancelModalOpen}>
                  キャンセル
                </CancelButton>
              </Box>
              <SaveButton
                label="更新する"
                sx={{
                  width: '118px',
                  height: '36px',
                  borderRadius: '20px',
                  color: '#fff !important',
                  boxShadow: 'none',
                  backgroundColor: 'secondary.main',
                  '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: 'secondary.main',
                  },
                  '& .MuiSvgIcon-root': {
                    display: 'none',
                  },
                }}
              />
            </Grid>
          </Form>
        </Box>
      </Modal>
      <CancelInputModal
        targetModalOpen={open}
        open={cancelModalOpen}
        onConfirm={handleOnCancelConfirm}
        onClose={handleOnCancelModalClose}
      />
    </>
  )
}

export default EditIngredientEjectionAdjustmentModal
