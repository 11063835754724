import React, { ReactNode, MouseEvent } from 'react'
import Button from '@mui/material/Button'

type Props = {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  children: ReactNode
}

const CancelButton: React.FC<Props> = ({ onClick, children }) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={onClick}
      disableElevation
      fullWidth
      sx={{
        height: '36px',
        backgroundColor: '#F8F8F8',
        border: '1px solid #E0E0E0',
        borderRadius: '20px',
        fontWeight: 700,
        color: '#757575',
        letterSpacing: '0.1em',
        '&:hover': {
          backgroundColor: '#F8F8F8',
        },
      }}>
      {children}
    </Button>
  )
}

export default CancelButton
