import { format, parseISO } from 'date-fns'
import React from 'react'
import {
  DateInput,
  FunctionField,
  ImageField,
  RadioButtonGroupInput,
  TextField,
  TextInput,
  WrapperField,
} from 'react-admin'
import { InputAdornment, Grid } from '@mui/material'
import { ReactComponent as SearchIcon } from '../assets/images/search.svg'
import { ReactComponent as CalenderIcon } from '../assets/images/calender.svg'
import ListDatagrid from '../components/ListDatagrid'
import OrderLogStatusField from '../components/field/OrderLogStatusField'
import { OrderLogRecord } from '../types/records/order-log-record'
import {
  LogRadioButtonGroupInputSx,
  StartDateInputSx,
  EndDateInputSx,
  TextInputSx,
} from '../assets/sx/filterSx'
import style from './OrderLogList.module.scss'

const OrderLogList: React.FC = () => {
  localStorage.setItem(
    'RaStore.order-logs.listParams',
    `{
        "displayedFilters": {},
        "filter": {
          "status": "すべて",
          "orderReceivedAtStart": "${format(new Date(), 'yyyy-MM-dd')}",
          "orderReceivedAtEnd": "${format(new Date(), 'yyyy-MM-dd')}"
        },
        "order": "ASC",
        "page": 1,
        "perPage": 10,
        "sort": "id"
      }`,
  )

  const filters = [
    <RadioButtonGroupInput
      key="status"
      label={false}
      source="status"
      alwaysOn
      margin="none"
      sx={LogRadioButtonGroupInputSx}
      choices={[
        { id: 'すべて', name: 'すべて' },
        { id: '調理中', name: '調理中' },
        { id: '完了', name: '完了' },
        { id: 'キャンセル', name: 'キャンセル' },
        { id: 'エラー', name: 'エラー' },
      ]}
    />,
    <TextInput
      key="machineSerial"
      label={false}
      source="machineSerial"
      placeholder="マシン シリアル"
      alwaysOn
      fullWidth
      resettable
      sx={TextInputSx}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />,
    <TextInput
      key="storeName"
      label={false}
      source="storeName"
      placeholder="店舗名"
      alwaysOn
      fullWidth
      resettable
      sx={TextInputSx}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />,
    <DateInput
      key="orderReceivedAtStart"
      label={false}
      source="orderReceivedAtStart"
      alwaysOn
      fullWidth
      sx={StartDateInputSx}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CalenderIcon />
          </InputAdornment>
        ),
      }}
    />,
    <DateInput
      key="orderReceivedAtEnd"
      label={false}
      source="orderReceivedAtEnd"
      alwaysOn
      fullWidth
      sx={EndDateInputSx}
    />,
  ]

  return (
    <ListDatagrid title="オーダーログ" filters={filters} modals={[]}>
      <WrapperField
        label="ステータス"
        sortable={false}
        headerClassName={style.orderLogStatusField}>
        <Grid style={{ paddingLeft: '10px' }}>
          <OrderLogStatusField />
        </Grid>
      </WrapperField>
      <TextField
        source="id"
        label="オーダーログID"
        sortable={false}
        headerClassName={style.orderIDField}
      />
      <ImageField
        source="recipe.imageURL"
        label="画像"
        sortable={false}
        sx={{
          '& .RaImageField-image': {
            borderRadius: '4px',
            border: '1px solid #F1EEE9',
          },
          '& img': { maxWidth: 32, maxHeight: 32 },
          paddingRight: '12px',
        }}
        headerClassName={style.imageURLField}
      />
      <TextField
        source="recipe.id"
        label="レシピID"
        sortable={false}
        headerClassName={style.recipeIDField}
      />
      <TextField
        source="recipe.name"
        label="商品"
        sortable={false}
        headerClassName={style.recipeNamField}
      />
      <TextField
        source="store.name"
        label="店舗名"
        sortable={false}
        headerClassName={style.storeNameField}
      />
      <TextField
        source="machine.serial"
        label="マシン シリアル"
        sortable={false}
        headerClassName={style.machineSerialField}
      />
      <FunctionField
        render={(record: OrderLogRecord) =>
          record && format(parseISO(record.orderReceivedAt), 'yyyy/MM/dd HH:mm')
        }
        label="オーダー受領日時"
        sortable={false}
        headerClassName={style.orderReceivedAtField}
      />
    </ListDatagrid>
  )
}

export default OrderLogList
