import { useNotify, usePermissions } from 'react-admin'
import { Action, Resource } from '../types/authorization'
import { AuthPermission } from '../types/records/auth-permission_record'

export const useAuthorize = (resource: Resource, action: Action) => {
  const { permissions } = usePermissions<AuthPermission[]>()

  return permissions?.find(
    (permission) =>
      permission.resource === resource && permission.action === action,
  )
    ? true
    : false
}

export const useNotifyForbidden = (): (() => void) => {
  const notify = useNotify()

  return () => notify('権限がないため操作できません')
}
