import React from 'react'

import styles from './CipLogStatusField.module.scss'
import { CipLogRecord } from '../../types/records/cip-log-record'

type Props = {
  cipLog: CipLogRecord
}

const CipLogStatusField: React.FC<Props> = ({ cipLog }) => {
  if (!cipLog) {
    return null
  }

  return (
    <>
      {cipLog.approvedAt && (
        <span className={`${styles.base} ${styles.approved}`}>承認済</span>
      )}
      {!cipLog.approvedAt && !cipLog.closingOperationCompletedAt && (
        <span className={`${styles.base} ${styles.not_closing_operation}`}>
          閉店オペ未
        </span>
      )}
      {!cipLog.approvedAt && cipLog.closingOperationCompletedAt && (
        <span className={`${styles.base} ${styles.not_approved}`}>承認前</span>
      )}
    </>
  )
}

export default CipLogStatusField
