import React from 'react'
import { useRecordContext } from 'react-admin'

import styles from './CipLogMachineStatusField.module.scss'
import { CipLogMachineRecord } from '../../types/records/cip-log-record'

const CipLogMachineStatusField = () => {
  const record = useRecordContext<CipLogMachineRecord>()

  if (!record) {
    return null
  }

  return (
    <>
      {!record.isApproved && (
        <span className={`${styles.base} ${styles.not_is_approved}`}>
          承認前
        </span>
      )}
      {record.isApproved && (
        <span className={`${styles.base} ${styles.is_approved}`}>承認済</span>
      )}
    </>
  )
}

export default CipLogMachineStatusField
