import React from 'react'
import { Box, Grid } from '@mui/material'
import { SxProps, Theme } from '@mui/system'

type Props = {
  validateMassages: string[]
  sx?: SxProps<Theme> | undefined
}

const ValidateMessageInfo: React.FC<Props> = ({ validateMassages, sx }) => {
  if (validateMassages.length < 1) {
    return null
  }

  return (
    <Grid container direction="row" sx={sx}>
      <Box
        sx={{
          padding: '12px',
          backgroundColor: '#FDE8E8',
          borderRadius: '4px',
          width: '100%',
        }}>
        {validateMassages.map((validateMassage, index) => (
          <Box key={`message_${index}`}>
            <span style={{ color: '#C80000' }}>{validateMassage}</span>
          </Box>
        ))}
      </Box>
    </Grid>
  )
}

export default ValidateMessageInfo
